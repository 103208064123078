<template>
    <div class="color-scheme-tru cpoc-page cpc-home">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" :tru-search-link="truSearchLink" />

                <s-blog-cpc-bar
                    :links="menuLinks"
                    :title="translations.cpcTitle"
                    :lead="translations.cpcLead"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                />
                <s-blog-cpc-intro v-if="spotlightedPost" :spotlighted-card="spotlightedPost" />
            </div>
            <div class="cpoc-wrapper">
                <s-blog-cpc-insights
                    :cards="CPCNews"
                    :title="translations?.cpcInsightsTitle || 'Insights'"
                />
                <s-blog-cpc-cards
                    ref="scrollTo"
                    :title="translations.cpcCardsTitle"
                    :cards="latestCards"
                    :is-loading="isXHRInProgress"
                >
                    <template v-if="listTotalPages > 1" #pagination>
                        <a-pagination
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :url-template="pageUrl"
                            :scroll-to="$refs.scrollTo"
                            :click-handler="loadLatestCardsPage"
                        />
                    </template>
                </s-blog-cpc-cards>
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import APagination from '@core/components/pagination/pagination.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcCards from '@core/slices/blog/blog-cpc/blog-cpc-cards.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import SBlogCpcInsights from '@core/slices/blog/blog-cpc/blog-cpc-insights.vue';
import SBlogCpcIntro from '@core/slices/blog/blog-cpc/blog-cpc-intro.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/cpoc.pcss';

export default {
    name: 'BlogCPCHome',

    components: {
        SBlogCpcHeader,
        SBlogCpcBar,
        SBlogCpcIntro,
        SBlogCpcInsights,
        SBlogCpcCards,
        APagination,
        SGlobalFooter,
    },

    mixins: [blog, analytics, breakpoint],

    props: {
        dispatchLoadLatestCardsPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            isXHRInProgress: false,
            listCurrentPage: 1,
        };
    },

    computed: {
        // ==== Input params
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        pageUrl() {
            return `/${this.locale}/cyber-protection-center/page/$PAGE_NUMBER$/`;
        },

        newsLink() {
            return {
                title: this.translations.newsArchive,
                link: `/${this.locale}/cyber-protection-center/categories/news/`,
            };
        },

        // ==== Slices data
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
            };
        },

        truSearchLink() {
            return this.isOnEnLocale ? this.sliceDataHeader.truSearchLink : null;
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        // ==== Store mappers
        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },
        latestCards() {
            return (this.$store.state.blog.latestCards.items || []).map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },

        spotlightedPost() {
            const post = (this.$store.state.blog.spotlightedCPC.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        CPCNews() {
            return (this.$store.state.blog.CPCNews.items || []).map((item) => this.getBlogPostFields(item, this.translations));
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        async loadLatestCardsPage(page, callback) {
            this.isXHRInProgress = true;
            const success = await this.dispatchLoadLatestCardsPage(page);
            this.isXHRInProgress = false;
            if (success) {
                this.listCurrentPage = page;
            }
            callback();
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpoc-page {
    &:deep(.cpc-bar) {
        margin-bottom: 24px;
    }
    &:deep(.cpc-insights) {
        padding-top: 48px;
        margin-bottom: 40px;
        @media (--viewport-tablet) {
            padding-top: 32px;
        }
    }
}
</style>
