<template>
    <div class="menu-tab-solutions">
        <div class="large-wrapper">
            <menu-tab v-bind="$props" />
        </div>
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabSolutions',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-solutions {
    --aside-width: 100%;
    --aside-width-desktop: 276px;
    --aside-width-desktop-wide: 356px;
    --aside-width-desktop-large: 370px;
    @media (--viewport-desktop-wide) {
        background: linear-gradient(90deg, var(--av-fixed-info-accent) 50%, transparent 50%);
    }
    .large-wrapper {
        @media(--viewport-desktop-wide) {
            max-width: 1312px;
            margin: 0 auto;
            padding-inline-start: var(--container-paddings-desktop-large);
            padding-inline-end: var(--container-paddings-desktop-large);
        }
    }
    &:deep(.s-menu-tab) {
        display: flex;
        flex-flow: row wrap;
        .inner-tabs {
            width: 100%;
            padding: 0 16px 24px 16px;
            @media (--viewport-desktop) {
                padding: 24px 32px;
            }
            @media (--viewport-desktop) {
                width: auto;
                width: var(--aside-width-desktop);
                padding: 32px;
            }
            @media (--viewport-desktop-large) {
                padding: 32px 64px;
                padding-inline-start: 0;
            }
        }
        .s-menu-tab-wrapper {
            width: var(--aside-width);
            flex-grow: 1;
            @media (--viewport-desktop) {
                width: calc(100% - var(--aside-width-desktop));
            }
            @media (--viewport-desktop-wide) {
                background: white;
                width: calc(100% - var(--aside-width-desktop-wide));
            }
            @media (--viewport-desktop-large) {
                width: calc(100% - var(--aside-width-desktop-large));
            }
        }
        .s-menu-tab__body_type_solutions {
            justify-content: flex-start;
            gap: 16px;
            padding-inline: 32px;
            @media (--viewport-desktop) {
                padding-inline: 16px;
            }
            &:has(.s-menu-tab__section_tabindex_1) {
                @media (--viewport-desktop-wide) {
                    gap: 24px;
                }
            }
            .s-menu-tab__section_type_solutions.s-menu-tab__section_tabindex_0.s-menu-tab__section_divider_wide {
                grid-column-end: 3;
                grid-column-start: 1;
                .s-menu-tab__list {
                    display: grid;
                    grid-template-columns: 2fr;
                    @media (--viewport-tablet) {
                        grid-template-columns: 1fr 1fr;
                        gap: 16px;
                        padding-right: 16px;
                    }
                    @media (--viewport-desktop) {
                        gap: 16px 24px;
                        padding-right: 32px;
                    }
                }
            }
            .s-menu-tab__section_tabindex_1 {
                flex-grow: 1;
            }
        }
        &__section {
            &_divider_wide {
                &:not(:last-child) {
                    @media screen and (max-width: 767px) {
                        &::before {
                            display: none;
                        }
                    }
                }

                @media (--viewport-tablet) {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 24px;
                        inset-inline-start: 100%;
                        background: var(--av-brand-secondary-light);
                        width: 1px;
                        height: calc(100% - 108px);
                    }
                }
            }

        }
        &__list {
            @media (--viewport-tablet) {
                padding-right: 16px;
            }
            @media (--viewport-desktop) {
                padding-right: 32px;
            }
        }
        &__list-item-link,
        &__linkmore {
            .a-link__content {
                @mixin lead;

                @media (--viewport-tablet) {
                    @mixin paragraph;
                }
            }
        }

        &__linktitle {
            pointer-events: none;

            .a-link__content {
                @mixin title-accent;
                color: var(--av-nav-primary);

                @media (--viewport-tablet) {
                    @mixin lead-accent;
                }
            }
        }
    }
}
</style>
