<template>
    <div v-if="tableOfContents" class="table-of-contents" :class="{'has-sticky': hasStickyElement}">
        <div class="title-wrap" @click="toggleExpand()">
            <span>{{ tableOfContentsTitle }}</span>
            <a-glyph :name="isExpanded ? 'chevron-big-down' : 'chevron-big-right'" />
        </div>
        <template v-if="isExpanded">
            <div
                v-for="(item, tocIndex) in tableOfContents"
                :key="tocIndex"
                class="table-of-contents-item"
                :class="{active: lastActive?.anchor === item.anchor}"
                @click="scroll(item.anchor)"
                v-html="item.text"
            />
            <div v-if="socials" class="socials">
                <div class="social-label">
                    {{ shareTitle }}
                </div>
                <a-social class="social" :social="socials" />
            </div>
        </template>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ASocial from '@core/components/social/social.vue';
import breakpoints from '@core/mixins/breakpoint';

export default {
    components: {
        ASocial,
        AGlyph,
    },
    mixins: [breakpoints],
    props: {
        tableOfContentsTitle: {
            type: String,
            default: () => 'TOC',
        },

        body: {
            type: Object,
            required: true,
        },

        socials: {
            type: Array,
            default: () => [],
        },

        shareTitle: {
            type: String,
            default: 'Share',
        },
    },
    data() {
        return {
            isExpanded: true,
            hasStickyElement: false,
            lastActive: null,
            ACTIVE_OFFSET: 300,
            SCROLL_MARGIN: 80,
        };
    },
    computed: {
        tableOfContents() {
            const toc = this.body.blocks.find((block) => block.type === 'tableofcontentstool');

            if (!toc) return null;

            return this.body.blocks
                .filter(
                    (block) => block.type === 'header' && toc.data.levels.includes(block.data.level),
                )
                .map((block) => ({
                    text: block.data.text,
                    level: block.data.level,
                    anchor: block.id,
                }));
        },
    },
    mounted() {
        const hash = this.$route.hash;
        if (hash) this.scroll(hash);

        window.addEventListener('scroll', this.calculatePosition);
        window.addEventListener('resize', this.calculatePosition);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.calculatePosition);
        window.removeEventListener('resize', this.calculatePosition);
    },
    methods: {
        isActive(id) {
            const el = document.querySelector(`[id="${id}"]`);
            if (!el) return false;
            return el.offsetTop - this.ACTIVE_OFFSET < window.pageYOffset;
        },
        calculatePosition() {
            if (process.env.VUE_ENV === 'server' || !this.tableOfContents) return;
            this.hasStickyElement = Boolean(document.querySelector('.is-sticky'));
            const passedItems = this.tableOfContents.filter((item) => this.isActive(item.anchor));
            this.lastActive = passedItems.pop();
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        async scroll(hash) {
            if (!('scrollBehavior' in document.documentElement.style)) return;

            const el = document.querySelector(`[id="${hash}"]`);
            if (!el) return;
            if (!this.$route?.hash.includes(hash)) this.$router.push({ hash });
            this.SCROLL_MARGIN = this.calculateScrollMargin();

            await this.$nextTick();
            window.scroll({
                top: el.getBoundingClientRect().top + window.scrollY - this.SCROLL_MARGIN,
                behavior: 'smooth',
            });
        },
        calculateScrollMargin() {
            if (this.hasStickyElement) return 80;

            switch (this.currentBreakpoint) {
                case ('mobile'): return 390;
                case ('mobileWide'): return 320;
                case ('tablet'): return 270;
                case ('desktop'): return 245;
                default: return 200;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.table-of-contents {
    word-wrap: break-word;
    text-align: start;
    user-select: none;
    margin-bottom: 40px;

    @media (--viewport-desktop-wide) {
        position: sticky;
        grid-column-start: 1;
        top: 0;
        max-height: 100vh;
        padding: 24px 0;
        margin-top: 16px;
        margin-bottom: 0;
        overflow: auto;
        &.has-sticky {
            top: 64px;
            max-height: calc(100vh - 64px);
        }
    }

    .title-wrap {
        display: inline-flex;
        align-items: center;
        gap: 16px;
        @mixin paragraph;
        font-weight: 700;
        margin-bottom: 24px;
        color: var(--av-nav-primary);
        cursor: pointer;
        @media (--viewport-desktop) {
            justify-content: space-between;
        }

        &:is(h3, h4, h5, h6) {
            @mixin lead;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }

    .socials {
        display: none;
        @media (--viewport-desktop-wide) {
            display: flex;
            justify-content: flex-start;
        }
    }

    &:deep(.social) {
        .a-social {
            &__link {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0 8px;
            }

            &__icon {
                height: 16px;
                width: 16px;
                fill: var(--av-brand-secondary);
            }
        }
    }

    .social-label {
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        white-space: nowrap;
        margin-inline-end: 8px;
        display: inline-block;
        color: var(--av-fixed-primary);
        opacity: 0.9;
    }
}

.table-of-contents-item {
    @mixin body;
    margin-bottom: 16px;
    color: var(--av-brand-primary);
    cursor: pointer;
    &.active {
        font-weight: 700;
    }
}
</style>
