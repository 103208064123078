/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-purchasing-v2 .loader {
        z-index: 1;
        height: 400px;
        position: relative;
    }

.s-purchasing-v2 .a-slice-header {
        margin: 0;
        width: 100%;
    }

.s-purchasing-v2 .a-slice-header .a-slice-header__title {
            margin: 0;
            font-size: 32px;
            line-height: 40px;
        }

@media (min-width: 768px) {

.s-purchasing-v2 .a-slice-header .a-slice-header__title {
                font-size: 40px;
                line-height: 48px;
                font-weight: 700
        }
            }

.s-purchasing-v2 .a-slice-header .a-slice-header__lead {
            font-size: 24px;
            line-height: 32px;
        }

@media (min-width: 768px) {

.s-purchasing-v2 .a-slice-header .a-slice-header__lead {
                font-size: 32px;
                line-height: 40px;
        }
            }

.s-purchasing-v2 .a-slice-header .a-slice-header__lead {
            margin: 32px 0 0;
}

.s-purchasing-v2 .container {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
    }

.s-purchasing-v2 .description {
        display: flex;
        flex-direction: column;
    }

@media (min-width: 768px) {

.s-purchasing-v2 .description {
            width: 50%;
            margin-inline-end: auto;
            padding-inline-end: 16px
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .description {
            width: 100%;
            padding-inline-end: 0
    }
        }

.s-purchasing-v2 .message {
        margin: 8px 0 0;
    }

.s-purchasing-v2 .message .description {
            font-size: 16px;
            line-height: 24px;
            gap: 8px;
            width: 100%;
            display: flex;
            flex-direction: row;
            font-weight: 600;
            color: var(--av-fixed-success);
        }

.s-purchasing-v2 .message .description .a-glyph {
                min-width: 24px;
                fill: var(--av-fixed-success);
            }

.s-purchasing-v2 .message .text {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: var(--av-nav-primary);
        }

@media (min-width: 768px) {

.s-purchasing-v2 .cta {
            width: 50%;
            margin: 0;
            display: flex;
            min-width: auto;
            flex-direction: column
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .cta {
            width: 100%
    }
        }

.s-purchasing-v2 .product {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
    }

@media (min-width: 1024px) {

.s-purchasing-v2 .product {
            flex-wrap: nowrap
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .product {
            gap: 0;
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid var(--av-brand-light);
            box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1)
    }
        }

.s-purchasing-v2 .license {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        flex-direction: column;
        padding: 24px 16px 40px;
        border: 1px solid var(--av-brand-light);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        background-color: var(--av-inversed-primary);
    }

@media (min-width: 1024px) {

.s-purchasing-v2 .license:first-child {
                border: none
        }
            }

.s-purchasing-v2 .license:nth-child(even) .product-cloud .a-button {
                    flex: 1 0 0;
                }

@media (min-width: 1024px) {

.s-purchasing-v2 .license:nth-child(even) .product-cloud .a-button {
                        flex: 0 0 0
                }
                        .s-purchasing-v2 .license:nth-child(even) .product-cloud .a-button .a-button__content {
                            min-width: 68px;
                            text-align: center;
                        }
                    }

.s-purchasing-v2 .license:nth-child(even) .product-cloud .a-button .a-button__content {
                        padding: 0 8px;
                    }

@media (min-width: 768px) {

.s-purchasing-v2 .license {
            flex-direction: row
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .license {
            flex: 1 1 0;
            flex-direction: column;
            border: none;
            border-radius: 0;
            box-shadow: none;
            border-inline-start: 1px solid var(--av-brand-light)
    }
        }

@media (min-width: 1280px) {

.s-purchasing-v2 .license {
            padding: 24px 24px 40px
    }
        }

@media (min-width: 1440px) {

.s-purchasing-v2 .license {
            padding: 24px 40px 40px
    }
        }

.s-purchasing-v2 .name {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        gap: 8px;
        display: flex;
        align-items: center;
        color: var(--av-nav-primary);
    }

.s-purchasing-v2 .name .a-glyph {
            margin-inline-start: 16px;
            vertical-align: baseline;
        }

.s-purchasing-v2 .name .a-label {
            overflow: hidden;
            max-height: 16px;
        }

.s-purchasing-v2 .headline {
        font-size: 18px;
        line-height: 24px;
        margin-top: 4px;
        color: var(--av-nav-primary);
    }

.s-purchasing-v2 .type {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-top: 16px;
        color: var(--av-nav-primary);
    }

.s-purchasing-v2 .price-container {
        padding-top: 16px;
        position: relative;
        color: var(--av-nav-primary);
    }

@media (min-width: 768px) {

.s-purchasing-v2 .price-container {
            margin-top: auto;
            padding-bottom: 20px
    }
        }

.s-purchasing-v2 .price-item {
        display: flex;
        align-items: center;
    }

.s-purchasing-v2 .price {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
    }

.s-purchasing-v2 .price-full {
        font-size: 18px;
        line-height: 24px;
        min-height: 24px;
        color: var(--av-fixed-light);
        text-decoration-line: line-through;
    }

.s-purchasing-v2 .price-full-item {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }

.s-purchasing-v2 .price-label {
        margin: 0 8px;
        font-weight: bold;
        color: var(--av-fixed-success);
    }

.s-purchasing-v2 .price-for-year {
        font-size: 14px;
        line-height: 24px;
        min-height: 24px;
    }

.s-purchasing-v2 .price-for-year-full {
        color: var(--av-fixed-light);
        text-decoration: line-through;
    }

.s-purchasing-v2 .price-period {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin: 0 8px 8px;
        vertical-align: top;
    }

.s-purchasing-v2 .features {
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        padding-top: 32px;
        padding-bottom: 28px;
        color: var(--av-nav-primary);
    }

@media (min-width: 768px) {

.s-purchasing-v2 .features {
            width: 50%;
            padding-top: 24px;
            padding-inline-end: 16px
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .features {
            width: 100%;
            padding-top: 32px;
            padding-inline-end: 0
    }
        }

.s-purchasing-v2 .features-item {
        position: relative;
        padding-inline-start: 20px;
    }

.s-purchasing-v2 .features-item:not(:first-child) {
            margin-top: 12px;
        }

.s-purchasing-v2 .features-title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        padding: 0 0 4px;
    }

.s-purchasing-v2 .features-glyph {
        padding: 0;
        display: flex;
    }

.s-purchasing-v2 .features-glyph .a-glyph {
            min-width: 16px;
            margin-inline-end: 8px;
            fill: var(--av-nav-primary);
        }

.s-purchasing-v2 .benefits {
        width: 100%;
        margin: auto 0 0;
        padding-top: 28px;
        color: var(--av-nav-primary);
        border-top: 1px solid var(--av-brand-secondary-light);
    }

@media (min-width: 768px) {

.s-purchasing-v2 .benefits {
            width: 50%;
            margin-top: 0;
            padding-top: 64px;
            border-top: none
    }
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .benefits {
            width: 100%;
            margin-top: auto;
            padding-top: 28px;
            border-top: 1px solid var(--av-brand-secondary-light)
    }
        }

.s-purchasing-v2 .benefits-item {
        font-size: 12px;
        line-height: 16px;
        padding-inline-start: 24px;
    }

.s-purchasing-v2 .benefits-item:not(:first-child) {
            margin-top: 12px;
        }

.s-purchasing-v2 .benefits-glyph {
        display: flex;
    }

.s-purchasing-v2 .benefits-glyph .a-glyph {
            margin: 0;
            margin-inline-end: 8px;
            margin-inline-start: -24px;
            fill: var(--av-nav-primary);
        }

.s-purchasing-v2 .product-pc,
    .s-purchasing-v2 .product-cloud {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        flex-direction: row;
    }

.s-purchasing-v2 .product-pc .a-button, .s-purchasing-v2 .product-cloud .a-button {
            flex: 1 1 0;
            padding: 3px 0;
            border-radius: 8px;
        }

.s-purchasing-v2 .product-pc .a-button__content, .s-purchasing-v2 .product-cloud .a-button__content {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: var(--av-link-primary);
        }

.s-purchasing-v2 .product-pc .is-active, .s-purchasing-v2 .product-cloud .is-active {
            background: var(--av-brand-secondary-accent);
        }

.s-purchasing-v2 .product-pc .is-active .a-button__content, .s-purchasing-v2 .product-cloud .is-active .a-button__content {
                font-weight: 700;
                color: var(--av-nav-primary);
            }

.s-purchasing-v2 .product-pc .a-button {
            max-width: 33.33%;
        }

.s-purchasing-v2 .product-cloud .a-button:first-child .a-button__content {
                    padding: 0 16px;
                }

.s-purchasing-v2 .product-cloud .a-button_disabled {
            max-width: 100%;
            border-color: var(--av-button-secondary-light);
        }

@media (min-width: 1024px) {

.s-purchasing-v2 .product-cloud .a-button_disabled {
                max-width: 50%
        }
            }

.s-purchasing-v2 .product-cloud .a-button_disabled .a-button__content {
                color: var(--av-link-secondary-light);
            }

.s-purchasing-v2 .product-pc-title {
        font-size: 14px;
        line-height: 24px;
        color: var(--av-nav-primary);
        margin-top: 20px;
    }

@media (min-width: 768px) {

.s-purchasing-v2 .product-pc-title {
            margin-top: 0
    }
        }

.s-purchasing-v2 .product-cloud-title {
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color: var(--av-nav-primary);
    }

.s-purchasing-v2 .product-button {
        margin-top: 32px;
    }

.s-purchasing-v2 .product-button .a-button {
            width: 100%;
            max-height: 48px;
            justify-content: center;
        }

.s-purchasing-v2 .product-button .a-button .a-button__glyph {
                margin-inline-start: 8px;
            }

.s-purchasing-v2 .product-button .a-button.a-button_type_secondary {
                background: var(--av-brand-secondary-accent);
            }

@media (min-width: 1024px) {

.s-purchasing-v2 .product-button {
            margin-top: 32px
    }
        }

.s-purchasing-v2 .license-active {
        background: var(--av-solid-brand-lightest);
    }

.s-purchasing-v2 .info {
        margin-top: 40px;
    }

@media (min-width: 768px) {

.s-purchasing-v2 .info {
            gap: 16px;
            display: flex;
            flex-direction: row
    }
        }

.s-purchasing-v2 .info-item {
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

.s-purchasing-v2 .info-item:first-child {
            margin-top: 0;
        }

.s-purchasing-v2 .info-item b {
            font-weight: 600;
        }

.s-purchasing-v2 .info-item a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

@media (min-width: 768px) {

.s-purchasing-v2 .info-item {
            flex: 1 1 0;
            margin-top: 0
    }
        }

.s-purchasing-v2 .note {
        margin: 32px 0 0;
    }

.s-purchasing-v2 .note a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

@media (min-width: 1280px) {

.s-purchasing-v2 .items-2 {
            display: flex;
            flex-direction: row
    }
        }

@media (min-width: 1280px) {

.s-purchasing-v2 .items-2 .product {
                width: 66.66%
        }
            }

@media (min-width: 1024px) {

.s-purchasing-v2 .items-2 .license,
        .s-purchasing-v2 .items-2 .info-item {
                width: 50%
        }
            }

@media (min-width: 1280px) {

.s-purchasing-v2 .items-2 .note {
                margin: 0;
                width: 33.33%;
                padding-inline-start: 32px
        }
            }

@media (min-width: 1024px) {

.s-purchasing-v2 .items-3 .license,
        .s-purchasing-v2 .items-3 .info-item {
                width: 33.33%
        }
            }
