<template>
    <div class="blog-card" :class="className">
        <dynamic-link class="inner" :to="link" native-type="a">
            <div class="date top">
                <blog-date-localized :date="published_at" />
                <span>&nbsp;— {{ time_to_read }} {{ minReadTitle }}</span>
            </div>
            <div v-if="sectionLabel" class="section-label" :class="`section-label--type-${section_id}`">
                {{ sectionLabel }}
            </div>
            <div class="content">
                <a-picture
                    class="image"
                    :link="fixedImageId"
                    fit="cover"
                    size="blogCover"
                />
            </div>
            <div class="text-content">
                <div class="date bottom">
                    <blog-date-localized :date="published_at" />
                    <span>&nbsp;— {{ time_to_read }} {{ minReadTitle }}</span>
                </div>
                <v-clamp class="title" tag="div" :max-lines="maxTitleLines">
                    {{ title }}
                </v-clamp>
                <v-clamp class="text" tag="div" :max-lines="maxTextLines">
                    {{ description }}
                </v-clamp>
            </div>
        </dynamic-link>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import { SECTION_ID_BLOG } from '@model/const/blog.ts';
import BlogDateLocalized from '../blog-date-localized/blog-date-localized.vue';

const FallbackImages = [
    'bd62a55daae50fdec0d6a6b238034607',
    '30ed349a67c19cf8c26685d868f90f47',
    'e44479aa9902652a121944102811a7bd',
    '18a373b02d45b7c3ed01a5efacfff718',
    'f62a350b7e35dfe1e3dd057a635af90b',
    '74dd033305077b8ad219667201379601',
    '701297a7531d17743bf6f7c5f50e97c8',
    '8d171c9d5dce1ca04801e22dc732de75',
];

export default {
    name: 'BlogCard',
    components: {
        DynamicLink,
        APicture,
        VClamp,
        BlogDateLocalized,
    },

    mixins: [breakpoint],

    props: {
        /* eslint-disable vue/prop-name-casing */
        id: {
            type: Number,
            required: true,
        },

        published_at: {
            type: String,
            required: true,
        },

        time_to_read: {
            type: Number,
            required: true,
        },

        image_id: {
            type: String,
            default: null,
        },

        link: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: null,
        },

        layout: {
            type: String,
            default: 'default',
        },

        theme: {
            type: String,
            default: 'light',
        },

        minReadTitle: {
            type: String,
            required: true,
        },

        section_id: {
            type: Number,
            required: true,
        },

        showSectionLabel: {
            type: Boolean,
            default: false,
        },
        /* eslint-enable vue/prop-name-casing */
    },

    computed: {
        fixedImageId() {
            return this.image_id || this.fallbackImage;
        },

        className() {
            return {
                row: this.layout === 'row' && this.currentBreakpoint !== 'mobile',
                main: this.layout === 'main',
                wide: this.layout === 'main-wide',
                dark: this.theme === 'dark',
            };
        },

        maxTitleLines() {
            return this.layout === 'main' ? 3 : 2;
        },

        maxTextLines() {
            return this.layout === 'main' ? 6 : 3;
        },

        fallbackImage() {
            return FallbackImages[this.id % FallbackImages.length];
        },

        sectionLabel() {
            if (!this.showSectionLabel) return null;

            const translations = this.$store.state.slices.items.blog || {};

            if (this.section_id === SECTION_ID_BLOG) {
                return translations.homeTitle || 'Acronis blog';
            }

            return translations.cpcTitle || 'Secure MSP';
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-card {
    &.row {
        .inner {
            @media (--viewport-mobile-wide) {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
            }
        }

        .date {
            &.top {
                display: none;
            }
            &.bottom {
                display: block;
            }
            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
            }
        }

        .content {
            @media (--viewport-mobile-wide) {
                margin-inline-start: 24px;
                order: 2;
            }
        }

        .text-content {
            @media (--viewport-mobile-wide) {
                order: 1;
                flex-grow: 1;
            }
        }

        .image {
            margin-bottom: 0;

            @media (--viewport-mobile-wide) {
                width: 164px;
                height: 89px;
                margin-top: 16px;
            }

            @media (--viewport-desktop) {
                width: 228px;
                height: 124px;
                margin-top: 0;
            }

            @media (--viewport-desktop-wide) {
                width: 224px;
                height: 122px;
            }
        }

        .title {
            @media (--viewport-mobile-wide) {
                margin-bottom: 8px;
            }
        }
    }

    &.main {
        .date {
            &.top {
                display: none;
            }
            &.bottom {
                display: block;
            }
        }
        .inner {
            @media (--viewport-desktop) {
                display: grid;
                column-gap: 16px;
                grid-template-columns: 4fr 5fr;
            }

            @media (--viewport-desktop-wide) {
                grid-template-columns: 5fr 4fr;
            }

            @media (--viewport-desktop-large) {
                grid-template-columns: 4fr 5fr;
            }
        }

        .image {
            height: 189px;

            @media (--viewport-mobile-wide) {
                height: 267px;
            }

            @media (--viewport-desktop) {
                height: 178px;
            }

            @media (--viewport-desktop-wide) {
                height: 272px;
            }

            @media (--viewport-desktop-large) {
                height: 247px;
            }
        }

        .title {
            @mixin lead;

            @media (--viewport-desktop) {
                @mixin title;
            }
        }

        .text {
            @mixin body;
        }
    }

    &.wide {
        .date {
            &.top {
                display: none;
            }
            &.bottom {
                display: block;
            }
        }
        .inner {
            @media (--viewport-mobile-wide) {
                display: grid;
                column-gap: 16px;
                grid-template-columns: 5fr 7fr;
            }

            @media (--viewport-desktop) {
                grid-template-columns: 4fr 8fr;
            }

            @media (--viewport-desktop-wide) {
                grid-template-columns: 5fr 7fr;
            }

            @media (--viewport-desktop-large) {
                grid-template-columns: 4fr 8fr;
            }
        }

        .image {
            height: 189px;

            @media (--viewport-mobile-wide) {
                height: 163px;
                margin-bottom: 0;
            }

            @media (--viewport-desktop) {
                height: 178px;
            }

            @media (--viewport-desktop-wide) {
                height: 272px;
            }

            @media (--viewport-desktop-large) {
                height: 247px;
            }
        }

        .title {
            @mixin lead;

            @media (--viewport-desktop) {
                @mixin title;
            }
        }

        .text {
            @mixin body;
        }
    }

    &.dark {
        .date {
            color: #b0cbe4;
        }

        .text {
            color: var(--av-inversed-primary);
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        text-decoration: none;

        &:hover {
            .title {
                color: var(--av-brand-primary);
            }
        }
    }

    .date {
        font-size: 11px;
        line-height: 16px;
        color: var(--av-fixed-primary);
        opacity: 0.9;
        margin-bottom: 8px;

        &.bottom {
            display: none;
        }
    }

    .image {
        width: 100%;
        height: 189px;
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            height: 119px;
        }

        @media (--viewport-desktop) {
            height: 165px;
        }

        @media (--viewport-desktop-wide) {
            height: 152px;
        }

        @media (--viewport-desktop-large) {
            height: 175px;
        }
    }

    .title {
        @mixin paragraph;

        color: var(--av-brand-secondary);
    }

    .text {
        @mixin body;

        color: var(--av-fixed-primary);
    }

    .section-label {
        padding: 0 8px 1px;
        text-align: center;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 8px;
        border-radius: 4px;
        align-self: flex-start;

        &--type-1 {
            background: var(--av-nav-secondary);
            color: var(--av-inversed-primary);
        }

        &--type-2 {
            background: var(--av-brand-accent);
            color: var(--av-brand-primary);
        }
    }
}
</style>

<style lang="postcss">
.blog-card .image .a-picture__img {
    width: 100%;
    height: 100%;
    max-width: none;
}
</style>
