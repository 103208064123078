<template>
    <div class="life">
        <div class="a-container">
            <h2 class="title">
                {{ title }}
            </h2>
            <div class="cards">
                <dynamic-link
                    v-for="card in cards"
                    :key="card.title"
                    v-bind="card"
                    :text="card.title || card.text"
                    class="card"
                >
                    <a-picture class="bg" :link="card.image" fit="cover" />
                    <div class="card-title">
                        {{ card.title }}
                    </div>
                    <div class="text">
                        {{ card.text }}
                    </div>
                </dynamic-link>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SCareersLife',

    components: {
        APicture,
        DynamicLink,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        cards: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.life {
    @mixin basic-slice-paddings;
    color: var(--av-nav-primary);

    @media (--viewport-desktop) {
        padding-top: 64px;
        padding-bottom: 80px;
    }

    .title {
        @mixin hero-accent;

        text-align: center;
        margin-bottom: 40px;
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto;
        max-width: 464px;

        @media (--viewport-desktop) {
            flex-direction: row;
            max-width: none;
        }
    }

    .card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        aspect-ratio: 1.55;
        padding: 16px;
        color: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border-radius: 12px;

        @media (--viewport-tablet) {
            padding: 28px 24px;
            height: 300px;
            width: 100%;
            aspect-ratio: unset;
        }

        &:hover {
            box-shadow: none;
        }
    }

    .bg {
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid var(--av-brand-light);
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        height: 100%;
        width: 100%;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            height: 136px;
            background: linear-gradient(180deg, rgba(0, 32, 77, 0) 0%, rgba(1, 26, 62, 1) 71.61%);
        }
    }

    .card-title {
        @mixin lead-strong;

        position: relative;
        z-index: 1;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            @mixin title-accent;
        }
    }

    .text {
        @mixin body;

        position: relative;
        max-width: 408px;
        z-index: 1;
    }

    &:deep(.a-picture__img) {
        width: 100%;
        height: 100%;
    }
}
</style>
