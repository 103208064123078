<template>
    <div class="press-releases-home-pagination">
        <template v-if="isMounted">
            <div
                v-if="totalPages > maxVisiblePages && firstVisiblePage > 1"
                class="pr-first-page page-cell"
            >
                <el-select
                    v-if="firstVisiblePage > 2 && lowerRange.length > 0"
                    v-model="selectedPage"
                    name="page-upper-range"
                    popper-class="pr-page-dropdown"
                    :hide-on-resize="isDesktop"
                    :popper-max-width="popperSize.w"
                    :popper-max-height="popperSize.h"
                >
                    <el-option
                        v-for="page in lowerRange"
                        :key="page"
                        class="range-option"
                        :value="page"
                        :label="page"
                        force-visible
                    >
                        <a :href="getPageURL(page)" @click.stop.prevent="selectPage">{{ page }}</a>
                    </el-option>
                </el-select>
                <div class="page-number">
                    <a :href="getPageURL(1)" @click.stop.prevent="setPage(1)">1</a>
                </div>
            </div>

            <div
                v-for="(page, i) in visiblePages"
                :key="`${page}_${i}`"
                :data-page="page"
                class="pr-page page-cell"
                :class="{
                    'pr-page--first': i === 0,
                    'pr-page--last': i === (visiblePages.length - 1),
                    'pr-page--current': page === currentPage
                }"
            >
                <a :href="getPageURL(page)" @click.stop.prevent="setPage(page)">{{ page }}</a>
            </div>

            <div
                v-if="currentPage !== totalPages && lastVisiblePage !== totalPages"
                class="pr-last-page page-cell"
            >
                <div class="page-number">
                    <a :href="getPageURL(totalPages)" @click.stop.prevent="setPage(totalPages)">{{ totalPages }}</a>
                </div>
                <el-select
                    v-if="lastVisiblePage !== totalPages - 1 && upperRange.length > 0"
                    v-model="selectedPage"
                    name="page-upper-range"
                    popper-class="pr-page-dropdown pr-page-dropdown-last"
                    :hide-on-resize="isDesktop"
                    :popper-max-width="popperSize.w"
                    :popper-max-height="popperSize.h"
                >
                    <el-option
                        v-for="page in upperRange"
                        :key="page"
                        class="range-option"
                        :value="page"
                        :label="page"
                        force-visible
                    >
                        <a :href="getPageURL(page)" @click.stop.prevent="selectPage">{{ page }}</a>
                    </el-option>
                </el-select>
            </div>
        </template>
        <div v-else class="seo-pagination">
            <a v-for="page in totalPages" :key="page" :href="getPageURL(page)">
                {{ page }}
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import breakpoint from '@core/mixins/breakpoint.js';
import commonUtils from '@utils/common';

const MAX_VISIBLE_PAGES = 5;
const PAGES_OFFSET = 2;

export default {
    name: 'PressReleaseHomePagination',

    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },

    mixins: [breakpoint],

    props: {
        totalPages: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        urlTemplate: {
            type: String,
            required: true,
        },
    },

    emits: ['change'],

    data: () => ({
        isMounted: false,
        selectedPage: 0,
    }),

    computed: {
        locale() {
            return this.$route?.params.locale || 'en-us';
        },

        popperSize() {
            return { w: '66px', h: '256px' };
        },

        maxVisiblePages() {
            return Math.min(this.totalPages, MAX_VISIBLE_PAGES);
        },

        firstVisiblePage() {
            if (this.totalPages <= this.maxVisiblePages) return 1;

            if (this.currentPage === this.totalPages) return this.currentPage - this.maxVisiblePages + 1;

            return Math.max(this.currentPage - PAGES_OFFSET, 1);
        },

        lastVisiblePage() {
            if (this.currentPage <= this.maxVisiblePages / 2 + 1) return this.maxVisiblePages;

            const res = this.currentPage + PAGES_OFFSET;

            return Math.min(res, this.totalPages);
        },

        visiblePages() {
            const diff = this.lastVisiblePage - this.firstVisiblePage;

            const rangeMoreThanAvailable =
                this.lastVisiblePage > this.maxVisiblePages &&
                diff < (this.maxVisiblePages - 1) &&
                (this.lastVisiblePage - this.maxVisiblePages + 1) > 0;

            // fixing range with last visible page
            if (rangeMoreThanAvailable) {
                return commonUtils.range(this.lastVisiblePage - this.maxVisiblePages + 1, this.lastVisiblePage);
            }

            return commonUtils.range(this.firstVisiblePage, this.lastVisiblePage);
        },

        lowerRange() {
            return commonUtils.range(2, this.firstVisiblePage - 1);
        },

        upperRange() {
            return commonUtils.range(this.lastVisiblePage + 1, this.totalPages - 1).reverse();
        },
    },

    watch: {
        selectedPage(newValue) {
            if (!newValue) return;
            this.setPage(newValue);
        },
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        selectPage(event) {
            event?.currentTarget?.parentElement?.click();
        },

        setPage(pageNumber) {
            if (this.currentPage === pageNumber) return;
            this.$emit('change', pageNumber);
        },

        getPageURL(page) {
            return `/${this.locale}${this.urlTemplate}page/${page}/`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.press-releases-home-pagination {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    margin-bottom: 24px;
    padding: 0 36px;

    a {
        pointer-events: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 34px;
    }

    .el-select {
        width: 32px;
    }

    :deep(.el-input__wrapper) {
        display: none;
    }

    :deep(.el-input__container) {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.page-cell {
    background: rgba(255, 255, 255, 0.0001);
    border-radius: 4px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    a {
        pointer-events: all;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        width: 100%;
        color: var(--av-nav-primary);
    }
}

.pr-page {
    width: 32px;
    pointer-events: none;

    &--current {
        background: var(--av-brand-secondary-accent);
        cursor: default;
    }

    &--first {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.pr-first-page,
.pr-last-page {
    a {
        text-align: center;
        width: 30px;

        @media (--viewport-tablet) {
            width: 56px;
        }
    }

    .page-number {
        width: 56px;
    }

    &:deep(.el-select .el-input .el-input__icon) {
        color: var(--av-nav-primary);
    }
}

.pr-first-page {
    &:deep(.el-input__container) {
        border: 0;
        border-inline-end: 1px solid var(--av-brand-secondary-accent);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-inline-start: 0;
    }
}

.pr-last-page {
    &:deep(.el-input__container) {
        border: 0;
        border-inline-start: 1px solid var(--av-brand-secondary-accent);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-inline-end: 0;
    }
}

:deep(.el-select-dropdown__item a) {
    flex-shrink: 0;
}
</style>

<style lang="postcss">
.pr-page-dropdown .el-select-dropdown__list {
    overflow-x: hidden;
}

.el-select-dropdown__item.range-option {
    justify-content: center;
    width: 66px;
    overflow-x: hidden;
    padding: 0;
    @mixin paragraph;

    a {
        width: 100%;
        text-align: center;
        flex-shrink: 0;
        color: var(--av-fixed-secondary);
    }
}

.page-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
}

.pr-page-dropdown-last {
    transform: translateX(-33px);
}
</style>
