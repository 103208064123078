<template>
    <div class="blog-page blog-post">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <div class="blog-post-header">
                <s-main-header v-bind="sliceDataHeader" />
            </div>
            <blog-container class="head">
                <div v-if="isDraft" class="draft-warning-banner">
                    <a-glyph name="attention2" />
                    <span>{{ draftWarningMessage }}</span>
                    <a-glyph name="attention2" />
                </div>
                <blog-post-head
                    :author="author"
                    :title="post.title"
                    :categories="categories"
                    :published-at="post.published_at"
                    :parent-link="parentLink"
                    :parent-link-title="translations.backTitle"
                    theme="light"
                />
            </blog-container>
            <blog-container width="narrow">
                <template #outer-before>
                    <blog-post-toc
                        v-if="showToc"
                        :body="postBody"
                        :table-of-contents-title="translations.tableOfContents"
                        :socials="socialsShareLinks"
                        :share-title="translations.shareTitle"
                    />
                </template>
                <template #outer-after>
                    <blog-post-related-posts
                        v-if="showRelatedPosts"
                        :title="translations.similarArticlesTitle"
                        :posts="relatedPosts"
                    />
                </template>
                <blog-post-body
                    :socials="socialsShareLinks"
                    :previous-post-link="previousPostLink"
                    :next-post-link="nextPostLink"
                    :image-id="post.image_id"
                    :published-at="post.published_at"
                    :previous-post-title="translations.previousPost"
                    :next-post-title="translations.nextPost"
                    :author="author"
                    :reviewer="reviewer"
                    :tags="tags"
                    :locale="locale"
                    :path="path"
                    :body="postBody"
                    :table-of-contents-title="translations.tableOfContents"
                    :cta="ctaData"
                    :cta-product="ctaProduct"
                    :share-title="translations.shareTitle"
                    :read-progress="readProgress"
                />
            </blog-container>
            <blog-container width="narrow">
                <blog-subscribe
                    class="subscribe"
                    view="blog-view"
                    :title="translations.subscribeFormTitle"
                    :lead="translations.subscribeFormLead"
                    :button="translations.subscribe"
                    :email="translations.emailAddress"
                    :agreement="translations.subscribeAgreement"
                    :sample-text="translations.mailSampleText"
                    :success-title="translations.subscribeSuccessTitle"
                    :success-text="translations.subscribeSuccessText"
                    :back-to-form-title="translations.tryAgainTitle"
                    :articles="morePosts"
                />
            </blog-container>
            <blog-container class="more" theme="light-grey">
                <h3 ref="morePostsBlock" class="title">
                    {{ translations.morePostsTitle }}
                </h3>
                <blog-list :cards="morePosts" :show-only-one-row="true" />
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoints from '@core/mixins/breakpoint.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogPostBody from '@core/slices/blog/blog-post-body/blog-post-body.vue';
import BlogPostHead from '@core/slices/blog/blog-post-head/blog-post-head.vue';
import BlogPostRelatedPosts from '@core/slices/blog/blog-post-related-posts/blog-post-related-posts.vue';
import BlogPostToc from '@core/slices/blog/blog-post-toc/blog-post-toc.vue';
import BlogSubscribe from '@core/slices/blog/blog-subscribe/blog-subscribe.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import BlogList from '@core/slices/blog/shared-components/blog-list/blog-list.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogPost',

    components: {
        AGlyph,
        SMainHeader,
        SGlobalFooter,
        BlogPostHead,
        BlogPostToc,
        BlogPostBody,
        BlogContainer,
        BlogList,
        BlogSubscribe,
        BlogPostRelatedPosts,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, localeRibbon, breakpoints],

    props: {
        dispatchSendViewBeacon: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            readProgress: 0,
            SCROLL_MARGIN: 16,
        };
    },

    computed: {
        showToc() {
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },

        showRelatedPosts() {
            const hasPosts = this.relatedPosts.length > 0;
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint) && hasPosts;
        },

        hasSticky() {
            if (process.env.VUE_ENV === 'server') return false;

            return document.querySelector('.is-sticky');
        },

        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
                hasSubscribe: false,
            };
        },

        locale() {
            return this.$route.params.locale;
        },

        path() {
            return this.$route.path;
        },

        draftWarningMessage() {
            return this.$store.state.slices.items.blog.draftWarningMessage;
        },

        isDraft() {
            return !this.post.is_published;
        },

        post() {
            return this.$store.state.blog.post.items[0];
        },

        postBody() {
            return JSON.parse(this.post.body);
        },

        cta() {
            return (this.$store.state.blog.cta.items || [])[0] || {};
        },

        ctaData() {
            return (this.$store.state.blog.ctaData.items || [])[0] || {};
        },

        categories() {
            return this.post.categories
                .filter((x) => x.title) // there could be no translation for current locale
                .map((category) => this.getCategoryFields(category, this.locale));
        },

        tags() {
            return this.post.tags
                .filter((tag) => tag.title) // there could be no translation for current locale
                .map((tag) => ({
                    ...tag,
                    link: `/blog/tags/${tag.slug}`,
                }));
        },

        previousPostLink() {
            const prev = this.$store.state.blog.previousPost.items;
            return prev?.length ? prev[0].pathname : null;
        },

        nextPostLink() {
            const next = this.$store.state.blog.nextPost.items;
            return next?.length ? next[0].pathname : null;
        },

        author() {
            return this.getAuthorFields(this.post.author);
        },

        reviewer() {
            if (!this.post.editors?.length) return null;
            return this.getAuthorFields(this.post.editors[0]);
        },

        parentLink() {
            return '/blog/';
        },

        morePosts() {
            const posts = (this.$store.state.blog.morePosts.items || [])
                .filter((card) => card.id !== this.post.id)
                .map((card) => this.getBlogPostFields(card, this.translations));
            return posts.length > 4 ? posts.slice(0, 4) : posts;
        },

        relatedPosts() {
            const relatedPostsSettings = this.postBody.blocks.find((x) => x.type === 'relatedarticles')?.data;

            if (!relatedPostsSettings) return [];

            const posts = (this.$store.state.blog.relatedBlogPosts.items || [])
                .filter((card) => card.id !== this.post.id)
                .map((card) => this.getBlogPostFields(card, this.translations));

            const limit = relatedPostsSettings?.isAutomated ? 4 : 5;
            return posts.length > limit ? posts.slice(0, limit) : posts;
        },

        socialsShareLinks() {
            const title = this.post.title || '';
            const url = typeof window !== 'undefined' ? window.location : '';

            return this.socials.share.map((item) => ({
                name: item.name,
                link: item.link(encodeURIComponent(url), encodeURIComponent(title)),
            }));
        },

        ctaProduct() {
            return this.$store.state.products.items[this.cta.product_id];
        },
    },

    mounted() {
        document.addEventListener('scroll', this.onScroll);
        this.dispatchSendViewBeacon();
    },

    destroyed() {
        document.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll() {
            const docEl = document.documentElement;
            const postEl = this.$refs.morePostsBlock;

            const postContentEndline = postEl.getBoundingClientRect().top + docEl.scrollTop;
            const threshold = postContentEndline - window.innerHeight;

            this.readProgress = (docEl.scrollTop / threshold) * 100;
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-post-header {
    position: static;
    width: 100%;
    background-color: var(--av-inversed-primary);
}

.blog-post {
    position: relative;

    .head {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            height: 493px;
            background: linear-gradient(
                180deg,
                rgba(38, 104, 197, 0.075) 0%,
                rgba(38, 104, 197, 0) 100%
            );
            z-index: -1;

            @media (--viewport-tablet) {
                height: 709px;
            }
        }
    }

    .more {
        padding: 64px 0 72px;

        .title {
            @mixin hero;

            text-align: center;
            color: var(--av-nav-primary);
            margin-bottom: 40px;

            @media (--viewport-tablet) {
                text-align: start;
                font-weight: 700;
            }
        }
    }

    .subscribe {
        margin-bottom: 80px;
    }
}
</style>
