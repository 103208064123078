<template>
    <div v-if="isVisible" class="workloads">
        <div class="workload-list">
            <div
                v-for="(workload, workloadIndex) in settings.workloads"
                :key="`workload-${workloadIndex}`"
                :class="['workload', { 'workload-active': hasSelected(workload.name) }]"
                @click="$emit('changeWorkload', workload.name)"
            >
                <template v-if="workload.tooltip">
                    <a-tooltip :text="workload.tooltip" popper-class="el-tooltip__purchasing-workload">
                        <span v-html="workload.title" />
                    </a-tooltip>
                </template>
                <template v-else>
                    <span class="tooltip-wrapper" v-html="workload.title" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ATooltip from '@core/components/tooltip/tooltip.vue';
import mixin from '../mixin.js';

export default {
    name: 'SWorkloads',

    components: {
        ATooltip,
    },

    mixins: [mixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeWorkload', 'toggleCart'],

    computed: {
        isVisible() {
            return this.settings.workloads?.length;
        },
    },

    methods: {
        hasSelected(name) {
            return this.settings.workloads.find((item) => item.name === name).selected;
        },
    },
};
</script>

<style lang="postcss" scoped>
.workloads {
    color: var(--av-nav-primary);
    .workload-list {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        justify-content: center;
        @media (--viewport-tablet) {
            gap: 4px;
            margin-top: 16px;
        }
        @media (--viewport-desktop-wide) {
            gap: 16px;
        }
    }
    .workload {
        overflow: hidden;
        user-select: none;
        &:deep(.tooltip-wrapper) {
            @mixin caption-accent;
            padding: 8px 14px;
            display: flex;
            border-radius: 24px;
            color: var(--av-brand-primary);
            border: 2px solid var(--av-brand-light);
            &:hover {
                background-color: var(--av-brand-secondary-accent);
            }
            @media (--viewport-desktop-wide) {
                @mixin body-accent;
            }
        }
    }
    .workload-active {
        &:deep(.tooltip-wrapper) {
            background-color: var(--av-brand-secondary-accent);
        }
    }
}
</style>

<style lang="postcss">
.el-tooltip__purchasing-workload {
    max-width: 230px;
    b {
        font-weight: 700;
    }
}
</style>
