/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-documentation {
    color: var(--av-nav-primary);
}

.s-documentation .a-slice-header {
        margin-bottom: 48px;
    }

.s-documentation__title {
        font-size: 32px;
        line-height: 40px;
    }

@media (min-width: 1280px) {

.s-documentation__title {
            font-size: 40px;
            line-height: 48px;
    }
        }

.s-documentation__title {

        padding: 0 0 24px;
}

.s-documentation__product {
        padding: 24px 0 0;
    }

.s-documentation__product-name {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }

@media (min-width: 1280px) {

.s-documentation__product-name {
            font-size: 24px;
            line-height: 32px;
            font-weight: 700
    }
        }

.s-documentation__licenses {
        margin: 24px 0;
        padding: 0 0 24px;
        border-bottom: 1px solid var(--av-brand-secondary-accent);
    }

.s-documentation__license-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
    }

.s-documentation__documents {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0;
        justify-content: flex-start;
    }

.s-documentation__documents:not(:first-child) {
            margin: 16px 0 0;
        }

@media (min-width: 1280px) {

.s-documentation__documents {
            flex-wrap: nowrap
    }
        }

.s-documentation__document-name {
        width: 100%;
    }

@media (min-width: 1280px) {

.s-documentation__document-name {
            padding-inline-start: 8px
    }
        }

.s-documentation__document-rn,
    .s-documentation__document-file,
    .s-documentation__document-link {
        width: 100%;
    }

.s-documentation__document-rn .a-link, .s-documentation__document-file .a-link, .s-documentation__document-link .a-link {
            margin: 16px 0 0;
        }

@media (min-width: 1280px) {

.s-documentation__document-rn .a-link, .s-documentation__document-file .a-link, .s-documentation__document-link .a-link {
                margin: 0
        }
            }

.s-documentation__document-rn .text, .s-documentation__document-file .text, .s-documentation__document-link .text {
            font-size: 14px;
            line-height: 24px;
            display: none;
        }

@media (min-width: 1280px) {

.s-documentation__document-rn .text, .s-documentation__document-file .text, .s-documentation__document-link .text {
                opacity: 0;
                display: block;
                padding-inline-start: 24px
        }
            }

@media (min-width: 1024px) {

.s-documentation__document-rn,
    .s-documentation__document-file,
    .s-documentation__document-link {
            width: auto
    }

            .s-documentation__document-rn .a-link, .s-documentation__document-file .a-link, .s-documentation__document-link .a-link {
                margin-inline-end: 32px;
            }
        }

@media (min-width: 1280px) {

.s-documentation__document-rn,
    .s-documentation__document-file,
    .s-documentation__document-link {
            margin-inline-start: 24px;
            white-space: nowrap
    }

            .s-documentation__document-rn .a-link, .s-documentation__document-file .a-link, .s-documentation__document-link .a-link {
                margin-inline-end: 0;
            }
        }
