<template>
    <s-purchasing-cyber-protect-home-office-v2 v-if="isVariantB" v-bind="$props" />
    <s-purchasing-cyber-protect-home-office-v1 v-else v-bind="$props" />
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import mixin from './mixin.js';
import SPurchasingCyberProtectHomeOfficeV1 from './version-1/index.vue';
import SPurchasingCyberProtectHomeOfficeV2 from './version-2/index.vue';

export default {
    name: 'SPurchasingCyberProtectHomeOffice',
    components: {
        SPurchasingCyberProtectHomeOfficeV1,
        SPurchasingCyberProtectHomeOfficeV2,
    },
    mixins: [purchasing, analytics, mixin, contentMixin, styleMixin],
    props: {
        purchasePageCTA: {
            type: [Object, Boolean],
            default: false,
        },
        singleSlice: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            default: null,
        },
        information: {
            type: Array,
            default: null,
        },
        productCards: {
            type: Object,
            default: null,
        },
    },
    async serverPrefetch() {
        await this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: this.locale });
        await this.findCleverBridgeCart(this.pageProductCards.items, { ...this.purchasePageCTA, isPurchase: true });
    },
};
</script>

<style lang="postcss">
    .s-purchasing-single {
        padding-top: 128px;
    }
</style>
