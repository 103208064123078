<template>
    <main :class="['main-page', {'main-page-skeleton': isLoading}]">
        <template v-for="(item, itemIndex) in list">
            <component
                :is="`s-${item.name}`"
                :key="`s-${itemIndex}`"
                :content="{...item, tabActive}"
                @setTabActive="setTabActive"
            />
        </template>
    </main>
</template>

<script>
import { merge } from 'lodash';
import SAcademy from './slices/academy.vue';
import SAnnounce from './slices/announce.vue';
import SAnnounces from './slices/announces.vue';
import SAwards from './slices/awards.vue';
import SBlog from './slices/blog.vue';
import SCasestudy from './slices/casestudy.vue';
import SEventCountdown from './slices/countdown.vue';
import SHeader from './slices/header/header-v2.vue';
import SPartners from './slices/partners.vue';
import SPlatform from './slices/platform.vue';
import SProducts from './slices/products.vue';
import SServices from './slices/services.vue';
import SStats from './slices/stats.vue';

export default {
    name: 'SMainPage',
    components: {
        SAcademy,
        SAnnounce,
        SAnnounces,
        SAwards,
        SBlog,
        SCasestudy,
        SHeader,
        SPartners,
        SPlatform,
        SServices,
        SProducts,
        SStats,
        SEventCountdown,
    },
    props: {
        products: {
            type: Object,
            default: null,
        },
        header: {
            type: Object,
            default: null,
        },
        slices: {
            type: Array,
            default: null,
        },
        translations: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            tabActive: 0,
            isLoading: true,
        };
    },
    computed: {
        list() {
            return merge(this.slices, this.translations);
        },
        productsList() {
            return this.list.find((item) => item.name === 'products')?.list || [];
        },
    },
    beforeMount() {
        const hash = window.location.hash.slice(1);
        const hashID = this.productsList.findIndex((item) => item.hash === hash);

        if (hashID !== -1) {
            this.changeTab(hashID);
            return;
        }

        const mainPageProductID = localStorage.getItem('main-page-product-id');
        if (!mainPageProductID) return;

        this.changeTab(parseInt(mainPageProductID, 10));
        this.changeHash();
    },
    mounted() {
        this.isLoading = false;
    },
    methods: {
        setTabActive(id) {
            if (this.tabActive === id) return;
            this.changeTab(id);
            this.changeHash();
        },
        changeTab(id) {
            this.tabActive = id;
            localStorage.setItem('main-page-product-id', id);
        },
        changeHash() {
            const hash = this.productsList?.[this.tabActive]?.hash;
            if (!this.$router || !hash) return;
            this.$router.push({ hash: `#${hash}` });
        },
    },
    async serverPrefetch() {
        const uniqueAwards = new Set(
            this.slices
                .filter((slice) => slice.name === 'awards' && Array.isArray(slice.list))
                .map((slice) => slice.list)
                .flat(),
        );

        const awardsPromises = Array
            .from(uniqueAwards)
            .map((id) => this.$store.dispatch('awards/getAward', id));

        await Promise.all(awardsPromises);
    },
};
</script>

<style lang="postcss" scoped>
.main-page {
    padding: 0 0 48px;
}

.main-page-skeleton {
    position: relative;
    &:before {
        top: 0;
        left: 0;
        z-index: 1;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &:deep(.products) {
        .item {
            &.is-active {
                border-color: #063B89;
                color: var(--av-inversed-primary);
                background: rgba(13, 86, 195, 0.40);
                .button {
                    &:after {
                        display: none;
                    }
                    .a-glyph {
                        fill: var(--av-inversed-primary);
                    }
                }
            }
        }
    }
    &:deep(.main-page-cta),
    &:deep(.main-page-header),
    &:deep(.main-page-services),
    &:deep(.main-page-products .name) {
        .a-glyph,
        .background {
            display: none;
        }
        .info,
        .title,
        .promo,
        .a-label,
        .a-button,
        .a-picture,
        .name-glyph,
        .description,
        .main-page-cta-title {
            border: none;
            outline: none;
            position: relative;
            .content {
                opacity: 0;
            }
            &:before {
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 4px;
                background: #EDF3FC;
            }
        }

        .name-glyph {
            min-width: 32px;
            min-height: 32px;
            &:before {
                border-radius: 50%;
                background: #03285d;
            }
        }

        .a-tabs {
            .a-tabs-item_active {
                background: transparent;
            }
        }
    }
}
</style>
