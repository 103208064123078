<template>
    <main class="page-container">
        <s-academy-ribbon v-if="page.showRibbon && ribbon" v-bind="ribbon" />
        <template v-if="page.header">
            <s-header-academy v-if="page.header" v-bind="page.header">
                <template #menu>
                    <s-academy-menu v-bind="header" />
                </template>
                <template v-if="page.video?.ytId" #video>
                    <div class="video">
                        <div v-modal="page.video.ytId" class="video-wrap">
                            <button class="video-play" type="button">
                                <a-glyph class="play-icon" name="play" />
                            </button>
                        </div>
                    </div>
                </template>
            </s-header-academy>
            <a-modal :id="page.video.ytId" type="youtube" is-youtube>
                <a-youtube :yt-id="page.video.ytId" :params="page.video.ytParams || {}" />
            </a-modal>
        </template>

        <s-features-large v-if="page.features" v-bind="page.features" />

        <s-basic-slice v-if="isLoaded" id="courses" v-bind="$attrs">
            <div class="section-container">
                <template v-if="filteredList && filteredList.length">
                    <div class="section-container-menu">
                        <el-menu
                            v-if="isDesktop"
                            type="secondary"
                            background="inversed-primary"
                            :value="activeCategoryID"
                            @select="onChangeCategory"
                        >
                            <el-menu-item
                                v-for="(item, itemIndex) in categories"
                                :key="`list-category-${itemIndex}`"
                                :index="item.order"
                            >
                                {{ translation(item.name) }}
                                <a-glyph name="breadcrumb-chevron-right" size="s" />
                            </el-menu-item>
                        </el-menu>
                        <el-select
                            v-else
                            v-model="activeCategoryID"
                            :hide-on-resize="isDesktop"
                            @change="onChangeCategory"
                        >
                            <el-option
                                v-for="(item, itemIndex) in categories"
                                :key="`list-option-${itemIndex}`"
                                :label="item.name"
                                :value="item.order"
                            />
                        </el-select>
                        <el-search v-model="searchText" :placeholder="translation('Search course')" />
                    </div>
                </template>

                <div class="section-container-courses">
                    <p v-if="activeCategoryTitle" class="courses-title">
                        {{ activeCategoryTitle }}
                    </p>
                    <p
                        v-if="activeCategoryDescription"
                        class="courses-description"
                        v-html="activeCategoryDescription"
                    />
                    <template v-if="listCoursesPage.length">
                        <div class="list-courses">
                            <a-small-card
                                v-for="(item, itemIndex) in listCoursesPage"
                                :key="`small-card-${itemIndex}`"
                                v-bind="item.card"
                            />
                        </div>
                        <Pagination
                            v-if="isPaginationVisible"
                            url-template="/$LOCALE$/catalogue?page=$PAGE_NUMBER$"
                            :current-page="pageCurrent"
                            :total-pages="totalPages"
                            :click-handler="onPaginationChange"
                        />
                    </template>
                    <div v-else class="list-courses-not-found">
                        <a-glyph name="search" size="l" />
                        <p class="list-courses-not-found-title">
                            {{ translation('notFoundTitle') }}
                        </p>
                        <p class="list-courses-not-found-description">
                            {{ translation('notFoundDescription') }}
                        </p>
                    </div>
                </div>
            </div>
        </s-basic-slice>

        <div class="reviews">
            <s-product-reviews v-if="page.reviews" v-bind="page.reviews" />
        </div>
        <s-cta-cards v-if="page.cta?.cards" v-bind="page.cta" />
        <s-academy-footer v-if="footer" v-bind="footer" />
    </main>
</template>

<script>
import { orderBy } from 'lodash';
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import AModal from '@core/components/modal/modal.vue';
import Pagination from '@core/components/pagination/pagination.vue';
import ASmallCard from '@core/components/small-card/small-card.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import SAcademyFooter from '@core/slices/academy/components/academy-footer.vue';
import SAcademyMenu from '@core/slices/academy/components/academy-menu.vue';
import SAcademyRibbon from '@core/slices/academy/components/academy-ribbon.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SCtaCards from '@core/slices/pages/cta-cards/cta-cards.vue';
import SFeaturesLarge from '@core/slices/pages/features-large/features-large.vue';
import SHeaderAcademy from '@core/slices/pages/header-academy/header-academy.vue';
import SProductReviews from '@core/slices/pages/product-reviews/product-reviews.vue';

const STORE_SLICE_NAME = 's-academy-acronis-com';

export default {
    name: 'AcademyCatalogue',

    components: {
        AGlyph,
        ASmallCard,
        AYoutube,
        Pagination,
        SBasicSlice,
        SCtaCards,
        SAcademyRibbon,
        SAcademyMenu,
        SFeaturesLarge,
        SAcademyFooter,
        SHeaderAcademy,
        SProductReviews,
        AModal,
        ElMenu: () => import('@uikit/ui-kit/packages/menu'),
        ElMenuItem: () => import('@uikit/ui-kit/packages/menu-item'),
        ElSearch: () => import('@uikit/ui-kit/packages/search'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },

    directives: {
        Modal,
    },

    mixins: [breakpoint],

    props: {
        example: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            pageSize: 1,
            pageCurrent: 1,
            searchText: '',
            categories: [],
            filteredList: [],
            isLoaded: false,
            activeCategoryID: '0',
            allCourses: 'All courses',
        };
    },

    computed: {
        ...mapState({
            courses: (state) => state.academy.courses,
            preset: (state) => state.slices.items?.[STORE_SLICE_NAME] || {},
        }),

        ribbon() {
            return this.preset?.defaults?.ribbon;
        },

        page() {
            return this.preset?.template?.catalogue || {};
        },

        header() {
            const fromDefaults = this.preset?.defaults?.header;
            const fromPage = this.page?.header;
            return { ...fromDefaults, ...fromPage };
        },

        footer() {
            const fromDefaults = this.preset?.defaults?.footer;
            const fromPage = this.page?.footer;
            return { ...fromDefaults, ...fromPage };
        },

        activeCategoryName() {
            return this.categories.find((item) => item.order === this.activeCategoryID)?.name || this.allCourses;
        },

        activeCategoryTitle() {
            return this.translation(this.activeCategoryName);
        },

        activeCategoryDescription() {
            return this.page?.courses?.description?.[this.activeCategoryName] || '';
        },

        pagination() {
            return {
                'page-size': this.pageSize,
                'current-page': this.pageCurrent,
                total: this.listCourses.length,
            };
        },

        isPaginationVisible() {
            return this.listCourses.length > this.pageSize && this.filteredList.length > this.pageSize;
        },

        listCourses() {
            let list = this.filteredList;
            if (this.activeCategoryID !== '0') {
                const order = parseInt(this.activeCategoryID, 10);
                const comparison = (item) => item.order === order;
                list = list.filter((course) => course.showcases.find(comparison));
            }
            if (!this.searchText) return list;
            return list.filter((item) => JSON
                .stringify({ title: item.name, description: item.description, code: item.code })
                .toLowerCase()
                .includes(this.searchText.toLowerCase()));
        },

        listCoursesPage() {
            if (!this.listCourses || this.listCourses.length === 0) return [];

            const start = (this.pageCurrent - 1) * this.pageSize;
            return this.listCourses.slice(start, start + this.pageSize);
        },

        totalPages() {
            return Math.max(1, Math.ceil(this.listCourses.length / this.pageSize));
        },
    },

    beforeMount() {
        const list = this.example || this.courses;
        // Read page from the URL query
        const pageFromQuery = parseInt(this.$route.query.page, 10);
        this.pageCurrent = Number.isNaN(pageFromQuery) || pageFromQuery < 1 ? 1 : pageFromQuery;

        if (!list) return;

        this.pageSize = this.page.settings?.pagination?.size || 3;
        const catalogueOrder = this.preset?.settings?.catalogue || [];

        this.filteredList = list
            .filter((item) => item.showcases)
            .map((item) => {
                const matchingCatalogue = catalogueOrder.find((c) => c.id === item.id) || {};

                const result = {
                    ...item,
                    order: matchingCatalogue.order || 999,
                    card: {
                        image: item.imageUrl || 'd5cc324cc1ef105952af433103c3dd1d',
                        title: item.name,
                        text: item.description,
                        labels: item.labels || item.showcases.map((showcase) => ({
                            text: this.translation(showcase.name.replace('MSP ', '')),
                            variant: 'info',
                        })),
                    },
                };
                if (item.code) {
                    result.card.labels.push({
                        text: item.code,
                        variant: 'success',
                    });
                }
                if (item.id) {
                    const slug = matchingCatalogue.slug || item.slug || '#';
                    result.card.buttons = [{
                        to: `/catalogue/${slug}/`,
                        text: this.translation('Learn more'),
                    }];
                }

                return result;
            });

        this.filteredList = orderBy(this.filteredList, 'order', 'desc');

        const comparison = (item) => ({ ...item, order: item.order.toString(), title: this.translation(item.name) });
        const showcases = this.filteredList.map((course) => course.showcases.map(comparison)).flat();
        showcases.unshift({ order: '0', name: this.allCourses, title: this.translation(this.allCourses) });
        this.categories = [...new Map(showcases.map((item) => [item.order, item])).values()];
        this.categories = orderBy(this.categories, 'order', 'asc');
    },

    mounted() {
        this.isLoaded = true;
    },

    methods: {
        translation(text) {
            return this.preset?.translation?.[text] || text;
        },

        onChangeCategory(id) {
            this.pageCurrent = 1;
            this.activeCategoryID = id;
        },

        onPaginationChange(pageNum, callback = () => {}) {
            if (pageNum === this.pageCurrent) return;

            this.pageCurrent = pageNum;

            // Update the URL query without reloading the page
            this.$router.push({ query: { ...this.$route.query, page: pageNum } });

            callback();
            document.getElementById('courses')?.scrollIntoView({ behavior: 'smooth' });
        },

        onPaginationClick(type) {
            const total = Math.ceil(this.listCourses.length / this.pageSize);
            if (type === 'previous') {
                this.pageCurrent = this.pageCurrent === 1 ? total : --this.pageCurrent;
                return;
            }
            this.pageCurrent = this.pageCurrent === total ? 1 : ++this.pageCurrent;
        },
    },

    async serverPrefetch() {
        const locale = this.$route?.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale });
    },
};
</script>

<style lang="postcss" scoped>
.page-container {
    .video {
        height: 264px;
        display: flex;
        margin-top: 32px;
        align-items: center;
        justify-content: center;

        &:deep(.video-play) {
            width: 64px;
            height: 64px;
            display: flex;
            cursor: pointer;
            border-radius: 32px;
            align-items: center;
            justify-content: center;
            background: var(--av-brand-secondary);
            border: 7px solid var(--av-inversed-light);

            @media (--viewport-tablet) {
                width: 82px;
                height: 82px;
                border-width: 9px;
                border-radius: 40px;
            }

            &:hover {
                background-color: var(--av-brand-primary);
                border-color: var(--av-inversed-secondary);
            }

            .a-glyph {
                width: 24px;
                height: 24px;
                fill: var(--av-inversed-primary);
            }
        }

        @media (--viewport-tablet) {
            height: 438px;
        }

        @media (--viewport-desktop) {
            margin: 0;
            width: 30%;
            height: auto;
            display: block;
            margin-bottom: 140px;
        }
    }

    &:deep(.s-header-academy) {
        z-index: unset;
        min-height: unset;
        text-align: center;
        padding: 138px 0 32px;

        @media (--viewport-desktop) {
            text-align: start;
            padding-top: 152px;
        }

        @media (--viewport-desktop-wide) {
            padding-top: 180px;
        }

        .title {
            @mixin display-accent;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
                max-width: 75%;
            }
        }

        .bottom {
            margin-top: 48px;

            @media (--viewport-desktop) {
                margin-top: 64px;
            }

            @media (--viewport-desktop-wide) {
                margin-top: 92px;
            }
        }

        .button {
            height: 40px;
            display: flex;
            padding: 0 16px;
            align-items: center;
        }

        .main-inner {
            @media (--viewport-desktop) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: row;
            }
        }

        .main-content {
            @media (--viewport-desktop) {
                width: 70%;
            }
        }

        .bg-container {
            margin: 0;
            bottom: 0;
            height: 296px;
            position: absolute;

            @media (--viewport-tablet) {
                height: 468px;
            }

            @media (--viewport-desktop) {
                top: 0;
                height: 100%;
            }

            @media (--viewport-desktop-large) {
                top: 0;
                inset-inline-start: 0;
                width: 100%;
                max-width: none;
            }

            &:before {
                top: 0;
                inset-inline-start: 0;
                z-index: 1;
                content: '';
                width: 100%;
                height: 90px;
                position: absolute;
                background: linear-gradient(180deg, #00204d, transparent);

                @media (--viewport-desktop) {
                    content: none;
                }
            }

            &:after {
                top: 0;
                inset-inline-start: -1px;
                content: '';
                width: 70%;
                height: 100%;
                position: absolute;
                background: linear-gradient(90deg, #00204d, transparent);

                @media (--viewport-desktop) {
                    content: none;
                }
            }
        }

        .bg {
            inset-inline-start: -16px;
            width: calc(100% + 32px);

            @media (--viewport-mobile-wide) {
                inset-inline-start: -32px;
                width: calc(100% + 64px);
            }

            @media (--viewport-desktop-large ) {
                width: 1440px;
                margin: 0 auto;
            }

            .a-picture__img {
                object-fit: cover!important;

                @media (--viewport-tablet) {
                    object-position: center top !important;
                }
            }
        }

        .cta {
            margin-bottom: 24px;
        }
    }

    &:deep(.a-slice-header) {
        .a-slice-header__title {
            font-weight: 700;
        }
    }

    &:deep(.s-cta-cards) {
        margin-top: auto;

        +.s-global-footer {
            margin-top: 0;
        }

        .s-cta-cards__logo {
            margin-bottom: 8px;
        }
    }
}

.section-container {
    @media (--viewport-desktop) {
        gap: 32px;
        display: flex;
    }
}

.pagination {
    display: flex;
    margin-top: 48px;
    align-items: center;
    justify-content: center;
    &:deep(.a-glyph) {
        margin: 0 8px;
        fill: var(--av-brand-primary);
    }
    &:deep(.el-pagination) {
        .el-pager {
            margin: 0;
        }
        .el-pager__item {
            border: none;
            border-radius: 4px;
            &:focus {
                border: none;
            }
        }
        .el-dropdown {
            display: none;
        }
    }
}

.pagination-arrow {
    display: flex;
    cursor: pointer;
}

.list-courses-not-found {
    text-align: center;
    padding: 48px 0 32px;
}

.list-courses-not-found-title {
    @mixin title;
    margin-top: 16px;
    margin-bottom: 8px;
}

.section-container-menu {
    max-width: 344px;

    @media (--viewport-desktop) {
        min-width: 294px;
        max-width: unset;
    }

    &:deep(.el-menu) {
        border-top: 1px solid var(--av-brand-secondary-light);
        border-bottom: 1px solid var(--av-brand-secondary-light);

        .el-menu-item {
            @mixin paragraph-accent;
            display: flex;
            padding: 16px;
            cursor: pointer;
            min-height: unset;
            color: var(--av-brand-primary);
            border-top-color: var(--av-brand-secondary-light);

            &:first-child {
                min-height: unset;
            }
            &:last-child {
                box-shadow: none;
            }
            &:before {
                height: auto;
            }
            &:hover {
                background: none;
            }
            &:focus,
            &.is-active {
                background-color: var(--av-brand-secondary-accent);
            }
            .a-glyph {
                fill: var(--av-brand-primary);
            }
        }

        .el-menu__bar {
            padding: 0;
        }

        .el-text {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
    }

    &:deep(.el-select) {
        .el-input__editor {
            @mixin paragraph-accent;
            color: var(--av-brand-primary);
        }
    }

    &:deep(.el-search) {
        margin-top: 40px;
    }
}

.section-container-courses {
    width: 100%;
    margin-top: 48px;

    @media (--viewport-desktop) {
        margin-top: 0;
    }
}

.list-courses {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    &:deep(.a-small-card) {
        height: auto;
        border-radius: 8px;
        padding: 0 16px 32px;

        @media (--viewport-tablet) {
            width: calc(50% - 8px);
        }

        @media (--viewport-desktop-wide) {
            width: calc(33.33% - 12px);
        }

        .labels {
            margin-bottom: 16px;
        }

        .text {
            margin-top: 16px;
        }

        .a-picture-image {
            height: 192px;
            margin: 0 -16px 16px;
        }
    }
}

.courses-title {
    @mixin display-accent;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        @mixin hero-accent;
    }
}

.courses-description {
    @mixin body;
    margin-top: 16px;
}
</style>
