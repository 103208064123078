/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.ribbon {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color linear 0.2s;
}

@media (min-width: 768px) {

.ribbon {
        height: 56px
}
    }

.ribbon .content {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
    }

.ribbon .content .label.primary,
        .ribbon .content .cta {
            display: inline;
        }

@media(min-width: 768px) {

.ribbon .content {
            font-size: 14px
    }
        }

.ribbon .content {

        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding: 16px;
        text-decoration: none;
        color: var(--av-nav-primary);
        transition: color linear 0.2s;
}

@media (min-width: 576px) {

.ribbon .content {
            flex-direction: row;
            justify-content: left;
            align-items: center;
            display: flex
    }
        }

@media (min-width: 768px) {

.ribbon .content {
            justify-content: center
    }
        }

.ribbon .label {
        margin-inline-end: 2px;
    }

.ribbon .label.primary {
            order: 2;
            margin-inline-start: 0;
        }

.ribbon .label.secondary {
            order: 4;
        }

.ribbon .cta {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        transition: color linear 0.2s;
        align-items: center;
        flex-shrink: 0;
        order: 6;
    }

@media(min-width: 768px) {

.ribbon .cta {
            font-size: 14px
    }
        }

@media (min-width: 576px) {

.ribbon .cta {
            display: flex
    }
        }

@media(min-width: 768px) {

.ribbon .cta.before-icon {
                color: unset;
                fill: unset
        }
            }

.ribbon .cta-icon {
        margin-inline-start: 8px;
    }

.ribbon .cta,
    .ribbon .cta-icon,
    .ribbon .close-icon {
        color: var(--av-brand-primary);
        fill: var(--av-brand-primary);
    }

.ribbon .close {
        position: absolute;
        inset-inline-end: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        align-items: center;

        /* No close button for now
        display: flex; */
        display: none;
    }

.ribbon .icon {
        fill: var(--av-nav-primary);
        display: none;
        flex-shrink: 0;
        margin: 0 16px;
    }

@media (min-width: 768px) {

.ribbon .icon {
            display: block
    }
        }

.ribbon .icon.start {
            order: 1;
            margin-inline-end: 16px;
        }

.ribbon .icon.divider {
            margin: 0 16px;
            order: 3;
        }

.ribbon .icon.end {
            margin-inline-end: 0;
            order: 5;
        }

@media (min-width: 576px) {

.ribbon .icon.end {
                margin-inline-end: 8px
        }
            }

.ribbon.yellow {
        background: var(--av-fixed-warning-light);
    }

.ribbon.yellow .content {
            color: var(--av-nav-primary);
        }

.ribbon.yellow .cta,
        .ribbon.yellow .cta-icon,
        .ribbon.yellow .close-icon {
            color: var(--av-link-primary);
            fill: var(--av-link-primary);
        }

.ribbon.yellow:hover {
            background: var(--av-nav-primary);
        }

.ribbon.yellow:hover .content {
                color: var(--av-inversed-primary);
            }

.ribbon.yellow:hover .cta,
            .ribbon.yellow:hover .cta-icon,
            .ribbon.yellow:hover .close-icon {
                color: var(--av-link-inversed);
                fill: var(--av-link-inversed);
            }

.ribbon.yellow:hover .icon {
                fill: var(--av-inversed-primary);
            }

.ribbon.blue {
        background: var(--av-nav-secondary);
    }

.ribbon.blue .content {
            color: var(--av-inversed-primary);
        }

.ribbon.blue .cta,
        .ribbon.blue .cta-icon,
        .ribbon.blue .close-icon {
            color: var(--av-link-inversed);
            fill: var(--av-link-inversed);
        }

.ribbon.blue .icon {
            fill: var(--av-inversed-primary);
        }

.ribbon.blue:hover {
            background: var(--av-nav-primary);
        }

.ribbon.green {
        background: var(--av-fixed-success-light);
    }

.ribbon.green .content {
            color: var(--av-nav-primary);
        }

.ribbon.green .cta,
        .ribbon.green .cta-icon,
        .ribbon.green .close-icon {
            color: var(--av-link-primary);
            fill: var(--av-link-primary);
        }

.ribbon.green:hover {
            background: var(--av-button-action);
        }

.ribbon.green:hover .content {
                color: var(--av-inversed-primary);
            }

.ribbon.green:hover .cta,
            .ribbon.green:hover .cta-icon,
            .ribbon.green:hover .close-icon {
                color: var(--av-inversed-primary);
                fill: var(--av-inversed-primary);
            }

.ribbon.green:hover .icon {
                fill: var(--av-inversed-primary);
            }
