/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-message-highlights-combo {
    padding-top: 48px;
    padding-bottom: 48px;
}

.s-message-highlights-combo ..s-slice_first-child-padding {
            padding-top: 0;
        }

.s-message-highlights-combo ..s-slice_first-child-padding .__item:not(:first-child) {
                    padding-top: 0;
                }

.s-message-highlights-combo .__item {
            padding: 0;
        }

.s-message-highlights-combo .__item:not(:first-child) {
                padding-top: 80px;
            }

@media (min-width: 1024px) {

.s-message-highlights-combo .__item:not(:first-child) {
                    padding-top: 104px
            }
                }

.s-message-highlights-combo .__item.s-slice_border-bottom {
                padding-bottom: 48px;
            }

.s-message-highlights-combo ..s-slice_indent_medium .__item:not(:first-child) {
                        padding-top: 48px;
                    }

.s-message-highlights-combo ..s-slice_indent_medium .__item.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }

.s-message-highlights-combo ..s-slice_indent_small .__item:not(:first-child) {
                        padding-top: 48px;
                    }

.s-message-highlights-combo ..s-slice_indent_small .__item.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }

.s-message-highlights-combo {

    overflow: hidden;
}

.s-message-highlights-combo__header {
        margin-bottom: 48px;
    }

@media (min-width: 576px) {

.s-message-highlights-combo__header {
            margin-bottom: 64px
    }
        }

..s-slice_first-child-padding {
            padding-top: 0;
        }

..s-slice_first-child-padding .__item:not(:first-child) {
                    padding-top: 0;
                }

.__item {
            padding: 0;
        }

.__item:not(:first-child) {
                padding-top: 80px;
            }

@media (min-width: 1024px) {

.__item:not(:first-child) {
                    padding-top: 104px
            }
                }

.__item.s-slice_border-bottom {
                padding-bottom: 48px;
            }

..s-slice_indent_medium .__item:not(:first-child) {
                        padding-top: 48px;
                    }

..s-slice_indent_medium .__item.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }

..s-slice_indent_small .__item:not(:first-child) {
                        padding-top: 48px;
                    }

..s-slice_indent_small .__item.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }
