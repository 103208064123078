/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.legal:deep(.a-slice-header) {
        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
        margin-bottom: 0;
    }

.legal:deep(.a-slice-header)__title {
            padding: 48px 0 64px 0;
        }

.legal b {
        font-weight: bold;
    }

.legal .a-section-header__title {
        margin-bottom: 64px;
    }

@media (min-width: 1280px) {

.legal__wrapper {
            display: flex
    }
        }

.legal__nav-wrapper {
        display: none;
        width: 25%;
        padding-inline-end: 32px;
    }

@media (min-width: 1280px) {

.legal__nav-wrapper {
            display: block
    }
        }

@media (min-width: 1280px) {

.legal__content {
            width: 75%
    }
        }

.legal__section:not(:last-of-type) {
            margin-bottom: 32px;
        }

.legal__section-title {
        font-size: 24px;
        line-height: 32px;
    }

@media (min-width: 768px) {

.legal__section-title {
            font-size: 32px;
            line-height: 40px;
    }
        }

.legal__section-title {

        margin-bottom: 16px;
        color: var(--av-nav-primary);
}

.legal__other-versions {
        margin-top: 88px;
    }

.legal:deep(.section-nav) {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

.legal:deep(.section-nav)::-webkit-scrollbar {
            display: none;
        }

@media (min-width: 1280px) {

.legal:deep(.section-nav) {
            position: sticky;
            top: 12px;
            max-height: 95vh;
            overflow-y: auto;
            padding-bottom: 12px
    }
        }

.legal:deep(.section-nav)__link {
            display: block;
            padding: 12px 32px 12px 16px;
            border-radius: 4px;
            text-decoration: none;
        }

.legal:deep(.section-nav)__link .a-link__content {
                color: var(--av-nav-primary);
            }

.legal:deep(.section-nav)__link:hover,
            .legal:deep(.section-nav)__link:active,
            .legal:deep(.section-nav)__link:focus,
            .legal:deep(.section-nav)__link_active {
                background-color: var(--av-brand-light);
            }

.legal:deep(.section-nav)__link:hover .a-link__content, .legal:deep(.section-nav)__link:active .a-link__content, .legal:deep(.section-nav)__link:focus .a-link__content, .legal:deep(.section-nav)__link_active .a-link__content {
                    background: none;
                    color: var(--av-nav-primary);
                }

.legal:deep(.text-section):not(:last-of-type) {
            margin-bottom: 32px;
        }

.legal:deep(.text-section)__title {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--av-nav-primary);
        }

.legal:deep(.text-section) p {
            color: var(--av-fixed-secondary);
        }

.legal:deep(.text-section) p:not(:last-of-type) {
                margin-bottom: 16px;
            }

.legal:deep(.text-section) a {
            color: var(--av-link-primary);
            text-decoration: none;
            word-wrap: break-word;
        }

.legal:deep(.text-section) a:hover {
                color: var(--av-link-primary-hover);
            }

.legal:deep(.text-section) a:active {
                color: var(--av-link-primary-active);
                background: rgba(255, 255, 255, 0);
            }

.legal:deep(.text-section) .a-list_type_bullet {
            margin: 16px 0 0 8px;
        }

.legal:deep(.text-section):deep(.a-link-list) {
            margin: 16px 0 0;
        }

.legal .word-formatter {
        padding: 0;
        margin-top: -24px;
    }

.legal .word-formatter:deep(.article-body) {
            padding: 0;
            max-width: initial;
        }
