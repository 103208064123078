<template>
    <section v-if="content" class="landing-page-guideline">
        <div class="a-container">
            <div class="wrapper">
                <div class="item">
                    <p v-if="content.title" class="title" v-html="content.title" />
                    <div v-if="content.links?.length" class="links">
                        <a-link
                            v-for="(item, itemIndex) in content.links"
                            :key="`item-${itemIndex}`"
                            v-bind="item"
                        />
                    </div>
                </div>
                <div class="item-description">
                    <a-picture v-if="content.picture" v-bind="content.picture" />
                    <p v-if="content.description" class="description" v-html="content.description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SGuideline',
    components: {
        ALink,
        APicture,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-guideline {
  padding-top: 80px;
  position: relative;
  @media (--viewport-tablet) {
    padding-top: 42px;
  }
  @media (--viewport-desktop) {
    padding-top: 128px;
  }
  &:after {
      content: "";
      width: 100%;
      bottom: -310px;
      height: 620px;
      position: absolute;
      inset-inline-start: 0;
      border-radius: 100%;
      background: radial-gradient(1985.49% 77.95% at 55.49% 0%, #ED91FF 38.54%, #8C0BF2 65.1%, #0B9FF2 100%);
      filter: blur(80px);
  }
  .a-container {
    z-index: 1;
  }
  .wrapper {
    display: flex;
    margin: 0 -16px;
    align-items: center;
    flex-direction: column;
    @media (--viewport-desktop) {
      flex-direction: row;
    }
    @media (--viewport-desktop) {
      margin: 0;
      margin-inline-end: -32px;
      align-items: stretch;
    }
  }
  .item {
    display: flex;
    margin: 0 16px;
    text-align: center;
    flex-direction: column;
    padding: 32px 16px 56px;
    border-radius: 8px 8px 0 0;
    border: 3px solid #11407c;
    background: var(--av-nav-primary);
    @media (--viewport-tablet) {
      width: 100%;
      border-radius: 8px;
      padding: 32px 32px 56px;
    }
    @media (--viewport-desktop) {
      flex: 1;
      margin: 0;
      width: auto;
      min-width: 325px;
      text-align: start;
      border-inline-end: none;
      border-radius: 8px 0 0 8px;
      padding: 32px 24px;
      padding-inline-end: 16px;
    }
    @media (--viewport-desktop-wide) {
      min-width: 390px;
      padding: 51px 32px 61px;
      padding-inline-end: 16px;
    }
    @media (--viewport-desktop-large) {
      min-width: 440px;
      padding-inline-end: 13px;
      padding-inline-start: 48px;
    }
    .title {
      @mixin display-accent;
      @media (--viewport-tablet) {
        @mixin hero;
        font-weight: 500;
      }
    }
    .links {
      gap: 16px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 24px;
      flex-direction: row;
      justify-content: center;
      @media (--viewport-desktop) {
        margin-top: auto;
        justify-content: flex-start;
      }
      &:deep(.a-link) {
        padding: 0 16px;
        position: relative;
        @media (--viewport-desktop) {
          padding: 0;
        }
        .a-link__content {
          gap: 20px;
          display: flex;
          align-items: center;
          color: var(--av-inversed-primary);
          &:before {
            content: "";
            display: block;
            min-width: 48px;
            min-height: 48px;
            border-radius: 28px;
            background: var(--av-inversed-primary);
          }
        }
        .a-glyph {
          top: 12px;
          margin: 0;
          width: 24px;
          height: 24px;
          position: absolute;
          inset-inline-start: 12px;
          fill: #2668c5;
        }
      }
    }
  }
  .item-description {
    width: 100%;
    display: flex;
    border: none;
    min-height: 448px;
    text-align: center;
    position: relative;
    align-items: center;
    background: #010a1b;
    margin: -40px -16px 0;
    @media (--viewport-tablet) {
      overflow: hidden;
      max-width: 584px;
      border-radius: 8px;
      margin: -32px auto 0;
    }
    @media (--viewport-desktop) {
      width: 100%;
      max-width: none;
      margin: -54px 0;
      min-height: 502px;
      border-radius: 8px 0 0 8px;
    }
    &:deep(.a-picture) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .description {
      @mixin title;
      width: 100%;
      padding: 16px;
      position: relative;
      @media (--viewport-tablet) {
        @mixin display;
        padding: 0;
        margin: 48px 56px;
      }
      @media (--viewport-desktop) {
        margin: 0;
        width: 555px;
        text-align: start;
        margin-inline-start: 80px;
        @media (--viewport-desktop-wide) {
          width: 641px;
          margin-inline-start: 98px;
        }
        @media (--viewport-desktop-large) {
          margin-inline-start: 118px;
        }
      }
    }
  }
}
</style>
