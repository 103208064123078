<template>
    <div class="academy-menu" :class="{ centered: !menuIsAvailable }">
        <header class="main-navigation">
            <div class="a-container">
                <div class="wrapper">
                    <dynamic-link :to="logoLink">
                        <a-picture v-if="smallLogo && isMobile" class="logo" :link="smallLogo" />
                        <a-picture v-else-if="logo && !isMobile" class="logo" :link="logo" />
                    </dynamic-link>
                    <template v-if="menuIsAvailable">
                        <ul v-if="!isMobile" class="navigation">
                            <li v-for="(link, index) in links" :key="`navigation_links_${index}`">
                                <a-link v-bind="link" class="item" :size="link.size || 'paragraph'" />
                            </li>
                            <li>
                                <a-link
                                    v-if="enrollLink"
                                    v-bind="enrollLink"
                                    class="item enroll"
                                    :size="enrollLink.size || 'lead'"
                                />
                            </li>
                        </ul>
                        <div v-if="isMobile" class="mobile-navigation">
                            <button
                                ref="menuToggler"
                                class="menu-toggler"
                                type="button"
                                tabindex="1"
                                @click.prevent.stop="toggleMenu"
                            >
                                <a-glyph class="icon" :class="{ active: isActive }" name="burger-menu" />
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </header>
        <div v-show="isActive && isMobile" ref="menu" class="mobile-menu">
            <ul class="menu">
                <li v-for="(link, index) in links" :key="`menu-accordion-item-${index}`" class="item">
                    <a-link v-bind="link" :size="link.size || 'paragraph'" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint';

export default {
    components: {
        DynamicLink,
        APicture,
        AGlyph,
        ALink,
    },
    mixins: [breakpoint],
    props: {
        logo: {
            type: String,
            default: '@7178e73672ff3bb5e7ed0bec61332818',
        },
        logoLink: {
            type: String,
            default: '/',
        },
        smallLogo: {
            type: String,
            default: '@514e56f14ae2093990c379af813cdac1',
        },
        links: {
            type: Array,
            default() {
                return [];
            },
        },
        phone: {
            type: String,
            default: '',
        },
        enrollLink: {
            type: Object,
            default() { },
        },
        menuIsAvailable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isActive: false,
            showMenuTogglerText: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
        },
    },
};
</script>

<style lang="postcss" scoped>
.academy-menu {
    .main-navigation {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 24px;

        .wrapper {
            display: flex;
            align-items: center;
            height: 48px;

            @media (--viewport-tablet) {
                height: 92px;
            }

            .logo {
                max-width: 138px;
                max-height: 48px;
                overflow: hidden;

                @media (--viewport-tablet) {
                    max-width: 220px;
                }

                @media (--viewport-desktop) {
                    max-width: 290px;
                    max-height: 84px;
                }
            }

            .navigation {
                display: flex;
                align-items: center;
                gap: 24px;
                margin-inline-start: auto;

                .item {
                    font-weight: 700;

                    &:deep(.a-link__content) {
                        color: var(--av-inversed-primary);

                        &__glyph {
                            fill: var(--av-inversed-primary);
                        }
                    }

                    &:hover {
                        &:deep(.a-link__content) {
                            color: var(--av-link-inversed);

                            &__glyph {
                                fill: var(--av-link-inversed);
                            }
                        }
                    }

                    &:focus {
                        &:deep(.a-link__content) {
                            background: var(--av-brand-secondary-light);
                            color: var(--av-link-inversed);

                            &__glyph {
                                fill: var(--av-link-inversed);
                            }
                        }
                    }

                    &.enroll {
                        padding: 0 8px;
                    }
                }
            }
        }
    }

    .mobile-navigation {
        display: flex;
        align-items: center;
        margin-inline-start: auto;

        .contact-us {
            line-height: 1;
            border-inline-end: 1px solid var(--av-brand-light);
            padding-inline-end: 12px;
            margin-inline-end: 8px;

            &:deep(.a-glyph) {
                fill: var(--av-inversed-primary);

                &:hover {
                    fill: var(--av-link-inversed);
                }

                &:focus {
                    fill: var(--av-link-inversed);
                }
            }
        }

        .menu-toggler {
            cursor: pointer;

            .icon {
                width: 32px;
                height: 32px;
                fill: var(--av-inversed-primary);
                transition: unset;

                &.active {
                    fill: var(--av-link-inversed);
                }
            }
        }
    }

    .mobile-menu {
        position: absolute;
        width: 100%;
        top: 72px;
        z-index: 900;
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border: 1px solid var(--av-brand-secondary-accent);

        .item {
            border-bottom: var(--av-border);

            &:deep(.a-link) {
                padding: 24px 32px;
                width: 100%;

                &__content {
                    @mixin lead-accent;
                }
            }

            &:last-child {
                border: none;
            }
        }
    }

    &.centered {
        .main-navigation {
            .wrapper {
                justify-content: center;
                height: 48px;

                @media (--viewport-desktop) {
                    justify-content: flex-start;
                    height: 92px;
                }
            }
        }
    }
}
</style>
