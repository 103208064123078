<template>
    <div :class="['s-info', {'s-info-blocks-container': blocks}]">
        <div
            v-if="isLoading"
            v-loading="true"
            class="s-info__loading"
            el-loading-size="48"
            el-loading-text="Loading"
        />
        <!-- we want it to be in the DOM initially for VWO to work -->
        <div v-show="!isLoading">
            <s-info-blocks v-if="blocks" :blocks="blocks" />
            <s-info-contact-sales v-else-if="content.isContactSales" :content="content" />
            <s-info-sem-ebook v-else-if="content.isEbook" :content="content" />
            <s-info-partner-registration v-else-if="content.isPartnerRegistration" :content="content" />
            <s-info-content v-else :content="content" />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import SInfoBlocks from './info-blocks.vue';
import SInfoContactSales from './info-contact-sales.vue';
import SInfoContent from './info-content.vue';
import SInfoPartnerRegistration from './info-partner-registration.vue';
import SInfoSemEbook from './info-sem-ebook.vue';

// TODO: Use admin settings from WEB-46453 after they will be released
const CHECK_ATTEMPTS_MAX = 30;
const CHECK_ATTEMPTS_DELAY = 50;

export default {
    name: 'SInfo',

    components: {
        SInfoContactSales,
        SInfoContent,
        SInfoPartnerRegistration,
        SInfoSemEbook,
        SInfoBlocks,
    },

    directives: {
        Loading,
    },

    props: {
        content: {
            type: Object,
            default: null,
        },

        blocks: {
            type: Array,
            default: null,
        },
    },

    data: () => ({
        timerId: null,
        checkAttempts: 0,
        isLoading: true,
    }),

    mounted() {
        this.checkLoading();
    },

    methods: {
        checkLoading() {
            clearTimeout(this.timerId);

            if (typeof window === 'undefined') {
                this.isLoading = true;
            } else {
                this.checkAttempts++;
                const vwoState = window.vwoEventState;
                this.isLoading = ![window.VWO_EVENT_STATE_CANCELED, window.VWO_EVENT_STATE_HANDLED].includes(vwoState);
            }

            if (this.isLoading) {
                if (this.checkAttempts < CHECK_ATTEMPTS_MAX) {
                    this.timerId = setTimeout(() => this.checkLoading(), CHECK_ATTEMPTS_DELAY);
                } else {
                    this.isLoading = false;
                }
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info {
    position: relative;
    flex: 1;

    @media (--viewport-desktop) {
        width: 100%;
        text-align: start;
        padding-inline-end: 16px;
    }

    @media (--viewport-desktop-wide) {
        padding-inline-end: 32px;
    }

    &__loading {
        top: 0;
        inset-inline-start: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: transparent;

        @media (--viewport-tablet) {
            margin-top: 40px;
        }

        &:deep(.el-loading) {
            .el-text {
                display: block;
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }
}

.s-form-beta {
    .s-info {
        @media (--viewport-desktop) {
            max-width: 471px;
        }

        @media (--viewport-desktop-large) {
            max-width: 600px;
        }
    }
}

.s-info-blocks-container {
    max-width: 532px;

    @media (--viewport-tablet) {
        width: 50%;
        min-width: 344px;
        padding-inline-end: 0;
    }

    &~:deep(.s-form-container) {
        width: 100%;
        margin: 0 0 auto;
        min-height: 350px;

        @media (--viewport-tablet) {
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            padding: 0;
            max-width: 568px;
        }

        @media (--viewport-desktop-large) {
            max-width: none;
        }

        .s-form-error,
        .s-form-login,
        .s-form-change,
        .s-form-upsell,
        .s-form-success,
        .s-form-registration,
        .s-form-support-confirmation,
        .notification {
            padding: 32px 16px;
            border-radius: 4px;
            position: relative;
            box-shadow: var(--av-shadow-small);
            border: 1px solid var(--av-brand-light);

            @media (--viewport-desktop-wide) {
                padding: 32px;
            }
        }
        .s-form-success {
            @media (--viewport-desktop-wide) {
                padding: 48px;
            }
        }
    }
}
</style>
