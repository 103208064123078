<template>
    <div :class="className">
        <div class="a-container">
            <p v-if="page.title" class="title" v-html="page.title" />
            <div v-if="page.description" class="description-wrapper">
                <div class="description" v-html="page.description" />
                <a-button
                    v-bind="page.buttonCart"
                    :class="{ 'has-product': addedLicenses.length }"
                    @click="$emit('toggleCart')"
                />
            </div>
            <s-workloads
                :settings="settings"
                @changeWorkload="$emit('changeWorkload', $event)"
                @toggleCart="$emit('toggleCart', $event)"
            />

            <template v-if="settings.version !== 3">
                <p v-if="settings.dictionary?.description" class="promo-description">
                    {{ settings.version }} -  {{ settings.dictionary.description }}
                </p>
                <p v-else-if="promo.isPromoActive && promo?.description" class="promo-description">
                    <a-glyph v-if="promo?.glyph" :name="promo?.glyph" size="m" /> {{ promo.description }}
                </p>
            </template>

            <s-editions
                :settings="settings"
                @toggleCart="$emit('toggleCart', $event)"
                @changeItem="$emit('changeItem', $event)"
                @changeQuantity="$emit('changeQuantity', $event)"
                @changeLoading="$emit('changeLoading', $event)"
            />

            <div v-if="settings.version === 3" class="summary">
                <div class="summary-item">
                    <div class="summary-title" v-html="translation('Summary')" />
                    <div class="summary-price-container">
                        <div class="summary-quantity-title" v-html="translation('Items selected')" />
                        <div class="summary-quantity" v-html="summaryQuantity" />
                        <div class="summary-price" v-html="summaryPrice" />
                    </div>
                </div>
                <a-button v-bind="summaryButton" @click="$emit('toggleCart')" />
            </div>

            <p v-if="settings.isVisibleCloudLink" class="link-card">
                <a-link size="paragraph" :text="textLink" @click="onClick()" />
            </p>
        </div>

        <div v-if="footer" class="footer">
            <div v-if="footer.title" v-html="footer.title" />
            <a-link v-if="footer.link" v-bind="footer.link" />
        </div>
        <s-awards v-if="awardIDs.length" :award_ids="awardIDs" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import SAwards from '@core/slices/pages/awards/awards.vue';
import SEditions from './components/editions.vue';
import SWorkloads from './components/workloads.vue';
import mixin from './mixin.js';

export default {
    name: 'SProducts',

    components: {
        AButton,
        AGlyph,
        ALink,
        SAwards,
        SEditions,
        SWorkloads,
    },

    mixins: [mixin, analytics, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleCart', 'changeWorkload', 'changeItem', 'changeQuantity', 'toggleProduct', 'changeLoading'],

    computed: {
        className() {
            return [
                's-purchasing-acp-products',
                { 'has-border': this.settings?.page?.hasBorder },
                { 'has-background': this.settings?.page?.hasBackground },
            ];
        },

        footer() {
            return this.settings.dictionary?.expert?.footer || null;
        },

        awardIDs() {
            return this.settings?.dictionary?.expert?.awards || [];
        },

        textLink() {
            return this.translation('I want to add Cloud Storage and/or Disaster Recovery');
        },

        promo() {
            return this.settings.promo;
        },
        page() {
            return this.settings?.dictionary?.expert || {};
        },
        summaryQuantity() {
            return this.addedLicenses
                .filter((item) => item.workload)
                .map((item) => item.quantity)
                .reduce((a, b) => a + b, 0);
        },
        summaryPrice() {
            const price = this.addedLicenses
                .filter((item) => item.workload)
                .map((item) => item.quantity * item.store.price)
                .reduce((a, b) => a + b, 0);
            return !price ? '' : `${this.setCurrency(price)} ${this.translation('per year')}`;
        },
        summaryButton() {
            return this.settings?.button || {
                size: 's',
                type: 'action',
                glyph: 'shopping-cart-o',
                disabled: !this.addedLicenses.length,
                text: this.translation('Add to cart'),
            };
        },
    },

    methods: {
        onClick() {
            this.addCloud();
            this.$emit('toggleCart', 'cloud');
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-products {
    padding-top: 96px;
    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin display-accent;
        }
    }
    .description-wrapper {
        @mixin paragraph;
        gap: 24px;
        display: flex;
        margin-top: 24px;
        flex-direction: column;
        align-items: flex-start;
        color: var(--av-nav-primary);
        justify-content: space-between;
        @media (--viewport-tablet) {
            gap: 48px;
            margin-top: 16px;
            flex-direction: row;
            align-items: center;
        }
        @media (--viewport-desktop) {
            @mixin lead;
        }
        .description {
            @media (--viewport-desktop-wide) {
                max-width: 960px;
            }
            @media (--viewport-desktop-large) {
                max-width: 760px;
            }
        }
        &:deep(.a-button) {
            gap: 8px;
            width: 100%;
            height: 40px;
            display: flex;
            border: none;
            position: relative;
            margin-inline-start: 0;
            justify-content: center;
            flex-direction: row-reverse;
            background: rgba(38, 104, 197, 0.10);
            @media (--viewport-tablet) {
                width: auto;
            }
            &:hover {
                background: var(--av-brand-accent);
            }
            &:active {
                background: var(--el-secondary-active);
            }
            .a-button__content {
                color: var(--av-brand-primary);
            }
            .a-button__glyph {
                margin: 0;
                fill: var(--av-brand-primary);
            }
            &.has-product {
                padding-inline-end: 32px;
                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    border-radius: 50%;
                    inset-inline-end: 10px;
                    background: var(--av-brand-primary);
                }
            }
        }
    }
    &:deep(.s-awards) {
        background-color: rgba(64, 139, 234, 0.05);
        .list {
            gap: 64px;
            flex-direction: column;
            @media (--viewport-tablet) {
                flex-wrap: nowrap;
                flex-direction: row;
            }
            .item {
                max-width: unset;
                @media (--viewport-tablet) {
                    width: 50%;
                }
            }
            .award {
                align-items: center;
                flex-direction: column-reverse;
                @media (--viewport-tablet) {
                    height: 100%;
                    justify-content: space-between;
                }
                @media (--viewport-desktop) {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
            }
            .title {
                @mixin lead;
                font-weight: 400;
                text-align: center;
                @media (--viewport-desktop) {
                    width: 50%;
                    text-align: start;
                }
            }
            .image-wrapper {
                @media (--viewport-desktop) {
                    width: 50%;
                }
            }
            .image {
                height: 80px;
            }
        }
    }
}

.has-border {
    border-bottom: 2px solid var(--av-brand-secondary-light);
}

.has-background {
    background: var(--av-gradient-to-top-lightest);
}

.promo-description {
    @mixin paragraph-accent;
    text-align: center;
    margin: 24px 0 -16px;
    padding-inline-start: 32px;
    color: rgba(124, 155, 30, 1);

    .a-glyph {
        margin: 0 8px;
        margin-inline-start: -32px;
        fill: rgba(124, 155, 30, 1);
    }
}

.link-card {
    padding: 4px 0;
    max-width: 400px;
    margin: 0 auto 24px;
    text-align: center;
    @media (--viewport-tablet) {
        max-width: none;
    }
    @media (--viewport-desktop) {
        margin-bottom: 16px;
    }
    @media (--viewport-desktop-large) {
        margin-bottom: 32px;
    }
    &:deep(.a-link) {
        &__content {
            @mixin lead-accent;
            text-align: center;
            color: var(--av-nav-secondary);

        }
    }
}

.footer {
    @mixin body;
    gap: 8px;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--av-nav-primary);
    background: var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        flex-wrap: nowrap;
    }

    &:deep(.a-link) {
        .a-link__content {
            @mixin note-heading;
            gap: 8px;
            display: flex;
            padding: 4px 16px;
            white-space: nowrap;
            border-radius: 12px;
            align-items: center;
            flex-direction: row-reverse;
            color: var(--av-fixed-info-dark);
            border: 1px solid var(--av-fixed-info);
            background: var(--av-fixed-info-accent);
        }

        .a-glyph {
            margin: 0;
            top: auto;
            position: relative;
            margin-inline-start: unset;
            fill: var(--av-fixed-info-dark);
        }
    }
}
.summary {
    gap: 24px;
    display: flex;
    margin: 24px 0 40px;
    align-items: center;
    flex-direction: column;
    @media (--viewport-tablet) {
        flex-direction: row;
        margin: -8px 0 24px;
        padding: 32px 8px 24px;
        border-end-end-radius: 8px;
        border-end-start-radius: 8px;
        justify-content: space-between;
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
        border: 1px solid var(--av-brand-secondary-light);
    }
    @media (--viewport-desktop) {
        margin-bottom: 16px;
        padding: 32px 16px 24px;
    }
    @media (--viewport-desktop-wide) {
        padding: 32px 24px 24px;
    }
    @media (--viewport-desktop-large) {
        margin-bottom: 32px;
    }
    .summary-title {
        @mixin lead-accent;
        width: 100%;
        text-align: center;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            width: auto;
        }
        @media (--viewport-desktop) {
            @mixin title-accent;
        }
    }
    .summary-item {
        gap: 24px;
        display: flex;
        flex-direction: column;
        @media (--viewport-tablet) {
            flex-direction: row;
            align-items: center;
        }
        @media ( --viewport-desktop-large) {
            gap: 104px;
        }
    }
    .summary-price-container {
        gap: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .summary-price {
        @mixin paragraph-accent;
        display: flex;
        color: var(--av-nav-primary);
    }
    .summary-quantity {
        display: flex;
        min-width: 40px;
        min-height: 40px;
        text-align: center;
        border-radius: 4px;
        align-items: center;
        margin-inline-end: 8px;
        margin-inline-start: 16px;
        justify-content: center;
        background: var(--av-brand-accent);
    }
    .summary-quantity-title {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
    }
    &:deep(.a-button) {
        gap: 8px;
        display: flex;
        min-width: 252px;
        justify-content: center;
        flex-direction: row-reverse;
        .a-glyph {
            margin: 0;
        }
    }
}
</style>
