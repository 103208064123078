<template>
    <section v-if="content" class="landing-page-casestudy">
        <a-slider :options="options" @change="onChange($event)">
            <a-slide v-for="(slide, slideIndex) in content.list" :key="`slide-${slideIndex}`">
                <template v-if="isDesktop && slide.poster">
                    <video
                        :ref="`video-${slideIndex}`"
                        loop
                        muted
                        playsinline
                        preload="none"
                        class="background"
                        :poster="`https://staticfiles.acronis.com/images/background/${slide.poster}.webp`"
                    >
                        <template v-if="slide.video">
                            <source :src="`https://staticfiles.acronis.com/videos/${slide.video}.webm`" type="video/webm" />
                            <source :src="`https://staticfiles.acronis.com/videos/${slide.video}.mp4`" type="video/mp4" />
                        </template>
                    </video>
                </template>
                <template v-else>
                    <a-picture v-if="slide.picture?.background" class="background" v-bind="slide.picture" />
                </template>
                <div class="wrapper">
                    <div class="item-content">
                        <p v-if="slide.title" class="title" v-html="slide.title" />
                        <p v-if="slide.description" class="description" v-html="slide.description" />
                        <ul v-if="slide.info" class="info">
                            <li
                                v-for="(item, itemIndex) in slide.info"
                                :key="`item-${itemIndex}`"
                                class="info-item"
                                v-html="item"
                            />
                        </ul>
                        <div v-if="slide.links?.length" class="links">
                            <a-link
                                v-for="(link, linkIndex) in slide.links"
                                :key="`link-${linkIndex}`"
                                :size="link.size || 'paragraph'"
                                :glyph="link.glyph || 'download-arrow'"
                                v-bind="link"
                            />
                        </div>
                    </div>
                    <div v-if="slide.logo" class="item-logo">
                        <a-picture v-bind="slide.logo" />
                    </div>
                </div>
            </a-slide>
        </a-slider>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';

export default {
    name: 'SCasestudy',
    components: {
        ALink,
        APicture,
        ASlide,
        ASlider,
    },
    mixins: [breakpoints],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: {
                useLoop: true,
                spaceBetween: 0,
                slidesPerGroup: 1,
                slidesPerView: 1,
                showNavigation: true,
                showPagination: true,
                customPagination: { type: 'bullets' },
                glyphs: {
                    left: 'i-long-arrow-left--16',
                    right: 'i-long-arrow-right--16',
                },
            },
        };
    },

    methods: {
        onChange(event) {
            if (!this.$refs[`video-${event}`]?.[0]) return;
            this.$refs[`video-${event}`][0].play();
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-casestudy {
    position: relative;
    .wrapper {
        gap: 40px;
        z-index: 1;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column-reverse;
        @media (--viewport-desktop) {
            gap: 0;
            flex-direction: row;
            align-items: center;
        }
        @media (--viewport-desktop-large) {
            width: 1440px;
            margin: 0 auto;
        }
    }
    .item-content {
        text-align: center;
        padding: 0 16px 88px;
        @media (--viewport-desktop) {
            width: 50%;
            padding: 0 32px 96px;
            text-align: start;
            padding-inline-start: var(--container-paddings-mobile-wide);
        }
        @media (--viewport-desktop-wide) {
            padding-inline-start: var(--container-paddings-desktop-wide);
        }
        @media (--viewport-desktop-large) {
            padding-inline-end: 130px;
        }
        .title {
            @mixin hero;
        }
        .description {
            @mixin title;
            margin-top: 48px;
        }
        .info {
            @mixin paragraph;
            margin-top: 32px;
            .info-item {
                gap: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (--viewport-desktop) {
                    justify-content: flex-start;
                }
                &:before {
                    width: 4px;
                    height: 4px;
                    content: "";
                    display: block;
                    background: var(--av-inversed-primary);
                }
            }
        }
        .links {
            gap: 16px;
            width: 100%;
            display: flex;
            margin-top: 48px;
            flex-direction: row;
            justify-content: center;
            @media (--viewport-desktop) {
                justify-content: flex-start;
            }
            &:deep(.a-link) {
                position: relative;
                .a-link__content {
                    gap: 16px;
                    display: flex;
                    align-items: center;
                    color: var(--av-inversed-primary);
                    &:before {
                        content: "";
                        display: block;
                        min-width: 48px;
                        min-height: 48px;
                        border-radius: 28px;
                        background: var(--av-inversed-primary);
                    }
                }
                .a-glyph {
                    top: 12px;
                    margin: 0;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    inset-inline-start: 12px;
                    fill: #2668c5;
                }
            }
        }
    }
    .item-logo {
        width: 100%;
        padding-top: 40px;
        text-align: center;
        @media (--viewport-desktop) {
            width: 50%;
        }
    }
    &:deep(.a-slider) {
        .a-slide {
            width: 100%;
            min-height: 814px;
            position: relative;
            @media (--viewport-tablet) {
                min-height: 621px;
            }
            @media (--viewport-desktop) {
                min-height: 754px;
            }
            &:before {
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                content: "";
                position: absolute;
                inset-inline-start: 0;
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(5px);
                @media (--viewport-desktop) {
                    width: 50%;
                }
            }
            .background {
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                object-fit: cover;
                position: absolute;
                inset-inline-start: 0;
            }
        }
        .a-slider__nav {
            left: 0;
            margin: 0;
            width: 100%;
            bottom: 16px;
            position: absolute;
            inset-inline-start: 0;
            justify-content: center;
            @media (--viewport-desktop) {
                width: auto;
                inset-inline-start: 32px;
                justify-content: flex-start;
            }
            @media (--viewport-desktop-wide) {
                inset-inline-start: 64px;
            }
            @media (--viewport-desktop-large) {
                inset-inline-start: calc(50% - 656px);
            }
        }
        .a-slider__dots {
            @media (--viewport-desktop) {
                display: none;
            }
        }
        .a-slider__controls {
            margin: 0;
            display: none;
            @media (--viewport-desktop) {
                display: flex;
                margin-inline-start: -16px;
            }
            .a-slider__control {
                margin: 0;
                border: none;
                .a-glyph {
                    fill: var(--av-inversed-primary);
                }
            }
            .a-slider__control_disabled {
                .a-glyph {
                    opacity: 0.5;
                }
            }
        }
    }
}
</style>
