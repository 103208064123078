<template>
    <s-basic-slice class="s-asset-cards" v-bind="l10n">
        <section v-for="(section, index) in l10n.sections" :key="`s-asset-cards-section-${index}`" class="s-asset-cards__section">
            <h3 class="s-asset-cards__section-title">
                {{ section.title }}
            </h3>
            <ul class="s-asset-cards__list">
                <li
                    v-for="(item, cardIndex) in section.cardList"
                    :key="`s-asset-cards-${section.title}-${cardIndex}`"
                    class="s-asset-cards__item"
                >
                    <a-asset-card v-bind="item" />
                </li>
            </ul>
        </section>
        <section v-if="l10n.relatedLinks" class="s-asset-cards__related-links">
            <h3 class="s-asset-cards__related-links-title">
                {{ l10n.relatedLinks.title }}
            </h3>
            <ul v-if="l10n.relatedLinks.list && l10n.relatedLinks.list.length" class="s-asset-cards__related-links-list">
                <li
                    v-for="(item, index) in l10n.relatedLinks.list"
                    :key="`s-asset-cards-related-link-${index}`"
                    class="s-asset-cards__related-links-item"
                >
                    <a-link
                        class="s-asset-cards__retalated-link"
                        v-bind="item"
                        :size="item.size || 'paragraph'"
                    />
                </li>
            </ul>
        </section>
    </s-basic-slice>
</template>

<script>
import AAssetCard from '@core/components/asset-card/asset-card.vue';
import ALink from '@core/components/link/link.vue';
import l10n from '@core/mixins/l10n.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SAssetCards',

    components: {
        SBasicSlice,
        AAssetCard,
        ALink,
    },
    mixins: [l10n],

    inheritAttrs: false,

    props: {
        sections: {
            type: Array,
            required: true,
        },

        relatedLinks: {
            type: Object,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss">
.s-asset-cards {
    &__section {
        margin-bottom: 72px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 64px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 56px;
        }
    }

    &__section-title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--av-nav-primary);
    }

    &__list {
        margin-bottom: -16px;

        @media (--viewport-mobile-wide) {
            margin: 0 -8px -24px;
            display: flex;
            flex-wrap: wrap;
        }

        @media (--viewport-desktop-wide) {
            margin: 0 -8px;
        }
    }

    &__item {
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            padding: 0 8px;
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 0;
            width: 33.33%;
        }
    }

    &__related-links-title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            margin-bottom: 16px;
        }
    }

    &__related-links-list {
        display: flex;
        flex-direction: column;
        margin-bottom: -16px;

        @media (--viewport-mobile-wide) {
            width: 100%;
            margin-inline-end: -40px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__related-links-item {
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            margin-inline-end: 40px;
        }
    }
}
</style>
