<template>
    <div class="resource-search-action-bar">
        <div class="search-results-counter">
            {{ commonTranslations.results }}
            <span>({{ totalResults }})</span>
        </div>
        <a-link
            class="toggle-filters-button"
            text="Open Filters"
            glyph="arrow-narrow-up"
            glyph-position="right"
            :has-no-ref="true"
            @click="$emit('toggle-filters')"
        />
        <resource-search-sort-dropdown v-model="sortRule" :items="sortItems" />
    </div>
</template>

<script lang="ts">
import ALink from '@core/components/link/link.vue';
import ResourceSearchSortDropdown from '../resource-search-sort-dropdown/resource-search-sort-dropdown.vue';

export default {
    name: 'ResourceSearchActionBar',

    components: {
        ALink,
        ResourceSearchSortDropdown,
    },

    props: {
        totalResults: {
            type: Number,
            default: 0,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        initialValue: {
            type: String,
            default: 'recent',
        },
    },

    emits: ['sort', 'toggle-filters'],

    data: () => ({
        sortRule: null,
        sortItems: [],
    }),

    watch: {
        sortRule(newRule, oldRule) {
            if (!oldRule) return;
            this.$emit('sort', newRule.value);
        },
    },

    created() {
        this.sortItems = [
            { value: 'recent', label: this.commonTranslations.recent },
            { value: 'popular', label: this.commonTranslations.popular },
        ];
        this.sortRule = this.sortItems.find((item) => item.value === this.initialValue) || this.sortItems[0];
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-action-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;

    @media (--viewport-tablet) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media (--viewport-desktop) {
        margin-bottom: -44px;
    }

    @media (--viewport-desktop-wide) {
        margin-bottom: 0;
    }

    @media (--viewport-desktop-large) {
        margin-bottom: -16px;
    }
}

.toggle-filters-button {
    &:deep(.a-link__content) {
        @mixin paragraph;
    }

    &:deep(.a-glyph) {
        width: 10px;
        height: 5px;
        transform: translateY(-5px);
    }

    @media (--viewport-desktop) {
        visibility: hidden;
    }
}

.search-results-counter {
    display: none;
    gap: 16px;
    @mixin paragraph;
    color: var(--av-nav-primary);

    @media (--viewport-desktop-wide) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
</style>
