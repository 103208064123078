<template>
    <a-modal :id="data.id" class="prices-breakdown-modal" :is-overlay-close="false">
        <div v-if="data.title" class="title">
            {{ data.title }}
        </div>
        <div v-if="data.lead" class="lead">
            {{ data.lead }}
        </div>
        <div v-if="isMobile" class="table">
            <div class="table-head">
                <a-link
                    v-bind="{...data.table.head[0], type: 'regular'}"
                    :size="data.table.head[0]?.size || 'paragraph'"
                    :event="getPdfEvent(data.table.head[0].to)"
                />
                <div class="table-head-rows">
                    <a-dangerous-html :content="data.table.head[1]?.value" tag="div" />
                    <a-dangerous-html :content="data.table.head[2]?.value" tag="div" />
                </div>
            </div>
            <div class="table-subhead">
                <a-dangerous-html v-if="data.table.subhead[0].text" :content="data.table.subhead[0].text" tag="div" />
                <div class="table-subhead-wrap">
                    <div>
                        <a-tooltip
                            v-if="data.table.subhead[1].tooltip"
                            :text="data.table.subhead[1].tooltip"
                            glyph="tooltip-icon-dark"
                        />
                        <a-dangerous-html :content="data.table.subhead[1].text" />
                    </div>
                    <div>
                        <a-tooltip
                            v-if="data.table.subhead[2].tooltip"
                            :text="data.table.subhead[2].tooltip"
                            glyph="tooltip-icon-dark"
                        />
                        <a-dangerous-html :content="data.table.subhead[2].text" />
                    </div>
                </div>
            </div>
            <div class="table-content">
                <div v-for="(row, i) in data.table.rows" :key="i" class="table-row">
                    <a-dangerous-html
                        v-if="row.content[0].text"
                        :content="row.content[0].text"
                        tag="div"
                        class="table-row-category"
                    />
                    <div class="table-row-wrap">
                        <a-dangerous-html :content="row.content[1].text" />
                        <a-dangerous-html :content="row.content[2].text" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="table">
            <div class="table-head">
                <div v-for="(row, i) in data.table.head" :key="i" class="table-head-row">
                    <a-link
                        v-if="row.type === 'link'"
                        v-bind="{...row, type: 'regular'}"
                        :size="row.size || 'paragraph'"
                        :event="getPdfEvent(row.to)"
                    />
                    <a-dangerous-html
                        v-else-if="row.type === 'text'"
                        :content="row.value"
                        tag="div"
                    />
                </div>
            </div>
            <div class="table-subhead">
                <div v-for="(row, i) in data.table.subhead" :key="i" class="table-subhead-row">
                    <a-tooltip v-if="row.tooltip" :text="row.tooltip" glyph="tooltip-icon-dark" />
                    <a-dangerous-html :content="row.text" />
                </div>
            </div>
            <div class="table-content">
                <div v-for="(row, i) in data.table.rows" :key="i" class="table-row">
                    <div v-for="(col, j) in row.content" :key="j" class="table-col">
                        <a-dangerous-html :content="col.text" tag="div" />
                    </div>
                </div>
            </div>
        </div>
        <a-button
            v-if="data.button"
            class="button"
            v-bind="data.button"
            :size="data.button.size || 'medium'"
            :event="setEvent(data.button)"
            data-micromodal-close
        />
        <a-button
            class="close-button"
            data-micromodal-close
            glyph="close"
            aria-label="Close modal"
        />
    </a-modal>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'InfoPricesBreakdownModal',

    components: {
        ATooltip,
        ADangerousHtml,
        ALink,
        AButton,
        AModal,
    },

    mixins: [breakpoint],

    props: {
        data: {
            type: Object as any,
            required: true,
        },
    },

    computed: {
        cssVars() {
            return {
                '--table-columns-count': this.data.table.head.length,
            };
        },
    },

    methods: {
        setEvent(item) {
            return item && item.sendEventGA ? { label: item.to, ...item.sendEventGA } : {};
        },

        getPdfEvent(eventLabel) {
            return {
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'documents',
                eventLabel,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.prices-breakdown-modal {
    cursor: default;
    text-align: start;

    .title {
        @mixin title-accent;
        width: 100%;
        text-align: start;
        margin-bottom: 8px;
        padding: 0 16px;

        @media (--viewport-tablet) {
            padding: 0 24px;
        }

        @media (--viewport-desktop) {
            padding: 0 32px;
        }
    }

    .lead {
        @mixin body;
        width: 100%;
        text-align: start;
        padding: 0 16px;

        @media (--viewport-tablet) {
            padding: 0 24px;
        }

        @media (--viewport-desktop) {
            padding: 0 32px;
        }
    }

    .button {
        margin-top: 40px;

        &:deep(.a-button__content) {
            color: var(--av-inversed-primary);
        }
    }

    &.a-modal {
        &:deep(.inner) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: var(--av-inversed-primary);
            padding: 14px 0 40px;
            @media (--viewport-tablet) {
                text-align: start;
                width: 704px;
                padding: 32px 0 40px;
            }
            @media (--viewport-desktop) {
                width: 910px;
                padding: 32px 0 40px;
            }
        }
        &:deep(.close),
        &:deep(.close-mobile) {
            display: none;
        }
        &:deep(.content) {
            @media (--viewport-desktop) {
                width: auto;
            }
        }
    }

    .table {
        margin-top: 24px;

        @media (--viewport-desktop) {
            margin-top: 40px;
        }

        .table-head {
            @media (--viewport-tablet) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                box-shadow: 0 4px 4px 0 rgba(82, 89, 102, 0.18);
                filter: drop-shadow(0 4px 9px rgba(82, 89, 102, 0.18));
                padding-bottom: 16px;
            }

            @media (--viewport-desktop) {
                gap: 56px;
            }

            .a-link {
                @media (--viewport-desktop) {
                    margin-inline-start: 16px;
                    width: 100%;
                }

                &:deep(.a-link__content) {
                    @mixin paragraph-accent;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    gap: 8px;
                    background: transparent;

                    @media (--viewport-tablet) {
                        @mixin caption;
                        font-weight: 400;
                    }

                    @media (--viewport-desktop) {
                        @mixin paragraph-accent;
                    }

                    &:deep(span) {
                        margin-inline-end: 0;
                    }
                }
            }

            &-row {
                @mixin paragraph-accent;
                width: 200px;
                flex-shrink: 0;
                text-align: start;

                &:first-child {
                    flex-basis: 100%;
                    flex-shrink: 1;
                    text-align: center;
                    padding-inline-start: 16px;

                    &:deep(.a-link__content span) {
                        @media (min-width: 768px) and (max-width: 1023px) {
                            max-width: 208px;
                            text-align: start;
                        }
                    }
                }

                @media (--viewport-desktop) {
                    width: 248px;
                }
            }
        }

        &-content {
            margin-top: 8px;

            @media (--viewport-tablet) {
                margin-top: 0;
                background: var(--av-solid-brand-lightest);
            }
        }

        &-head-rows {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            padding: 0 16px;

            div {
                flex-basis: 100%;
                @mixin paragraph-accent;
                color: var(--av-nav-primary);
            }
        }

        &-head-row {
            @mixin paragraph-accent;
            padding-inline-end: 16px;

            @media (--viewport-desktop) {
                padding-inline-end: 32px;
            }

            &:deep(.a-link__content) {
                background: transparent;
            }
        }

        &-subhead {
            padding-bottom: 24px;
            box-shadow: 0 4px 0 0 rgba(82, 89, 102, 0.18);

            @media (--viewport-tablet) {
                display: flex;
                justify-content: space-between;
                gap: 16px;
                box-shadow: none;
                margin-top: 0;
                padding: 24px 0;
                background: var(--av-solid-brand-lightest);
                border-bottom: 1px solid rgba(26, 96, 179, 0.15);
            }

            @media (--viewport-desktop) {
                gap: 56px;
            }

            &-row {
                width: 200px;
                flex-shrink: 0;
                text-align: start;
                padding-inline-end: 16px;

                @media (--viewport-tablet) {
                    display: flex;
                    gap: 4px;
                }

                @media (--viewport-desktop) {
                    width: 248px;
                    padding-inline-end: 32px;
                }

                &:first-child {
                    flex-basis: 100%;
                    flex-shrink: 1;
                }

                .a-dangerous-html {
                    display: inline;
                    @mixin body;
                    color: var(--av-nav-primary);
                }

                .a-tooltip {
                    transform: translateY(2px);

                    &:deep(.a-glyph) {
                        fill: var(--av-nav-primary);
                    }
                }
            }
        }

        &-subhead-wrap {
            display: flex;
            padding: 10px 16px;

            > div {
                flex-basis: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .a-dangerous-html {
                flex-basis: 100%;
            }
        }

        &-row-wrap {
            display: flex;
            align-items: center;
            background: var(--av-brand-lightest);
            padding: 16px 24px;
            @mixin body;
            color: var(--av-nav-primary);
            border-bottom: 1px solid var(--av-brand-secondary-accent);

            .a-dangerous-html {
                flex-basis: 100%;
            }
        }

        &-row-category {
            @mixin paragraph;
            line-height: 30px;
            color: var(--av-nav-primary);
            height: 32px;
        }

        &-row {
            @media (--viewport-tablet) {
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0 24px 16px;
                gap: 16px;
                border-bottom: 1px solid rgba(26, 96, 179, 0.15);
            }

            @media (--viewport-desktop) {
                gap: 56px;
                padding: 16px 0 16px 32px;
            }
        }

        &-col {
            @mixin body;
            width: 200px;
            flex-shrink: 0;
            text-align: start;

            @media (--viewport-desktop) {
                width: 248px;
            }

            &:first-child {
                @mixin paragraph;
                flex-basis: 100%;
                flex-shrink: 1;
                padding-inline-start: 8px;

                @media (--viewport-desktop) {
                    padding-inline-start: 0;
                    min-width: 270px;
                }
            }
        }
    }

    .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -40px;
        inset-inline-end: 0;
        width: 32px;
        height: 32px;
        border: 1px solid white;
        border-radius: 50%;
        cursor: pointer;

        @media (--viewport-desktop) {
            top: 0;
            inset-inline-end: -44px;
        }

        &:hover {
            filter: brightness(110%);
        }

        &:deep(.a-button__glyph) {
            margin: 0;
        }
    }
}
</style>
