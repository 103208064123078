<template>
    <div class="event-tab-control">
        <div class="header">
            <div ref="list" class="list-wrapper">
                <ul class="list">
                    <li v-for="tab in tabs" :key="tab.id" class="item">
                        <button
                            :id="tab.id"
                            :ref="`tab${tab.id}`"
                            class="item-button"
                            type="button"
                            :class="{ active: isTabActive(tab.id) }"
                            @click="changeTab(tab.id)"
                        >
                            <div class="label">
                                {{ tab.title }}
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventTabControl',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        activeTabId: {
            type: String,
            default: null,
        },
    },

    emits: ['change'],

    data() {
        return {};
    },

    computed: {
        activeTab() {
            return this.$refs?.[`tab${this.activeTabId}`]?.[0];
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.moveUnderline);
        document.fonts.removeEventListener('loadingdone', this.moveUnderline);
    },

    methods: {
        isTabActive(id) {
            return this.activeTabId === id.toString();
        },

        changeTab(id) {
            this.$emit('change', { id: id.toString() });
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-tab-control {
    .header {
        margin-bottom: 24px;
        position: relative;
        display: flex;
        overflow-x: auto;
        overflow-y: visible;
        transition: transform 0.3s ease-out;
        scrollbar-width: none;
        margin-inline-end: -16px;
        padding-inline-end: 16px;

        &::-webkit-scrollbar {
            height: 0;
            background: transparent;
        }

        @media (--viewport-tablet) {
            margin-inline-end: -32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 8px;
        }
    }

    .list-wrapper {
        position: relative;
        flex: 1;
    }

    .list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 2px;

        margin-inline-start: -24px;

        @media (--viewport-mobile-wide) {
            margin-inline-start: -40px;
        }
    }

    .item {
        margin-bottom: 16px;
        border-bottom: 2px solid var(--av-brand-secondary-accent);

        padding-inline-start: 24px;

        @media (--viewport-mobile-wide) {
            padding-inline-start: 40px;
        }
    }

    .item-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 8px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: relative;

        &.active {
            pointer-events: none;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                display: block;
                height: 2px;
                bottom: -2px;
                background: var(--av-nav-primary);
            }

            .label {
                color: var(--av-nav-primary);
            }
        }
    }

    .label {
        @mixin lead;

        width: 100%;
        color: var(--av-brand-secondary);
        white-space: nowrap;
        font-weight: 500;

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .hidden {
        display: none;
    }
}
</style>
