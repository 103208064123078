<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-products">
        <div class="products">
            <a-picture v-if="content.picture?.background" v-bind="content.picture" />
            <div class="a-container">
                <ul class="list">
                    <li
                        v-for="(tab, index) in content.list"
                        :key="index"
                        :class="['item', {'is-active': content.tabActive === index}]"
                    >
                        <dynamic-link
                            class="button"
                            :event="eventGA(tab.event)"
                            @click="$emit('setTabActive', index)"
                        >
                            <div class="name">
                                <div class="name-glyph">
                                    <a-glyph v-if="tab.glyph" v-bind="tab.glyph" />
                                </div>
                                <div>{{ tab.text }}</div>
                            </div>
                            <a-label v-if="tab.label?.text" v-bind="tab.label">
                                {{ tab.label.text }}
                            </a-label>
                        </dynamic-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import APicture from '@core/components/picture/picture.vue';
import mixin from '../components/mixin.js';

export default {
    name: 'SMainPageProducts',
    components: {
        DynamicLink,
        AGlyph,
        ALabel,
        APicture,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    emits: ['setTabActive'],
    computed: {
        isVisible() {
            return this.content?.list?.length;
        },
    },
    methods: {
        eventGA(event) {
            if (!event) return { doNotSendGA: true };
            return {
                event: 'Acronis',
                category: 'Interactions',
                action: 'Homepage Product Tab',
                context: 'click',
                content: '',
                location: 'Homepage Top Product Banner',
                ...event,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-products {
    padding-top: 96px;
    position: relative;
    border-bottom: 2px solid var(--av-brand-secondary-light);
    &:deep(.a-picture) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #063B89;
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
    .list {
        align-items: stretch;
        display: inline-flex;
        border-radius: 6px 6px 0 0;
        @media (--viewport-tablet) {
            width: 100%;
        }
        @media (--viewport-desktop) {
            width: auto;
        }
    }
    .item {
        flex: 1;
        border-top: 2px solid #063B89;
        border-inline-start: 2px solid #063B89;
        color: var(--av-inversed-primary);
        background: rgba(13, 86, 195, 0.40);
        @media (--viewport-desktop) {
            display: flex;
            align-items: center;
        }
        @media (--viewport-desktop) {
             flex: unset;
        }
        &:first-child {
            border-radius: 6px 0 0 0;
        }
        &:last-child {
            border-radius: 0 6px 0 0;
        }
        &.is-active {
            position: relative;
            color: var(--av-nav-primary);
            border-color: var(--av-inversed-primary);
            background: var(--av-inversed-primary);
            .button {
                cursor: default;
                &:after {
                    left: -2px;
                    content: '';
                    height: 4px;
                    bottom: -2px;
                    position: absolute;
                    width: calc(100% + 2px);
                    background: var(--av-inversed-primary);
                }
                &:deep(.a-glyph) {
                    fill: var(--av-nav-primary);
                }
            }
        }
    }
    .button {
        gap: 8px;
        width: 100%;
        padding: 8px;
        display: flex;
        cursor: pointer;
        text-align: center;
        flex-direction: column;
        @media (--viewport-tablet) {
            text-align: start;
        }
        @media (--viewport-tablet) {
            padding: 16px 24px;
        }
        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: center;
            text-align: start;
        }
        &:deep(.a-glyph) {
            min-width: 24px;
            fill: var(--av-inversed-primary);
        }
        &:deep(.a-label) {
            margin: 0 auto;
            @media (--viewport-desktop) {
                margin: 0;
            }
        }
    }
    .name {
        gap: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        @mixin descriptor-accent;
        @media (--viewport-tablet) {
            @mixin lead-accent;
            flex-direction: row;
            justify-content: center;
        }
    }
}
</style>
