<template>
    <s-basic-slice :id="id" class="s-cards-with-icon" :class="{ ...className, ...basicClassName }">
        <div class="s-cards-with-icon__text">
            <a-slice-header :title="l10n.title" :lead="l10n.lead" />
            <div v-if="l10n.subtitle" class="s-cards-with-icon__subtitle">
                {{ l10n.subtitle }}
            </div>
            <a-link
                v-if="l10n.downloadFile"
                class="s-cards-with-icon__download-file"
                v-bind="l10n.downloadFile"
                :to="downloadFile.link"
                :type="downloadFile.type || 'download-file'"
                :glyph="downloadFile.glyph || 'download-arrow'"
                :glyph-position="downloadFile.glyphPosition || 'left'"
                :text="l10n.downloadFile.title"
                :desc="l10n.downloadFile.desc"
            />
        </div>
        <ul class="s-cards-with-icon__list">
            <li v-for="card in l10n.cards" :key="card.text" class="s-cards-with-icon__item">
                <a-value-card v-bind="card">
                    {{ card.text }}
                </a-value-card>
            </li>
        </ul>
        <div class="s-cards-with-icon__link-container">
            <a-link
                v-if="l10n.link"
                class="s-cards-with-icon__link"
                v-bind="l10n.link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import AValueCard from '@core/components/value-card/value-card.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCardsWithIcon',

    components: {
        ALink,
        ASliceHeader,
        AValueCard,
        SBasicSlice,
    },

    mixins: [l10n, contentMixin, styleMixin],

    props: {
        cards: {
            type: Array,
            required: true,
        },

        link: {
            type: Object,
            default: undefined,
        },

        subtitle: {
            type: String,
            default: undefined,
        },

        /**
         * Download file
         */
        downloadFile: {
            type: Object,
            default: undefined,
        },

        isRowLayout: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        className() {
            return {
                's-cards-with-icon_layout_row': this.isRowLayout,
                's-cards-with-icon_layout_with-link': this.link,
                's-cards-with-icon_layout_2-cards': [2, 4].includes(this.cards.length),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cards-with-icon {
    &__list {
        margin-bottom: -16px;
    }

    &__item {
        margin-bottom: 16px;
    }

    &__link {
        margin-top: 56px;
    }

    &__subtitle {
        @mixin lead;

        margin-bottom: 40px;
    }

    &__download-file {
        margin-bottom: 40px;
    }

    @media (--viewport-mobile-wide) {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px -16px;
            width: 100%;
        }

        &__item {
            width: 50%;
            padding: 0 8px;
            display: flex;
        }

        &__subtitle {
            @mixin title;
        }
    }

    @media (--viewport-desktop) {
        &__item {
            width: 33.33%;
        }

        &_layout {
            &_with-link {
                .s-cards-with-icon {
                    &__list {
                        grid-row: span 2;
                        -ms-grid-row-span: 2;
                    }
                }
            }

            &_2-cards {
                .s-cards-with-icon {
                    &__list {
                        width: 66.66%;
                    }

                    &__item {
                        width: 50%;
                    }
                }
            }

            &_row {
                &:deep(.a-slice-header) {
                    width: 100%;

                    &__title {
                        width: 100%;
                    }

                    &__lead {
                        width: 100%;
                    }
                }

                &:deep(.a-container) {
                    display: grid;
                    grid-template-columns: calc(33.33% - 16px) 66.66%;
                    -ms-grid-columns: calc(33.33% - 16px) 16px 66.66%;
                    grid-template-rows: 1fr auto;
                    grid-column-gap: 16px;
                }

                .s-cards-with-icon {
                    &__text {
                        -ms-grid-column: 1;
                    }

                    &__list {
                        width: auto;
                        -ms-grid-column: 3;
                    }

                    &__item {
                        width: 50%;
                    }

                    &__link-container {
                        -ms-grid-column: 1;
                        -ms-grid-row: 2;
                    }
                }
            }
        }
    }
}
</style>
