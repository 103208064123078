<template>
    <s-basic-slice class="s-main-section">
        <a-breadcrumbs
            :links="breadcrumbs"
            :sublinks="true"
            class="space-top"
        />

        <div class="main-content">
            <div class="header">
                <div v-if="department" class="tag">
                    {{ department }}
                </div>
                <h1 class="title">
                    {{ title }}
                </h1>
                <p v-if="shortDescription" class="lead">
                    {{ shortDescription }}
                </p>
                <div class="features">
                    <div class="locations">
                        <s-job-locations
                            :primary-location="primaryLocation"
                            :additional-locations="additionalLocations"
                        />
                    </div>
                    <div v-if="timeType" class="time-type feature">
                        <div class="glyph-wrap">
                            <a-glyph name="i-case--16-1" />
                        </div>
                        {{ timeType }}
                    </div>
                    <div v-if="date" class="date feature">
                        <div class="glyph-wrap">
                            <a-glyph name="i-calendar--16" />
                        </div>
                        {{ dateTitle }}
                    </div>
                    <div v-if="jobId" class="job-id feature">
                        <div class="glyph-wrap">
                            <a-glyph name="i-folder--16" />
                        </div>
                        {{ jobId }}
                    </div>
                </div>
                <div class="buttons">
                    <a-button
                        v-bind="autofillButton"
                        :size="autofillButton.size || 'm'"
                        :type="autofillButton.type || 'main'"
                    />
                    <a-button
                        v-bind="applyButton"
                        :size="applyButton.size || 'm'"
                        :type="applyButton.type || 'main'"
                    />
                    <a-button
                        v-bind="useLastButton"
                        :size="useLastButton.size || 'm'"
                        :type="useLastButton.type || 'main'"
                    />
                </div>
                <div v-if="applicationNote" class="application-note">
                    <a-glyph :name="applicationNote.glyph" size="m" />
                    <div class="note-content">
                        {{ applicationNote.text }}
                    </div>
                </div>
            </div>

            <a-dangerous-html :content="fullDescription" class="job-body" />

            <div class="bottom">
                <div v-if="department" class="tag">
                    {{ department }}
                </div>
                <div class="bottom-title">
                    {{ title }}
                </div>
                <p v-if="shortDescription" class="lead">
                    {{ shortDescription }}
                </p>
                <div class="buttons">
                    <a-button
                        v-bind="autofillButton"
                        :size="autofillButton.size || 'm'"
                        :type="autofillButton.type || 'main'"
                    />
                    <a-button
                        v-bind="applyButton"
                        :size="applyButton.size || 'm'"
                        :type="applyButton.type || 'main'"
                    />
                    <a-button
                        v-bind="useLastButton"
                        :size="useLastButton.size || 'm'"
                        :type="useLastButton.type || 'main'"
                    />
                </div>
                <div v-if="applicationNote" class="application-note">
                    <a-glyph :name="applicationNote.glyph" size="m" />
                    <div class="note-content">
                        {{ applicationNote.text }}
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SJobLocations from './job-locations.vue';

export default {
    name: 'SJobDescriptionMainSection',

    components: {
        AButton,
        ABreadcrumbs,
        SBasicSlice,
        ADangerousHtml,
        AGlyph,
        SJobLocations,
    },

    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },

        department: {
            type: String,
            default: null,
        },

        jobId: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        shortDescription: {
            type: String,
            default: null,
        },

        fullDescription: {
            type: String,
            default: null,
        },

        date: {
            type: String,
            default: null,
        },

        primaryLocation: {
            type: String,
            required: true,
        },

        additionalLocations: {
            type: Array,
            default: () => [],
        },

        timeType: {
            type: String,
            default: null,
        },

        autofillButton: {
            type: Object,
            default: null,
        },

        applyButton: {
            type: Object,
            default: null,
        },

        useLastButton: {
            type: Object,
            default: null,
        },

        applicationNote: {
            type: Object,
            default: null,
        },

        postedDateTitle: {
            type: String,
            default: 'Posted $date Days Ago',
        },

        postedDateSingleTitle: {
            type: String,
            default: 'Posted $date Day Ago',
        },
    },

    computed: {
        dateTitle() {
            const date = new Date(this.date).getTime();
            const now = new Date().getTime();
            const ms = now - date;
            const days = Math.floor(ms / (1000 * 60 * 60 * 24));
            return days > 1 ? this.postedDateTitle.replace('$date', days) : this.postedDateSingleTitle.replace('$date', days);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-main-section {
    padding-top: 84px;
    padding-bottom: 64px;

    &:deep(.a-breadcrumbs .a-container) {
        padding: 0;
    }
}

.main-content {
    margin: 0 auto;

    @media (--viewport-desktop) {
        @mixin colls 10;
    }

    @media (--viewport-desktop-wide) {
        @mixin colls 8;
    }
}

.tag {
    @mixin note-heading;

    padding: 4px 12px;
    display: inline-flex;
    color: var(--av-nav-primary);
    border-radius: 16px;
    background: linear-gradient(90deg, #cde2ff 0%, #7db7ff 100%);
}

.header {
    margin-top: 48px;
    background: var(--av-solid-brand-lightest);
    padding: 40px 16px;
    border-radius: 16px;
    margin-bottom: 48px;
    text-align: center;

    @media (--viewport-tablet) {
        padding: 40px;
    }
}

.features {
    @mixin body;

    margin-top: 32px;
    padding: 24px 16px;
    border-radius: 8px;
    background: var(--av-brand-accent);
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--av-fixed-secondary);

    @media (--viewport-tablet) {
        align-items: baseline;
        flex-direction: row;
    }

    @media (--viewport-desktop) {
        justify-content: space-between;
    }

    .a-glyph {
        fill: var(--av-brand-primary);
        flex-shrink: 0;
    }
}

.locations {
    width: 100%;

    @media (--viewport-desktop) {
        width: auto;
        margin-inline-end: auto;
    }

    &:deep(.location.with-popup) {
        min-width: 260px;
        justify-content: center;

        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
    }

    &:deep(.glyph-wra.dropdown) {
        margin-inline-start: 0;
        @media (--viewport-desktop) {
            margin-inline-start: auto;
        }
    }
}

.s-job-locations {
    width: fit-content;
    margin: 0 auto;

    @media (--viewport-desktop) {
        min-width: 280px;
    }
}

.feature {
    display: inline-flex;
    align-items: center;
}

.glyph-wrap {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-inline-end: 4px;
}

.time-type,
.job-id {
    flex-shrink: 0;
}

.title {
    @mixin hero-accent;

    color: var(--av-nav-primary);
}

.lead {
    margin-top: 8px;
    color: var(--av-fixed-secondary);
}

.job-body {
    margin-bottom: 48px;
    color: var(--av-fixed-secondary);

    &:deep(p) {
        margin-bottom: 24px;
    }

    &:deep(h2) {
        @mixin title-accent;

        text-align: center;
        margin-top: 48px;
        margin-bottom: 16px;
    }

    &:deep(li) {
        margin-bottom: 0;
        padding-inline-start: 24px;
        position: relative;

        &:before {
            position: absolute;
            inset-inline-start: 8.5px;
            top: 10.5px;
            margin: 0;
            border-radius: 0;
        }
    }
}

.bottom-title {
    @mixin display-accent;

    color: var(--av-nav-primary);
}

.bottom {
    text-align: center;

    .lead {
        @mixin paragraph-accent;
    }
}

.buttons {
    margin-top: 32px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media (--viewport-tablet) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.application-note {
    @mixin descriptor;

    margin-top: 32px;
    border-radius: 8px;
    background: var(--av-brand-accent);
    color: var(--av-fixed-secondary);
    display: flex;
    padding: 8px 16px;
    max-width: 566px;
    margin-inline: auto;
    align-items: center;
    gap: 8px;

    .a-glyph {
        flex-shrink: 0;
        fill: var(--av-fixed-lighter);
    }

    .note-content {
        text-align: start;
    }
}

</style>
<style lang="postcss">
.el-popover.careers-location-popover {
    min-width: 270px;
}
</style>
