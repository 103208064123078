<template>
    <div class="root">
        <a
            ref="widgetTarget"
            class="twitter-widget-target"
        />
    </div>
</template>

<script>
import { Logger } from '@utils/logger';

export default {
    name: 'BlogTwitterSidebar',

    props: {
        feedAddress: {
            type: String,
            required: false,
            default: () => 'https://twitter.com/Acronis',
        },

        uiLanguage: {
            type: String,
            required: false,
            default: () => 'en',
        },
    },

    mounted() {
        const apiScript = document.createElement('script');
        apiScript.src = 'https://platform.twitter.com/widgets.js';
        apiScript.setAttribute('charset', 'utf-8');
        apiScript.addEventListener('load', this.onApiReady);
        document.body.appendChild(apiScript);
    },

    methods: {
        onApiReady() {
            if (!window.twttr) {
                Logger.error('window.twttr is not available');
                return;
            }

            const parsedProfileUrl = new URL(this.feedAddress);
            const profileName = parsedProfileUrl.pathname
                .split('/')
                .filter(Boolean)
                .shift();

            window.twttr.widgets.createTimeline(
                {
                    sourceType: 'profile',
                    url: profileName,
                },
                this.$refs.widgetTarget,
                {
                    // See: https://github.com/andrewsuzuki/react-twitter-widgets/issues/45#issuecomment-889733612
                    id: `profile:${profileName}`,
                    width: 394,
                    height: 1100,
                    chrome: 'transparent noborders',
                    lang: this.uiLanguage,
                },
            );
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    text-align: center;
    background: var(--av-brand-accent);
    border-radius: 4px;
}

.twitter-widget-target {
    color: var(--av-brand-secondary);
}
</style>
