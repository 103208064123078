/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

main.acronis-scs-main-page section.intro {
        padding-top: 88px;
        padding-bottom: 48px;
        background: linear-gradient(180deg, #f4f7fc 0%, #fff 100%);
    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.intro {
            padding-top: 128px
    }
        }

@media (min-width: 768px) {

main.acronis-scs-main-page section.intro {
            padding-top: 120px
    }
        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro {
            padding-top: 144px
    }
        }

main.acronis-scs-main-page section.intro .wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
        }

main.acronis-scs-main-page section.intro .wrapper .content {
                width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                color: var(--av-nav-primary);
            }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .content {
                    width: calc(((100% - (16px * 11)) / 12) * 4 + (16px * (4 - 1)))
            }
                }

main.acronis-scs-main-page section.intro .wrapper .content .title {
                    font-size: 32px;
                    line-height: 40px;
                    font-weight: 700;
                    margin-bottom: 16px;
                }

@media (min-width: 576px) {

main.acronis-scs-main-page section.intro .wrapper .content .title {
                        font-size: 40px;
                        line-height: 48px;
                        font-weight: 700
                }
                    }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .content .title {
                        font-size: 48px;
                        line-height: 56px;
                        font-weight: 700
                }
                    }

main.acronis-scs-main-page section.intro .wrapper .content .lead {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 24px;
                }

@media (min-width: 768px) {

main.acronis-scs-main-page section.intro .wrapper .content .lead {
                        margin-bottom: 40px
                }
                    }

main.acronis-scs-main-page section.intro .wrapper .content .button {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 8px 32px;
                    font-weight: 400;
                }

@media (min-width: 576px) {

main.acronis-scs-main-page section.intro .wrapper .content .button {
                        padding: 8px 30px
                }
                    }

main.acronis-scs-main-page section.intro .wrapper .content .certifications {
                    margin-top: 32px;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: space-between;
                }

@media (min-width: 576px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications {
                        margin-top: 40px
                }
                    }

@media (min-width: 1280px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications {
                        margin-top: 48px
                }
                    }

main.acronis-scs-main-page section.intro .wrapper .content .certifications .certifications-title {
                        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 500;
                        margin-bottom: 16px;
                    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .certifications-title {
                            width: calc(((100% - (16px * 11)) / 12) * 4 + (16px * (4 - 1)));
                            margin-bottom: 0
                    }
                        }

@media (min-width: 768px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .certifications-title {
                            width: auto
                    }
                        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .certifications-title {
                            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                            margin-bottom: 16px
                    }
                        }

main.acronis-scs-main-page section.intro .wrapper .content .certifications .logos {
                        display: flex;
                        flex-flow: row nowrap;
                        gap: 16px;
                        align-items: flex-start;
                    }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .logos {
                            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)))
                    }
                        }

main.acronis-scs-main-page section.intro .wrapper .content .certifications .logos:deep(.a-picture__img) {
                            width: auto;
                            height: 48px;
                        }

main.acronis-scs-main-page section.intro .wrapper .content .certifications .link {
                        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                        margin-top: 16px;
                    }

@media (min-width: 768px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .link {
                            width: auto
                    }
                        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .content .certifications .link {
                            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)))
                    }
                        }

main.acronis-scs-main-page section.intro .wrapper .image-wrapper {
                width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                margin-top: 40px;
            }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.intro .wrapper .image-wrapper {
                    width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)));
                    margin: 0
            }
                }

main.acronis-scs-main-page section.cta {
        background: var(--av-solid-brand-lightest);
        padding: 32px 16px;
    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.cta {
            padding: 32px 0
    }
        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.cta {
            padding: 44px 0
    }
        }

main.acronis-scs-main-page section.cta .wrapper {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            justify-content: space-between;
        }

main.acronis-scs-main-page section.cta .wrapper p {
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                color: var(--av-nav-primary);
                margin-bottom: 24px;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.cta .wrapper p {
                    margin-bottom: 40px
            }
                }

@media (min-width: 768px) {

main.acronis-scs-main-page section.cta .wrapper p {
                    width: auto;
                    margin: 0
            }
                }

main.acronis-scs-main-page section.cta .wrapper p:deep(strong) {
                    font-size: 24px;
                    line-height: 32px;
                    display: block;
                    font-weight: 700;
                    color: rgba(179, 25, 66, 1);
                }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.cta .wrapper p:deep(strong) {
                        display: inline-block
                }
                    }

main.acronis-scs-main-page section.cta .wrapper .button {
                padding: 12px 16px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
            }

main.acronis-scs-main-page section.content-blocks {
        padding-top: 48px;
        color: var(--av-nav-primary);
    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks {
            padding-top: 64px
    }
        }

main.acronis-scs-main-page section.content-blocks .head {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .head {
                width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
        }
            }

main.acronis-scs-main-page section.content-blocks .head .title {
                margin-bottom: 24px;
                font-size: 32px;
                line-height: 40px;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .head .title {
                    font-size: 40px;
                    line-height: 48px
            }
                }

@media (min-width: 1280px) {

main.acronis-scs-main-page section.content-blocks .head .title:deep(br) {
                        display: none
                }
                    }

main.acronis-scs-main-page section.content-blocks .head .lead {
                font-size: 18px;
                line-height: 24px;
            }

main.acronis-scs-main-page section.content-blocks .sections {
            padding-top: 48px;
            padding-bottom: 64px;
        }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections {
                padding-top: 64px;
                padding-bottom: 88px
        }
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top {
        position: relative;
        margin-top: 1px;
    }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            height: 1px;
            background-color: var(--av-brand-secondary-accent);
        }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_none {
            margin: 0;
        }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_border-dark::before {
                background-color: var(--av-brand-secondary-light);
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_full-width::before {
                width: 100%;
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_content-wide::before {
                inset-inline-start: var(--container-paddings-mobile);
                inset-inline-end: var(--container-paddings-mobile);
                width: auto;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_content-wide::before {
                    inset-inline-start: var(--container-paddings-mobile-wide)
            }
                }

@media (min-width: 1280px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_content-wide::before {
                    inset-inline-start: var(--container-paddings-desktop-wide);
                    inset-inline-end: var(--container-paddings-desktop-wide)
            }
                }

@media (min-width: 1440px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_content-wide::before {
                    max-width: 1312px;
                    inset-inline-start: var(--container-paddings-desktop-large);
                    inset-inline-end: var(--container-paddings-desktop-large);
                    margin: 0 auto
            }
                }

main.acronis-scs-main-page section.content-blocks .sections .section_border-top_short::before {
                inset-inline-start: 50%;
                right: unset;
                width: 65%;
                max-width: 760px;
                transform: translateX(-50%);
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom {
        position: relative;
        margin-bottom: 1px;
    }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom::after {
            content: "";
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            height: 1px;
            background-color: var(--av-brand-secondary-accent);
        }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_none {
            margin: 0;
        }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_border-dark::after {
                background-color: var(--av-brand-secondary-light);
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_full-width::after {
                width: 100%;
            }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_content-wide::after {
                inset-inline-start: var(--container-paddings-mobile);
                inset-inline-end: var(--container-paddings-mobile);
                width: auto;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_content-wide::after {
                    inset-inline-start: var(--container-paddings-mobile-wide)
            }
                }

@media (min-width: 1280px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_content-wide::after {
                    inset-inline-start: var(--container-paddings-desktop-wide);
                    inset-inline-end: var(--container-paddings-desktop-wide)
            }
                }

@media (min-width: 1440px) {

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_content-wide::after {
                    max-width: 1312px;
                    inset-inline-start: var(--container-paddings-desktop-large);
                    inset-inline-end: var(--container-paddings-desktop-large);
                    margin: 0 auto
            }
                }

main.acronis-scs-main-page section.content-blocks .sections .section_border-bottom_short::after {
                inset-inline-start: 50%;
                right: unset;
                width: 65%;
                max-width: 760px;
                transform: translateX(-50%);
            }

main.acronis-scs-main-page section.content-blocks .sections .section {
                padding: 48px 0;
                color: var(--av-nav-primary);
}

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections .section {
                    padding: 64px 0
            }
                }

main.acronis-scs-main-page section.content-blocks .sections .section:first-child {
                    padding-top: 0;
                }

main.acronis-scs-main-page section.content-blocks .sections .section:last-child {
                    padding-bottom: 0;
                }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: center;
                }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper {
                        justify-content: space-between
                }
                    }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content {
                        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                        margin-bottom: 40px;
                    }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content {
                            width: calc(((100% - (16px * 11)) / 12) * 6 + (16px * (6 - 1)));
                            margin-bottom: 0
                    }
                        }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content .title {
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 700;
                            margin-bottom: 24px;
                        }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content .title {
                                font-size: 24px;
                                line-height: 32px;
                                font-weight: 400
                        }
                            }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content .text {
                            font-size: 16px;
                            line-height: 24px;
                        }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content .text.has-link {
                                margin-bottom: 40px;
                            }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content:deep(.link) .a-link__content, main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .content:deep(.link-mobile) .a-link__content {
                                font-weight: 700;
                            }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .image-wrapper {
                        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .image-wrapper {
                            width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
                    }
                        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper .image-wrapper {
                            width: calc(((100% - (16px * 11)) / 12) * 5 + (16px * (5 - 1)))
                    }
                        }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper.reversed {
                        flex-flow: row wrap-reverse;
                    }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper.reversed {
                            flex-flow: row-reverse
                    }
                        }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper.reversed .content {
                            margin: 0;
                        }

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper.reversed .image-wrapper {
                            margin-bottom: 40px;
                        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.content-blocks .sections .section .wrapper.reversed .image-wrapper {
                                margin-bottom: 0
                        }
                            }

main.acronis-scs-main-page section.testimonials {
        padding: 48px 0 64px;
        background: linear-gradient(180deg, #f4f7fc 0%, #fff 100%);
        color: var(--av-nav-primary);
        overflow: hidden;
    }

@media (min-width: 576px) {

main.acronis-scs-main-page section.testimonials {
            padding: 64px 0
    }
        }

main.acronis-scs-main-page section.testimonials .head {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.testimonials .head {
                width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
        }
            }

main.acronis-scs-main-page section.testimonials .head .title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 24px;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.testimonials .head .title {
                    font-size: 40px;
                    line-height: 48px
            }
                }

main.acronis-scs-main-page section.testimonials .head .lead {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 32px;
            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.testimonials .head .lead {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 40px
            }
                }

@media (min-width: 768px) {

main.acronis-scs-main-page section.testimonials .head .lead {
                    margin-bottom: 48px
            }
                }

@media (min-width: 768px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider) {
                overflow: visible
        }
            }

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide {
                width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
            }

@media (min-width: 768px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide {
                    width: calc(((100% - (16px * 11)) / 12) * 9 + (16px * (9 - 1)));
                    margin-inline-end: calc(8.3333% + 17.3333px)
            }
                }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide {
                    width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
            }
                }

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide:last-child {
                    width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                    margin-inline-end: 0;
                }

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide:last-child .testimonial {
                        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                    }

@media (min-width: 768px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide:last-child .testimonial {
                            width: calc(((100% - (16px * 11)) / 12) * 9 + (16px * (9 - 1)))
                    }
                        }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide:last-child .testimonial {
                            width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
                    }
                        }

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide .testimonial {
                    width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--av-fixed-secondary);
                }

main.acronis-scs-main-page section.testimonials:deep(.a-slider) .a-slide .testimonial .text {
                        margin-bottom: 32px;
                    }

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) {
                margin: 0;
                margin-top: 32px;

            }

@media (min-width: 576px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) {
                    margin-top: 48px

            }
                }

@media (min-width: 768px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) {
                    margin-top: 40px

            }
                }

@media (min-width: 1024px) {

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) {
                    margin-top: 48px

            }
                }

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) .a-slider__counter-current,
                    main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) .a-slider__counter-text,
                    main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) .a-slider__counter-total {
                        color: var(--av-fixed-light);
                        font-size: 18px;
                    }

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) .a-slider__counter-current {
                        font-weight: 600;
                    }

main.acronis-scs-main-page section.testimonials:deep(.a-slider):deep(.a-slider__nav) .a-slider__counter-text {
                        margin: 0 8px;
                    }

main.acronis-scs-main-page section.testimonials:deep(.a-slider).a-slider_off .a-slide {
                    opacity: 1;
                }

main.acronis-scs-main-page section.testimonials:deep(.a-slider).a-slider_off .a-slider__nav {
                    display: none;
                }
