<template>
    <div v-if="content" class="s-form-upsell-container">
        <div v-if="!content.table" class="s-form-upsell">
            <div v-if="content.flag" class="s-form-upsell-flag">
                {{ content.flag }}
            </div>
            <div v-if="content.title" class="s-form-upsell-title">
                {{ content.title }}
            </div>
            <a-picture v-if="content.image" class="s-form-upsell-image" :link="content.image" />
            <div v-if="content.description" class="s-form-upsell-description">
                {{ content.description }}
            </div>
            <a-button
                v-if="content.button"
                class="s-form-upsell-button"
                to="#"
                :text="content.button"
                :event="content.buttonEventGA || { doNotSendGA: true }"
                @click="onClick()"
            />
            <a-button
                v-if="content.link"
                class="s-form-upsell-link"
                :text="content.link"
                to="#"
                :event="content.linkEventGA || { doNotSendGA: true }"
                @click="onSubmit()"
            />
        </div>
        <div v-else class="s-form-upsell-table">
            <div v-if="content.table?.length" class="table">
                <div v-for="(row, rowIndex) in content.table" :key="`row-${rowIndex}`" class="row">
                    <template v-for="(cell, cellIndex) in row">
                        <div :key="`cell-${cellIndex}`" class="cell" v-html="cell" />
                    </template>
                </div>
            </div>
            <div v-if="content.buttons?.length" class="buttons">
                <a-button
                    v-for="(button, buttonIndex) in content.buttons"
                    :key="`button-${buttonIndex}`"
                    :event="button.event || { doNotSendGA: true }"
                    v-bind="button"
                    @click="button.hasRedirect ? onClick(): onSubmit()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormUpsell',

    components: {
        AButton,
        APicture,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    emits: ['submit', 'showNotification'],

    mounted() {
        this.sendDataLayer({
            eventCategory: 'Upsell',
            eventAction: 'show',
            eventLabel: 'dnl_typage_promo',
            formFieldA: 'Prosumer End-Customer',
        });
    },

    methods: {
        onSubmit() {
            this.sendDataLayer({
                eventCategory: 'Upsell',
                eventAction: 'click',
                eventLabel: 'Acronis Backup',
            });
            this.$emit('submit');
        },

        onClick() {
            const notification = get(this, 'content.notification', null);
            const targetURL = `https://www.acronis.com/${this.locale}/homecomputing/thanks/home-office/`;
            const url = this.content?.buttonTargetURL || targetURL;

            this.sendDataLayer({
                eventCategory: 'Upsell',
                eventAction: 'click',
                eventLabel: 'Acronis True Image',
            });

            if (isEmpty(notification)) {
                window.location.assign(url);
                return;
            }

            if (notification.openLink) {
                window.open(url);
            }

            this.$emit('showNotification', notification);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-upsell {
    @mixin body;
    text-align: center;
    padding: 40px 16px 56px;
    background: var(--av-inversed-primary);
    @media (--viewport-mobile-wide) {
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }
    @media (--viewport-tablet) {
        padding: 40px 48px;
    }
    &-flag {
        @mixin note;
        color: #83ad00;
        min-width: 110px;
        padding: 4px 12px;
        border-radius: 12px;
        display: inline-block;
        background-color: rgba(141, 183, 14, 0.15);
    }

    &-title {
        @mixin title-accent;
        margin-top: 16px;
        color: var(--av-fixed-secondary);
    }

    &-image {
        margin: 24px auto 0;
        width: 248px;
        height: 168px;
    }

    &-description {
        margin-top: 24px;
        color: var(--av-fixed-secondary);
    }

    &-button {
        @mixin body;
        color: var(--av-inversed-primary);
        display: block;
        margin-top: 24px;
        padding: 4px 16px;
        background-color: #4875b3;

        &:hover {
            color: var(--av-inversed-primary);
            background-color: #4875b3;
        }
    }

    &-link {
        padding: 0;
        color: #0275d8;
        font-size: 14px;
        font-weight: 400;
        background: none;
        margin-top: 16px;
        line-height: 20px;
        white-space: unset;

        &:hover {
            background: none;
            color: #014c8c;
        }
    }

    &-link,
    &-button {
        border: none;
        box-shadow: none;
    }
}
.s-form-upsell-table {
    z-index: 1;
    margin: 0 16px;
    text-align: center;
    border-radius: 16px;
    box-shadow: var(--av-shadow-small);
    border: 1px solid var(--av-brand-light);
    background: var(--av-inversed-primary);
    @media (--viewport-tablet) {
        margin: 0;
        text-align: left;
        width: calc(100% - 64px);
    }
    @media (--viewport-desktop) {
        max-width: 984px;
    }
    .table {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
        .row {
            gap: 8px 0;
            display: flex;
            padding: 16px;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            background: var(--av-brand-lightest);
            border-bottom: 1px solid var(--av-brand-accent);
            @media (--viewport-tablet) {
                gap: 32px;
                padding: 16px 32px;
            }
            @media (--viewport-desktop) {
                padding: 16px 48px;
            }
            &:first-child {
                gap: 24px 0;
                padding: 24px 16px;
                align-items: flex-start;
                background: transparent;
                box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.10);
                border-bottom: 1px solid var(--av-brand-accent);
                @media (--viewport-tablet) {
                    gap: 32px;
                    padding: 24px 32px;
                }
                @media (--viewport-desktop) {
                    padding: 24px 48px;
                }
                .cell {
                    padding: 0 16px;
                    @media (--viewport-tablet) {
                        padding: 0;
                    }
                }
            }
        }
        .cell {
            width: 50%;
            @media (--viewport-tablet) {
                flex: 1;
                width: auto;
            }
            &:first-child {
                width: 100%;
                font-weight: 700;
                @media (--viewport-tablet) {
                    width: auto;
                    min-width: 237px;
                    font-weight: 400;
                }
            }
        }
        &:deep(.title) {
            font-weight: 400;
            b {
                @mixin lead-accent;
                display: block;
                color: var(--av-nav-primary);
                @media (--viewport-tablet) {
                    @mixin title-accent;
                }
            }

        }
         &:deep(.product) {
            font-weight: 700;
            color: var(--av-nav-primary);
        }
        &:deep(.note) {
            @mixin body;
            padding: 0 16px;
            font-weight: 400;
            text-align: start;
            color: var(--av-fixed-light);
            @media (--viewport-tablet) {
                padding: 0;
            }
        }
    }
    .buttons {
        gap: 16px;
        display: flex;
        padding: 24px 16px;
        flex-direction: row;
        @media (--viewport-tablet) {
            gap: 56px;
            padding: 24px 104px;
            justify-content: center;
        }
        .a-button {
            white-space: unset;
            text-align: center;
            @media (--viewport-tablet) {
                flex: 1;
                align-items: center;
            }
        }
    }
}
</style>
