/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-header-with-anchor-cards ~ .s-anchor-menu {
        height: 0;
        visibility: hidden;
    }

.s-header-with-anchor-cards ~ .s-anchor-menu.is-sticky {
            height: 56px;
            visibility: visible;
        }

.s-header-with-anchor-cards:deep(.a-picture__img) {
        top: 0;
        height: auto;
        bottom: auto;
    }

.s-header-with-anchor-cards .a-slice-header__title {
            font-size: 40px;
            line-height: 48px;
            font-weight: 300;
            letter-spacing: -0.8px;
            color: var(--av-inversed-primary);
        }

.s-header-with-anchor-cards .a-slice-header__description {
            font-size: 32px;
            line-height: 40px;
            color: var(--av-inversed-primary);
            font-weight: 300;
        }

@media (min-width: 1280px) {

.s-header-with-anchor-cards .a-slice-header__description {
                font-size: 48px;
                line-height: 56px
        }
            }

.s-header-with-anchor-cards .a-slice-header__lead {
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            margin-top: 248px;
            color: var(--av-inversed-primary);
        }

.s-header-with-anchor-cards {

    padding-top: 72px;
    position: relative;
    padding-bottom: 56px;
    background-color: #091f58;
    color: var(--av-inversed-primary);
}

.s-header-with-anchor-cards__list {
        display: flex;
        margin-top: 40px;
        align-items: stretch;
        flex-direction: column;
    }

.s-header-with-anchor-cards__item {
        text-align: center;
        border-radius: 4px;
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-fixed-lightest);
    }

.s-header-with-anchor-cards__item:not(:first-child) {
            margin-top: 16px;
        }

.s-header-with-anchor-cards__item-link {
        height: 100%;
        padding: 16px;
        display: block;
        text-decoration: none;
    }

.s-header-with-anchor-cards__text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        color: var(--av-brand-secondary);
    }

.s-header-with-anchor-cards__description {
        font-size: 14px;
        line-height: 24px;
        display: block;
        margin-top: 8px;
        color: var(--av-fixed-secondary);
    }

.s-header-with-anchor-cards__cta {
        margin-top: 40px;
        text-align: center;
    }

.s-header-with-anchor-cards__item-cta:not(:first-child) {
            margin-top: 16px;
        }

.s-header-with-anchor-cards__item-cta .a-button {
            width: 100%;
        }

.s-header-with-anchor-cards__item-cta .a-link {
            color: var(--av-inversed-light);
        }

.s-header-with-anchor-cards__menu {
        visibility: hidden;
        margin-bottom: -56px;
    }

.s-header-with-anchor-cards__menu.is-sticky {
            visibility: visible;
        }

.s-header-with-anchor-cards__content-page {
        text-align: start;
        padding: 48px 8px;
        background-color: var(--av-inversed-primary);
        border-radius: 8px;
        box-shadow: var(--av-shadow-modal);
    }

@media (min-width: 768px) {

.s-header-with-anchor-cards__content-page {
            padding: 48px 60px
    }
        }

@media (min-width: 1024px) {

.s-header-with-anchor-cards__content-page {
            max-width: 798px;
            padding: 48px 82px 64px
    }
        }

@media (min-width: 1280px) {

.s-header-with-anchor-cards__content-page {
            max-width: 761px;
            padding: 48px 96px 64px
    }
        }

@media (min-width: 1440px) {

.s-header-with-anchor-cards__content-page {
            max-width: 870px;
            padding: 48px 111px 64px
    }
        }

.s-header-with-anchor-cards__content-page-icon {
        width: 64px;
        height: 64px;
        margin: 0 auto;
    }

@media (min-width: 1024px) {

.s-header-with-anchor-cards__content-page-icon {
            width: 96px;
            height: 96px
    }
        }

.s-header-with-anchor-cards__content-page-title {
        font-size: 18px;
        line-height: 24px;
        margin: 24px 0 0;
        color: var(--av-fixed-secondary);
    }

@media (min-width: 1024px) {

.s-header-with-anchor-cards__content-page-title {
            font-size: 24px;
            line-height: 32px
    }
        }

.s-header-with-anchor-cards__content-page-list {
        margin: 16px 0 0;
        padding-inline-start: 16px;
        list-style: decimal;
    }

@media (min-width: 768px) {

.s-header-with-anchor-cards__content-page-list {
            margin-top: 24px
    }
        }

.s-header-with-anchor-cards__content-page-list-item {
        font-size: 14px;
        line-height: 24px;
        padding-inline-start: 8px;
        color: var(--av-fixed-secondary);
    }

.s-header-with-anchor-cards__content-page-list-item:not(:first-child) {
            margin: 16px 0 0;
        }

.s-header-with-anchor-cards__content-page-list-item a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

@media (min-width: 1024px) {

.s-header-with-anchor-cards__content-page-list-item {
            font-size: 16px;
            line-height: 24px
    }
        }

.s-header-with-anchor-cards__content-page-note {
        font-size: 14px;
        line-height: 24px;
        color: var(--av-fixed-secondary);
        margin-top: 24px;
        padding-inline-end: 40px;
    }

.s-header-with-anchor-cards__content-page-note a {
            font-weight: 600;
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

@media (min-width: 1024px) {

.s-header-with-anchor-cards__content-page-note {
            font-size: 16px;
            line-height: 24px
    }
        }

@media (min-width: 768px) {
        .s-header-with-anchor-cards .a-slice-header {
            margin-bottom: 32px;
        }

        .s-header-with-anchor-cards__list {
            flex-wrap: wrap;
            flex-direction: row;
            margin: -16px -8px 0;
        }

        .s-header-with-anchor-cards__cta,
        .s-header-with-anchor-cards__item {
            margin: 16px 8px 0;
            width: calc(50% - 8px * 2);
        }

            .s-header-with-anchor-cards__cta:first-child, .s-header-with-anchor-cards__item:first-child {
                margin-top: 16px;
            }

        .s-header-with-anchor-cards__cta-list {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: center;
        }
            .s-header-with-anchor-cards__item-cta:not(:first-child) {
                margin-top: 8px;
            }

            .s-header-with-anchor-cards__item-cta .a-button {
                width: auto;
                min-width: 190px;
            }
}

@media (min-width: 576px) {
            .s-header-with-anchor-cards .a-slice-header__lead {
                font-size: 40px;
                line-height: 48px;
                font-weight: 700;
            }
}

@media (min-width: 1024px) {
            .s-header-with-anchor-cards .a-slice-header__lead {
                font-size: 48px;
                line-height: 56px;
                font-weight: 700;
                margin-top: 128px;
            }

        .s-header-with-anchor-cards:deep(.a-picture__img) {
                height: 100%;
        }

        .s-header-with-anchor-cards__cta,
        .s-header-with-anchor-cards__item {
            width: calc(33.33% - 8px * 2);
        }
}
