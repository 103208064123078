<template>
    <div class="s-job-locations">
        <el-popover
            v-if="additionalLocations.length"
            placement="bottom-end"
            trigger="click"
            popper-class="careers-location-popover"
            :width="popoverWidth"
        >
            <div class="popover-inner">
                <div class="location">
                    <div class="glyph-wrap">
                        <a-glyph name="pin--16" size="m" />
                    </div>
                    {{ primaryLocation }} {{ primaryLocationLabel }}
                </div>
                <div v-for="location in additionalLocations" :key="location" class="location">
                    <div class="glyph-wrap">
                        <a-glyph name="pin--16" size="m" />
                    </div>
                    {{ location }}
                </div>
            </div>
            <template #reference>
                <div class="location with-popup">
                    <div class="glyph-wrap">
                        <a-glyph name="pin--16" size="m" />
                    </div>
                    <div class="location-text">
                        {{ primaryLocation }} {{ primaryLocationLabel }}
                    </div>
                    <div class="glyph-wrap dropdown">
                        <a-glyph name="chevron-down" size="s" />
                    </div>
                </div>
            </template>
        </el-popover>
        <div v-else class="location">
            <div class="glyph-wrap">
                <a-glyph name="pin--16" size="m" />
            </div>
            <div class="location-text">
                {{ primaryLocation }}
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'SJobLocations',
    components: {
        AGlyph,
        ElPopover: () => import('@uikit/ui-kit/packages/popover'),
    },

    props: {
        primaryLocation: {
            type: String,
            required: true,
        },

        additionalLocations: {
            type: Array,
            default: () => [],
        },

        primaryLocationLabel: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            popoverWidth: null,
        };
    },

    mounted() {
        window.addEventListener('resize', this.calculatePopoverWidth);
        this.calculatePopoverWidth();
    },

    destroy() {
        window.removeEventListener('resize', this.calculatePopoverWidth);
    },

    methods: {
        calculatePopoverWidth() {
            const locationBlockWidth = this.$el.offsetWidth;
            this.popoverWidth = Number.isInteger(locationBlockWidth) ? locationBlockWidth + 9 : 'auto';
        },
    },
};
</script>
<style lang="postcss" scoped>
.location {
    display: flex;
    width: 100%;
    align-items: flex-start;

    &.with-popup {
        cursor: pointer;
    }
}

.s-job-locations:deep(.el-popover__reference) {
    width: 100%;
}

.glyph-wrap {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &.dropdown {
        margin-inline-start: auto;
    }

    .a-glyph {
        fill: var(--av-brand-primary);
    }
}

.location-text {
    margin-inline-start: 4px;
}
</style>
<style lang="postcss">
.el-popover.careers-location-popover {
    border-radius: 4px;
    padding: 16px 8px;
    border: 1px solid var(--av-brand-primary);
    max-width: none;

    .popover-inner {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .location {
        display: flex;
        align-items: flex-start;

        .a-glyph {
            fill: var(--av-brand-primary);
        }
    }

    .glyph-wrap {
        display: inline-flex;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        justify-content: center;
        margin-inline-end: 4px;
    }
}
</style>
