<template>
    <section v-if="content" class="landing-page-partners">
        <div class="a-container">
            <p v-if="content.title" class="title" v-html="content.title" />
            <div v-if="content.list" class="wrapper">
                <div class="buttons">
                    <a-button
                        v-for="(button, buttonIndex) in buttons"
                        v-bind="button.button"
                        :key="`button-${buttonIndex}`"
                        :class="{'a-button__active': active === buttonIndex}"
                        @click="active = buttonIndex"
                    />
                </div>
                <div class="partners">
                    <div v-for="(partner, partnerIndex) in partners" :key="`partner-${partnerIndex}`">
                        <dynamic-link v-if="partner.to" to="/" native-type="a">
                            <a-picture :link="partner.logo" />
                        </dynamic-link>
                        <a-picture v-else :link="partner.logo" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SPartners',
    components: {
        AButton,
        APicture,
        DynamicLink,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            active: 0,
        };
    },
    computed: {
        buttons() {
            return this.content.list.filter((item) => item.items?.length);
        },
        partners() {
            return this.content.list[this.active].items;
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-partners {
    padding: 64px 0;
    background: linear-gradient(180deg, rgba(3, 4, 26, 0.00) 0%, #03041A 23.89%);
    background-color: #03041a;
    .title {
        @mixin display-accent;
        text-align: center;
        @media (--viewport-tablet) {
            font-size: 40px;
            font-weight: 500;
            line-height: 56px;
        }
    }
    .wrapper {
        gap: 16px;
        display: flex;
        margin-top: 16px;
        flex-direction: column;
        @media (--viewport-desktop) {
            gap: 32px;
            margin-top: 24px;
        }
    }
    .buttons {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        .a-button {
            padding: 8px;
            color: var(--av-inversed-primary);
            &:hover,
            &:active {
                background: var(--av-brand-secondary-light);
            }
        }
        .a-button__active {
            border-radius: 8px;
            background: var(--av-brand-secondary-light);
        }
    }
    .partners {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        @media (--viewport-tablet) {
            gap: 40px;
            flex-direction: row;
            justify-content: center;
        }
        @media (--viewport-desktop) {
            margin: 0 auto;
            padding: 0 96px;
        }
        @media (--viewport-desktop-wide) {
            padding: 0
        }
        .a-picture {
            min-height: 64px;
            max-width: 170px;
        }
    }
}
</style>
