<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <iframe
        v-if="enabled"
        id="sfchat-iframe"
        :style="style"
        :src="iframeSource"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import { locales, pageRegex, getEnableAfter } from './constants.js';

export default {
    data() {
        return {
            style: '',
            enabled: false,
            iframeSource: '',
        };
    },
    computed: {
        ...mapGetters('config', ['$config']),
        locale() {
            return this.$route?.params.locale || 'en-us';
        },
        enabledAfter() {
            if (localStorage.getItem('acronis_sfchat_maximized') === '1') {
                return 0;
            }

            return getEnableAfter(window.location.pathname);
        },
    },
    mounted() {
        if (!this.checkEligibility()) return;
        setTimeout(() => {
            this.enabled = true;
            this.iframeSource = `/public/integrations/sfchat?lang=${this.locale.slice(0, 2)}`;
        }, this.enabledAfter);
        window.addEventListener('message', this.onMessageReceive);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessageReceive);
    },
    methods: {
        onMessageReceive(e) {
            if (e.data.sender !== 'sf_iframe') return;
            if (e.data.message === 'onHelpButtonClick' || e.data.message === 'afterMaximize') {
                localStorage.setItem('acronis_sfchat_maximized', 1);
                this.style = 'height: 670px; width: 400px;';
            } else if (e.data.message === 'afterMinimize' || e.data.message === 'afterDestroy') {
                localStorage.setItem('acronis_sfchat_maximized', 0);
                this.style = 'height: 70px; width: 220px';
            } else if (e.data.message === 'onLoad') {
                e.source.postMessage(
                    {
                        message: 'callback',
                        sender: 'sf_chat_interpreter',
                        sliceData: this.$store.state.slices.items?.['s-chat-settings'] || {},
                    },
                    e.origin,
                );
            } else {
                this.style = '';
            }
        },
        checkEligibility() {
            const localeEligible = locales.includes(this.locale);
            const routeEligible = pageRegex.some((reg) => reg.test(window.location.pathname));

            return localeEligible && routeEligible;
        },
    },
    async serverPrefetch() {
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-chat-settings', locale: 'null' });
    },
};
</script>
<style scoped>
#sfchat-iframe {
    border: none;
    bottom: 0;
    inset-inline-end: 0;
    position: fixed;
    height: 70px;
    width: 200px;
    z-index: 500;
    overflow: hidden;
}
</style>
