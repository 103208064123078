/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.a-container {
    width: 100%;
}

.a-service-card {
    width: 100%;
}

.s-header-service {
    width: 100%;
    display: flex;
    background-color: var(--av-solid-brand-accent);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 51px;
    padding-bottom: 24px;
}

.s-header-service_theme_dark {
            background-color: #00214d;
        }

.s-header-service_theme_dark .s-header-service__product {
                color: var(--av-inversed-primary);
            }

.s-header-service_text .s-header-service__left {
            align-self: center;
        }

.s-header-service_text .s-header-service__left {
            grid-row-start: 3;
            margin-top: 0;
            margin-bottom: 24px;
        }

.s-header-service_text .s-header-service__card-wrapper {
            grid-row-start: 4;
        }

@media (min-width: 576px) {
            .s-header-service_text .s-header-service__update-text {
                max-width: 50%;
            }
        }

@media (min-width: 1024px) {
            .s-header-service_text .s-header-service__update-text {
                max-width: 100%;
            }

            .s-header-service_text .s-header-service__card-wrapper {
                grid-row-start: 3;
            }

            .s-header-service_text .s-header-service__left {
                margin-bottom: 0;
            }
        }

@media (min-width: 1024px) {
            .s-header-service_large .s-header-service__content {
                grid-template-rows: 2fr 1fr;
            }
        }

@media (min-width: 576px) {
            .s-header-service_large .s-header-service__card {
                width: calc(50% - 8px);
            }
        }

@media (min-width: 576px) {
            .s-header-service_full .s-header-service__left {
                align-self: end;
                margin-bottom: 24px;
            }
        }

@media (min-width: 1024px) {
            .s-header-service_full .s-header-service__content {
                grid-template-rows: 2fr 1fr 1fr;
                grid-template-columns: 1fr;
            }

            .s-header-service_full .s-header-service__card-wrapper {
                grid-row-start: 3;
            }

            .s-header-service_full .s-header-service__card {
                width: calc((100% / 3) - 11px);
            }
        }

.s-header-service__card {
        width: 100%;
    }

.s-header-service__left {
        grid-row-start: 4;
        margin-top: 24px;
    }

@media (min-width: 576px) {

.s-header-service__left {
            grid-row-start: 3;
            margin-bottom: 24px;
            margin-top: 0
    }
        }

@media (min-width: 1024px) {

.s-header-service__left {
            margin-bottom: 0
    }
        }

.s-header-service__card-wrapper {
        grid-row-start: 3;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 16px;
    }

@media (min-width: 576px) {

.s-header-service__card-wrapper {
            grid-row-start: 4
    }
        }

.s-header-service__alt-info {
        grid-row-start: 4;
        -ms-grid-row: 4;
        width: 100%;
    }

.s-header-service__top {
        width: 100%;
        -ms-grid-row: 1;
    }

.s-header-service__links {
        -ms-grid-row: 2;
    }

.s-header-service__content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows:
            minmax(min-content, 350px) minmax(0, max-content)
            1fr;
        -ms-grid-columns: 100%;
        -ms-grid-rows: minmax(min-content, 350px) minmax(0, max-content) 1fr;
        height: 100%;
    }

@media (min-width: 1280px) {

.s-header-service__content {
            grid-template-rows:
                minmax(min-content, 400px) minmax(0, max-content)
                1fr;
            -ms-grid-columns: 100%;
            -ms-grid-rows: minmax(min-content, 400px) minmax(0, max-content) 1fr
    }
        }

.s-header-service__product {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        color: #00204d;
        margin: 30px 0;
        max-width: 327px;
    }

@media (min-width: 576px) {

.s-header-service__product {
            font-size: 40px;
            line-height: 48px;
            font-weight: 700
    }
        }

@media (min-width: 1024px) {

.s-header-service__product {
            font-size: 48px;
            line-height: 56px;
            font-weight: 700
    }
        }

.s-header-service__label-flag {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

.s-header-service__info-flag {
        font-weight: 600;
        color: var(--av-inversed-light);
    }

.s-header-service__price {
        font-size: 24px;
        line-height: 32px;
    }

@media (min-width: 768px) {

.s-header-service__price {
            font-size: 32px;
            line-height: 40px;
    }
        }

.s-header-service__price {

        margin-bottom: 24px;
}

.s-header-service__block:first-child {
            margin-bottom: 40px;
        }

.s-header-service__title {
        font-size: 24px;
        line-height: 32px;

        margin-bottom: 48px;
    }

@media (min-width: 1024px) {

.s-header-service__title {
            font-size: 32px;
            line-height: 40px
    }
        }

@media (min-width: 1280px) {

.s-header-service__title {
            font-size: 40px;
            line-height: 48px
    }
        }

.s-header-service__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

.s-header-service__link_call {
            color: var(--av-inversed-primary);
        }

.s-header-service__update-text {
        font-weight: 600;
    }

.s-header-service__buttons {
        margin-bottom: 24px;
    }

.s-header-service__button-wrapper {
        position: relative;
    }

.s-header-service__button-wrapper:not(:first-child) {
            margin-top: 16px;
        }

.s-header-service__button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

.s-header-service__button .a-glyph {
            display: none;
        }

.s-header-service__button-disclaimer {
        font-size: 12px;
        line-height: 16px;

        display: none;
        position: absolute;
        width: 100%;
        margin-top: 8px;
        text-align: center;
        color: var(--av-inversed-light);
    }

.s-header-service__link-item:not(:last-child) {
        margin-bottom: 16px;
    }

@media (min-width: 576px) {
        .s-header-service__product {
            margin: 40px 0;
        }

        .s-header-service__top {
            width: 50%;
        }

        .s-header-service__buttons {
            display: flex;
        }

        .s-header-service__button-wrapper {
            width: calc(50% - 8px);
            min-width: 164px;
        }

            .s-header-service__button-wrapper:not(:first-child) {
                margin-inline-start: 16px;
                margin-top: 0;
            }

        .s-header-service__button {
            display: inline-flex;
            justify-content: space-between;
        }

            .s-header-service__button .a-glyph {
                display: block;
            }
    }

@media (min-width: 768px) {

.s-header-service {
        padding-top: 67px;
        padding-bottom: 40px
}

        .s-header-service__bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

            .s-header-service__bottom:first-child {
                margin-bottom: 40px;
            }

        .s-header-service__block {
            display: flex;
        }

            .s-header-service__block:first-child {
                align-items: flex-end;
                margin-bottom: 24px;
            }

        .s-header-service__price,
        .s-header-service__links {
            flex-grow: 0;
            flex-shrink: 0;
            padding-inline-end: 16px;
        }

        .s-header-service__price {
            margin-bottom: 0;
        }

        .s-header-service__links {
            display: block;
        }

        .s-header-service__buttons {
            margin-bottom: 16px;
        }

        .s-header-service__button-wrapper {
            width: auto;
        }

        .s-header-service__button {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

@media (min-width: 1024px) {

.s-header-service {
        height: 600px;
        padding-top: 72px;
        padding-bottom: 40px
}

        .s-header-service_large {
            height: 800px;
        }

        .s-header-service__content {
            grid-template-columns: 4fr 8fr;
            -ms-grid-columns: 4fr 8fr;
            grid-template-rows:
                minmax(min-content, 450px) minmax(50px, max-content)
                1fr;
            -ms-grid-rows: minmax(min-content, 450px) minmax(50px, max-content) 1fr;
        }

        .s-header-service__left {
            grid-row-start: 2;
            -ms-grid-row: 2;
        }

        .s-header-service__card-wrapper {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: end;
        }

        .s-header-service__alt-info {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        .s-header-service__actions {
            display: flex;
        }
            .s-header-service__block:last-child {
                align-items: center;
            }

        .s-header-service__button-wrapper {
            min-width: 147px;
        }

        .s-header-service__buttons {
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        .s-header-service__button-disclaimer {
            display: block;
        }

        .s-header-service__top {
            width: 100%;
        }
    }

@media (min-width: 1280px) {
        .s-header-service__product {
            max-width: 373px;
        }

        .s-header-service__button-wrapper {
            width: 180px;
        }
    }

@media (min-width: 1440px) {
        .s-header-service__product {
            max-width: 426px;
        }

        .s-header-service__button-wrapper {
            width: 205px;
        }
    }
