/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-global-footer {
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);
}

.s-global-footer--locale-selector-visible {
        position: relative;
        z-index: 2004;
    }

.s-global-footer .a-product-card__text {
        display: none;
    }

@media (min-width: 768px) {

.s-global-footer .a-product-card__text {
            display: block
    }
        }

.s-global-footer #ot-sdk-btn.ot-sdk-show-settings {
        display: inline-block;
        transition: color linear 0.12s;
        cursor: pointer;
        color: var(--av-brand-primary);
        border: none;
        padding: 0;
        font-size: 14px;
    }

.s-global-footer #ot-sdk-btn.ot-sdk-show-settings:hover {
            color: var(--av-brand-secondary);
            background: none;
        }

.s-global-footer .a-footer__padded {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
    }

.s-global-footer .a-footer__border-top {
        border-top: 1px solid var(--av-brand-primary);
    }

.s-global-footer .a-accordion__icon {
        fill: var(--av-link-inversed-light);
    }

.s-global-footer__social-wrapper {
            display: flex;
            flex-flow: row wrap;
            padding-inline: 16px;
        }

@media (min-width: 768px) {

.s-global-footer__social-wrapper {
                flex-flow: row nowrap;
                padding-inline: 0
        }
            }

@media (min-width: 1280px) {

.s-global-footer__social-wrapper {
                align-items: center
        }
            }

.s-global-footer__social-text {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 51px;
        }

@media (min-width: 768px) {

.s-global-footer__social-text {
                width: -moz-fit-content;
                width: fit-content;
                margin-bottom: 0;
                margin-inline-end: 51px;
                white-space:nowrap
        }
            }

.s-global-footer__social-actions {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
            gap: 32px;
            flex-flow: row wrap;
            justify-content: start;
        }

@media (min-width: 768px) {

.s-global-footer__social-actions {
                width: -moz-fit-content;
                width: fit-content;
                flex-flow: row nowrap
        }

            }

.s-global-footer__social-actions .a-social__item .a-social__link {
                    margin: 0;
                }

.s-global-footer__social-actions .a-social__item .a-social__link .a-glyph {
                        fill: var(--av-inversed-primary);
                    }

.s-global-footer__social-actions .a-social__item .a-social__link:hover .a-glyph {
                            fill: var(--av-link-inversed-hover);
                        }

.s-global-footer_theme_light,
        .s-global-footer_theme_white {
            background: var(--av-brand-lightest);
            color: var(--av-nav-primary);
        }

.s-global-footer_theme_light .s-global-footer__social-actions .a-social__item .a-social__link .a-glyph, .s-global-footer_theme_white .s-global-footer__social-actions .a-social__item .a-social__link .a-glyph {
                fill: var(--av-nav-primary);
            }

.s-global-footer_theme_light .s-global-footer__social-actions .a-social__item .a-social__link:hover .a-glyph, .s-global-footer_theme_white .s-global-footer__social-actions .a-social__item .a-social__link:hover .a-glyph {
                fill: var(--av-brand-primary);
            }

.s-global-footer_theme_light .a-navigation .a-additional-navigation__link .a-link__content,  .s-global-footer_theme_white .a-navigation .a-additional-navigation__link .a-link__content  {
                color: var(--av-nav-primary);
            }

.s-global-footer_theme_light .a-navigation .a-link__content,  .s-global-footer_theme_white .a-navigation .a-link__content  {
                color: var(--av-brand-primary);
            }

.s-global-footer_theme_light .a-footer__border-top, .s-global-footer_theme_white .a-footer__border-top {
                border-top: 1px solid var(--av-brand-light);
            }

.s-global-footer_theme_light .a-link__content, .s-global-footer_theme_white .a-link__content {
                color: var(--av-brand-primary);
            }

.s-global-footer_theme_light .a-footer__copyright, .s-global-footer_theme_white .a-footer__copyright {
                color: var(--av-nav-primary);
            }

.s-global-footer_theme_light .a-accordion__icon, .s-global-footer_theme_white .a-accordion__icon {
                fill: var(--av-brand-primary);
            }

.s-global-footer_theme_light .a-link:hover .a-link__content, .s-global-footer_theme_white .a-link:hover .a-link__content {
                color: var(--av-brand-secondary);
            }

.s-global-footer_theme_light .a-footer__info .a-link-content, .s-global-footer_theme_white .a-footer__info .a-link-content {
                    color: var(--av-brand-primary);
                }

.s-global-footer_theme_light .a-footer__info .a-footer__item:before, .s-global-footer_theme_white .a-footer__info .a-footer__item:before {
                        background: var(--av-brand-light);
                    }

.s-global-footer_theme_light .a-footer__info .a-footer__item-lang-btn .a-footer__item-lang-text, .s-global-footer_theme_white .a-footer__info .a-footer__item-lang-btn .a-footer__item-lang-text {
                        color: var(--av-brand-primary);
                    }

.s-global-footer_theme_light .a-footer__info .a-footer__item-lang-btn .a-glyph, .s-global-footer_theme_white .a-footer__info .a-footer__item-lang-btn .a-glyph {
                        fill: var(--av-brand-primary);
                    }

.s-global-footer_theme_light .a-footer__info .a-footer__item-lang-btn:hover .a-footer__item-lang-text, .s-global-footer_theme_white .a-footer__info .a-footer__item-lang-btn:hover .a-footer__item-lang-text {
                            color: var(--av-brand-secondary);
                        }

.s-global-footer_theme_light .a-footer__info .a-footer__item-lang-btn:hover .a-glyph, .s-global-footer_theme_white .a-footer__info .a-footer__item-lang-btn:hover .a-glyph {
                            fill: var(--av-brand-secondary);
                        }

@media (min-width: 1024px) {

.s-global-footer_theme_light .a-footer__meta, .s-global-footer_theme_white .a-footer__meta {
                    padding: 0
            }
                }

.s-global-footer_theme_light .a-navigation .a-additional-navigation__link:hover .a-link__content, .s-global-footer_theme_white .a-navigation .a-additional-navigation__link:hover .a-link__content {
                color: var(--av-brand-secondary);
            }

.s-global-footer_theme_white {
            background: #fafafc;
            color: var(--av-nav-primary);
        }

.s-global-footer_theme_white .a-additional-navigation__link .a-link__content {
                color: var(--av-nav-primary);
            }

.s-global-footer.is-bottom-fixed {
        margin-top: auto;
    }

.a-navigation .a-additional-navigation {
        padding-inline: 16px;
    }

@media (min-width: 768px) {

.a-navigation .a-additional-navigation {
            padding-inline: 0
    }
        }

.a-navigation .a-additional-navigation .a-accordion-item {
            border-top: 1px solid var(--av-brand-light);
        }

.a-navigation .a-additional-navigation .a-accordion-item:first-of-type {
                border-top: none;
            }

.a-navigation .a-additional-navigation .a-accordion-item:last-of-type {
                border-bottom: none;
                margin: 0;
            }

.a-navigation .a-additional-navigation .a-accordion-item.active .a-accordion__icon {
                transform: rotate(180deg);
            }

.a-navigation .a-additional-navigation .a-accordion-item .content-wrapper {
                padding: 0 0 24px;
            }

@media(min-width: 768px) {

.a-navigation .a-additional-navigation .a-accordion-item .content-wrapper {
                    padding-bottom: 40px
            }
                }

.a-navigation .a-additional-navigation .a-accordion-item .title {
                align-items: center;
                padding: 24px 0;
            }

.a-navigation .a-additional-navigation__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: -40px;
        }

@media (min-width: 768px) {

.a-navigation .a-additional-navigation__list {
                margin-top: 0
        }
            }

.a-navigation .a-additional-navigation__column {
            width: calc(50% - 8px);
            padding-inline-end: 16px;
            margin-top: 40px;
        }

@media (min-width: 768px) {

.a-navigation .a-additional-navigation__column {
                width: calc((100% - 48px) / 4);
                margin-top: 0
        }
            }

.a-navigation .a-additional-navigation__title {
            margin-bottom: 16px;
        }

.a-navigation .a-additional-navigation__link {
            margin-inline-end: auto;
        }

.a-navigation .a-additional-navigation__link + a-glyph {
                color: var(--av-link-inversed);
            }

.a-navigation .a-additional-navigation__link .a-link__content {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: var(--av-inversed-primary);
            }

@media (min-width: 768px) {

.a-navigation .a-additional-navigation__link .a-link__content {
                    font-size: 16px;
                    line-height: 24px
            }
                }

.a-navigation .a-additional-navigation__link:hover .a-link__content, .a-navigation .a-additional-navigation__link:focus .a-link__content {
                    color: var(--av-brand-primary);
                    background: none;
                    opacity: 0.7;
                }

.a-navigation .a-additional-navigation__item {
            font-size: 14px;
            line-height: 20px;
        }

.a-navigation .a-additional-navigation__item:not(:first-child) {
                margin-top: 16px;
            }

@media (min-width: 768px) {

.a-navigation .a-additional-navigation__item {
                font-size: 16px;
                line-height: 24px
        }
            }

.a-navigation .a-additional-navigation:last-of-type {
            display: none;
        }

@media (min-width: 1024px) {
            .a-navigation .a-additional-navigation:first-of-type {
                display: none;
            }
            .a-navigation .a-additional-navigation:last-of-type {
                display: block;
            }
    }

.a-footer__meta {
        display: flex;
        flex-direction: column;
        padding: 0 0 16px;
    }

@media (min-width: 768px) {

.a-footer__meta {
            flex-direction: row;
            padding: 0 0 25px
    }
        }

@media (min-width: 1024px) {

.a-footer__meta {
            padding: 0
    }
        }

@media (min-width: 768px) {
        .a-footer__logo {
            margin-inline-end: 40px;
        }
    }

.a-footer__content {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;
    }

@media (min-width: 1024px) {

.a-footer__content {
            flex-direction: row;
            align-items: center
    }
        }

.a-footer__logo {
        display: inline-block;
        margin-bottom: 8px;
    }

.a-footer__logo .a-logo {
            height: 22px;
            width: 108px;
        }

.a-footer__info {
        padding-top: 8px;
    }

.a-footer__info .a-link__content {
            font-size: 14px;
        }

.a-footer__info {
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;
}

@media (min-width: 1024px) {

.a-footer__info {
            padding-top: 0;
            row-gap: 12px;
            min-height: 32px
    }
        }

.a-footer__copyright {
        font-size: 14px;
        line-height: 24px;
        color: var(--av-inversed-primary);
        margin-inline-end: 23px;
        display: flex;
        align-items: center;
    }

.a-footer__item {
        margin-top: 16px;
        display: flex;
        align-items: center;
        margin-inline-end: 23px;
    }

@media (min-width: 1024px) {

.a-footer__item {
            margin-top: 0
    }
        }

@media (min-width: 768px) {

.a-footer__item {
            display: inline-flex
    }
        }

.a-footer__item .a-link,
        .a-footer__item .ot-sdk-show-settings {
            margin-inline-end: 23px;
        }

.a-footer__item:after {
            height: 16px;
            width: 1px;
            content: '';
            background: var(--av-brand-primary);
        }

.a-footer__item:first-of-type .a-link {
                margin: 0 23px 0 0;
            }

.a-footer__item:last-of-type:after {
                content: inherit;
            }

@media (min-width: 1024px) {

.a-footer__item:last-of-type:after {
                    display: none
            }
                }

.a-footer__item-lang-btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
        }

.a-footer__item-lang-btn:hover .a-footer__item-lang-text {
                    color: var(--av-link-inversed-hover);
                }

.a-footer__item-lang-btn:hover .a-glyph {
                    fill: var(--av-link-inversed-hover);
                }

.a-footer__item-lang-btn .a-glyph {
                fill: var(--av-link-inversed);
            }

.a-footer__item-lang-text {
            margin-inline-start: 9px;
            margin-bottom: 1px;
            color: var(--av-link-inversed);
            cursor: pointer;
        }

.a-footer__item .a-link {
            display: inline-block;
        }

[dir='rtl'] .a-footer__info {
        text-align: end;
    }
