<template>
    <s-basic-slice
        :id="id"
        class="s-cta-discount-code"
        :class="className"
        v-bind="l10n"
    >
        <div class="main" :class="cardClassName">
            <div class="text-content">
                <p v-if="l10n.cardTitle" class="card-title">
                    {{ l10n.cardTitle }}
                </p>
                <div v-if="l10n.cardLead?.length" class="card-lead">
                    {{ l10n.cardLead }}
                </div>
                <div v-if="l10n.button" class="button-wrapper">
                    <a-button
                        v-bind="l10n.button"
                        :type="l10n.button.type || 'main'"
                    />
                </div>
            </div>
            <div class="code-block-wrap">
                <div class="code-block">
                    <div class="code-label">
                        {{ l10n.discountCodeLabel }}
                    </div>
                    <div class="code-control">
                        <a-tooltip :text="l10n.codeHoverText" placement="bottom">
                            <button class="code-button" @click="copyCode">
                                <a-glyph name="i-copy-o--16" />
                            </button>
                        </a-tooltip>
                        <input
                            ref="code"
                            type="text"
                            :value="code"
                            class="code"
                            readonly="readonly"
                            @click="highlightCode"
                        />
                        <div v-if="codeCopied" class="code-copied">
                            <a-glyph name="i-check-circle-outline--16" />
                            {{ l10n.codeCopiedText }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { COLORS } from '@core/slices/pages/basic-slice/constants.js';

const CODE_SIZE_BREAKPOINT = 12;

export default {
    name: 'SCtaDiscountCode',

    components: {
        AButton,
        AGlyph,
        SBasicSlice,
        ATooltip,
    },

    mixins: [l10n, contentMixin, styleMixin],
    props: {
        /**
         * Title inside main section
         */
        cardTitle: {
            type: String,
            required: true,
        },

        /**
         * Lead inside main section
         */
        cardLead: {
            type: String,
            required: true,
        },

        /**
         * Button
         */
        button: {
            type: Object,
            default: null,
        },

        /**
         * Is card layout being used
         */
        isCardLayout: {
            type: Boolean,
            default: false,
        },

        /**
         * coupon code
         */
        discountCode: {
            type: String,
            required: true,
        },

        /**
         * Theme
         */
        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },

        /**
         * Card background (used only with card layout)
         */
        cardBackground: {
            type: String,
            validator: (value) => value === undefined || COLORS.includes(value),
            default: 'white',
        },

        /**
         * Theme for Card
         */
        cardTheme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },

        /**
         * Coupon code label
         */
        discountCodeLabel: {
            type: String,
            default: 'Your coupon code',
        },

        /**
         * Text shown when code button is hovered
         */
        codeHoverText: {
            type: String,
            default: 'Copy to clipboard',
        },

        /**
         * Text shown when code is copied
         */
        codeCopiedText: {
            type: String,
            default: 'Copied',
        },

        /**
         * Code
         */
        code: {
            type: String,
            required: true,
        },

        /**
         * Basic slice layout
         */
        layout: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            codeCopied: false,
        };
    },

    computed: {
        className() {
            return {
                ...this.basicClassName,
                dark: this.theme === 'dark',
            };
        },

        cardClassName() {
            return {
                'card-layout': this.isCardLayout,
                dark: this.cardTheme === 'dark',
                'small-code': this.code.length >= CODE_SIZE_BREAKPOINT,
                [`card-bg_${this.cardBackground}`]: this.cardBackground,
            };
        },
    },

    methods: {
        async copyCode() {
            try {
                await navigator.clipboard.writeText(this.code);
                this.codeCopied = true;
            } catch {
                /* Empty catch needed because navigator clipboard write returns a promise */
            }
        },

        highlightCode() {
            this.$refs.code?.focus();
            this.$refs.code?.select();
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-discount-code {
    color: var(--av-nav-primary);
    text-align: center;

    @media (--viewport-desktop) {
        text-align: start;
    }

    &.dark {
        color: var(--av-inversed-primary);

        &:deep(.a-slice-header__title),
        &:deep(.a-slice-header__lead),
        &:deep(.a-slice-header__body-text) {
            color: var(--av-inversed-primary);
        }
    }
}

.main {
    column-gap: 32px;
    color: var(--av-nav-primary);

    &.dark {
        color: var(--av-inversed-primary);
    }

    @media (--viewport-desktop) {
        display: flex;
    }
    @media (--viewport-desktop-wide) {
        column-gap: 56px;
    }
    @media (--viewport-desktop-large) {
        column-gap: 80px;
    }
}

.code-block-wrap {
    margin-top: 32px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    @media (--viewport-mobile-wide) {
        @mixin colls 8;
    }
    @media (--viewport-desktop) {
        margin-top: 0;
        @mixin colls 5;
    }
    @media (--viewport-desktop-wide) {
        width: 432px;
    }
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.code-block {
    border-radius: 8px;
    background: var(--av-brand-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
    color: var(--av-inversed-primary);
    flex-direction: column;
    gap: 16px;
    height: 200px;

    @media (--viewport-tablet) {
        height: 224px;
        padding-inline: 48px;
    }
    @media (--viewport-desktop) {
        padding-inline: 32px;
    }
}

.code-label {
    @mixin title-accent;
    width: 100%;
}

.code-control {
    position: relative;
    border-radius: 4px;
    background: var(--av-inversed-primary);
    border: 2px solid var(--av-brand-secondary-light);
    height: 64px;
    width: 100%;
    display: flex;
    gap: 0;
}

.code-copied {
    @mixin paragraph-accent;
    position: absolute;
    top: 100%;
    margin-top: 16px;
    height: 28px;
    border-radius: 14px;
    left: 50%;
    transform: translateX(-50%);
    margin-inline: auto;
    padding-block: 2px;
    padding-inline: 8px;
    background: var(--av-fixed-info-dark);

    .a-glyph {
        fill: var(--av-inversed-primary);
        margin-inline-end: 4px;
        vertical-align: middle;
        margin-top: -4px;
    }
}

.code-button {
    width: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-inline-end: 1px solid var(--av-brand-secondary-light);
    flex-shrink: 0;
    cursor: pointer;

    .a-glyph {
        fill: var(--av-brand-primary);
    }

    &:hover {
        background: var(--av-solid-brand-accent);
    }

    &:active {
        background: var(--av-solid-brand-secondary-light);
    }
}

.code {
    @mixin title-accent;
    height: 100%;
    width: 100%;
    padding: 16px 4px;
    color: var(--av-nav-primary);
    flex-grow: 1;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    background: transparent;
    border: 0;
}

.small-code .code {
    @mixin paragraph-accent;
}

.main.card-layout {
    padding: 32px;
    border-radius: 8px;

    @media (--viewport-desktop-wide) {
        padding: 24px 40px;
    }
    @media (--viewport-desktop-large) {
        padding: 40px;
    }

    &.card-bg {
        @mixin background;

        &_white {
            background: var(--av-inversed-primary);
        }
    }

    .card-title {
        @mixin title-accent;

        @media (--viewport-tablet) {
            @mixin display-accent;
        }
    }
}

.card-title {
    @mixin display-accent;

    @media (--viewport-tablet) {
        @mixin hero-accent;
    }
}

.card-lead {
    @mixin lead;
    margin-top: 18px;
}

.button-wrapper {
    margin-top: 24px;

    .a-button {
        min-width: 256px;
    }
}
</style>
