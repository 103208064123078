<template>
    <div class="event-map">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div class="features">
            <div v-if="location" class="feature">
                <div class="caption">
                    {{ location.title }}
                </div>
                <div class="value value_with-tooltip">
                    {{ location.value }}
                </div>
            </div>
            <div v-if="website" class="feature">
                <div class="caption">
                    {{ website.title }}
                </div>
                <div class="value">
                    <a class="event-link" :href="website.value" target="_blank">{{ trimLink(website.value) }}</a>
                </div>
            </div>
            <div v-if="phone" class="feature">
                <div class="caption">
                    {{ phone.title }}
                </div>
                <div class="value">
                    <a class="event-link" :href="`tel:${phone.value}`">{{ phone.value }}</a>
                </div>
            </div>
        </div>
        <div id="map" class="map">
            <template v-if="!isLoadedGoogleMaps">
                <div v-loading="true" el-loading-size="48" :el-loading-text="loadingText" />
            </template>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import googleMaps from '@core/mixins/google-maps.js';

export default {
    name: 'EventMap',
    directives: {
        Loading,
    },
    mixins: [googleMaps],
    props: {
        title: {
            type: String,
            default: null,
        },
        location: {
            type: Object,
            default: null,
        },
        website: {
            type: Object,
            default: null,
        },
        phone: {
            type: Object,
            default: null,
        },
        marker: {
            type: Object,
            required: true,
        },
        loadingText: {
            type: String,
            required: true,
        },
    },
    methods: {
        async addMap() {
            const { Map } = await window.google.maps.importLibrary('maps');
            const position = new window.google.maps.LatLng(this.marker.lat, this.marker.lng);
            this.map = new Map(document.getElementById('map'), {
                center: position,
                mapId: 'mapEvent',
                mapTypeControl: true,
                scrollwheel: false,
                streetViewControl: false,
                zoom: 17,
            });
        },
        async addMarkers() {
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');
            const position = new window.google.maps.LatLng(this.marker.lat, this.marker.lng);
            const createMarker = () => {
                const img = document.createElement('img');
                img.src = '/public/assets/images/marker.svg';
                return img;
            };
            const marker = new AdvancedMarkerElement({
                content: createMarker(),
                map: this.map,
                position,
            });
            marker.zIndex = 0;
            this.isLoadedGoogleMaps = true;
        },
        trimLink(link) {
            return link.replace(/^[^/]*?\/\//i, '');
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-map {
    .title {
        margin-bottom: 40px;
    }

    .features {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 32px;
            display: flex;
            justify-content: flex-start;
        }
    }

    .feature {
        @mixin paragraph;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-inline-end: 56px;
            margin-bottom: 0;

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }

    .caption {
        color: var(--av-fixed-light);
        margin-bottom: 8px;
    }

    .value {
        color: var(--av-fixed-primary);
    }

    .map {
        height: 280px;
        background: var(--av-fixed-invisible);
        @media (--viewport-tablet) {
            height: 392px;
        }
        @media (--viewport-desktop) {
            height: auto;
            aspect-ratio: 2.14;
        }
        &:deep(.el-loading-parent--relative) {
            width: 100%;
            height: 100%;
            .caption {
                opacity: 0;
            }
        }
    }
}
</style>
