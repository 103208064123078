<template>
    <section class="s-slice s-all-products">
        <s-side-menu
            class="s-all-products__side-menu"
            :slices="slicesData"
            :links="links"
            type="combined"
            is-wide
        />
    </section>
</template>

<script>
import SSideMenu from '@core/slices/pages/side-menu/side-menu.vue';

export default {
    name: 'SAllProducts',

    components: {
        SSideMenu,
    },

    props: {
        // TODO: this is the only slice that uses `slicesData` instead of `slices` prop name.
        // need to align it with others.
        /**
         * Data for slices in tabs
         */
        slicesData: {
            type: Array,
            required: true,
        },

        /**
         * Extra links after menu
         */
        links: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.all-products-intro {
    padding-bottom: 80px;

    @media (--viewport-tablet) {
        padding-bottom: 88px;
    }
}

.s-all-products {
    position: relative;
    background: var(--av-gradient-to-bottom-lightest);
    padding: 0;

    &__side-menu {
        padding-top: 56px;
    }

    .s-side-menu-content {
        margin-top: 0;
    }

    &:deep(.s-cards-product) {
        padding-top: 0;
        padding-bottom: 80px;

        @media (--viewport-tablet) {
            padding-bottom: 96px;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    /* hiding accordions */
    &:deep(.s-side-menu-control__arrow) {
        display: none;
    }

    &:deep(.a-accordion__content) {
        display: none;
    }

    &:deep(.s-side-menu .a-accordion-item) {
        border: 0;
    }

    @media (--viewport-desktop) {
        &:deep(.s-side-menu-control) {
            max-height: initial;
            overflow: unset;
        }

        &:deep(.s-side-menu__links) {
            margin-top: -24px;
        }
    }
}
</style>
