.solutions-container {
    position: relative;
    padding: 0 16px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 64px;
        max-width: 1440px;
    }
}
