<template>
    <section :id="id" class="s-insertion" :class="basicClassName">
        <a-dangerous-html class="s-insertion__text" :content="l10n.lead" />
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SInsertion',
    components: {
        ADangerousHtml,
    },
    mixins: [l10n, contentMixin, styleMixin],
};
</script>

<style lang="postcss" scoped>
.s-insertion {
    padding: 80px 0;

    @media (--viewport-mobile-wide) {
        padding: 104px 0;
    }

    &__text {
        @mixin lead-accent;

        text-align: center;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin title-accent;
        }

        @media (--viewport-desktop) {
            margin: 0 auto;
            max-width: 83.33%;
        }

        @media (--viewport-desktop-wide) {
            max-width: 66.66%;
        }
    }
}
</style>
