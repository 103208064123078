<template>
    <section v-if="isVisible && isAvailable" class="main-page-partners">
        <div class="a-container">
            <s-main-page-tag v-bind="tag({name: 'title', isDangerous: true})" />
            <div class="content">
                <div class="slider-wrapper">
                    <a-slider :options="options">
                        <a-slide v-for="(item, itemIndex) in content.list" :key="itemIndex">
                            <dynamic-link v-if="item.to" to="/" native-type="a">
                                <a-picture :link="item.logo" />
                            </dynamic-link>
                            <a-picture v-else :link="item.logo" />
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPagePartners',
    components: {
        APicture,
        ASlide,
        ASlider,
        DynamicLink,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: {
                loop: false,
                spaceBetween: 20,
                slidesPerView: 2,
                slidesPerGroup: 2,
                showNavigation: false,
                showPagination: false,
                customPagination: { type: 'bullets' },
                breakpoints: {
                    768: { slidesPerGroup: 3, slidesPerView: 3 },
                    1024: { slidesPerGroup: 4, slidesPerView: 4 },
                    1280: { slidesPerGroup: 6, slidesPerView: 6 },
                    1440: { slidesPerGroup: 7, slidesPerView: 7 },
                },
            },
        };
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.list?.length;
        },
    },
    mounted() {
        if (!this.isVisible) return;
        this.options.showPagination = this.isMobile;
        this.options.showNavigation = !this.isMobile;
    },
};
</script>

<style lang="postcss" scoped>
.main-page-partners {
    padding: 24px;
    @media (--viewport-desktop-wide) {
        padding: 40px;
    }
    .title {
        @mixin lead;
        padding: 0 32px;
        text-align: center;
        color: var(--av-fixed-primary);
        @media (--viewport-tablet) {
            padding: 0;
            @mixin title
        }
        @media (--viewport-desktop) {
            @mixin display
        }
    }
    .content {
        margin-top: 32px;
    }

    .slider-wrapper {
        margin: 0 auto;
        max-width: 296px;
        @media (--viewport-mobile-wide) {
            max-width: 454px;
        }
        @media (--viewport-desktop) {
            max-width: 612px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 928px;
        }
        @media (--viewport-desktop-large) {
            max-width: 1086px;
        }
        &:deep(.a-slider) {
            position: initial;
            .a-slide {
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                .a-picture__img {
                    max-width: 119px;
                    max-height: 76px;
                }
            }
            .a-slider__dot {
                &::before {
                    background-color: var(--av-nav-primary);
                }
            }
            .a-slider__dot_active {
                &::before {
                    background-color: var(--av-brand-secondary);
                }
            }
            .a-slider__nav {
                @media (--viewport-tablet) {
                    margin: 0;
                }
            }
            .a-slider__controls {
                bottom: 0;
                margin: 0;
                z-index: 0;
                width: 100%;
                height: 76px;
                display: flex;
                position: absolute;
                align-items: center;
                inset-inline-end: 0;
                inset-inline-start: 0;
                background: transparent;
                justify-content: space-between;
                padding: 0 var(--container-paddings-mobile-wide);
                @media (--viewport-desktop-wide) {
                    padding: 0 var(--container-paddings-desktop-wide);
                }
                @media (--viewport-desktop-large) {
                    padding: 0;
                }
                .a-slider__control {
                    margin: 0;
                }
            }
            .a-slider__wrapper  {
                z-index: 1;
            }
        }
    }
}
</style>
