<template>
    <div class="s-menu-tab" :class="className">
        <div v-if="tabs" class="inner-tabs">
            <div class="inner-tabs-title">
                {{ tabs.title }}
            </div>
            <div class="inner-tabs-wrapper">
                <div
                    v-for="(tab, i) in tabs.tabs"
                    :key="`inner-tab-${i}`"
                    class="inner-tabs-item"
                    :class="{ 'inner-tabs-item--active': i === activeTabIndex }"
                    @click="setActiveTabIndex(i)"
                >
                    {{ tab }}
                </div>
            </div>
            <div v-if="card">
                <a-picture :link="card.image" :alt="tabs.title" />
                <div class="content">
                    <div class="title">
                        {{ card.title }}
                    </div>
                    <p class="desc">
                        {{ card.description }}
                    </p>
                    <a-link v-if="card.link" v-bind="card.link" />
                </div>
            </div>
        </div>
        <div class="s-menu-tab-wrapper">
            <div class="s-menu-tab__body" :class="`s-menu-tab__body_type_${id}`">
                <section
                    v-for="(section, index) in sections"
                    :key="`s-menu-section-${index}`"
                    class="s-menu-tab__section"
                    :class="getSectionClassName(section, id)"
                >
                    <template v-for="(item, sectionIndex) in section.content">
                        <div
                            v-if="/linkTitle|linkMore/.test(item.type)"
                            :key="item.to"
                            :class="`s-menu-tab__${item.type.toLowerCase()}`"
                        >
                            <a-link
                                :to="item.to"
                                :text="getTitle(item)"
                                :glyph="item.type === 'linkMore' ? 'long-arrow' : ''"
                                :event="eventGA(item)"
                            />

                            <a-label v-if="item.label" v-bind="getLabelProps(item.label)">
                                <span>{{ item.label.text }}</span>
                            </a-label>
                            <a-button
                                v-if="item.button"
                                v-bind="item.button"
                                :type="item.button.type || 'main'"
                            />
                            <template v-if="isMobile && version.startsWith('46942')">
                                <a-dangerous-html
                                    v-if="item.desc || item.type === 'desc'"
                                    :key="getDescription(item)"
                                    :content="getDescription(item)"
                                    :class="`s-menu-tab__${item.desc ? 'description' : 'text'}`"
                                />
                            </template>
                        </div>
                        <template v-if="!version.startsWith('46942') || (version.startsWith('46942') && !isMobile)">
                            <a-dangerous-html
                                v-if="item.desc || item.type === 'desc'"
                                :key="getDescription(item)"
                                :content="getDescription(item)"
                                :class="`s-menu-tab__${item.desc ? 'description' : 'text'}`"
                            />
                        </template>
                        <div v-if="item.type === 'linkStrong'" :key="item.text" class="s-menu-tab__link-strong">
                            {{ item.text }}
                        </div>
                        <ul
                            v-if="['cta','list','glyphList'].includes(item.type)"
                            :key="`menu-tab-${sectionIndex}`"
                            :class="getTabClasses(item)"
                        >
                            <li
                                v-for="(listItem, listItemIndex) in item.items"
                                :key="`${item.type}-item-${listItemIndex}`"
                                :class="`s-menu-tab__${item.type.toLowerCase()}-item`"
                            >
                                <a-link
                                    v-if="listItem.modal && isDesktop"
                                    v-modal="listItem.modal"
                                    text="Interactive demo"
                                    :class="getItemClass(item, listItem)"
                                    :glyph="listItem.glyph || (item.type === 'cta' ? 'long-arrow' : '')"
                                    :glyph-position="item.type === 'cta' ? 'right' : 'left'"
                                    :event="eventGA(listItem)"
                                    :modal-events="listItem.modalEvents"
                                />
                                <a-link
                                    v-else-if="!listItem.modal || !isDesktop"
                                    :to="listItem.to"
                                    :text="listItem.text"
                                    :class="getItemClass(item, listItem)"
                                    :glyph="listItem.glyph || (item.type === 'cta' ? 'long-arrow' : '')"
                                    :glyph-position="item.type === 'cta' ? 'right' : 'left'"
                                    :event="eventGA(listItem)"
                                />
                                <a-dangerous-html
                                    v-if="listItem.desc"
                                    class="s-menu-tab__description"
                                    :content="listItem.desc"
                                />
                                <a-label v-if="listItem.label" v-bind="getLabelProps(listItem.label)">
                                    <span>{{ listItem.label.text }}</span>
                                </a-label>
                            </li>
                        </ul>
                        <ul
                            v-if="['button'].includes(item.type)"
                            :key="`menu-tab-${sectionIndex}`"
                            :class="getTabClasses(item)"
                        >
                            <li
                                v-for="(button, buttonIndex) in item.items"
                                :key="`${button.type}-item-${buttonIndex}`"
                                :class="`s-menu-tab__${item.type.toLowerCase()}-item`"
                            >
                                <a-button
                                    v-bind="button"
                                    :size="button.size || 's'"
                                    :type="button.type || 'main'"
                                    :glyph="button.glyph || 'i-long-arrow-right--16'"
                                />
                            </li>
                        </ul>
                        <div
                            v-if="item.type === 'listColumns'"
                            :key="`list-columns-${sectionIndex}`"
                            class="list-columns"
                        >
                            <div
                                v-for="(column, i) in item.columns"
                                :key="`column-list-item-${i}`"
                                class="list-column"
                            >
                                <template v-for="(colItem, j) in column">
                                    <div
                                        v-if="colItem.type === 'category'"
                                        :key="`column-list-item-${i}-${j}`"
                                        class="list-column-category"
                                    >
                                        {{ colItem.text }}
                                    </div>
                                    <a-link
                                        v-if="colItem.type === 'link'"
                                        :key="`column-list-item-${i}${j}`"
                                        class="list-column-link"
                                        :class="{
                                            'has-star': colItem.glyph === 'star',
                                            'text-thin': colItem.thinText
                                        }"
                                        :to="colItem.to"
                                        :text="colItem.text"
                                        :glyph="colItem.glyph || ''"
                                        glyph-position="left"
                                        :event="eventGA(colItem)"
                                    />
                                </template>
                            </div>
                        </div>
                    </template>
                </section>
            </div>
            <div v-if="environments" class="environments-wrapper" :class="{ single: !environments.compare || activeTabIndex === 1 }">
                <div class="environments">
                    <h2 class="environments-title">
                        {{ environments.title }}
                    </h2>
                    <div class="environments-items">
                        <div v-for="(col, j) in environments.cols" :key="`cols_${j}`" class="cols">
                            <div v-for="(group, i) in col" :key="`group_${i}`" class="item">
                                <h3 class="title">
                                    {{ group.title }}
                                </h3>
                                <div
                                    v-for="(child, c) in group.children"
                                    :key="`link_${j}_${i}_${c}`"
                                    class="links-container"
                                >
                                    <a-link
                                        class="link"
                                        v-bind="child"
                                        :event="eventGA(child)"
                                    />
                                    <span
                                        v-if="c !== group.children.length - 1"
                                        class="separator"
                                    >
                                        |
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="environments.compare && activeTabIndex === 0" class="compare">
                    <h2 class="compare-title">
                        {{ environments.compare.title }}
                    </h2>
                    <div class="compare-links">
                        <a-link
                            v-for="(link, i) in environments.compare.links"
                            :key="`compare_${i}`"
                            class="link"
                            v-bind="link"
                        />
                    </div>
                    <p class="description">
                        {{ environments.compare.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SMenuTab',

    components: {
        AButton,
        ALabel,
        APicture,
        ADangerousHtml,
        ALink,
    },

    directives: { Modal },
    mixins: [breakpoint],

    props: {
        /**
         * Menu
         */
        menu: {
            type: Array,
            required: false,
            default: () => [],
        },

        /**
         * Tabs
         */
        tabs: {
            type: Object,
            default: undefined,
        },

        /**
         * Environments
         */
        environments: {
            type: Object,
            default: undefined,
        },

        /**
         * Id
         */
        id: {
            type: String,
            default: 'Item',
        },

        /**
         * Active tab id
         */
        activeTab: {
            type: String,
            default: undefined,
        },

        /**
         * If a menu is centered around menu-link (for minisites)
         */
        type: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || ['mini', 'centered', 'default'].includes(value),
        },

        version: {
            type: String,
            default: '',
        },

        card: {
            type: Object,
            default: undefined,
        },
    },

    emits: ['select-tab', 'select-link', 'onTabChange'],

    data: () => ({
        activeTabIndex: 0,
    }),

    computed: {
        sections() {
            if (Array.isArray(this.menu?.[0])) {
                return this.menu[this.activeTabIndex];
            }
            return this.menu;
        },

        className() {
            const isMini = this.type === 'mini';
            const items = Object.keys(this.menu).length;
            return {
                's-menu-tab_centered': this.type === 'centered',
                's-menu-tab_mini': isMini,
                [`s-menu-tab__items-${items}`]: !isMini,
            };
        },

    },

    methods: {
        eventGA(item) {
            const event = {
                context: 'click',
                content: item.text,
                action: `Menu ${this.id.charAt(0).toUpperCase() + this.id.slice(1)}`,
            };

            if (this.tabs?.eventLocation?.length) {
                event.location = this.tabs.eventLocation?.[this.activeTabIndex] || '';
            }

            return { ...event, ...item.event };
        },

        getTabClasses(item) {
            const classes = [`s-menu-tab__${item.type.toLowerCase()}`];

            if (item.class) {
                classes.push(`s-menu-tab__${item.type.toLowerCase()}_${item.class}`);
            }
            if (item.bottomDivider) {
                classes.push('s-menu-tab__bottom-divider');
            }
            return classes;
        },

        getSectionClassName(section, id) {
            return {
                [`s-menu-tab__section_tabindex_${this.activeTabIndex}`]: this.activeTabIndex !== undefined,
                [`s-menu-tab__section_type_${id}`]: id,
                [`s-menu-tab__section_divider_${section.divider}`]: section.divider,
                's-menu-tab__section_submenu': section.submenu,
                's-menu-tab__section_full-width': section.type === 'full',
            };
        },

        getTitle(item) {
            return item.text;
        },

        getDescription(item) {
            return item.desc || item.text;
        },

        getLabelProps(item) {
            return {
                size: item.size || 's',
                variant: item.variant || 'info',
                theme: item.theme || 'light',
                layout: item.layout || 'solid',
            };
        },

        getItemClass(item, listItem) {
            return [
                `s-menu-tab__${item.type.toLowerCase()}-item-link`,
                `s-menu-tab__${item.type.toLowerCase()}-item-link--${listItem.type}`,
                {
                    'has-star': listItem.glyph === 'star',
                    'text-thin': listItem.thinText,
                    'make-accent': listItem.accent,
                },
            ];
        },

        setActiveTabIndex(index) {
            this.activeTabIndex = index;
        },
    },
};
</script>

<style lang="postcss">
.s-menu-tab {
    &.s-menu-tab_centered {
        /* styles for centered version of menu tabs (on minisite) */
        @media (--viewport-desktop) {
            .s-menu-tab {
                &__glyphlist-item-link,
                &__linkmore {
                    vertical-align: top;
                }

                &__body {
                    display: flex;
                    padding: 8px 0;
                    padding-inline-end: 136px;
                    padding-inline-start: 64px;
                    grid-column-gap: 48px;
                }

                &__list,
                &__list ~ .s-menu-tab__linkmore {
                    margin-top: 0;
                }

                &__linkmore,
                &__link-strong {
                    + .s-menu-tab__list,
                    + .s-menu-tab__list ~ .s-menu-tab__linkmore {
                        margin-top: 24px;
                    }
                }

                &__section {
                    &_submenu {
                        padding-inline-start: 24px;

                        &::before {
                            inset-inline-start: 0;
                        }
                    }

                    &_divider {
                        &_short {
                            min-width: 180px;
                        }
                    }
                }
            }
        }
    }

    &__body {
        padding: 0 16px 8px;

        @media (--viewport-tablet) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 32px;
            padding: 0 48px 8px;
            padding-inline-start: 32px;
        }

        @media (--viewport-desktop) {
            grid-column-gap: 32px;
            padding-inline-end: 0;
        }

        @media (--viewport-desktop-wide) {
            grid-column-gap: 72px;
            padding-inline-start: 64px;
        }

        @media (--viewport-desktop-large) {
            grid-column-gap: 80px;
            padding-inline-start: 0;
        }
    }

    &__section {
        padding: 32px 0;

        &_full-width {
            @media (--viewport-tablet) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &_divider {
            &_wide,
            &_short {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    inset-inline-start: 0;
                    height: 1px;
                    background-color: var(--av-brand-accent);

                    @media (--viewport-tablet) {
                        display: none;
                    }
                }
            }

            &_short {
                &::before {
                    width: 54px;
                }
            }

            &_wide {
                &::before {
                    width: 100%;
                }
            }
        }

        &_submenu {
            @media (--viewport-tablet) {
                border-top: 1px solid var(--av-brand-accent);
            }

            @media (--viewport-desktop) {
                position: relative;
                border-top: none;

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    inset-inline-start: -24px;
                    width: 1px;
                    height: calc(100% + 8px);
                    background-color: var(--av-brand-accent);
                }
            }
        }
    }

    &__section_full-width {
        padding-top: 32px;
        padding-bottom: 40px;
        @media (--viewport-tablet) {
            padding-top: 24px;
            padding-bottom: 36px;
        }
        @media (--viewport-desktop) {
            padding-bottom: 32px;
        }
        @media (--viewport-desktop-wide) {
            padding-bottom: 16px;
        }
        @media (--viewport-desktop-large) {
            padding-bottom: 32px;
        }
    }

    &__linktitle {
        .a-link__content {
            @mixin title-accent;

            @media (--viewport-tablet) {
                @mixin paragraph-accent;
            }
            @mixin title-accent;

            @media (--viewport-tablet) {
                @mixin paragraph-accent;
            }
        }
    }

    &__description {
        display: block;
        margin-top: 8px;
        @mixin descriptor;
        color: var(--av-fixed-secondary);
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px 0;
    }

    &__text {
        @mixin paragraph;
        color: var(--av-fixed-secondary);

        a:hover {
            color: var(--av-link-primary-hover);
        }
    }

    &__link-strong {
        @mixin body-accent;
        color: var(--av-nav-primary);

        .a-link__content {
            @mixin body-accent;
            color: var(--av-nav-primary);
        }

        &:hover,
        &:focus {
            .a-link__content {
                background: none;
                color: var(--av-nav-primary);
            }
        }

        &.nuxt-link-exact-active {
            .a-link__content {
                color: var(--av-nav-primary);
                pointer-events: auto;
            }
        }
    }

    &__bottom-divider {
        position: relative;
        padding-bottom: 24px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            width: 130px;
            height: 1px;
            background: var(--av-brand-accent);
        }
    }

    &__list {
        ~ .s-menu-tab {
            &__linkmore {
                margin: 32px 0 0;
            }
        }
    }

    &__list,
    &__cta {
        margin: 24px 0 0;

        &_nomargin {
            margin-top: 0;
        }
        &_inline {
            gap: 16px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .s-menu-tab__cta-item {
                &:not(:first-child) {
                    margin: 0!important;
                }
            }
        }
    }

    &__description + &__list {
        margin-top: 24px !important;
    }

    &__cta-item {
        line-height: 0;

        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    &__list-item-link,
    &__cta-item-link {
        @mixin caption-accent;

        &:hover {
            color: var(--av-link-primary-hover);
        }

        @media (--viewport-desktop) {
            @mixin body-accent;
        }
    }

    &__list-item-link {
        @mixin paragraph-accent;

        &.has-star {
            .a-link__content {
                &.glyphLeft {
                    margin-inline-start: -24px;
                }

                .a-glyph {
                    top: 1px;
                }
            }
        }

        &.text-thin {
            font-weight: 400;
        }

        &--category {
            .a-link__content {
                color: var(--av-nav-primary);
                @mixin paragraph-accent;
            }
        }
    }

    &__cta-item-link {
        padding: 8px;

        &.make-accent {
            background: var(--el-secondary-hover);
            border-radius: 4px;
        }

        .a-link__content:not(:hover) {
            color: var(--av-nav-primary);

            .a-glyph {
                color: var(--av-brand-primary);
            }
        }
    }

    &__glyphlist {
        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
        }
    }

    &__glyphlist-item {
        &:not(:last-of-type) {
            margin-bottom: 24px;

            @media (--viewport-tablet) {
                margin-bottom: 0;
                margin-inline-end: 56px;
            }
        }

        .a-link {
            margin: 0;
        }
    }

    &__glyphlist-item-link {
        .a-glyph {
            margin: 0 0 2px;
            margin-inline-end: 8px;
        }
    }

    &__linkmore,
    &__glyphlist-item-link {
        .a-link__content {
            color: var(--av-nav-primary);

            svg {
                color: var(--av-brand-primary);
            }
        }

        &:hover,
        &:focus {
            .a-link__content {
                background: none;
                color: var(--av-link-primary-hover);

                svg {
                    fill: var(--av-brand-secondary);
                }
            }
        }
    }

    &__linkmore {
        @mixin body-accent;

        .a-link__glyph {
            margin-inline-start: 8px;
        }
    }

    &__glyphlist-item-link {
        @mixin body-accent;
    }

    &__items-4 {
        .s-menu-tab__body {
            display: grid;
            grid-column-gap: 32px;

            .s-menu-tab__section {
                margin: 0;
            }

            @media (--viewport-desktop) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (--viewport-desktop-wide) {
                grid-column-gap: 24px;
            }
        }

        .s-menu-tab__section {
            @media (--viewport-desktop) {
                max-width: 164px;
            }

            @media (--viewport-desktop-wide) {
                max-width: 264px;
            }

            @media (--viewport-desktop-large) {
                max-width: 288px;
            }
        }

        .s-menu-tab__section:last-child {
            @media (--viewport-tablet) {
                grid-column: span 3;
            }

            @media (--viewport-desktop) {
                grid-column: auto;
            }

            @media (--viewport-desktop-wide) {
                max-width: 204px;
            }

            @media (--viewport-desktop-large) {
                max-width: 260px;
            }
        }
    }
}

#ot-sdk-btn-floating {
    &.ot-floating-button {
        button {
            text-align: center;

            svg {
                vertical-align: middle;
            }
        }
    }
}

/* old solution tab support */
.s-main-header {
    .s-menu-tab__body_type_solutions {
        @media (--viewport-desktop) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (--viewport-desktop) {
            display: flex;
            gap: 48px;
            justify-content: space-between;
        }
    }
}
</style>

<style lang="postcss" scoped>
.s-menu-tab__list-item {
    .a-link {
        display: inline;
        margin-inline-end: 4px;

        &:deep(.a-link__content) {
            display: inline;
        }
    }
}

.list-columns {
    display: flex;
    flex-direction: column;
    padding-inline-start: 16px;

    @media (--viewport-desktop) {
        flex-direction: row;
        gap: 20px;
    }
}

.list-column {
    display: flex;
    flex-direction: column;

    @media (--viewport-tablet) {
        max-width: 228px;
    }

    @media (--viewport-desktop) {
        flex-basis: 100%;
    }

    @media (--viewport-desktop-wide) {
        max-width: 276px;
    }

    &-category {
        @mixin paragraph-accent;
        color: var(--av-nav-primary);
        margin: 16px 0;

        @media (--viewport-desktop) {
            margin: 24px 0 16px;
        }
    }

    &-link {
        margin-bottom: 8px;
        font-weight: 700;

        &.text-thin {
            font-weight: 400;
        }

        &:deep(.a-link__content.glyphLeft) {
            padding-inline-start: 20px;
            margin-inline-start: -20px;
        }
    }
}

.s-menu-tab__section_type_company {
    .s-menu-tab__list ~ .s-menu-tab__link-strong {
        margin: 32px 0 0;
    }
}

.s-menu-tab__body_type_company {
    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);

        .s-menu-tab__section_divider_short::before {
            content: none;
        }
    }
}

.inner-tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px 16px 24px;
    background: var(--av-inversed-primary);

    @media (--viewport-tablet) {
        flex-direction: row;
        align-items: center;
        gap: 30px;
        padding: 0 32px 24px 32px;
    }

    @media (--viewport-desktop) {
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        gap: 0;
        background: var(--av-fixed-info-accent);
    }

    @media (--viewport-desktop-wide) {
        padding: 32px 80px 32px 64px;
    }

    @media (--viewport-desktop-large) {
        padding: 32px 100px 32px 64px;
    }

    &-title {
        display: none;

        @media (--viewport-desktop) {
            @mixin title-accent;
            color: var(--av-nav-primary);
            display: block;
            padding: 8px 0 8px 16px;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (--viewport-tablet) {
            flex-direction: row;
        }

        @media (--viewport-desktop) {
            flex-direction: column;
        }
    }

    &-item {
        @mixin lead-accent;
        color: var(--av-brand-primary);
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;

        &--active {
            color: var(--av-inversed-primary);
            background: var(--av-fixed-info-light);
            pointer-events: none;
        }
    }
}

.s-menu-tab-wrapper {
    width: 100%;
}

.environments-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-inline: 32px;
    padding-top: 32px;
    padding-bottom: 24px;
    color: var(--av-nav-primary);
    border-top: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-desktop) {
        padding-inline: 0;
        margin-inline: 16px;
        max-width: calc(100% - 32px);
    }
    .environments-title, .compare-title {
        @mixin title-accent;
        margin-bottom: 16px;
        @media (--viewport-tablet) {
            @mixin lead-accent;
        }
    }
    .environments {
        width: 100%;
        @media (--viewport-desktop-wide) {
            width: 455px;
        }
        @media (--viewport-desktop-large) {
            width: 427px;
        }
        .environments-items {
            .cols {
                .item {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    margin-bottom: 24px;
                    @media (--viewport-tablet) {
                        margin-bottom: 16px;
                    }
                    @media (--viewport-desktop-wide) {
                        margin-bottom: 8px;
                    }
                    gap: 0 4px;
                    .title {
                        @mixin lead-accent;
                        margin-inline-end: 4px;
                        padding: 0;
                        @media (--viewport-tablet) {
                            @mixin paragraph-accent;
                        }
                        @media (--viewport-desktop) {
                            @mixin descriptor-accent;
                        }
                    }
                    &:deep(.a-link) {
                        &__content {
                            @mixin paragraph;
                            @media (--viewport-desktop) {
                                @mixin descriptor;
                                line-height: 24px;
                            }
                        }
                    }
                    .separator {
                        color: var(--av-nav-primary);
                    }
                }
            }
        }
    }
    .compare {
        width: 100%;
        margin-top: 24px;
        @media (--viewport-desktop-wide) {
            width: calc(100% - 487px);
            margin: 0;
        }
        @media (--viewport-desktop-large) {
            width: calc(100% - 459px);
        }
        .compare-links {
            display: flex;
            flex-flow: row wrap;
            gap: 8px 16px;
            margin-bottom: 8px;
            max-width: calc(100% - 50px);
            @media (--viewport-desktop-wide) {
                max-width: calc(100% - 72px);
            }
            @media (--viewport-desktop-large) {
                max-width: calc(100% - 220px);
            }
            &:deep(.a-link__content) {
                @mixin paragraph;
            }
        }
        .description {
            @mixin paragraph;
            color: var(--av-fixed-primary);
            @media (--viewport-desktop) {
                @mixin descriptor;
            }
        }
    }
    &.single {
        .environments {
            width: 100%;
            .environments-items {
                @media (--viewport-desktop-wide) {
                    display: flex;
                    gap: 32px;
                }
                .cols {
                    &:first-child {
                        min-width: 357px;
                        @media (--viewport-desktop-large) {
                            min-width: 411px;
                        }
                    }
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

}
</style>
