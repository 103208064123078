<template>
    <section class="s-event-countdown" :class="className">
        <template v-if="useSmallVersion">
            <a-picture class="bg" :background="timerBackground" fit="cover" />
            <div class="container a-container">
                <div class="inner">
                    <div class="text">
                        {{ l10n.title }}
                    </div>
                    <div v-if="eventStarted" class="started-text">
                        <span>
                            {{ l10n.eventStartsNowText }}
                        </span>
                    </div>
                    <template v-else>
                        <a-glyph name="add-action" size="l" />
                        <div class="timer-values">
                            <div
                                v-for="(item, i) in timerData"
                                :key="i"
                                :class="item.class"
                            >
                                <template v-if="item.separator">
                                    :
                                </template>
                                <template v-else>
                                    <div class="time-digit">
                                        {{ item.value }}
                                    </div>
                                    <div class="time-unit">
                                        {{ item.unit }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
                <a-button
                    v-if="l10n.ctaButton"
                    class="cta-button"
                    v-bind="l10n.ctaButton"
                    :size="l10n.ctaButton.size || 'small'"
                    :type="l10n.ctaButton.type || 'main'"
                />
            </div>
        </template>
        <s-basic-slice v-else :background="background">
            <div class="container">
                <div class="text-content">
                    <div class="title">
                        {{ l10n.title }}
                    </div>
                    <div v-if="l10n.lead" class="lead">
                        {{ l10n.lead }}
                    </div>
                    <div class="cta-button-wrapper">
                        <a-button
                            v-if="l10n.ctaButton"
                            class="cta-button"
                            v-bind="l10n.ctaButton"
                            :size="l10n.ctaButton.size || 'medium'"
                            :type="l10n.ctaButton.type || 'main'"
                        />
                    </div>
                </div>
                <div class="countdown">
                    <div class="bg-wrapper">
                        <a-picture :background="timerBackground" />
                    </div>
                    <div v-if="eventStarted" class="started-text take-full-space">
                        <span>
                            {{ l10n.eventStartsNowText }}
                        </span>
                    </div>
                    <div v-else class="timer-block take-full-space">
                        <div class="timer-text">
                            {{ l10n.topText }}
                        </div>
                        <div class="timer-values">
                            <div
                                v-for="(item, i) in timerData"
                                :key="i"
                                :class="item.class"
                            >
                                <template v-if="item.separator">
                                    :
                                </template>
                                <template v-else>
                                    <div class="time-digit">
                                        {{ item.value }}
                                    </div>
                                    <div class="time-unit">
                                        {{ item.unit }}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="timer-text">
                            {{ l10n.bottomText }}
                        </div>
                    </div>
                </div>
            </div>
        </s-basic-slice>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import l10n from '@core/mixins/l10n.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { COLORS } from '@core/slices/pages/basic-slice/constants.js';

export default {
    name: 'SEventCountdown',

    components: {
        SBasicSlice,
        APicture,
        AButton,
        AGlyph,
    },

    mixins: [l10n, breakpoint],

    props: {
        /**
         * Title
         */
        title: {
            type: String,
            required: true,
        },

        /**
         * Lead
         */
        lead: {
            type: String,
            default: '',
        },

        /**
         * Text on the top of countdown
         */
        topText: {
            type: String,
            default: 'Big announcement is coming in..',
        },

        /**
         * Text on the bottom of countdown
         */
        bottomText: {
            type: String,
            default: 'Attend the event to learn more!',
        },

        /**
         * Event starts now text
         */
        eventStartsNowText: {
            type: String,
            default: 'The event starts now!',
        },

        /**
         * Days label
         */
        days: {
            type: String,
            default: 'days',
        },

        /**
         * Hours label
         */
        hours: {
            type: String,
            default: 'hours',
        },

        /**
         * Minutes label
         */
        minutes: {
            type: String,
            default: 'minutes',
        },

        /**
         * Seconds label
         */
        seconds: {
            type: String,
            default: 'seconds',
        },

        /**
         * CTA button
         */
        ctaButton: {
            type: Object,
            required: true,
        },

        /**
         * Use small slice version
         */
        useSmallVersion: {
            type: Boolean,
            default: false,
        },

        /**
         * Start date of the event
         */
        startDate: {
            type: String,
            required: true,
        },

        /**
         * Theme
         */
        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },

        /**
         * Slice background
         */
        background: {
            type: String,
            validator: (value) => value === undefined || COLORS.includes(value),
            default: 'l-b-gradient-top',
        },

        /**
         * Event countdown background
         */
        timerBackground: {
            type: Object,
            required: true,
        },

        /**
         * Slice translations
         */
        translations: {
            type: Object,
            required: true,
        },
    },

    data() {
        const utcOffset = (new Date()).getTimezoneOffset() / 60;
        const utcOffsetDate = new Date(this.startDate);
        utcOffsetDate.setHours(utcOffsetDate.getHours() - utcOffset);

        return {
            startTs: utcOffsetDate.getTime(),
            eventStarted: false,
            timerId: 0,
            timerData: [],
        };
    },

    computed: {
        className() {
            return {
                'small-version': this.useSmallVersion,
                dark: this.theme === 'dark',
            };
        },
    },

    mounted() {
        this.timerData = [
            { value: '00', unit: this.l10n.days, class: 'timer-value' },
            { separator: true, class: 'timer-separator' },
            { value: '00', unit: this.l10n.hours, class: 'timer-value' },
            { separator: true, class: 'timer-separator' },
            { value: '00', unit: this.l10n.minutes, class: 'timer-value' },
            { separator: true, class: 'timer-separator' },
            { value: '00', unit: this.l10n.seconds, class: 'timer-value' },
        ];
        this.timerTick();
    },

    methods: {
        timerTick() {
            if (this.eventStarted) return;

            const msLeft = this.startTs - new Date().getTime();

            this.timerData = this.toDhmsFormat(msLeft);

            if (msLeft > 0) {
                if (this.timerId) clearTimeout(this.timerId);
                this.timerId = setTimeout(this.timerTick, 1000);
            } else {
                this.eventStarted = true;
            }
        },

        toDhmsFormat(ms) {
            const days = Math.floor(ms / (24 * 60 * 60 * 1000));
            const daysms = ms % (24 * 60 * 60 * 1000);
            const hours = Math.floor(daysms / (60 * 60 * 1000));
            const hoursms = ms % (60 * 60 * 1000);
            const minutes = Math.floor(hoursms / (60 * 1000));
            const minutesms = ms % (60 * 1000);
            const sec = Math.floor(minutesms / 1000);

            const res = [];

            // days
            res.push(
                { value: days >= 10 ? days : `0${days}`, unit: this.l10n.days, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // hours
            res.push(
                { value: hours >= 10 ? hours : `0${hours}`, unit: this.l10n.hours, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // minutes
            res.push(
                { value: minutes >= 10 ? minutes : `0${minutes}`, unit: this.l10n.minutes, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // seconds
            res.push({ value: sec >= 10 ? sec : `0${sec}`, unit: this.l10n.seconds, class: 'timer-value' });

            return res;
        },
    },
};
</script>

<style lang="postcss" scoped>
@font-face {
    font-family: 'DIN Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/public/assets/fonts/temp/DinCondensed/DinCondensed-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'DIN Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/public/assets/fonts/temp/DinCondensed/DinCondensed-Bold.otf') format('otf');
}

@keyframes bg-rotation {
    100% { transform: rotate(360deg); }
}

.s-event-countdown {
    position: relative;
    color: var(--av-nav-primary);
}

.s-event-countdown.dark {
    color: var(--av-inversed-primary);

    .title {
        background: linear-gradient(90deg, #94CEF1 0%, #0065E3 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .countdown {
        .time-digit,
        .started-text {
            text-shadow: 0 0 16px #f00;
            -webkit-text-stroke-color: #ff005c;
        }
    }
}

.s-event-countdown.small-version {
    padding: 0;
    color: var(--av-nav-primary);
    background: transparent;
    overflow: hidden;
    text-align: center;

    @media (--viewport-desktop) {
        text-align: start;
        min-height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .container {
        padding-block: 12px;
        align-items: center;
        gap: 16px;

        @media (--viewport-desktop) {
            padding-block: 2px;
        }
    }

    .inner {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;

        @media (--viewport-tablet) {
            gap: 24px;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        @media (--viewport-desktop) {
            gap: 16px;
            flex-wrap: nowrap;
        }
    }

    .bg {
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;

        &:deep(.a-picture__img) {
            height: 100%;
            width: 100%;
        }
    }

    .text {
        @mixin descriptor-accent;

        @media (--viewport-desktop) {
            @mixin paragraph-accent;
            line-height: 20px;
        }
    }

    .a-glyph {
        flex-shrink: 0;
        fill: var(--av-nav-primary);
        display: none;

        @media (--viewport-desktop) {
            display: block;
        }
    }

    .started-text {
        @mixin paragraph-accent;
        position: static;
        text-transform: uppercase;
        flex-shrink: 0;
        text-align: center;
        margin: auto;
    }

    .timer-values {
        text-align: center;
        display: flex;
        margin: 0 auto;
        gap: 8px;
    }

    .time-digit,
    .timer-separator {
        font-size: 40px;
        line-height: 44px;
    }

    .time-unit {
        font-size: 12px;
        line-height: 12px;

    }

    .cta-button {
        margin: 8px auto 0;
        width: 100%;
        max-width: 328px;

        @media (--viewport-tablet) {
            max-width: 464px;
        }

        @media (--viewport-desktop) {
            margin-top: 0;
            max-width: 260px;
        }
    }
}

.container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
        gap: 32px;
    }
}

.title {
    @mixin display-accent;
    text-align: center;

    @media (--viewport-mobile-wide) {
        @mixin hero-accent;
    }

    @media (--viewport-desktop) {
        @mixin large-accent;
        text-align: start;
    }
}

.lead {
    text-align: center;
    margin-top: 24px;

    @media (--viewport-desktop) {
        text-align: start;
    }
}

.cta-button-wrapper {
    width: 100%;
    text-align: center;

    @media (--viewport-desktop) {
        width: 0;
    }
}

.cta-button {
    min-width: 152px;
    margin-top: 48px;
    margin-bottom: 56px;

    @media (--viewport-desktop) {
        margin-bottom: 0;
    }
}

.take-full-space {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
}

.text-content {
    @media (--viewport-desktop) {
        max-width: 600px;
    }
}

.countdown {
    position: relative;
    overflow: hidden;
    width: 328px;
    height: 220px;
    padding: 0;
    margin: 0 auto;
    border: 1px solid var(--av-nav-secondary);
    border-radius: 4px;
    box-shadow: var(--av-shadow-small);
    transition: box-shadow 0.3s ease-out;
    cursor: default;
    flex-shrink: 0;

    @media (--viewport-mobile-wide) {
        width: 464px;
        height: 275px;
    }

    @media (--viewport-desktop) {
        width: 456px;
        height: 255px;
        margin: 0;
    }

    @media (--viewport-desktop-wide) {
        width: 537px;
        height: 300px;
    }

    .bg-wrapper {
        position: absolute;
        background: #060f28;
        overflow: hidden;
    }

    .a-picture {
        position: relative;
        animation: bg-rotation 30s linear infinite;
        top: -105px;
        inset-inline-start: -50px;
        width: 420px;
        height: 420px;

        @media (--viewport-mobile-wide) {
            top: -163px;
            inset-inline-start: -69px;
            width: 601px;
            height: 601px;
        }

        @media (--viewport-desktop) {
            top: -152px;
            inset-inline-start: -52px;
            width: 560px;
            height: 560px;
        }

        @media (--viewport-desktop-wide) {
            top: -151px;
            inset-inline-start: -36px;
            width: 601px;
            height: 601px;
        }

        &:deep(.a-picture__img) {
            max-width: initial;
            max-height: initial;
            width: 100%;
            height: 100%;
        }
    }

    .started-text {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--av-inversed-primary);
        text-shadow: 0 0 16px rgba(41, 91, 255, 1);
        -webkit-text-stroke-color: #2668c5;
        -webkit-text-stroke-width: 0.5px;
        font-family: 'DIN Condensed', sans-serif;
        font-weight: 700;
        line-height: 120%;
        font-size: 40px;

        @media (--viewport-desktop) {
            font-size: 56px;
        }
    }

    .timer-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px 0;

        @media (--viewport-tablet) {
            padding: 42px 0;
        }

        @media (--viewport-desktop) {
            padding: 32px 0;
        }

        @media (--viewport-desktop-wide) {
            padding: 56px 0;
        }
    }

    .timer-text {
        @mixin paragraph-accent;
        color: var(--av-inversed-primary);
        text-align: center;
    }

    .timer-values {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 12px;
        padding: 34px 0;
        margin-bottom: -24px;

        @media (--viewport-tablet) {
            padding: 36px 0;
        }
    }

    .timer-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .time-digit {
        color: var(--av-inversed-primary);
        text-align: center;
        text-shadow: 0 0 16px rgba(41, 91, 255, 1);
        -webkit-text-stroke-color: #2668c5;
        -webkit-text-stroke-width: 0.5px;
        font-family: 'DIN Condensed', sans-serif;
        font-size: 56px;
        font-weight: 700;
        line-height: 1;

        @media (--viewport-tablet) {
            font-size: 80px;
        }
    }

    .time-unit {
        color: var(--av-inversed-primary);
        text-align: center;
        font-family: 'DIN Condensed', sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        transform: translateY(-4px);

        @media (--viewport-tablet) {
            transform: translateY(-10px);
        }
    }

    .timer-separator {
        color: var(--av-inversed-primary);
        font-family: 'DIN Condensed', sans-serif;
        font-size: 56px;
        line-height: 66px;
        transform: translateY(-14px);

        @media (--viewport-tablet) {
            font-size: 80px;
            line-height: 66px;
            transform: translateY(-6px);
        }
    }
}
</style>
