<template>
    <div class="a-container">
        <div class="blog-cpc-people">
            <div class="title-wrap">
                <div class="title">
                    {{ title }}
                </div>
                <a-link
                    v-if="link"
                    v-bind="link"
                    class="link"
                    size="lead"
                    glyph="arrow"
                />
            </div>
            <div class="people">
                <div v-for="(person, i) in people" :key="i" class="person">
                    <a-picture class="photo" :link="person.photo || fallbackImage" fit="cover" />
                    <div class="info">
                        <div class="name">
                            {{ person.name }}
                        </div>
                        <div class="position">
                            {{ person.position }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SBlogCpcPeople',
    components: {
        ALink,
        APicture,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            default: null,
        },

        people: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            fallbackImage: 'fd3353668ea5bda3b807d3682fe6ac8e',
        };
    },
};
</script>

<style lang="postcss" scoped>
.blog-cpc-people {
    padding: 64px 0;
    .title-wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            flex-direction: row;
            align-items: center;
            margin-bottom: 48px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 40px;
        }
        .link {
            &:deep(.a-link__content) {
                @mixin lead-accent;
            }
        }
    }

    .title {
        @mixin title-accent;
        color: var(--av-fixed-primary);
        margin-bottom: 16px;
        @media (--viewport-tablet) {
            margin: 0;
            @mixin display-accent;
        }
        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .people {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        .person {
            display: flex;
            flex-direction: row;
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 6;
            }
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
            @media (--viewport-desktop-wide) {
                flex-direction: row;
            }
            .photo {
                flex-grow: 0;
                flex-shrink: 0;
                width: 80px;
                height: 80px;
                border-radius: 4px;
                margin-inline-end: 16px;
                margin-bottom: 16px;
            }
            .info {
                flex-grow: 1;
            }
            .name {
                margin-bottom: 8px;
                @mixin lead-accent;
                color: var(--av-nav-secondary);
            }
            .position {
                font-size: 14px;
                line-height: 24px;
                color: var(--av-fixed-primary);
            }
        }
    }
}
</style>
