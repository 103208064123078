<template>
    <div class="event-partners" :class="{ 'inline': useInlineLayout }">
        <h2 class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>
        <div v-if="tiers.length" class="tiers">
            <div v-for="tier in tiers" :key="tier.id" class="tier">
                <div class="tier-title-wrap">
                    <div class="tier-title" :style="{ 'background-color': `#${tier.color}` }">
                        {{ tier.name }}
                    </div>
                </div>
                <div class="tier-logos">
                    <div
                        v-for="partner in getTierPartners(tier)"
                        :key="partner.id"
                        class="logo-wrap"
                        @click="setPartnerInModal(partner)"
                    >
                        <a-picture
                            v-modal="'partner-modal'"
                            class="logo"
                            :link="partner.logo"
                            fit="contain"
                            size="small"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="logos">
            <div
                v-for="partner in partners"
                :key="partner.id"
                class="logo-wrap"
                @click="setPartnerInModal(partner)"
            >
                <a-picture
                    v-modal="'partner-modal'"
                    class="logo"
                    :link="partner.logo"
                    fit="contain"
                    size="small"
                    @click="setPartnerInModal(partner)"
                />
            </div>
        </div>
        <a-modal id="partner-modal" is-overlay-close>
            <div v-if="partnerInModal" class="partner-modal">
                <div class="modal-logo-wrap">
                    <a-picture
                        class="modal-logo"
                        :link="partnerInModal.logo"
                        fit="contain"
                        size="small"
                    />
                    <div v-if="partnerInModal.link" class="links">
                        <a-link
                            class="event-link link"
                            :to="partnerInModal.link.to"
                            target="_blank"
                            glyph="i-globe--16"
                        />
                    </div>
                </div>
                <div class="description">
                    <div class="modal-title">
                        {{ partnerInModal.title }}
                    </div>
                    <div class="text">
                        {{ partnerInModal.description }}
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'EventPartners',

    components: {
        APicture,
        AModal,
        ALink,
    },

    directives: {
        Modal,
    },

    mixins: [breakpoint],

    props: {
        title: {
            type: String,
            default: '',
        },

        lead: {
            type: String,
            default: '',
        },

        partners: {
            type: Array,
            required: true,
        },

        tiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            partnerInModal: null,
            isModalOpened: false,
        };
    },

    computed: {
        useInlineLayout() {
            const isWide = ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
            const partnersInlineLimit = isWide ? 4 : 3;
            const fitInline = this.partners.length <= partnersInlineLimit;

            return !this.lead && this.title && !this.tiers.length && fitInline;
        },
    },

    methods: {
        getTierPartners(tier) {
            return this.partners.filter((p) => p.tierId === tier.id);
        },

        setPartnerInModal(partner) {
            this.partnerInModal = partner;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-partners {
    color: var(--av-nav-primary);

    &.inline {
        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: 16px;
        }

        .logos {
            @media (--viewport-tablet) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            @media (--viewport-desktop) {
                margin-top: 0;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (--viewport-desktop-wide) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }

        .title {
            @media (--viewport-desktop) {
                height: 120px;
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
        }
    }

    &:deep(.a-picture__img) {
        margin: 0;
    }
}

.section-lead {
    margin-bottom: 24px;
}

.logos {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    align-items: center;
    margin-top: 16px;

    @media (--viewport-mobile-wide) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (--viewport-desktop) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (--viewport-desktop-wide) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

.logo-wrap {
    margin: 8px 8px 8px 0;
    height: 156px;
    display: flex;
    align-items: center;

    @media (--viewport-mobile-wide) {
        margin: 8px 40px 8px 0;
    }

    @media (--viewport-tablet) {
        height: 172px;
    }
}

.logo {
    margin: 0 auto;
    width: 156px;
    height: 156px;
    height: 100%;
    display: flex;
    cursor: pointer;

    @media (--viewport-tablet) {
        width: 172px;
        height: 172px;
    }
}

.tiers {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .logo-wrap {
        padding: 0;
    }
}

.tier {
    @media (--viewport-desktop-wide) {
        display: flex;
        gap: 64px;
        justify-content: space-between;
        align-items: flex-start;
    }

    &:not(:first-child) {
        border-top: 1px solid var(--av-brand-secondary-light);
        padding-top: 24px;
    }
}

.tier-title-wrap {
    margin-bottom: 40px;

    @media (--viewport-desktop-wide) {
        @mixin colls 2;

        height: 120px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
}

.tier-title {
    @mixin paragraph-accent;

    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: var(--av-inversed-primary);
    padding: 8px 16px;
    border-radius: 20px;

    @media (--viewport-mobile-wide) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
    }

    @media (--viewport-desktop-wide) {
        width: 100%;
        margin-bottom: 0;
        border-radius: 48px;
    }
}

.tier-logos {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    align-items: center;
    flex-grow: 1;

    @media (--viewport-mobile-wide) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (--viewport-desktop) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.with-zoom {
    .logo {
        transition: transform .5s;
    }
    &:hover .logo {
        transform: scale(1.1);
    }
}

.partner-modal {
    max-width: 768px;
    margin: 0 auto;
    box-shadow: var(--av-shadow-modal);
    border-radius: 6px;
    background: var(--av-inversed-primary);
    text-align: start;
    padding: 24px 16px 32px;

    @media (--viewport-tablet) {
        display: flex;
        justify-content: flex-start;
        padding: 48px 48px 56px;
    }

    .modal-logo-wrap {
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
            margin-inline-end: 32px;
        }
    }

    .modal-logo {
        display: flex;
        width: 156px;
        height: 156px;
        margin: 0 auto 24px;

        @media (--viewport-tablet) {
            width: 180px;
            height: 180px;
            flex-shrink: 0;
            flex-grow: 0;
        }
    }

    .links {
        text-align: center;
    }

    .link {
        display: inline-block;
        margin-inline-start: 8px;
        margin-inline-end: 8px;

        &:deep(.a-glyph) {
            margin: 0;
        }

        &:hover {
            &:deep(.a-glyph) {
                fill: var(--av-brand-primary);
            }
        }
    }

    .modal-title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .description {
        @mixin paragraph;
        color: var(--av-fixed-primary);
    }
}

</style>
