<template>
    <s-basic-slice :id="id" class="s-card-highlight" :class="{ ...className, ...basicClassName }">
        <div class="s-card-highlight__text">
            <a-slice-header :title="l10n.title" :lead="l10n.lead" />
            <ul v-if="l10n.list" class="s-card-highlight__list">
                <li v-for="item in l10n.list" :key="item.to" class="s-card-highlight__list-item">
                    <a-link
                        v-if="item.to"
                        class="s-card-highlight__list-link"
                        v-bind="item"
                        :glyph="item.glyph || 'arrow'"
                        :glyph-position="item.glyphPosition || 'left'"
                    />
                    <span v-else class="s-card-highlight__list-text">{{ item.text }}</span>
                </li>
            </ul>
        </div>
        <div class="s-card-highlight__cards-cell">
            <div class="s-card-highlight__cards">
                <a-value-card v-if="l10n.valueCard" class="s-card-highlight__value-card" v-bind="l10n.valueCard">
                    {{ l10n.valueCard.text }}
                </a-value-card>
                <a-product-card v-if="l10n.productCard" v-bind="l10n.productCard">
                    {{ l10n.productCard.text }}
                </a-product-card>
                <ul v-if="l10n.smallCardArr" class="s-card-highlight__small-cards-list">
                    <li
                        v-for="(card, i) in l10n.smallCardArr"
                        :key="i"
                        class="s-card-highlight__small-cards-item"
                    >
                        <a-small-card v-bind="card" />
                    </li>
                </ul>
            </div>
        </div>
        <a-dangerous-html
            v-if="l10n.addText && !$slots.default"
            class="s-card-highlight__additional-text"
            :content="l10n.addText"
        />
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import AProductCard from '@core/components/product-card/product-card.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import ASmallCard from '@core/components/small-card/small-card.vue';
import AValueCard from '@core/components/value-card/value-card.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCardsHighlight',

    components: {
        ADangerousHtml,
        ALink,
        AProductCard,
        ASliceHeader,
        ASmallCard,
        AValueCard,
        SBasicSlice,
    },

    mixins: [l10n, styleMixin, contentMixin],

    props: {
        background: {
            type: String,
            default: 'light-blue',
        },
        valueCard: {
            type: Object,
            default: undefined,
        },
        productCard: {
            type: Object,
            default: undefined,
        },
        addText: {
            type: String,
            default: undefined,
        },
        smallCardArr: {
            type: Array,
            default: undefined,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: undefined,
        },
        type: {
            type: String,
            default: '',
        },
    },

    computed: {
        className() {
            return {
                's-card-highlight_layout_one-card': this.l10n.productCard || this.l10n.valueCard || this.l10n.smallCard,
                's-card-highlight_layout_small-cards': this.l10n.smallCardArr,
                's-card-highlight_grid_centered': this.centered,
                [`s-slice_type_${this.type}`]: this.type,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-card-highlight {
    &:deep(.a-slice-header) {
        width: 100%;
    }

    &__cards {
        margin-bottom: 40px;
    }

    &.s-slice_type_1 {
        &:deep(.a-slice-header) {
            margin: 0;
            &__title {
                margin-bottom: 24px;
            }
            &__lead {
                margin: 0;
                @mixin lead-accent;
            }
        }
        .s-card-highlight {
            &__cards {
                margin: 40px 0 0;
            }

            &__list {
                margin-top: 24px;
            }

            &__list-item {
                &:not(:first-child) {
                    margin-top: 16px;
                }
            }
        }

        &:deep(.a-product-card) {
            &__title {
                @mixin title-accent;
            }

            &__text {
                @mixin paragraph;
            }
        }
    }

    &__additional-text {
        color: var(--av-fixed-secondary);

        p {
            margin: 16px 0;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        a {
            color: var(--av-brand-secondary);
            text-decoration: none;
        }
    }

    &__small-cards-item {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &__value-card {
        &:deep(.a-value-card__icon) {
            width: 64px;
            height: 64px;
        }
    }

    @media (--viewport-mobile-wide) {
        &_layout {
            &_one-card {
                &:deep(.a-container) {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: 1fr 1.05fr;
                    -ms-grid-columns: 1fr 32px 1.05fr;
                    grid-template-rows: auto 1fr;
                    -ms-grid-rows: auto 1fr;
                    grid-column-gap: 32px;
                }

                .s-card-highlight {
                    &__cards {
                        margin-bottom: 0;
                    }

                    &__text {
                        -ms-grid-column: 1;
                    }

                    &__cards-cell {
                        grid-row: span 2;
                        -ms-grid-column: 3;
                        -ms-grid-row-span: 2;
                    }

                    &__additional-text {
                        -ms-grid-row: 2;
                    }
                }
            }

            &_small-cards {
                .s-card-highlight {
                    &__small-cards-list {
                        display: flex;
                    }

                    &__small-cards-item {
                        width: 50%;
                        min-width: 224px;

                        &:not(:last-of-type) {
                            margin-bottom: 0;
                            margin-inline-end: 16px;
                        }
                    }
                }
            }
        }
    }

    @media (--viewport-tablet) {
        &.s-slice_type_1 {
            .a-slice-header {
                &__lead {
                    @mixin title;
                    font-weight: 400;
                }
            }
            .s-card-highlight {
                &__cards {
                    margin: 0;
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &.s-slice_type_1 {
            &:deep(.a-container) {
                grid-template-columns: 49.2% 40.8%;
                -ms-grid-columns: 49.2% 40.8%;
                grid-column-gap: 10%;
            }
        }

        &__additional-text {
            margin-top: 0;
        }

        &_layout {
            &_one-card {
                &:deep(.a-slice-header__lead) {
                    max-width: 100%;
                }

                &:deep(.a-container) {
                    grid-template-columns: 57.7% 32.3%;
                    -ms-grid-columns: 57.7% 10% 32.3%;
                    grid-column-gap: 10%;
                }
            }

            &_small-cards {
                &:deep(.a-container) {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: 41% 50%;
                    -ms-grid-columns: 41% 9% 50%;
                    grid-column-gap: 9%;
                    grid-template-rows: auto 1fr;
                    -ms-grid-rows: auto 1fr;
                }

                .s-card-highlight {
                    &__cards-cell {
                        grid-row: span 2;
                        -ms-grid-column: 3;
                        -ms-grid-row-span: 2;
                    }

                    &__cards {
                        margin-bottom: 0;
                    }

                    &__text {
                        -ms-grid-column: 1;
                    }

                    &__additional-text {
                        -ms-grid-row: 2;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &.s-slice_type_1 {
            &:deep(.a-container) {
                grid-template-columns: 49.1% 40.9%;
                -ms-grid-columns: 49.1% 40.9%;
            }
        }
    }

    @media (--viewport-desktop-large) {
        &.s-slice_type_1 {
            &:deep(.a-container) {
                grid-template-columns: 49% 41%;
                -ms-grid-columns: 49% 41%;
            }
        }
    }

    &_grid_centered {
        &:deep(.a-container) {
            align-items: center;
            grid-template-rows: none !important;
        }

        .s-card-highlight__text {
            align-self: flex-end;
        }

        .s-card-highlight__additional-text {
            align-self: flex-start;
        }
    }
}

&:deep(.s-light-wrapper-slice) {
    &.s-slice_indent_small {
        &:deep(.s-light-wrapper-slice__item) {
            ~ .s-slice_type_1 {
                @media (--viewport-tablet) {
                    margin-top: 24px;
                }
                @media (--viewport-desktop) {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
