/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-cpoc-header {
    position: absolute;
    top: 24px;
    width: 100%;
    z-index: 400;
}

.s-cpoc-header--menu-active {
        z-index: 999;
    }

@media (min-width: 1024px) {

.s-cpoc-header {
        top: 32px
}
                            .s-cpoc-header_state_active .s-cpoc-header__menu-link_active::after,
                            .s-cpoc-header_state_active .s-cpoc-header__menu-link_active::before {
                                opacity: 1;
                            }
                                .s-cpoc-header.s-cpoc-header_state_active-light .s-cpoc-header__menu-link_active::after,
                                .s-cpoc-header.s-cpoc-header_state_active-light .s-cpoc-header__menu-link_active::before {
                                    background: none;
                                }
                    .s-cpoc-header.s-cpoc-header_state_active-light .s-main-menu {
                        z-index: 100;
                    }
    }

.s-cpoc-header__logo {
        height: 48px;
        width: 132px;
    }

.s-cpoc-header__logo-container {
            margin: auto;
        }

@media (min-width: 1024px) {

.s-cpoc-header__logo-container {
                margin: 0;
                margin-inline-end: 53px
        }
            }

@media (min-width: 1280px) {

.s-cpoc-header__logo-container {
                margin-inline-end: 148px
        }
            }

.s-cpoc-header__content {
        z-index: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

.s-cpoc-header__nav {
        z-index: 100;
    }

.s-cpoc-header__menu {
        display: none;
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        width: 100%;
        height: 0;
    }

@media (min-width: 1024px) {

.s-cpoc-header__menu {
            position: static;
            height: auto !important;
            display: flex;
            width: auto;
            margin-inline-end: auto
    }
        }

.s-cpoc-header__menu-item {
            padding: 10px 0;
        }

@media (min-width: 1024px) {

.s-cpoc-header__menu-item {
                padding: 0
        }
            }

.s-cpoc-header__menu-item:first-of-type .s-cpoc-header__menu-link {
                    padding-inline-start: 0;
                }

.s-cpoc-header__menu-item:last-of-type .s-cpoc-header__menu-link {
                    padding-inline-end: 0;
                }

.s-cpoc-header__menu-link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            padding: 0 12px;
            background: none;
            border: none;
            color: var(--av-nav-secondary);
            cursor: pointer;
        }

.s-cpoc-header__menu-link::before {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 28px);
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 27px;
                height: 27px;
                background-color: var(--av-nav-secondary);
                pointer-events: none;
            }

.s-cpoc-header__menu-link::after {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 32px);
                inset-inline-start: 50%;
                transform: translateX(-50%);
                width: 38px;
                height: 30px;
                background-color: var(--av-nav-secondary);
                pointer-events: none;
            }

@media (min-width: 1024px) {

.s-cpoc-header__menu-link {
                position: relative;
                text-transform: capitalize;
                color: #000
        }
            }

.s-cpoc-header__buttons {
        border: 0;
        z-index: 105;
        height: 24px;
        display: flex;
        margin-inline-start: auto;
        position: relative;
        align-items: center;
    }

.s-cpoc-header__buttons.noText .s-cpoc-header__contact-text,
            .s-cpoc-header__buttons.noText .s-cpoc-header__login-text {
                display: none;
            }

.s-cpoc-header__buttons.noText .s-login-menu,
            .s-cpoc-header__buttons.noText .s-contact-menu {
                top: calc(100% + 16px);
            }

.s-cpoc-header__buttons::before {
            top: 0;
            inset-inline-end: 0;
            width: 1px;
            content: '';
            height: 24px;
            position: absolute;
        }

.s-cpoc-header__buttons > div {
            position: relative;
            height: 24px;
            display: flex;
            align-items: center;
        }

.s-cpoc-header__buttons > div::before {
                width: 1px;
                position: absolute;
                content: '';
                background-color: var(--av-fixed-info-dark);
                inset-inline-start: 0;
                top: 0;
                bottom: 0;
            }

.s-cpoc-header__buttons > div:first-of-type {
                padding-inline-start: 0;
            }

.s-cpoc-header__buttons > div:first-of-type::before {
                    width: 0;
                }

.s-cpoc-header__buttons > div {

            margin-inline-end: 0;
            padding-inline-start: 12px;
            padding-inline-end: 12px;
}

@media (min-width: 576px) {

.s-cpoc-header__buttons > div {
                padding-inline-start: 20px;
                padding-inline-end: 20px
        }
            }

@media (min-width: 1024px) {
            .s-cpoc-header__buttons::before {
                display: none;
            }
        }

.s-cpoc-header .menu-toggler {
        padding: 0;
        border: none;
        display: flex;
        z-index: 100;
        height: 32px;
        min-width: 48px;
        cursor: pointer;
        background: none;
        position: absolute;
        inset-inline-end: var(--container-paddings-mobile);
        align-items: center;
        justify-content: flex-end;
        margin-top: -6px;
    }

@media (min-width: 576px) {

.s-cpoc-header .menu-toggler {
            min-width: 83px;
            inset-inline-end: var(--container-paddings-mobile-wide)
    }
        }

.s-cpoc-header .menu-toggler.noText {
            min-width: 40px;
        }

.s-cpoc-header .menu-toggler.noText.menu-toggler_state_active .menu-toggler__icon {
                margin-inline-end: 12px;
            }

.s-cpoc-header .menu-toggler.noText .menu-toggler__caption {
                display: none;
            }

.s-cpoc-header .menu-toggler__caption {
            font-size: 14px;
            line-height: 24px;
            padding: 0 8px 0 16px;
        }

.s-cpoc-header .menu-toggler__caption-default-text {
            display: none;
        }

@media (min-width: 576px) {

.s-cpoc-header .menu-toggler__caption-default-text {
                display: block
        }
            }

.s-cpoc-header .menu-toggler__caption-opened-text {
            display: none;
        }

.s-cpoc-header .menu-toggler__icon {
            width: 32px;
            height: 32px;
            transition: none;
            fill: var(--av-inversed-primary);
        }

.s-cpoc-header .menu-toggler_state_active .menu-toggler__caption-default-text {
                        display: none;
                    }

@media (min-width: 576px) {

.s-cpoc-header .menu-toggler_state_active .menu-toggler__caption-opened-text {
                            display: block
                    }
                        }

.s-cpoc-header .menu-toggler_state_active .menu-toggler__icon {
                        width: 16px;
                        height: 16px;
                    }

@media (min-width: 576px) {

.s-cpoc-header .menu-toggler_state_active .menu-toggler__icon {
                            margin-inline-start: 8px
                    }
                        }

@media (min-width: 1024px) {

.s-cpoc-header .menu-toggler {
            display: none
    }
        }

.s-cpoc-header .s-main-menu {
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 48px;
        width: 100%;
        background: var(--av-inversed-primary);
        position: absolute;
        min-height: calc(100vh - 75px);
        z-index: 100;
        border-top: 2px solid var(--av-nav-secondary);
        box-shadow: var(--av-shadow-regular);
    }

@media (min-width: 1024px) {

.s-cpoc-header .s-main-menu--mobile-accordion {
                display: none
        }
            }

.s-cpoc-header .s-main-menu--desktop-accordion {
            display: none;
        }

@media (min-width: 1024px) {

.s-cpoc-header .s-main-menu--desktop-accordion {
                display: block
        }
            }

@media (min-width: 768px) {

.s-cpoc-header .s-main-menu {
            top: 56px
    }
        }

@media (min-width: 1024px) {

.s-cpoc-header .s-main-menu {
            min-height: auto;
            top: 44px;
            width: 100vw;
            max-width: 100%
    }
        }

@media (min-width: 1440px) {

.s-cpoc-header .s-main-menu {
            top: 48px
    }
        }

.s-cpoc-header .s-main-menu.s-main-menu_centered {
            inset-inline-end: auto;
            width: auto;
            transform: translateX(-50%);
            border-radius: 4px;
        }

.s-cpoc-header .s-main-menu__tabs-wrapper {
            position: relative;
            margin: auto;
            width: 100%;
            max-width: 1312px;
        }

.s-cpoc-header .s-main-menu__tab-information {
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
            text-align: center;
            position: relative;
            color: #000;
            background-color: var(--av-solid-brand-accent);
        }

.s-cpoc-header .s-main-menu__tab-information .s-main-menu__closer {
                    top: auto;
                    bottom: -64px;
                }

.s-cpoc-header .s-main-menu__disable {
            display: none;
        }

.s-cpoc-header .s-main-menu__accordion-wrapper {
            width: 100%;
            padding: 16px 0 0;
        }

.s-cpoc-header .s-main-menu__closer {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 36px;
            inset-inline-end: 32px;
            z-index: 1;
            background: none;
            border: none;
            width: 16px;
            height: 16px;
            cursor: pointer;
        }

@media (min-width: 1024px) {

.s-cpoc-header .s-main-menu__closer {
                display: flex
        }
            }

@media (min-width: 1280px) {

.s-cpoc-header .s-main-menu__closer {
                inset-inline-end: 52px
        }
            }

@media (min-width: 1440px) {

.s-cpoc-header .s-main-menu__closer {
                inset-inline-end: 64px
        }
            }

.s-cpoc-header .s-main-menu__closer-icon {
            flex: 0 0 16px;
            height: 16px;
        }

.s-cpoc-header .s-main-menu .a-accordion-item:first-of-type {
                border-top: none;
            }

.s-cpoc-header .s-main-menu .a-accordion-item .title {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                padding: 24px 16px 24px 16px;
            }

@media (min-width: 768px) {

.s-cpoc-header .s-main-menu .a-accordion-item .title {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;
                    padding: 24px 16px 24px 32px
            }
                }

.s-cpoc-header .s-main-menu .a-accordion-item .icon {
                margin-inline-end: 16px;
            }

@media (min-width: 768px) {

.s-cpoc-header .s-main-menu .a-accordion-item .icon {
                    margin-top: 8px
            }
                }

.s-cpoc-header .s-main-menu .a-accordion-item .content-wrapper {
                padding: 0;
            }

.s-cpoc-header .s-main-menu .a-accordion-item > .content {
                padding: 0;
                height: 0;
            }

.s-cpoc-header .s-main-menu .a-accordion-item .s-menu-tab__section_type_contact-us {
                padding-top: 0;
            }

.s-cpoc-header .tru-search-link {
        padding: 0 8px;
        display: flex;
        height: 100%;
        align-items: center;
    }

.s-cpoc-header .tru-search-link .a-glyph {
            fill: var(--av-inversed-primary);
        }

.s-cpoc-header_theme_dark .s-cpoc-header__menu-link {
                    color: var(--av-inversed-primary);
                }

.s-cpoc-header_theme_dark .s-cpoc-header__menu-link:hover {
                        color: var(--av-link-inversed-hover);
                    }

.s-cpoc-header_theme_dark .s-cpoc-header__menu-link .a-glyph {
                        fill: var(--av-inversed-primary);
                    }

.s-cpoc-header_theme_dark .s-cpoc-header__menu-link .a-glyph:hover {
                            fill: var(--av-inversed-lighter);
                        }

.s-cpoc-header_theme_dark .s-cpoc-header__menu-link_active {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        color: var(--av-inversed-primary);
                    }

.s-cpoc-header_theme_dark .s-cpoc-header__buttons::before {
                        background-color: var(--av-fixed-info-dark);
                    }

.s-cpoc-header_theme_dark .menu-toggler__icon {
                    fill: var(--av-inversed-primary);
                }

.s-cpoc-header_theme_dark .menu-toggler__caption {
                    color: var(--av-inversed-primary);
                }

[dir='rtl'] .s-cpoc-header .s-main-menu.s-main-menu_centered {
                inset-inline-start: auto;
                inset-inline-end: unset;
            }

[dir='rtl'] .s-cpoc-header__menu-link::before,
    [dir='rtl'] .s-cpoc-header__menu-link::after {
        inset-inline-start: unset;
        inset-inline-end: 50%;
    }
