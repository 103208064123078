<template>
    <div class="step" :class="`step-${step}`">
        <div class="step-head" @click="onClickHandler(step)">
            <div class="title-wrapper">
                <a-glyph v-if="!isMobile && isDone" class="status-icon" name="clr-check-circle--24" />
                <span v-else class="status" :class="{ active: isMobile || isShown }">
                    <span>{{ step }}</span>
                </span>
                <div v-if="title" class="title">
                    {{ title }}
                </div>
                <a-glyph v-if="isRequired" class="required-icon" name="i-star--16" />
                <a-glyph
                    v-if="!isMobile"
                    class="arrow-icon"
                    :name="isShown ? 'i-chevron-down--16' : 'i-chevron-right--16'"
                />
            </div>
            <div v-if="!isMobile && !isShown && selected?.length" class="selected">
                <div
                    v-for="(item, i) in selected"
                    :key="`step_${currentStep}_selected_items_${i}`"
                    class="item"
                >
                    {{ item }}
                </div>
            </div>
        </div>
        <div
            v-show="isMobile || isShown"
            class="step-content"
        >
            <slot />
        </div>
        <div v-if="!isMobile && isShown && !shouldHide" class="navigation">
            <a-button
                v-if="currentStep > 1"
                :text="navigation.prev"
                size="tiny"
                type="secondary"
                @click="onPrevHandler"
            />
            <a-button
                v-if="currentStep < count"
                :text="navigation.next"
                size="tiny"
                type="main"
                @click="onNextHandler"
            />
            <a-button
                v-if="currentStep === count"
                :text="navigation.last"
                size="tiny"
                type="main"
                @click="onLastClick"
            />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    components: {
        AButton,
        AGlyph,
    },
    props: {
        step: {
            type: Number,
            default: 1,
        },
        navigation: {
            type: Object,
            default: () => ({
                prev: 'Previous',
                next: 'Next',
                last: 'Finish',
            }),
        },
        currentStep: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        selectedServices: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: 2,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        isShown: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        isDone: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onNextClick', 'onPrevClick', 'onHeadClick', 'onLastClick'],
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        shouldHide() {
            return this.step === 2 && !this.selectedServices.length;
        },
    },
    methods: {
        onClickHandler(step) {
            this.$emit('onHeadClick', step);
        },
        onNextHandler() {
            this.$emit('onNextClick');
        },
        onLastClick() {
            this.$emit('onLastClick');
        },
        onPrevHandler() {
            this.$emit('onPrevClick');
        },
    },
};
</script>

<style lang="postcss" scoped>
.step {
    box-shadow: var(--av-shadow-small);
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-light);
    color: var(--av-nav-primary);
    border-radius: 4px;
    .step-head {
        cursor: pointer;
        user-select: none;
        padding: 16px;
        @media (--viewport-tablet) {
            padding: 24px;
        }
        .title-wrapper {
            display: flex;
            flex-flow: row;
            align-items: center;
            @media (--viewport-desktop) {
                flex-flow: row wrap;
            }
            .status-icon {
                width: 16px;
                height: 16px;
                margin-inline-end: 16px;
            }
            .status {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-inline-end: 16px;
                background: var(--av-fixed-info-dark);
                color: var(--av-inversed-primary);
                &.active {
                    background: var(--av-fixed-success);
                }
                span {
                    @mixin note-accent;
                    letter-spacing: 0;
                    font-size: 10px;
                }
            }
            .title {
                max-width: calc(100% - 64px);
                @mixin lead-accent;
                margin-inline-end: 4px;
            }
            .required-icon {
                fill: var(--av-fixed-danger);
            }
            .arrow-icon {
                margin-inline-start: auto;
            }
        }
        .selected {
            @mixin descriptor;
            display: flex;
            align-items: center;
            margin-top: 8px;
            padding-inline-start: 32px;
            color: var(--av-fixed-light);
            gap: 8px;
            .item {
                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    background: var(--av-fixed-lightest);
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-inline-end: 4px;
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .navigation {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid var(--av-brand-secondary-light);
        margin-top: 24px;
        padding: 16px 24px;
    }
}
</style>
