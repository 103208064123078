<template>
    <header class="s-main-header" :class="{ ...className, ...sliceClassName }">
        <div class="a-container">
            <div class="s-main-header__content">
                <div ref="mainHeaderLogo" class="s-main-header__logo-container">
                    <dynamic-link :to="logoLink">
                        <a-logo
                            class="s-main-header__logo"
                            :class="{ 'full': logoType === 'full', 'icon': logoType === 'icon' }"
                            :type="logoType"
                            :fill="theme === 'light' ? null : 'white'"
                        />
                    </dynamic-link>
                </div>
                <div v-if="menuIsAvailable" ref="mainHeaderMenu" class="s-main-header__nav">
                    <ul id="s-main-header-menu" class="s-main-header__menu">
                        <li
                            v-for="(item, index) in headerMenu"
                            :key="`menu-item-${index}`"
                            class="s-main-header__menu-item"
                            :class="{ 's-main-header__menu-item_active': item.id === activeTabId }"
                        >
                            <button
                                :ref="`menuLink_${item.id}`"
                                class="s-main-header__menu-link"
                                type="button"
                                :class="menuItemClassName(item)"
                                @click.prevent.stop="onHeaderMenuLinkClick(item.id)"
                            >
                                {{ item.name }}
                            </button>
                        </li>
                    </ul>
                </div>
                <div
                    v-if="panelIsAvailable"
                    ref="mainHeaderButtons"
                    :class="{ noText: showMenuButtonsText }"
                    class="s-main-header__buttons"
                >
                    <div v-if="showContactBtn" ref="contact" class="s-main-header__contact">
                        <button
                            class="s-main-header__contact-btn"
                            type="button"
                            :class="{ 's-main-header__contact-btn_active': isContactMenuShown }"
                            @click.prevent.stop="toggleContactMenu()"
                        >
                            <a-glyph class="s-main-header__contact-glyph" name="phone3" />
                            <span class="s-main-header__contact-text">{{ contact.text }}</span>
                        </button>
                        <div v-show="isContactMenuShown" ref="contactMenu" class="s-contact-menu">
                            <ul class="s-contact-menu__list-services">
                                <li
                                    v-for="(contactItem, index) in contact.list"
                                    :key="`contact-item-${index}`"
                                    class="s-contact-menu__list-services-item"
                                >
                                    <a-link
                                        class="s-contact-menu__list-services-link"
                                        v-bind="contactItem"
                                        :event="{ action: 'Menu Contact' }"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div ref="login" class="s-main-header__login" @mouseleave="resetLoginState">
                        <button
                            class="s-main-header__login-btn"
                            type="button"
                            :class="{
                                's-main-header__login-btn_active': isLoginShown,
                                's-main-header__login-btn_logged-in': user
                            }"
                            @click.prevent.stop="checkSubmenu"
                        >
                            <span :class="{ 'logged-in': user }" class="s-main-header__login-glyph-wrapper">
                                <a-glyph class="s-main-header__login-glyph" name="group" />
                            </span>
                            <span v-if="user" class="s-main-header__login-text">
                                {{ account.menu.loggedInText }}
                            </span>
                            <span v-else class="s-main-header__login-text" v-html="account.text" />
                        </button>
                        <div v-show="isLoginShown" ref="loginMenu" class="s-login-menu">
                            <template v-if="user">
                                <div class="s-login-menu__top">
                                    <div class="s-login-menu__title">
                                        {{ account.menu.title }}
                                    </div>
                                    <div v-if="user && user.email" class="s-login-menu__email">
                                        {{ user.email }}
                                    </div>
                                </div>
                                <ul class="s-login-menu__list">
                                    <li
                                        v-for="(item, index) in account.menu.nav"
                                        :key="`login-menu-item-${index}`"
                                        class="s-login-menu__item"
                                    >
                                        <a-link
                                            class="s-login-menu__link"
                                            v-bind="item"
                                            :event="{ action: 'Login', label: 'Drop-down list' }"
                                        />
                                    </li>
                                </ul>
                                <div class="s-login-menu__bottom">
                                    <dynamic-link
                                        class="s-login-menu__logout"
                                        type="button"
                                        :event="{ action: 'signOut' }"
                                        @click.prevent.stop="logout"
                                    >
                                        <span class="s-login-menu__logout-text">{{ account.menu.logout }}</span>
                                        <a-glyph class="s-login-menu__logout-icon" name="exit" />
                                    </dynamic-link>
                                </div>
                            </template>
                            <ul v-if="account.menu && account.menu.services" class="s-login-menu__list-services">
                                <li
                                    v-for="(item, index) in servicesList"
                                    :key="`list-services-item-${index}`"
                                    class="s-login-menu__list-services-item"
                                >
                                    <a-link
                                        class="s-login-menu__list-services-link"
                                        v-bind="item"
                                        :event="{ action: 'Menu signIn' }"
                                        :target="item.target || '_self'"
                                        :desc="item.description"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="lang" class="s-main-header__lang">
                        <button
                            v-if="localeSelectorIsAvailable"
                            class="s-main-header__lang-btn short-version"
                            @click="showLocaleSelector"
                        >
                            <a-glyph name="language-select" />
                        </button>
                        <s-locale-selector :is-shown="isLocaleSelectorShown" @close="hideLocaleSelector" />
                    </div>
                    <div v-if="search?.to" class="s-main-header__search">
                        <a-link
                            class="s-main-header__search-link"
                            :to="search.to"
                            :glyph="search.glyph || 'search'"
                            :event="{ action: 'search' }"
                        />
                    </div>
                </div>
                <button
                    v-if="panelIsAvailable && menuIsAvailable"
                    ref="menuToggler"
                    class="menu-toggler"
                    :class="{ 'menu-toggler_state_active': isActive, noText: showMenuTogglerText }"
                    type="button"
                    tabindex="1"
                    @click.prevent.stop="toggleMenu"
                >
                    <span class="menu-toggler__caption menu-toggler__caption-default-text">{{ upText }}</span>
                    <span class="menu-toggler__caption menu-toggler__caption-opened-text">{{ downText }}</span>
                    <a-glyph class="menu-toggler__icon" :name="getMenuTogglerIcon" />
                </button>
            </div>
        </div>
        <div
            v-if="menuIsAvailable"
            v-show="isActive"
            ref="menu"
            class="s-main-menu"
            :class="{ 's-main-menu_centered': activeTab && activeTab.type === 'centered' }"
            :style="mainMenuStyle"
        >
            <div v-if="!isFullMenuShown" class="s-main-menu--mobile-accordion">
                <div class="s-main-menu__accordion-wrapper">
                    <a-accordion class="s-main-menu__accordion">
                        <a-accordion-item
                            v-for="(item, index) in getTabs"
                            :id="`accordion-item-${item.id}`"
                            :key="`menu-tab-${index}`"
                            :class="`a-accordion-item--${item.id}`"
                            :title="item.name"
                        >
                            <component
                                :is="getMenuTabComponent(item)"
                                :id="item.id"
                                :menu="item.data"
                                :active-tab-id="activeTabId"
                                :type="item.type"
                                :tabs="item.tabs"
                                :environments="item.environments"
                                :version="item.version"
                            />
                        </a-accordion-item>
                    </a-accordion>
                </div>
            </div>
            <div v-if="isFullMenuShown" class="s-main-menu--desktop-accordion">
                <div v-if="hasInfo" class="s-main-menu__tab-information">
                    {{ activeTab.info }}
                    <button
                        class="s-main-menu__closer"
                        type="button"
                        aria-label="Close menu"
                        @click="hideMenu"
                    >
                        <a-glyph class="s-main-menu__closer-icon" name="times" />
                    </button>
                </div>
                <div
                    class="s-main-menu__tabs-wrapper"
                    :class="getTabsWrapperClassName(activeTab)"
                >
                    <div class="s-main-menu__tab">
                        <component
                            :is="getMenuTabComponent(activeTab)"
                            v-if="activeTabId"
                            :id="activeTab.id"
                            ref="menuTab"
                            :menu="activeTab.data"
                            :type="activeTab.type"
                            :active-tab-id="activeTabId"
                            :tabs="activeTab.tabs"
                            :environments="activeTab.environments"
                            :version="activeTab.version"
                        />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { debounce, cloneDeepWith } from 'lodash';
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SLocaleSelector from '@core/slices/pages/locale-selector/locale-selector.vue';
import MenuTab from './menu-tab.vue';
import MenuTabCompany from './tabs/menu-tab-company.vue';
import MenuTabPartners from './tabs/menu-tab-partners.vue';
import MenuTabPricing from './tabs/menu-tab-pricing.vue';
import MenuTabProducts from './tabs/menu-tab-products.vue';
import MenuTabResourceCenter from './tabs/menu-tab-resource-center.vue';
import MenuTabSolutions from './tabs/menu-tab-solutions.vue';
import MenuTabSupport from './tabs/menu-tab-support.vue';

const TAB_COMPONENT_MAP = {
    company: MenuTabCompany,
    resourceCenter: MenuTabResourceCenter,
    partners: MenuTabPartners,
    products: MenuTabProducts,
    solutions: MenuTabSolutions,
    pricing: MenuTabPricing,
    support: MenuTabSupport,
    default: MenuTab,
};

export default {
    name: 'SMainHeader',

    components: {
        DynamicLink,
        SLocaleSelector,
        MenuTab,
        MenuTabProducts,
        MenuTabSolutions,
        ALogo,
        ALink,
        AGlyph,
        AAccordion,
        AAccordionItem,
    },

    mixins: [breakpoint],

    props: {
        sliceSettingsName: {
            type: String,
            default: 's-main-header',
            required: false,
        },

        /**
         * Theme
         */
        theme: {
            type: String,
            default: 'dark',
            required: false,
        },

        /**
         * Ability to change the link of the logo
         */
        logoLink: {
            type: String,
            default: '/',
            required: false,
        },

        /**
         * menu is available
         */
        menuIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * panel is available
         */
        panelIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * Locale selector is available
         */
        localeSelectorIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * List of links to update
         */
        listToUpdate: {
            type: Array,
            default: null,
        },
    },

    data: () => ({
        MINI_MENU_RIGHT_WINDOW_MARGIN: 32,
        LANGUAGE_MAX_WIDTH: 120,
        selectors: {
            menu: '.s-main-menu',
            nav: '.s-main-header__nav',
        },
        isActive: false,
        lastClass: 's-main-menu-active',
        activeTabId: null,
        menuState: null,
        isLoginShown: false,
        isContactMenuShown: false,
        loginState: null,
        isLocaleSelectorShown: false,
        leftMenuMargin: 0,
        langButtonWidth: 0,
        showMenuButtonsText: false,
        showMenuTogglerText: false,
        moveContactsToMobile: false,
    }),

    computed: {
        config() {
            return this.$store.state.slices.items[this.sliceSettingsName] ?? {};
        },

        className() {
            return {
                's-main-header_theme_dark': this.theme === 'dark',
                's-main-header_theme_light': this.theme === 'light',
            };
        },

        user() {
            // Used for TI site.
            // Doing it here instead of `beforeMount()` in order to avoid bugs in case
            // any other slice dispatches the `user/get` action.
            return this.config.disableLoginCheck
                ? null
                : this.$store.state.user.data;
        },

        lang() {
            return this.config.lang || '';
        },

        account() {
            return this.config.account || {};
        },

        contact() {
            return this.config.contact || {};
        },

        showContactBtn() {
            return this.config.showContactBtn || false;
        },

        search() {
            return this.config.search;
        },

        upText() {
            return this.config.upText || '';
        },

        downText() {
            return this.config.downText || '';
        },

        headerMenu() {
            return this.config.menu || {};
        },

        getMenuText() {
            return this.isActive ? this.downText : this.upText;
        },

        getMenuTogglerIcon() {
            //  TODO: Invalid prop: custom validator check failed for prop "name
            return this.isActive ? 'big-close' : 'burger-menu';
        },

        logoType() {
            return this.config.logoType ?? 'full';
        },

        getTabs() {
            const contacts = {
                id: 'contact-us',
                name: this.contact?.text,
                data: [{
                    content: [{
                        items: this.contact?.list,
                        type: 'list',
                    }],
                }],
            };

            if (this.listToUpdate) {
                const tabs = cloneDeepWith(this.headerMenu, (value) => {
                    const link = this.listToUpdate.find((item) => item.to === value?.to);

                    if (link?.queryString) {
                        const queryString = new URLSearchParams(link.queryString);
                        return { ...value, to: `${value.to}?${queryString.toString()}` };
                    }

                    return undefined;
                });

                if (this.moveContactsToMobile) {
                    tabs.push(contacts);
                }

                return tabs;
            }

            return this.moveContactsToMobile && this.showContactBtn ? [...this.headerMenu, contacts] : this.headerMenu;
        },

        activeTab() {
            return this.activeTabId
                ? this.getTabs.find((tab) => tab.id === this.activeTabId)
                : null;
        },

        hasInfo() {
            return this.activeTab?.info || false;
        },

        mainMenuStyle() {
            return { left: `${this.leftMenuMargin}px` };
        },

        sliceClassName() {
            return {
                's-main-header_state_active': this.isActive,
                's-main-header_state_active-light': this.hasInfo,
                's-main-header--menu-active': this.isActive || this.isLoginShown || this.isContactMenuShown,
            };
        },

        servicesList() {
            if (!this.account.menu || !this.account.menu.services) return false;

            if (this.user) {
                return this.account.menu.services.filter((el) => el.authorization);
            }
            return this.account.menu.services;
        },

        isWidthChecked() {
            return this.langButtonWidth > 0;
        },

        isShortLangDisplayed() {
            return this.isMobile || this.isTablet || this.langButtonWidth > this.LANGUAGE_MAX_WIDTH;
        },

        isFullMenuShown() {
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },
    },

    watch: {
        isFullMenuShown() {
            this.hideMenu();
        },

        async isContactMenuShown() {
            await this.$nextTick();

            this.setContactMenuPosition();
        },

        menuState() {
            if (this.menuState === 'hover') {
                document.addEventListener('mousemove', this.removeHoverMenuState);
            }

            if (this.menuState === 'fix') {
                document.addEventListener('click', this.removeFixedMenuState);
                document.addEventListener('scroll', debounce(this.setMenuCoordsListener, 100));
            }

            if (this.menuState === null) {
                document.removeEventListener('mousemove', this.removeHoverMenuState);
                document.removeEventListener('click', this.removeFixedMenuState);
                document.removeEventListener('scroll', debounce(this.setMenuCoordsListener, 200));
            }
        },

        loginState() {
            if (this.loginState === 'fix') {
                document.addEventListener('click', this.removeFixedLoginState);
                document.addEventListener('scroll', this.setLoginCoordsListener);
            }

            if (this.loginState === null) {
                document.removeEventListener('click', this.removeFixedLoginState);
                document.removeEventListener('scroll', this.setLoginCoordsListener);
            }
        },
    },

    beforeMount() {
        this.$store.dispatch('user/get');
    },

    mounted() {
        document.addEventListener('click', this.hideContactMenu);
        window.addEventListener('resize', this.calculateMenuPosition);
        this.checkLangButtonWidth();

        window.addEventListener('resize', debounce(this.checkSpaceBetweenMenuItems, 500));
        this.checkSpaceBetweenMenuItems();
    },

    methods: {
        getTabsWrapperClassName(data) {
            if (!data) return [];

            return {
                [`s-main-menu__tabs-wrapper--${data.id}`]: data.id,
                's-main-menu__tabs-wrapper--no-tabs': !data.tabs,
            };
        },
        getMenuTabComponent(tab) {
            const tabId = tab.id;
            const key = tabId in TAB_COMPONENT_MAP ? tabId : 'default';

            if (tabId === 'solutions' && !(tab.tabs && tab.environments)) {
                return TAB_COMPONENT_MAP.default;
            }

            return TAB_COMPONENT_MAP[key];
        },

        setContactMenuPosition() {
            const el = this.$refs.contactMenu;
            if (!this.isContactMenuShown) {
                el.style.left = 0;
                return;
            }

            const right = el.getBoundingClientRect().right;
            const documentWidth = document.body.getBoundingClientRect().width;

            const distance = documentWidth - right;

            if (distance > 0) return;

            el.style.left = `${distance}px`;
        },
        onHeaderMenuLinkClick(id) {
            this.selectTab(id);
            this.switchMenuState('fix');
        },

        headerItemsMinDistance() {
            const MIN_DISTANCE = 48;

            const mainHeaderMenu = this.$refs.mainHeaderMenu;
            const mainHeaderButtons = this.$refs.mainHeaderButtons;
            const mainHeaderLogo = this.$refs.mainHeaderLogo;

            const mainHeaderMenuRight = mainHeaderMenu?.getBoundingClientRect().right || 0;
            const mainHeaderButtonsLeft = mainHeaderButtons?.getBoundingClientRect().left || 0;
            const mainHeaderLogoRight = mainHeaderLogo?.getBoundingClientRect().right || 0;

            const distanceFromMenu = mainHeaderButtonsLeft - mainHeaderMenuRight;
            const distanceFromLogo = mainHeaderButtonsLeft - mainHeaderLogoRight;

            return distanceFromMenu <= MIN_DISTANCE || distanceFromLogo <= MIN_DISTANCE;
        },

        async checkSpaceBetweenMenuItems() {
            this.showMenuButtonsText = false;
            this.showMenuTogglerText = false;

            await this.$nextTick();
            this.showMenuButtonsText = this.headerItemsMinDistance();

            await this.$nextTick();
            this.showMenuTogglerText = this.headerItemsMinDistance();

            await this.$nextTick();
            this.moveContactsToMobile = this.headerItemsMinDistance();
        },

        menuItemClassName(item) {
            return {
                's-main-header__menu-link_active': item.id === this.activeTabId,
            };
        },

        toggleMenu() {
            this.isActive = !this.isActive;
        },

        selectTab(to) {
            if (this.activeTabId === to) {
                if (this.isActive) {
                    this.activeTabId = null;
                }
                this.toggleMenu();
                return;
            }
            this.isActive = true;
            this.activeTabId = to;
        },

        switchMenuState(actionType) {
            if (this.isFullMenuShown) {
                if (actionType !== this.menuState) {
                    if (!(this.menuState === 'fix' && actionType === 'hover')) {
                        this.menuState = actionType;
                        this.isActive = true;
                    }
                }
            }

            if (this.isLoginShown) {
                this.isLoginShown = false;
                this.loginState = null;
            }

            if (this.isContactMenuShown) {
                this.isContactMenuShown = false;
            }
            this.$nextTick(() => this.calculateMenuPosition());
        },

        removeHoverMenuState(event) {
            if (
                this.menuState !== 'fix' &&
                !event.target.closest(this.selectors.menu) &&
                !event.target.closest(this.selectors.nav)
            ) {
                this.hideMenu();
            }
        },

        toggleContactMenu() {
            this.isContactMenuShown = !this.isContactMenuShown;

            if (this.isContactMenuShown) {
                this.hideMenu();
                this.hideLogin();
            }
        },

        hideContactMenu(event) {
            if (event.currentTarget !== this.$refs.contact) {
                this.isContactMenuShown = false;
            }
        },

        setMenuCoordsListener() {
            if (this.isFullMenuShown) {
                if (this.$refs.menu.getBoundingClientRect().bottom < 0) {
                    this.hideMenu();
                }
            }
        },

        removeFixedMenuState(event) {
            if (!event.target.closest(this.selectors.menu)) {
                this.hideMenu();
            }
        },

        hideMenu() {
            this.menuState = null;
            this.isActive = false;
            this.activeTabId = null;
        },

        hasServicesMenu() {
            return this.account?.menu?.services || false;
        },

        checkSubmenu() {
            if (this.user || this.hasServicesMenu()) {
                this.isLoginShown = !this.isLoginShown;

                if (this.isContactMenuShown) {
                    this.isContactMenuShown = false;
                }

                this.switchLoginState('fix');
                return;
            }
            window.location.assign('https://account.acronis.com/');
        },

        hideLogin() {
            this.isLoginShown = false;
        },

        switchLoginState(actionType) {
            if (actionType !== this.loginState) {
                if (!(this.loginState === 'fix' && actionType === 'hover')) {
                    this.loginState = actionType;
                }
            }

            this.hideMenu();
        },

        resetLoginState() {
            if (this.loginState !== 'fix') {
                this.isLoginShown = false;
            }
        },

        removeFixedLoginState(event) {
            if (event.currentTarget !== this.$refs.login) {
                this.loginState = null;
                this.isLoginShown = false;
            }
        },

        // WARN: don't change `function()` to `() =>` because this.$refs won't be available in this case
        setLoginCoordsListener: debounce(function setLoginCoordsListenerDebounced() {
            if (this.$refs.loginMenu.getBoundingClientRect().bottom < 0) {
                this.loginState = null;
                this.isLoginShown = false;
            }
        }, 200),

        logout() {
            this.$store.dispatch('user/logout');
            this.hideLogin();
        },

        showLocaleSelector() {
            this.isLocaleSelectorShown = true;
        },

        hideLocaleSelector() {
            this.isLocaleSelectorShown = false;
        },

        /* used on minisite to align menu to the center of the active menu link */
        calculateMenuPosition() {
            if (!(this.$refs.menuTab && this.activeTab.type === 'centered')) {
                this.leftMenuMargin = 0;
                return;
            }

            let leftMarginRelativeToLink = 0;
            const activeLinkRefs = this.$refs?.[`menuLink_${this.activeTabId}`];

            if (activeLinkRefs?.length) {
                const activeLink = activeLinkRefs[0];
                leftMarginRelativeToLink = activeLink.getBoundingClientRect().left + activeLink.clientWidth / 2;
            }

            /* using half width because menu tab is transformed by 50% to the left */
            const menuTabHalfWidth = this.$refs.menuTab.$el.offsetWidth / 2;

            const windowWidth = window.innerWidth;

            /* changing left margin if it makes menu tab overflow window */
            if (
                leftMarginRelativeToLink + menuTabHalfWidth >
                windowWidth - this.MINI_MENU_RIGHT_WINDOW_MARGIN
            ) {
                this.leftMenuMargin =
                    windowWidth - this.MINI_MENU_RIGHT_WINDOW_MARGIN - menuTabHalfWidth;
            } else {
                this.leftMenuMargin = leftMarginRelativeToLink;
            }
        },

        checkLangButtonWidth() {
            this.langButtonWidth = this.$refs?.langText?.scrollWidth || 0;
        },
    },

    async serverPrefetch() {
        const locale = this.$route.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: this.sliceSettingsName, locale });
    },
};
</script>

<style lang="postcss">
/* WEB-43924 Check all z-indexes here */
.s-main-header {
    position: absolute;
    top: 24px;
    width: 100%;
    z-index: 400;

    .logged-in {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 999px;

        .a-glyph {
            margin-inline-end: 2px;
        }
    }

    &--menu-active {
        z-index: 999;
    }

    @media (--viewport-desktop-wide) {
        top: 32px;

        &_state {
            &_active {
                .s-main-header {
                    &__menu-link {
                        &_active {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.s-main-header {
            &_state {
                &_active-light {
                    .s-main-header {
                        &__menu-link {
                            &_active {
                                &::before {
                                    background: var(--av-solid-brand-accent);
                                }
                            }
                        }
                    }
                    .s-main-menu {
                        z-index: 100;
                    }
                }
            }
        }
    }

    &__logo {
        height: 27px;
        width: 133px;

        @media (--viewport-desktop) {
            height: 32px;
            width: 158px;
        }

        &-container {
            @media (--viewport-tablet) {
                margin-inline-end: 48px;
            }
        }
    }

    &__logo.icon {
        width: 38px;
    }

    &__content {
        z-index: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (--viewport-desktop-wide) {
            justify-content: flex-start;
        }
    }

    &__nav {
        z-index: 100;
    }

    &__menu {
        display: none;
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        width: 100%;
        height: 0;
        background: var(--av-inversed-primary);

        @media (--viewport-desktop-wide) {
            position: static;
            height: auto !important;
            background: rgba(255, 255, 255, 0);
            display: flex;
            width: auto;
            margin-inline-end: auto;
        }

        &-item {
            padding: 10px 0;

            @media (--viewport-desktop-wide) {
                padding: 0;
            }
            &:first-of-type {
                .s-main-header__menu-link {
                    padding-inline-start: 0;
                }
            }

            &:last-of-type {
                .s-main-header__menu-link {
                    padding-inline-end: 0;
                }
            }
        }

        &-link {
            @mixin paragraph-accent;

            padding: 0 12px;
            background: none;
            border: none;
            color: var(--av-nav-secondary);
            cursor: pointer;

            &::before {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 12px);
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 27px;
                height: 27px;
                background: linear-gradient(
                    135deg,
                    rgb(255, 255, 255) 10px,
                    rgba(255, 255, 255, 0) 10px
                );
                pointer-events: none;

                @media (--viewport-desktop-large) {
                    top: calc(100% + 17px);
                }
            }

            @media (--viewport-desktop) {
                position: relative;
                text-transform: capitalize;
                color: var(--av-inversed-primary);
            }
        }
    }

    &__buttons {
        border: 0;
        z-index: 105;
        height: 24px;
        display: flex;
        margin-inline-start: auto;
        position: relative;
        align-items: center;

        &.noText {
            .s-main-header__contact-text,
            .s-main-header__login-text {
                display: none;
            }
            .s-login-menu,
            .s-contact-menu {
                top: calc(100% + 16px);
            }
        }

        &::before {
            top: 0;
            inset-inline-end: 0;
            width: 1px;
            content: '';
            height: 24px;
            position: absolute;
        }

        > div {
            position: relative;
            height: 24px;
            display: flex;
            align-items: center;

            &::before {
                width: 1px;
                position: absolute;
                content: '';
                background-color: var(--av-fixed-info-dark);
                inset-inline-start: 0;
                top: 0;
                bottom: 0;
            }

            &:first-of-type {
                padding-inline-start: 0;
                &::before {
                    width: 0;
                }
            }

            margin-inline-end: 0;
            padding-inline-start: 12px;
            padding-inline-end: 12px;

            @media (--viewport-mobile-wide) {
                padding-inline-start: 20px;
                padding-inline-end: 20px;
            }
        }

        @media (--viewport-desktop-wide) {
            &::before {
                display: none;
            }
        }
    }

    &__lang {
        margin: 0;
        z-index: 100;

        &-btn {
            margin: 0;
            padding: 0;
            display: flex;
            border: none;
            cursor: pointer;
            background: none;
            align-items: center;

            &.short-version {
                .s-main-header {
                    &__lang-text {
                        visibility: hidden;
                        position: absolute;
                    }
                }
            }

            &.hidden {
                visibility: hidden;
                white-space: nowrap;
            }
        }

        &-text {
            overflow: hidden;
            max-width: 56px;
            margin-inline-start: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (--viewport-desktop) {
                margin-inline-end: 8px;
                display: inline-block;
            }
        }
    }

    &__contact,
    &__login,
    &__search {
        z-index: 90;
    }

    &__lang,
    &__contact,
    &__login {
        margin-inline-end: 16px;
        position: relative;
        white-space: nowrap;

        @media (--viewport-desktop) {
            margin-inline-end: 24px;
        }

        &-btn {
            display: flex;
            border: none;
            cursor: pointer;
            overflow: hidden;
            background: none;
            position: relative;
            align-items: center;
            padding: 0 0 21px 0;
            margin: 0 0 -21px 0;

            &::before {
                content: '';
                opacity: 0;
                width: 24px;
                z-index: 102;
                height: 24px;
                position: absolute;
                pointer-events: none;
                top: calc(100% - 4px);
                inset-inline-start: calc(50% - 12px);
                transform: rotate(45deg);
                background: var(--av-inversed-primary);
                border: 1px solid var(--av-brand-secondary);
            }

            &_active {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &__contact,
    &__login {
        &-text {
            overflow: hidden;
            margin-inline-start: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__login {
        &-glyph-wrapper {
            display: flex;
            align-items: center;
        }
    }

    &__search {
        height: 100%;

        &-link {
            height: 100%;
            display: block;
            padding: 4px 0 0;
        }

        @media (--viewport-tablet) {
            margin-inline-end: 16px;
        }

        @media (--viewport-desktop) {
            margin: 0;
            display: block !important;
        }
    }

    .menu-toggler {
        padding: 0;
        border: none;
        display: flex;
        z-index: 100;
        height: 32px;
        min-width: 48px;
        cursor: pointer;
        background: none;
        position: relative;
        align-items: center;
        justify-content: flex-end;

        &.noText {
            min-width: 40px;
            &.menu-toggler_state_active .menu-toggler__icon {
                margin-inline-end: 12px;
            }
            .menu-toggler__caption {
                display: none;
            }
        }

        @media (--viewport-mobile-wide) {
            min-width: 83px;
        }
        &__caption {
            @mixin body;
            padding: 0 8px 0 16px;
        }

        &__caption-opened-text {
            display: none;
        }

        &__icon {
            width: 32px;
            height: 32px;
            transition: none;
            fill: var(--av-inversed-primary);
        }

        &_state {
            &_active {
                .menu-toggler {
                    &__caption-default-text {
                        display: none;
                    }

                    &__caption-opened-text {
                        color: var(--av-brand-secondary);
                        display: block;
                    }

                    &__icon {
                        width: 16px;
                        height: 16px;
                        color: var(--av-brand-secondary);
                        @media (--viewport-mobile-wide) {
                            margin-inline-start: 8px;
                        }
                    }
                }
            }
        }

        @media (--viewport-desktop-wide) {
            display: none;
        }
    }

    .s-main-menu {
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 48px;
        z-index: 75;
        width: 100%;
        position: absolute;
        min-height: calc(100vh - 75px);
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border: 1px solid var(--av-brand-accent);

        &--mobile-accordion {
            @media (--viewport-desktop-wide) {
                display: none;
            }
        }

        &--desktop-accordion {
            display: none;

            @media (--viewport-desktop) {
                display: block;
            }
        }

        @media (--viewport-tablet) {
            top: 56px;
        }

        @media (--viewport-desktop-wide) {
            min-height: auto;
            top: 44px;
            width: 100vw;
            max-width: 100%;
        }

        @media (--viewport-desktop-large) {
            top: 48px;
        }

        &.s-main-menu_centered {
            inset-inline-end: auto;
            width: auto;
            transform: translateX(-50%);
            border-radius: 4px;
        }

        &__tabs-wrapper {
            position: relative;
            margin: auto;
            width: 100%;
            max-width: 1312px;

            &--solutions {
                max-width: initial;
            }
        }

        &__tab-information {
            @mixin caption;
            padding: 8px 16px;
            text-align: center;
            position: relative;
            color: var(--av-fixed-light);
            background-color: var(--av-solid-brand-accent);

            .s-main-menu {
                &__closer {
                    top: auto;
                    bottom: -64px;
                }
            }
        }

        &__disable {
            display: none;
        }

        &__accordion-wrapper {
            width: 100%;
            padding: 16px 0 0;
        }

        &__closer {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 36px;
            inset-inline-end: 32px;
            z-index: 1;
            background: none;
            border: none;
            width: 16px;
            height: 16px;
            cursor: pointer;

            &:hover {
                .s-main-menu__closer-icon {
                    color: var(--av-link-primary-hover);
                }
            }

            @media (--viewport-desktop) {
                display: flex;
            }

            @media (--viewport-desktop-wide) {
                inset-inline-end: 52px;
            }

            @media (--viewport-desktop-large) {
                inset-inline-end: 64px;
            }
        }

        &__closer-icon {
            flex: 0 0 16px;
            height: 16px;
            color: var(--av-brand-secondary);
        }

        .a-accordion-item {
            &:first-of-type {
                border-top: none;
            }

            .title {
                @mixin lead-accent;

                padding: 24px 16px 24px 16px;

                @media (--viewport-tablet) {
                    @mixin title-accent;

                    padding: 24px 16px 24px 32px;
                }
            }

            .icon {
                margin-inline-end: 16px;

                @media (--viewport-tablet) {
                    margin-top: 8px;
                }
            }

            .content-wrapper {
                padding: 0;
            }

            .content {
                padding: 0;
                background-color: var(--av-brand-lightest);
                box-shadow: inset 0 5px 6px rgba(138, 173, 207, 0.1865);
            }

            .s-menu-tab__section_type_contact-us {
                padding-top: 0;
            }
        }
    }
    .s-contact-menu,
    .s-login-menu {
        inset-inline-start: 0;
        width: 264px;
        z-index: 101;
        border-radius: 4px;
        position: absolute;
        margin-inline-start: -166px;
        top: calc(100% + 20px);
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border: 1px solid var(--av-brand-secondary);

        @media(--viewport-mobile-wide) {
            top: calc(100% + 20px);
        }

        &__top {
            padding-top: 24px;
            padding-inline-start: 16px;
        }

        &__title {
            @mixin paragraph-accent;
            color: var(--av-nav-primary);
        }

        &__email {
            @mixin paragraph;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--av-brand-secondary);
        }

        &__list {
            padding: 24px 0 0;

            &-services {
                margin: -1px 0 0 0;

                &-item {
                    border-top: 1px solid var(--av-brand-accent);

                    .a-link__content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: calc(100%);
                    }
                }

                &-link {
                    @mixin paragraph-accent;
                    display: block;
                    padding: 12px 16px;
                    color: var(--av-nav-primary);

                    .a-link__content {
                        font-size: 16px;
                    }

                    .a-link__desc {
                        @mixin body;
                        color: var(--av-fixed-secondary);
                        margin-top: 0;
                        font-weight: 400;
                        white-space: pre-wrap;
                    }
                }
            }
        }

        &__link {
            @mixin paragraph-accent;
            display: block;
            padding: 8px 0;
            padding-inline-start: 16px;
        }

        &__bottom {
            @mixin paragraph-accent;
            padding: 8px 0 0;
            position: relative;
            margin: 16px 0 24px;
            color: var(--av-nav-primary);

            &::before {
                content: '';
                top: 0;
                inset-inline-start: 16px;
                height: 1px;
                width: 40px;
                position: absolute;
                background: var(--av-brand-accent);
            }
        }

        &__logout {
            width: 100%;
            border: none;
            display: block;
            cursor: pointer;
            padding: 8px 16px;
            align-items: center;
            background: rgba(255, 255, 255, 0);

            &-text {
                @mixin paragraph-accent;
                margin-inline-end: 8px;
                color: var(--av-nav-primary);
            }

            &-icon {
                fill: var(--av-brand-secondary);
                vertical-align: middle;
            }
        }

        &__link,
        &__logout,
        &__list-services-link {
            &:hover {
                background: var(--av-brand-secondary-accent);
            }
            &:active {
                background: var(--av-brand-secondary-light);
            }
        }

        @media (--viewport-mobile-wide) {
            margin-inline-start: -169px;
        }

        @media (--viewport-tablet) {
            margin-inline-start: -135px;
        }

        @media (--viewport-desktop) {
            margin-inline-start: -168px;
        }
    }

    &__lang + &__contact .s-contact-menu {
        margin-inline-start: -122px;

        @media (--viewport-tablet) {
            margin-inline-start: -106px;
        }

        @media (--viewport-desktop) {
            margin-inline-start: -85px;
        }
    }

    &__contact {
        .s-contact-menu {
            min-width: 216px;
            width: fit-content;
            margin-inline-start: -142px;

            @media (--viewport-tablet) {
                margin-inline-start: -106px;
            }

            @media (--viewport-desktop) {
                margin-inline-start: -85px;
            }
        }

        &-text {
            max-width: initial;
        }
    }

    &_theme {
        &_dark {
            .s-main-header {
                &__menu-link {
                    color: var(--av-inversed-primary);

                    &:hover {
                        color: var(--av-link-inversed);
                    }

                    &:active {
                        color: var(--av-inversed-light);
                    }

                    &_active {
                        @mixin paragraph-accent;
                        color: var(--av-inversed-primary);

                        &:hover {
                            color: var(--av-inversed-primary);
                        }
                    }
                }

                &__login-text.active  {
                    color: var(--av-link-inversed);
                }

                &__lang-btn,
                &__login-btn,
                &__contact-btn,
                &__search-link {
                    color: var(--av-inversed-primary);
                    transition: all linear 0.12s;

                    .a-glyph {
                        fill: var(--av-inversed-primary);
                    }

                    &:hover {
                        color: var(--av-link-inversed-active);

                        .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }

                        .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }
                    }

                    &_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

                    &:active {
                        color: var(--av-link-inversed-active);
                        .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }

                        .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }
                    }

                    .logged-in {
                        background-color: var(--av-inversed-primary);

                        .a-glyph {
                            fill: var(--av-brand-primary);
                        }

                        &.active {
                            background-color: var(--av-link-inversed-active);
                        }
                    }

                    &_active {
                        color: var(--av-link-inversed-active);
                        .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }
                        .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }
                    }

                    &:hover .logged-in {
                    &:hover {
                        .a-glyph {
                                fill: var(--av-brand-primary);
                            }
                        }
                    }
                }

                &__buttons {
                    &::before {
                        background-color: var(--av-inversed-primary);
                    }
                }
            }

            .menu-toggler {
                &__icon {
                    fill: var(--av-inversed-primary);
                }

                &__caption {
                    color: var(--av-inversed-primary);
                }

                &:hover {
                    .menu-toggler__caption {
                        color: var(--av-link-inversed-active);
                    }
                    .menu-toggler__icon {
                        fill: var(--av-link-inversed-active);
                        color: var(--av-link-inversed-active);
                    }
                }

                &:active {
                    .menu-toggler__caption {
                        color: var(--av-link-inversed-hover);
                    }
                    .menu-toggler__icon {
                        color: var(--av-link-inversed-hover);
                        fill: var(--av-link-inversed-hover);
                    }
                }
            }
        }

        &_light {
            .s-main-header {
                &__menu-link {
                    color: var(--av-nav-primary);

                    &:hover {
                        color: var(--av-brand-primary);
                    }

                    &_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

                    &_active {
                        @mixin paragraph-accent;
                        color: var(--av-brand-primary);
                    }

                    &:active {
                        color: var(--av-brand-secondary);
                        .a-glyph {
                            fill: var(--av-brand-secondary);
                        }
                    }
                }

                &__login-text.active  {
                    color: var(--av-brand-primary);
                }

                &__lang-btn,
                &__login-btn,
                &__contact-btn,
                &__search-link {
                    color: var(--av-nav-primary);

                    .a-glyph {
                        fill: var(--av-nav-primary);
                    }

                    &:hover {
                        color: var(--av-brand-primary);

                        .a-glyph {
                            fill: var(--av-brand-primary);
                        }

                        .logged-in {
                            background-color: var(--av-brand-primary);
                        }
                    }

                    &:active {
                        color: var(--av-brand-secondary);
                        .a-glyph {
                            fill: var(--av-brand-secondary);
                        }
                        .logged-in {
                            background-color: var(--av-brand-secondary);
                        }
                    }

                    &_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

                    &_active {
                        color: var(--av-brand-primary);
                        .a-glyph {
                            fill: var(--av-brand-primary);
                        }
                    }

                    .logged-in {
                        background-color: var(--av-nav-primary);

                        .a-glyph {
                            fill: var(--av-inversed-primary);
                        }

                        &.active {
                            background-color: var(--av-brand-primary);
                        }
                    }

                    &_active {
                        .logged-in {
                            background-color: var(--av-brand-primary);
                        }
                    }

                    &:hover .logged-in {
                        &:hover {
                            .a-glyph {
                                fill: var(--av-inversed-primary);
                            }
                        }
                    }
                }

                &__buttons {
                    &::before,
                    & > div::before {
                        background: var(--av-brand-light);
                    }
                }
            }

            .menu-toggler {
                &__icon {
                    fill: var(--av-nav-primary);
                }

                &__caption {
                    color: var(--av-nav-primary);
                }

                &:hover {
                    .menu-toggler__caption {
                        color: var(--av-brand-secondary);
                    }
                    .menu-toggler__icon {
                        fill: var(--av-brand-secondary);
                        color: var(--av-brand-secondary);
                    }
                }

                &:active {
                    .menu-toggler__caption {
                        color: var(--av-brand-secondary);
                    }
                    .menu-toggler__icon {
                        fill: var(--av-brand-secondary);
                        color: var(--av-brand-secondary);
                    }
                }
            }
        }
    }
}

[dir='rtl'] {
    .s-main-header {
        .s-main-menu {
            &.s-main-menu_centered {
                inset-inline-start: auto;
                inset-inline-end: unset;
            }
        }

        .logged-in .a-glyph {
            margin-inline-end: -2px;
            margin-top: -2px;
        }
    }
    .s-main-header__menu-link::before,
    .s-main-header__menu-link::after {
        inset-inline-start: unset;
        inset-inline-end: 50%;
    }
}
</style>
