<template>
    <section
        v-if="isVisible"
        :id="id"
        class="s-integrations"
        :class="{ ...className, ...basicClassName }"
    >
        <div class="a-container">
            <component :is="titleTag" v-if="l10n.title" class="title">
                {{ l10n.title }}
            </component>
            <div v-if="l10n.list?.length" class="content">
                <div class="slider-wrapper">
                    <a-slider :options="options">
                        <a-slide v-for="(item, itemIndex) in list" :key="itemIndex">
                            <dynamic-link v-if="item.to" to="/" native-type="a">
                                <a-picture :link="item.logo" />
                            </dynamic-link>
                            <a-picture v-else :link="item.logo" />
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';
import contentMixin from '@core/mixins/content';
import l10n from '@core/mixins/l10n';
import styleMixin from '@core/mixins/style';

export default {
    name: 'SIntegrations',
    components: {
        APicture,
        ASlide,
        ASlider,
        DynamicLink,
    },
    mixins: [breakpoints, l10n, contentMixin, styleMixin],
    props: {
        titleTag: {
            type: String,
            default: 'h2',
        },
        title: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'light',
        },
        list: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: {
                loop: false,
                spaceBetween: 20,
                slidesPerView: 2,
                slidesPerGroup: 2,
                showNavigation: false,
                showPagination: false,
                customPagination: { type: 'bullets' },
                breakpoints: {
                    768: { slidesPerGroup: 3, slidesPerView: 3 },
                    1024: { slidesPerGroup: 5, slidesPerView: 5 },
                    1280: { slidesPerGroup: 7, slidesPerView: 7 },
                },
            },
        };
    },
    computed: {
        isVisible() {
            return this.title || this.list?.length;
        },
        className() {
            return {
                's-integrations_theme_dark': this.theme === 'dark',
            };
        },
    },
    mounted() {
        if (!this.isVisible) return;
        this.options.showPagination = this.isMobile;
        this.options.showNavigation = !this.isMobile;
    },
};
</script>

<style lang="postcss" scoped>
.s-integrations {
    padding: 24px 0;
    .title {
        @mixin title-accent;
        text-align: center;
        color: var(--av-fixed-primary);
        @media (--viewport-tablet) {
            @mixin display-accent;
        }
        @media (--viewport-desktop-wide) {
            margin: 0 auto;
            max-width: 970px;
        }
    }
    .content {
        margin-top: 32px;
    }
    .slider-wrapper {
        margin: 0 auto;
        width: 288px;
        @media (--viewport-mobile-wide) {
            width: calc(100% - 96px);
        }
        @media (--viewport-desktop-large) {
            width: calc(100% - 220px);
        }
        &:deep(.a-slider) {
            position: initial;
            .a-slide {
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                .a-picture__img {
                    max-width: 119px;
                    max-height: 76px;
                }
            }
            .a-slider__dot {
                &::before {
                    background-color: var(--av-fixed-lighter);
                }
            }
            .a-slider__dot_active {
                &::before {
                    background-color: var(--av-fixed-secondary);
                }
            }
            .a-slider__nav {
                margin-top: 16px;
                @media (--viewport-tablet) {
                    margin-top: 0;
                }
            }
            .a-slider__controls {
                bottom: 0;
                margin: 0;
                z-index: 0;
                width: 100%;
                height: 76px;
                display: flex;
                position: absolute;
                align-items: center;
                inset-inline-end: 0;
                inset-inline-start: 0;
                background: transparent;
                justify-content: space-between;
                padding: 0 var(--container-paddings-mobile-wide);
                @media (--viewport-desktop-wide) {
                    padding: 0 var(--container-paddings-desktop-wide);
                }
                @media (--viewport-desktop-large) {
                    padding: 0;
                }
                .a-slider__control {
                    margin: 0;
                }
            }
            .a-slider__wrapper  {
                z-index: 1;
            }
        }
    }

    &_theme_dark {
        background: var(--av-nav-primary);
        .title {
            color: var(--av-inversed-primary);
        }
        .slider-wrapper {
            &:deep(.a-slider) {
                &__control {
                    border: 1px solid var(--av-inversed-primary);
                    .a-slider__control-arrow {
                        fill: var(--av-inversed-primary);
                    }
                }
                &__control_disabled {
                    border: 1px solid var(--av-inversed-lighter);
                    .a-slider__control-arrow {
                        fill: var(--av-inversed-lighter);
                    }
                }

                .a-slider__dot {
                    &::before {
                        background-color: var(--av-inversed-lighter);
                    }
                    &_active {
                        &::before {
                            background-color: var(--av-inversed-primary);
                        }
                    }
                }
            }
        }
    }
}
</style>
