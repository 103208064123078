<template>
    <div class="menu-tab-pricing">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabPricing',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-pricing {
    &:deep(.s-menu-tab__body) {
        padding: 0 32px;
        grid-template-columns: 1fr;
    }

    &:deep(.s-menu-tab__list) {
        margin-top: 0;
        gap: 24px;
    }
    &:deep(.a-link__content) {
        @media (--viewport-tablet) {
            @mixin paragraph;
        }
    }
}
</style>
