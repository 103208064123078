<template>
    <div class="event-partner-card">
        <div class="logo-wrap">
            <a-picture
                class="logo"
                :link="logo"
                fit="contain"
                size="small"
            />
        </div>
        <div class="text">
            <div class="title">
                {{ title }}
            </div>
            <div v-if="description" class="description">
                {{ description }}
            </div>
            <a-link
                v-if="link"
                class="event-link link"
                :to="link.to"
                target="_blank"
                glyph="i-globe--16"
            />
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'EventPartnerCard',

    components: {
        APicture,
        ALink,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        logo: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: null,
        },

        link: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-partner-card {
    color: var(--av-nav-primary);
    padding: 32px 16px;
    background-color: var(--av-brand-accent);
    border-radius: 16px;
    gap: 40px;
    display: flex;
    flex-direction: column;

    @media (--viewport-tablet) {
        flex-direction: row;
    }

    @media (--viewport-desktop) {
        width: 83%;
    }

    @media (--viewport-desktop-wide) {
        width: 66.6%;
    }

    .link {
        &:deep(.a-glyph) {
            margin: 0;
        }

        &:hover {
            &:deep(.a-glyph) {
                fill: var(--av-brand-primary);
            }
        }
    }
}

.logo-wrap {
    margin: 0 auto;
    max-width: 300px;
    flex-shrink: 0;
    height: 184px;
}

.logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.text {
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        flex-grow: 1;
    }
}

.title {
    @mixin title-accent;
    margin-bottom: 16px;
}

.description {
    @mixin paragraph;
    margin-bottom: 24px;
}
</style>
