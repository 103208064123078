<template>
    <div v-if="isVisible" class="cta">
        <ul v-if="hasBenefits" class="benefits">
            <li v-for="(item, i) in content.benefits" :key="`benefit-${i}`" class="benefits-item">
                <p v-if="item.title" class="benefits-title">
                    {{ item.title }}
                </p>
                <span v-if="item.description" class="benefits-description">
                    {{ item.description }}
                </span>
            </li>
        </ul>
        <ul v-if="hasFeatures" class="features">
            <li v-for="(item, i) in content.features" :key="`feature-${i}`" class="features-item">
                <a-icon v-if="item.icon" class="features-icon" :number="item.icon" />
                <p v-if="item.title" class="features-title">
                    {{ item.title }}
                </p>
                <span v-if="item.description" class="features-description">
                    {{ item.description }}
                </span>
            </li>
        </ul>
        <ul v-if="hasLinks" class="links">
            <li v-for="(link, i) in content.links" :key="`link-${i}`" class="link-item">
                <a-button
                    v-if="link.isButton"
                    v-bind="{ ...link, type: 'secondary' }"
                    :size="link.size || 's'"
                />
                <a-link
                    v-else
                    v-bind="link"
                    :size="link.size || 'paragraph'"
                />
            </li>
        </ul>
        <ul v-if="hasAwards" class="awards">
            <li v-for="(item, i) in awards" :key="`item-${i}`" class="awards-item">
                <a-picture :link="item.image" :alt="item.title" :style="imgHeight(i)" />
            </li>
        </ul>
        <p v-if="hasTrustpilot" class="trustpilot">
            <a-picture :link="content.trustpilot.picture" :alt="content.trustpilot.alt || ''" />
        </p>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SContentCTA',

    components: {
        AButton,
        AIcon,
        ALink,
        APicture,
    },

    props: {
        /**
         * Content
         */
        content: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        hasBenefits() {
            return this.content.benefits;
        },

        hasFeatures() {
            return this.content.features;
        },

        hasLinks() {
            return this.content.links;
        },

        hasAwards() {
            return Boolean(this.content.awards?.id);
        },

        hasTrustpilot() {
            return Boolean(this.content.trustpilot?.picture);
        },

        isVisible() {
            return (
                this.hasBenefits ||
                this.hasFeatures ||
                this.hasLinks ||
                this.hasAwards ||
                this.hasTrustpilot
            );
        },

        awards() {
            return (this.content.awards?.id || [])
                .map((pid) => this.$store.state.awards.items[pid])
                .filter(Boolean);
        },
    },

    methods: {
        imgHeight(i) {
            const result = this.content.awards?.height || [];
            return result[i] ? `height: ${result[i]}px` : null;
        },
    },

    async serverPrefetch() {
        if (!this.hasAwards) {
            return;
        }

        const promises = this.content.awards.id.map((aid) => this.$store.dispatch('awards/getAward', aid));
        await Promise.all(promises);
    },
};
</script>

<style lang="postcss" scoped>
.cta {
    width: 100%;
    margin-top: 24px;
}

.benefits-item {
    position: relative;
    padding-inline-start: 32px;

    &::before {
        content: '•';
        top: 0;
        inset-inline-start: 8px;
        position: absolute;
        color: var(--av-brand-primary);
    }

    &:not(:first-child) {
        margin-top: 16px;
    }
}

.benefits-title {
    @mixin paragraph-accent;
    color: var(--av-fixed-secondary);
}

.benefits-description {
    @mixin paragraph;
    color: var(--av-fixed-light);
}

.features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.features-item {
    &:not(:first-child) {
        margin-top: 24px;
    }
}

.features-icon {
    width: 48px;
    height: 48px;
}

.features-title {
    @mixin paragraph-accent;
    margin-top: 16px;
    color: var(--av-fixed-secondary);
}

.features-description {
    @mixin paragraph;
    margin-top: 8px;
    color: var(--av-fixed-light);
}

.links {
    margin-top: 64px;

    .a-button {
        width: 100%;
        max-width: 280px;
    }
}

.link-item {
    text-align: center;

    &:not(:first-child) {
        margin-top: 32px;
    }

    .a-button {
        @mixin paragraph;
    }

    .a-link {
        @mixin paragraph-accent;
    }
}

.awards {
    width: 280px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 64px auto 0;
    justify-content: space-evenly;
}

.awards-item {
    width: 119px;
    padding: 8px 0;

    .a-picture {
        height: 48px;
    }
}

.trustpilot {
    margin-top: 32px;

    &:deep(.a-picture) {
        &__img {
            width: 280px;
            margin: 0 auto;
        }
    }
}

@media (--viewport-mobile-wide) {
    .cta {
        margin-top: 64px;
    }

    .links {
        .a-button {
            max-width: 502px;
        }
    }

    .awards {
        width: auto;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .awards-item {
        .a-picture {
            height: 56px;
        }
    }

    .trustpilot {
        &:deep(.a-picture) {
            &__img {
                margin: auto;
            }
        }
    }
}

@media (--viewport-desktop) {
    .cta {
        margin-top: 40px;
    }

    .links {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0 -16px;
        align-items: center;
    }

    .link-item {
        margin-bottom: 16px;

        &:first-child {
            margin-inline-end: 32px;
        }

        &:not(:first-child) {
            margin-top: 0;
        }

        .a-button {
            width: auto;
            min-width: 169px;
        }
    }

    .awards {
        justify-content: left;
    }

    .trustpilot {
        &:deep(.a-picture) {
            &__img {
                margin: 0;
            }
        }
    }
}

@media (--viewport-desktop-wide) {
    .features {
        flex-wrap: nowrap;
    }

    .features-item {
        margin: 0 8px;

        &:not(:first-child) {
            margin-top: 0;
        }
    }
}
</style>
