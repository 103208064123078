<template>
    <div class="event-locale-select" :class="{ dark: theme === 'dark' }">
        <template v-if="defaultLocationReady">
            <div class="locale-button" @click="mobileMenuClick">
                <span>{{ chosenLocation.name }}</span>
                <a-glyph class="glyph" name="language-select2" />
            </div>
            <el-select
                ref="select"
                v-model="chosenLocation"
                v-bind="labelSelect"
                class="select"
                name="country"
                filterable
                :hide-on-resize="isDesktop"
                popper-class="dropdown locale-dropdown"
                @change="onLocationChange"
                @click="onLocationSelectClick"
                @visible-change="onVisibleChange"
            >
                <el-option
                    v-for="item in countries"
                    :key="item.iso_code"
                    :label="item.name"
                    :value="item"
                />
            </el-select>
            <div v-if="showConfirmationPopup && chosenLocation.name" class="popup">
                <a-icon class="popup-icon" number="171" />
                <div class="popup-text">
                    {{ countryIdentifiedTitle }}
                </div>
                <div class="popup-location">
                    {{ chosenLocation.name }}
                </div>
                <a class="event-link popup-link" @click="changeCountryClick">{{ changeCountryTitle }}</a>
                <button class="event-button popup-button" @click="confirmLocationClick">
                    {{ confirmCountryButtonTitle }}
                </button>
            </div>
            <div v-if="countryChangedNotify && isMobile" class="country-change-notify">
                <a-dangerous-html :content="countryChangedHtml" />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

import AGlyph from '@core/components/glyph/glyph.vue';
import AIcon from '@core/components/icon/icon.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import commonUtils from '@utils/common';

const NOTIFICATION_TIME = 3000;
const POPUP_VISIBLE_OFFSET = 375;

export default {
    name: 'EventLocaleSelect',

    components: {
        AGlyph,
        AIcon,
        ADangerousHtml,
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },
    mixins: [form, breakpoint],
    props: {
        theme: {
            type: String,
            default: 'light',
        },
        countries: {
            type: Array,
            required: true,
        },
        countryId: {
            type: Number,
            default: null,
        },
        isConfirmed: {
            type: Boolean,
            default: false,
        },
        countryIdentifiedTitle: {
            type: String,
            required: true,
        },
        changeCountryTitle: {
            type: String,
            required: true,
        },
        confirmCountryButtonTitle: {
            type: String,
            required: true,
        },
        countryChangedLabel: {
            type: String,
            required: true,
        },
    },

    emits: ['select'],

    data: () => ({
        chosenLocation: null,
        defaultLocationReady: false,
        showConfirmationPopup: false,
        countryChangedNotify: false,
        countryChangedHtml: '',
        prevScrollTop: 0,
    }),

    mounted() {
        this.setDefaultLocation();
        this.checkLocationConfirmation();

        // confirmation popup: workaround for safari to fix layout composition on scroll during page load
        if (commonUtils.isSafariBrowser() && !this.isConfirmed) {
            document.addEventListener('scroll', this.handleScroll, { passive: true });
        }
    },

    beforeDestroy() {
        document.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        async handleScroll() {
            const scrollTop = document.scrollingElement.scrollTop;
            if ((!this.prevScrollTop || scrollTop < this.prevScrollTop) && scrollTop <= POPUP_VISIBLE_OFFSET) {
                this.showConfirmationPopup = false;
                await this.$nextTick();
                this.showConfirmationPopup = true;
            }
            this.prevScrollTop = scrollTop;
        },

        mobileMenuClick() {
            this.$refs.select.$refs.reference.$el.click();
        },

        setDefaultLocation() {
            const location = this.countries.find(
                (item) => parseInt(item.id, 10) === this.countryId,
            );
            if (!location) {
                return;
            }

            this.chosenLocation = location;
            this.defaultLocationReady = true;
        },

        checkLocationConfirmation() {
            if (!this.isConfirmed) {
                this.showConfirmationPopup = true;
            }
        },

        onLocationChange(chosenLocation) {
            this.showCountryChangedNotification(chosenLocation);
            this.$emit('select', chosenLocation.id);
        },

        confirmLocationClick() {
            this.closePopup();
            this.showCountryChangedNotification(this.chosenLocation);
            this.$emit('select', this.chosenLocation.id);
        },

        changeCountryClick() {
            this.closePopup();
            this.$refs.select.$refs.reference.$el.click();
        },

        onLocationSelectClick() {
            this.closePopup();
        },

        closePopup() {
            this.showConfirmationPopup = false;
            document.removeEventListener('scroll', this.handleScroll);
        },

        async onVisibleChange(isOpened) {
            if (!isOpened) return;

            await this.$nextTick();
            const dropdownWrap = document.querySelector('.locale-dropdown .el-select-dropdown__wrap');
            const selected = dropdownWrap?.querySelector('.el-select-dropdown__item.selected') as HTMLElement | null;
            dropdownWrap?.scroll({ top: selected?.offsetTop || 0 });
        },

        showCountryChangedNotification(chosenLocation) {
            this.countryChangedHtml = this.countryChangedLabel.replace('@country', `<strong>${chosenLocation.name}</strong>`);
            this.countryChangedNotify = true;
            setTimeout(() => { this.countryChangedNotify = false; }, NOTIFICATION_TIME);
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-locale-select {
    position: relative;

    .locale-button {
        @mixin paragraph-accent;
        cursor: pointer;
        margin-inline-start: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--av-inversed-invisible);
        padding: 7px;
        border: 1px solid var(--av-inversed-primary);
        border-radius: 4px;

        > span {
            display: none;
        }

        @media (--viewport-tablet) {
            padding: 8px 16px;

            > span {
                display: inline;
            }
        }
    }

    .select {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 320px;
        top: 0;
        inset-inline-end: 0;
    }

    .glyph {
        margin-inline-start: 8px;
        fill: var(--av-nav-primary);
    }

    .popup {
        z-index: 1;
        @mixin paragraph;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-primary);
        color: var(--av-fixed-primary);
        padding: 32px 24px;
        text-align: center;
        border-radius: 6px;
        width: 328px;
        position: absolute;
        top: 100%;
        inset-inline-end: 0;
        margin: 7px 0 0;

        @media (--viewport-tablet) {
            margin-top: -4px;
        }
    }

    .popup-icon {
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
    }

    .popup-text {
        margin-bottom: 24px;
    }

    .popup-location {
        @mixin lead-strong;
    }

    .popup-link {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 24px;
        color: var(--av-brand-secondary);
        cursor: pointer;
        display: inline;
        border: 0;
        background: transparent;

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .popup-button {
        height: 48px;
        width: 100%;
        font-weight: 700;
        color: var(--av-inversed-primary);
        cursor: pointer;
    }

    .country-change-notify {
        @mixin descriptor;
        position: fixed;
        top: 80px;
        inset-inline: 0;
        margin: auto;
        border: 1px solid var(--av-brand-secondary-light);
        padding: 8px 16px;
        background: var(--av-inversed-primary);
        color: var(--av-nav-primary);
        border-radius: 18px;
        text-align: center;
        display: inline-block;
        width: fit-content;
    }
}
</style>

<style lang="postcss">
.event-locale-select {
    &.dark {
        .a-glyph {
            fill: var(--av-inversed-primary);
        }
        .select {
            .el-input__editor {
                color: var(--av-inversed-primary);
            }
            .svg {
                color: var(--av-inversed-primary);
            }
        }
    }

    .mobile {
        .a-glyph {
            fill: var(--av-nav-primary);
            width: 26px;
            height: 18px;
        }
    }

    .select {
        .el-input {
            visibility: hidden;
            opacity: 0;

            @media (--viewport-tablet) {
                visibility: visible;
                opacity: 1;
            }
        }
        .el-input__wrapper {
            padding-top: 12px;
        }
        .el-input__suffix {
            min-width: 16px;
        }
        .el-input__container {
            background: transparent;
            padding: 0;
            border: 0;
        }
        .el-input__editor {
            text-align: end;
            color: var(--av-nav-primary);
            font-size: 16px;
            font-weight: 700;
        }
        .svg {
            color: var(--av-nav-primary);
        }
    }
}

.el-select-dropdown.dropdown.locale-dropdown {
    border-radius: 6px;
    margin-top: -41px;
    width: 328px;

    @media (--viewport-tablet) {
        margin-top: -4px;
    }

    @media (max-width: 1023px) {
        inset-inline-end: 32px;
        inset-inline-start: auto !important;
    }

    @media (max-width: 767px) {
        inset-inline-end: 16px;
    }

    .el-scrollbar .el-select-dropdown__item {
        font-weight: 400;
    }

    .el-input__editor {
        font-size: 16px;
    }
}
</style>
