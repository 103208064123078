<template>
    <div class="color-scheme-tru cpoc-page cpoc-news">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" :tru-search-link="truSearchLink" />

                <s-blog-cpc-bar
                    :links="menuLinks"
                    :title="translations.cpcTitle"
                    :lead="translations.cpcLead"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                />
                <div class="a-container">
                    <div class="intro">
                        <blog-bcrumbs v-bind="bcrumbs" />
                        <div class="news-title-mobile">
                            {{ translations.newsArchive }}
                        </div>
                        <div v-if="socialLinks" class="socials">
                            <div class="social-label">
                                {{ translations.followUsOn }}
                            </div>
                            <a-social :social="socialLinks" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="cpoc-wrapper">
                <div class="a-container">
                    <blog-news-search
                        ref="scrollTo"
                        :title="translations.newsTitle"
                        :filter-news-by="translations.filterNewsBy"
                        :follow-us-on="translations.followUsOn"
                        :product-label="translations.product"
                        :year-label="translations.year"
                        :month-label="translations.month"
                        :no-news-found="translations.noNewsFound"
                        :select-all="translations.selectAll"
                        :products="products"
                        :tags="tags"
                        :years="availableYears"
                        :search-by-tag-label="translations.searchByTagLabel"
                        :collapse-label="translations.collapseLabel"
                        :explore-more-tags-label="translations.exploreMoreTagsLabel"
                        :try-reset-date-filters-label="translations.tryResetDateFiltersLabel"
                        :try-reset-filters-label="translations.tryResetFiltersLabel"
                        :is-loading="isXHRInProgress"
                        :global-search-link="globalSearchLink"
                        @updateFilterParams="updateFilterParams"
                    >
                        <template v-if="news.length > 0" #searchResults>
                            <blog-news-item v-for="item in news" :key="item.id" v-bind="{ ...item, type: 'full' }" />
                        </template>
                    </blog-news-search>
                    <div v-if="listTotalPages > 1" class="pagination">
                        <a-pagination
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :scroll-to="$refs.scrollTo"
                            :url-template="pageUrl"
                            :click-handler="loadLatestCardsPage"
                        />
                    </div>
                </div>
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import { range } from 'lodash';
import APagination from '@core/components/pagination/pagination.vue';
import ASocial from '@core/components/social/social.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import BlogNewsSearch from '@core/slices/blog/blog-news-search/blog-news-search.vue';
import BlogNewsItem from '@core/slices/blog/shared-components/blog-news-item/blog-news-item.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/cpoc.pcss';

const DATE_START = '2021/07/01';

export default {
    name: 'BlogNews',

    components: {
        ASocial,
        APagination,
        SGlobalFooter,
        SBlogCpcHeader,
        SBlogCpcBar,
        BlogBcrumbs,
        BlogNewsSearch,
        BlogNewsItem,
    },

    mixins: [blog, analytics],

    props: {
        dispatchGoToPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            listCurrentPage: 1,
            isXHRInProgress: false,
            year: null,
            month: null,
            product: null,
            tag: null,
        };
    },

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'dark',
            };
        },

        truSearchLink() {
            return this.isOnEnLocale ? this.sliceDataHeader.truSearchLink : null;
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        pageUrl() {
            return `/${this.locale}/cyber-protection-center/categories/news/page/$PAGE_NUMBER$/`;
        },

        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        products() {
            return this.$store.state.products.items || [];
        },

        tags() {
            return this.$store.state.blog.tags.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        translations() {
            return this.$store.state.slices.items.blog || {};
        },

        globalSearchLink() {
            return {
                to: '/search/?category=TRU',
                text: this.translations.searchByKeywords,
            };
        },

        bcrumbs() {
            const bcrumbs = [{
                to: '/cyber-protection-center/',
                title: this.translations.cpcTitle,
            },
            { title: this.translations.newsArchive },
            ];
            const mobileLink = bcrumbs[0];
            return {
                theme: 'dark',
                bcrumbs,
                mobileLink,
            };
        },

        socialLinks() {
            const linksSource = this.translations.customSocialLinks || socials.links;
            const links = Array.from(linksSource);
            const rss = {
                link: '/cyber-protection-center/feed.xml#',
                name: 'rss',
                title: 'RSS',
            };
            links.push(rss);
            return links;
        },

        news() {
            const news = this.$store.state.blog.news.items || [];
            return news.map((item) => this.getNewsFields(item));
        },

        listTotalPages() {
            return this.$store.state.blog.news.pagination?.pages_total || 1;
        },

        availableYears() {
            return range((new Date().getFullYear()), new Date(DATE_START).getFullYear() - 1, -1);
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        updateFilterParams(newParams) {
            this.year = newParams.year;
            this.month = newParams.month;
            this.product = newParams.products;
            this.tag = newParams.tags;
            this.loadLatestCardsPage(1);
        },

        async loadLatestCardsPage(page, callback) {
            this.isXHRInProgress = true;
            const res = await this.dispatchGoToPage(page, this.year, this.month, this.product, this.tag);
            this.isXHRInProgress = false;

            if (res) {
                this.listCurrentPage = page;
            }
            if (callback) callback();
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpoc-news {
    .news-archive {
        margin-bottom: 24px;

        @media (--viewport-desktop-wide) {
            min-height: calc(100vh - 624px);
        }
    }

    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        padding-bottom: 16px;
        @media (--viewport-desktop) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 32px;
            margin-bottom: 24px;
        }
        .news-title-mobile {
            @mixin display-accent;
            margin-bottom: 24px;
            color: var(--av-inversed-primary);
            @media (--viewport-desktop) {
                display: none;
            }
        }
        .socials {
            display:flex;
            align-items: center;
        }
        &:deep(.a-social) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            &__link {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0;
            }
            &__icon {
                height: 16px;
                width: 16px;
            }
        }
        .social-label {
            @mixin body-accent;
            line-height: 16px;
            white-space: nowrap;
            margin-inline-end: 16px;
            color: var(--av-inversed-primary);
        }
    }

    &:deep(.blog-news-search) {
        .filter-label {
            color: var(--av-fixed-primary);
            margin-bottom: 24px;
        }
        .el-input {
            .svg--size-16 {
                color: #000;
            }
        }
        .news {
            & > .title {
                margin-bottom: 48px;
                @media (--viewport-tablet) {
                   .news > .title {
                        margin-bottom: 24px;
                    }
                }
            }
        }
        @media (--viewport-tablet) {
            display: block;
            .filters {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                .filter-label {
                    text-align: center;
                    width: 100%;
                    margin-bottom: 24px;
                }
                .filter-select {
                    @mixin colls 4;
                }
                .filter-button {
                    @mixin colls 4;
                    margin: 0;
                }
            }
        }
        @media (--viewport-desktop) {
            display: grid;
            .news-title {
                display: block;
                text-align: start;
            }
            .filters {
                display: block;
                .filter-label {
                    text-align: start;
                    width: 100%;
                    margin-bottom: 16px;
                }
                .filter-select {
                    @mixin colls 12;
                }
                .filter-button {
                    @mixin colls 12;
                }
            }
        }
    }
    .pagination,
    .cpoc-wrapper .a-container {
        margin-bottom: 64px;
    }
}
</style>
