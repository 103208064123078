<template>
    <div
        class="filters-overlay"
        :class="{
            'filters-overlay--visible': isVisible,
            'filters-overlay--hidden': !isVisible,
        }"
    >
        <div class="filters-panel">
            <div class="header-wrapper">
                <a-link
                    v-if="!isDesktop"
                    class="close-button"
                    text="Close"
                    glyph="close"
                    :has-no-ref="true"
                    @click="$emit('close')"
                />
                <div v-if="!isDesktop" class="title">
                    {{ commonTranslations['searchForResources'] }}
                </div>
            </div>

            <div class="action-links">
                <span class="filters-counter">{{ commonTranslations['filters'] }} ({{ selectedFiltersCount }})</span>
                <a-link
                    v-if="selectedFiltersCount > 0"
                    class="reset-filters-button"
                    :text="commonTranslations['resetFilters']"
                    glyph="close"
                    :has-no-ref="true"
                    @click="resetFilters"
                />
            </div>

            <div v-if="filtersInitialized" class="filters-list">
                <!-- products -->
                <el-select
                    v-if="products.length"
                    v-bind="labelSelect"
                    v-model="selectedFilters.products"
                    name="products-filter"
                    :label="commonTranslations['product']"
                    :placeholder="commonTranslations['product']"
                    :hide-on-resize="isDesktop"
                    :popper-class="dropdownPopperClass"
                    filterable
                    multiple
                    @change="onFiltersChanged"
                >
                    <el-option
                        v-for="(item, i) in productsOptions"
                        :key="`product_${i}`"
                        :value="i"
                        :label="item.title"
                    />
                </el-select>

                <!-- content types -->
                <el-select
                    v-if="types.length"
                    v-bind="labelSelect"
                    v-model="selectedFilters.types"
                    name="types-filter"
                    :label="commonTranslations['contentType']"
                    :placeholder="commonTranslations['contentType']"
                    :hide-on-resize="isDesktop"
                    :popper-class="dropdownPopperClass"
                    filterable
                    multiple
                    @change="onFiltersChanged"
                >
                    <el-option
                        v-for="(item, i) in typesOptions"
                        :key="`type_${i}`"
                        :value="i"
                        :label="item.title"
                    />
                </el-select>

                <!-- audiences -->
                <el-select
                    v-if="audiences.length"
                    v-bind="labelSelect"
                    v-model="selectedFilters.audiences"
                    name="audience-filter"
                    :label="commonTranslations['audience']"
                    :placeholder="commonTranslations['audience']"
                    :hide-on-resize="isDesktop"
                    :popper-class="dropdownPopperClass"
                    filterable
                    multiple
                    @change="onFiltersChanged"
                >
                    <el-option
                        v-for="(item, i) in audiencesOptions"
                        :key="`audience_${i}`"
                        :value="i"
                        :label="item.title"
                    />
                </el-select>

                <!-- industries -->
                <el-select
                    v-if="industriesEnabled"
                    v-bind="labelSelect"
                    v-model="selectedFilters.industries"
                    name="industries-filter"
                    :label="commonTranslations['industry']"
                    :placeholder="commonTranslations['industry']"
                    :hide-on-resize="isDesktop"
                    :popper-class="dropdownPopperClass"
                    filterable
                    multiple
                    @change="onFiltersChanged"
                >
                    <el-option
                        v-for="(item, i) in industriesOptions"
                        :key="`industries_${item.id}`"
                        :value="i"
                        :label="item.title"
                    />
                </el-select>
            </div>

            <div v-if="!lockedTagId" class="tags-list">
                <div class="tags-title">
                    {{ commonTranslations['tags'] }}
                </div>
                <div class="tags-search-wrapper">
                    <el-input
                        v-model="tagSearchString"
                        name="search-by-tag"
                        :label="commonTranslations['searchByTag']"
                    />
                    <a-glyph name="search" />
                </div>
                <div class="selected-tags">
                    <resource-search-tag
                        v-for="tag in tagsList"
                        :id="tag.id"
                        :key="tag.id"
                        :title="tag.title"
                        :selected="tag.selected"
                        @select="selectTag"
                        @remove="removeTag"
                    />
                </div>
                <div
                    v-if="tags.length > VISIBLE_TAGS_LIMIT"
                    class="more-tags-block"
                    :class="{ 'more-tags-block--opened': moreTagsVisible }"
                >
                    <a-link
                        :text="moreTagsVisible ? commonTranslations['collapse'] : commonTranslations['exploreMoreTags']"
                        glyph="arrow-narrow-up"
                        glyph-size="xs"
                        :has-no-ref="true"
                        @click="toggleMoreTags"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import { RC_CASE_STUDY_TYPE_ID } from '@model/const/resource-center';
import ResourceSearchTag from '../resource-search-tag/resource-search-tag.vue';

const VISIBLE_TAGS_LIMIT = 10;
const ALL_OPTION_KEY = 0;

export default {
    name: 'ResourceSearchFilterPanel',

    components: {
        AGlyph,
        ALink,
        ResourceSearchTag,
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },

    mixins: [breakpoint, form],

    props: {
        initialFilters: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        lockedTypeId: {
            type: Number,
            default: 0,
        },
        lockedAudienceId: {
            type: Number,
            default: 0,
        },
        lockedTagId: {
            type: Number,
            default: 0,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        products: {
            type: Array,
            required: true,
        },
        types: {
            type: Array,
            default: () => [],
        },
        audiences: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
        industries: {
            type: Array,
            default: () => [],
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
    },

    emits: ['change', 'close'],

    data() {
        return {
            filtersInitialized: false,
            selectedFilters: {
                products: [ALL_OPTION_KEY],
                types: [ALL_OPTION_KEY],
                audiences: [ALL_OPTION_KEY],
                industries: [ALL_OPTION_KEY],
            },
            tagSearchString: '',
            selectedTagsIds: [],
            moreTagsVisible: false,
            productsOptions: [...this.products],
            typesOptions: [{ id: ALL_OPTION_KEY, title: this.commonTranslations.any }, ...this.types],
            audiencesOptions: [{ id: ALL_OPTION_KEY, title: this.commonTranslations.any }, ...this.audiences],
            industriesOptions: [{ id: ALL_OPTION_KEY, title: this.commonTranslations.any }, ...this.industries],
            VISIBLE_TAGS_LIMIT,
        };
    },

    computed: {
        dropdownPopperClass() {
            return this.loading ? 'rc-search-filter-disabled' : '';
        },

        industriesEnabled() {
            if (this.selectedFilters.types === undefined) {
                return this.industries.length;
            }
            return this.industries.length && this.selectedFilters.types.includes(RC_CASE_STUDY_TYPE_ID);
        },

        selectedFiltersCount() {
            const filtersCount = Object
                .values(this.selectedFilters)
                .filter((val) => val?.length && !val.includes(ALL_OPTION_KEY))
                .reduce((acc: number, val: Array<number>) => acc + val.length, 0);
            return filtersCount + (this.lockedTagId ? 0 : this.selectedTagsIds.length);
        },

        tagsList() {
            const selectedTagsIds = new Set(this.selectedTagsIds);
            const res = this.tags
                .map((tag) => ({
                    id: tag.id,
                    title: tag.title,
                    selected: selectedTagsIds.has(tag.id),
                }))
                .sort((a, b) => b.selected - a.selected);

            if (this.tagSearchString) {
                const tagSearchString = this.tagSearchString.toLowerCase();
                return res
                    .filter((tag) => tag.title.toLowerCase().includes(tagSearchString))
                    .slice(0, VISIBLE_TAGS_LIMIT);
            }

            if (this.tags.length > VISIBLE_TAGS_LIMIT && !this.moreTagsVisible) {
                return res.slice(0, VISIBLE_TAGS_LIMIT);
            }

            return res;
        },

        isVisible() {
            return this.isDesktop || this.visible;
        },
    },

    mounted() {
        this.clearLockedItems();
        this.setInitialFilters();
    },

    methods: {
        clearLockedItems() {
            if (this.lockedTypeId) {
                delete this.selectedFilters?.types;
            }

            if (this.lockedAudienceId) {
                delete this.selectedFilters?.audiences;
            }

            if (this.lockedTagId) {
                delete this.selectedFilters?.tags;
            }
        },

        setInitialFilters() {
            // we only want to set filters if we have them, otherwise we use the default values
            if (!Object.keys(this.initialFilters).length) {
                this.filtersInitialized = true;
                return;
            }

            const filters: any = this.initialFilters;

            Object.keys(this.selectedFilters).forEach((key) => {
                if (!filters[key]) return;

                const optionsKey = `${key}Options`;

                if (typeof filters[key] === 'string') {
                    const itemId = parseInt(filters[key], 10);
                    this.selectedFilters[key] = [this[optionsKey].findIndex((item) => item.id === itemId)];
                } else {
                    this.selectedFilters[key] = filters[key]
                        .map(Number)
                        .map((itemId) => this[optionsKey].findIndex((item) => item.id === itemId));
                }
            });

            if (filters.tags) {
                this.selectedTagsIds = typeof filters.tags === 'string'
                    ? [parseInt(filters.tags, 10)]
                    : filters.tags.map(Number);
            }

            this.$nextTick(() => {
                this.filtersInitialized = true;
            });
        },

        onFiltersChanged() {
            const filters = this.selectedFilters;

            Object.keys(filters).forEach((key) => {
                // select "all" option only if it's last or unselect "all" otherwise
                if (!filters[key]?.length) {
                    filters[key] = [ALL_OPTION_KEY];
                } else if (filters[key].length > 1 && filters[key].includes(ALL_OPTION_KEY)) {
                    if (filters[key][filters[key].length - 1] === ALL_OPTION_KEY) {
                        filters[key] = [ALL_OPTION_KEY];
                    } else if (filters[key][filters[key].length - 1] !== ALL_OPTION_KEY) {
                        filters[key] = filters[key].filter((value) => value !== ALL_OPTION_KEY);
                    }
                }
            });

            this.applyFilters();
        },

        resetFilters() {
            Object.keys(this.selectedFilters).forEach((key) => {
                this.selectedFilters[key] = [ALL_OPTION_KEY];
            });
            this.selectedTagsIds = [];
            this.applyFilters();
        },

        selectTag(tagId) {
            if (this.selectedTagsIds.includes(tagId)) return;
            this.selectedTagsIds.push(tagId);
            this.applyFilters();
        },

        removeTag(tagId) {
            this.selectedTagsIds = this.selectedTagsIds.filter((id) => id !== tagId);
            this.applyFilters();
        },

        toggleMoreTags() {
            this.moreTagsVisible = !this.moreTagsVisible;
        },

        applyFilters() {
            const selectedFilters: any = {};

            if (!this.industriesEnabled) {
                this.selectedFilters.industries = [ALL_OPTION_KEY];
            }

            Object.keys(this.selectedFilters).forEach((key) => {
                if (this.selectedFilters[key] && !this.selectedFilters[key].includes(ALL_OPTION_KEY)) {
                    selectedFilters[key] = this.selectedFilters[key].map((itemId) => this[`${key}Options`][itemId].id);
                }
            });

            if (this.selectedTagsIds.length) {
                selectedFilters.tags = this.selectedTagsIds;
            }

            if (this.lockedTypeId) {
                selectedFilters.types = [this.lockedTypeId];
            }
            if (this.lockedAudienceId) {
                selectedFilters.audiences = [this.lockedAudienceId];
            }
            if (this.lockedTagId) {
                selectedFilters.tags = [this.lockedTagId];
            }

            this.$emit('change', selectedFilters);
        },
    },
};
</script>

<style lang="postcss" scoped>
@keyframes filters-panel-appearance-mobile {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes filters-panel-disappearance-mobile {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

@keyframes filters-panel-appearance-tablet {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes filters-panel-disappearance-tablet {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.filters-overlay {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    bottom: auto;
    inset-inline-end: 0;

    /* WEB-43924 why do we use 402 here ? resource-page-header 401 */
    z-index: 402;
    background: var(--av-fixed-light);
    transition: all 0.25s ease-out;

    &--visible {
        opacity: 1;
        min-height: -webkit-fill-available;

        @media (--viewport-tablet) {
            display: flex;
            align-items: stretch;
        }

        @media (--viewport-desktop) {
            display: block;
        }

        .filters-panel {
            animation: filters-panel-appearance-mobile 0.25s ease-out;
            transform: translate(0);

            @media (--viewport-tablet) {
                animation: filters-panel-appearance-tablet 0.25s ease-out;
                min-height: calc(100vh + 148px); /* footer height / 2 */
                height: auto;
            }

            @media (--viewport-desktop) {
                animation: none;
                min-height: auto;
            }
        }
    }

    &--hidden {
        opacity: 0;
        pointer-events: none;

        .filters-panel {
            animation: filters-panel-disappearance-mobile 0.25s ease-out;
            transform: translateY(-100%);

            @media (--viewport-tablet) {
                animation: filters-panel-disappearance-tablet 0.25s ease-out;
                transform: translateY(0) translateX(-100%);
            }

            @media (--viewport-desktop) {
                animation: none;
            }
        }
    }

    @media (--viewport-desktop) {
        position: relative;
        background: transparent;
        height: 100%;
        top: -18px;
    }

    @media (--viewport-desktop-wide) {
        top: -29px;
    }
}

.filters-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 40px;
    background: var(--av-inversed-primary);
    will-change: transform;

    @media (--viewport-tablet) {
        width: 391px;
        height: 100%;
    }

    @media (--viewport-desktop) {
        width: 228px;
        padding-inline-start: 0;
        background: transparent;
    }

    @media (--viewport-desktop-wide) {
        width: 276px;
    }

    @media (--viewport-desktop-large) {
        width: 326px;
    }
}

.header-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media (--viewport-tablet) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.close-button {
    margin: 0 auto;

    &:deep(.a-link__content) {
        color: var(--av-brand-primary);
    }

    &:deep(.a-glyph) {
        width: 12px;
        height: 12px;
        top: 0;
        transform: translateY(-1px);
    }
}

.title {
    margin-top: 24px;
    width: 100%;
    text-align: center;
    @mixin display;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        max-width: 284px;
        text-align: start;
        margin: 0;
    }

    @media (--viewport-desktop) {
        max-width: initial;
    }
}

.action-links {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 38px;
    width: 100%;
}

.filters-counter {
    @mixin paragraph;
    color: var(--av-nav-primary);
}

.reset-filters-button {
    color: var(--av-brand-primary);

    &:deep(.a-glyph) {
        width: 12px;
        height: 12px;
        transform: translateY(-1px);
    }
}

.filters-list {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 26px;
}

:deep(.el-input__label.is-active) {
    padding-top: 4px;
}

.tags-list {
    margin-top: 33px;
    width: 100%;

    .el-input {
        margin-top: 16px;
    }
}

.tags-title {
    color: var(--av-nav-primary);
}

.tags-search-wrapper {
    position: relative;

    &:deep(.a-glyph) {
        position: absolute;
        top: 32px;
        inset-inline-end: 12px;
        pointer-events: none;
    }
}

.selected-tags {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &:deep(.resource-search-tag) {
        margin-bottom: 4px;
    }
}

.more-tags-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;

    &:deep(.a-glyph) {
        top: 7px;
        transform: rotate(180deg);
    }

    &:deep(.a-link) {
        width: 100%;
        text-align: center;
    }

    &:deep(.a-link__content) {
        background: transparent !important;
    }

    &--opened {
        &:deep(.a-glyph) {
            transform: rotate(0);
        }
    }
}
</style>

<style lang="postcss">
.rc-search-filter-disabled {
    pointer-events: none;
}
</style>
