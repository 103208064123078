/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.press-releases {
    margin: 0 auto;
}

.press-releases .header-wrapper {
        padding: 0 16px;
    }

@media (min-width: 768px) {

.press-releases .header-wrapper {
        padding: 0 32px;
    }
    }

@media (min-width: 1280px) {

.press-releases .header-wrapper {
        padding: 0 64px;
    }
    }

.press-releases .header-wrapper {
        padding-top: 20px;
        padding-bottom: 48px;
        border-top: 1px solid var(--av-brand-light);
        border-bottom: 1px solid var(--av-brand-light);
        background: var(--av-brand-lightest);
        background: linear-gradient(var(--av-brand-lightest), rgba(255, 255, 255, 0));
}

@media (min-width: 768px) {

.press-releases .header-wrapper {
            padding-top: 16px
    }
        }

.press-releases .title {
        font-size: 40px;
        line-height: 48px;
        max-width: 1312px;
        margin: 20px auto 0;
        text-align: center;
    }

@media (min-width: 768px) {

.press-releases .title {
            text-align: start
    }
        }

.press-releases .content-wrapper {
        padding: 0 16px;
    }

@media (min-width: 768px) {

.press-releases .content-wrapper {
        padding: 0 32px;
    }
    }

@media (min-width: 1280px) {

.press-releases .content-wrapper {
        padding: 0 64px;
    }
    }

@media (min-width: 768px) {

.press-releases .content-wrapper {
            display: flex;
            gap: 16px;
            margin: 32px auto 56px
    }
        }

.press-releases .press-releases-list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex-grow: 1;
    }

.press-releases .press-releases-cards {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex-grow: 1;
        margin-bottom: 56px;
    }

@media (min-width: 768px) {

.press-releases .press-releases-cards {
            min-height: 340px
    }
        }

@media (min-width: 1024px) {

.press-releases .press-releases-cards {
            min-height: 362px
    }
        }

@media (min-width: 1280px) {

.press-releases .press-releases-cards {
            min-height: 464px
    }
        }

.press-releases .filter-media-wrapper {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 100%;
    }

@media (min-width: 768px) {

.press-releases .filter-media-wrapper {
            margin-top: 0;
            max-width: 224px
    }
        }

@media (min-width: 1024px) {

.press-releases .filter-media-wrapper {
            max-width: 228px
    }
        }

@media (min-width: 1280px) {

.press-releases .filter-media-wrapper {
            max-width: 276px
    }
        }

.press-releases .media-resources {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: -8px;
    }

.press-releases .media-resources-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }

.press-releases .media-resources-desc {
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }

.press-releases .media-resources-links {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 16px;
    }

.press-releases .media-resource-link {
        font-size: 16px;
        line-height: 24px;
        color: var(--av-brand-primary);
    }

.press-releases .media-resource-link:hover {
            color: var(--av-brand-secondary);
        }

.press-releases .media-resource-link:active {
            color: var(--av-brand-primary);
        }

.resource-center-page:deep(.main-content::before) {
        content: none;
    }

.resource-center-page:deep(.s-global-footer__social .s-global-footer__social-wrapper) {
        border-top: 0;
    }

.empty-result-message {
    text-align: center;
    margin: 100px auto 0;
    font-size: 24px;
    line-height: 32px;
    color: var(--av-fixed-primary);
    max-width: 80%;
}

@media (min-width: 576px) {

.empty-result-message {
        max-width: 82%
}
    }

@media (min-width: 768px) {

.empty-result-message {
        max-width: 90%
}
    }

@media (min-width: 1024px) {

.empty-result-message {
        max-width: 774px
}
    }

.empty-result-reset {
    cursor: pointer;
    color: var(--av-brand-primary);
}
