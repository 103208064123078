<template>
    <main class="page-container">
        <s-academy-ribbon v-if="page.showRibbon && ribbon" v-bind="ribbon" />
        <s-header-academy v-if="page.header" v-bind="header">
            <template #menu>
                <s-academy-menu v-bind="headerSettings" />
            </template>
        </s-header-academy>
        <s-basic-slice v-bind="$attrs">
            <div :class="className" v-html="course.about" />
        </s-basic-slice>
        <s-cta-cards v-if="cta" v-bind="cta" />
        <s-academy-footer v-bind="footerSettings" />
    </main>
</template>

<script>
import { mapState } from 'vuex';
import breakpoint from '@core/mixins/breakpoint';
import SAcademyFooter from '@core/slices/academy/components/academy-footer.vue';
import SAcademyMenu from '@core/slices/academy/components/academy-menu.vue';
import SAcademyRibbon from '@core/slices/academy/components/academy-ribbon.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SCtaCards from '@core/slices/pages/cta-cards/cta-cards.vue';
import SHeaderAcademy from '@core/slices/pages/header-academy/header-academy.vue';

const STORE_SLICE_NAME = 's-academy-acronis-com';

export default {
    name: 'AcademyCourse',
    components: {
        SAcademyRibbon,
        SAcademyMenu,
        SBasicSlice,
        SCtaCards,
        SHeaderAcademy,
        SAcademyFooter,
    },
    mixins: [breakpoint],

    props: {
        example: {
            type: Array,
            default: null,
        },
    },

    computed: {
        ...mapState({
            courses: (state) => state.academy?.courses || {},
            preset: (state) => state.slices.items?.[STORE_SLICE_NAME] || {},
        }),

        page() {
            return this.preset?.template?.page || {};
        },

        ribbon() {
            return this.preset?.defaults?.ribbon;
        },

        headerSettings() {
            const fromDefaults = this.preset?.defaults?.header;
            const fromPage = this.page?.header;
            return { ...fromDefaults, ...fromPage };
        },

        footerSettings() {
            const fromDefaults = this.preset?.defaults?.footer;
            const fromPage = this.page?.footer;
            return { ...fromDefaults, ...fromPage };
        },

        slug() {
            return this.$route.params.slug;
        },

        slugFromTitle() {
            return this.courses?.find((item) => item.slug === this.slug);
        },

        slugFromSettings() {
            const courseID = this.preset?.settings?.catalogue?.find((item) => item.slug === this.slug)?.id;
            return this.courses?.find((item) => item.id === courseID);
        },

        course() {
            return this.example?.[0] || this.slugFromSettings || this.slugFromTitle || {};
        },

        className() {
            const listQuantity = this.course.about.split('list-item').length - 1;
            return `list-version-${listQuantity % 3}`;
        },

        cta() {
            if (!this.page.cta?.cards?.length) return false;
            const cards = this.page.cta.cards
                .filter((item) => this.courseLink(item.linkTo))
                .map((item) => ({
                    text: item.text,
                    title: item.title,
                    learnLink: {
                        to: this.courseLink(item.linkTo),
                        text: item.linkText,
                        type: item.linkType,
                    },
                }));
            return { ...this.page.cta, cards };
        },

        header() {
            const text = this.course.showcases?.[0]?.name || 'Business';
            const label = { type: 'label', layout: 'subtle', size: 'l', theme: 'dark' };
            const labels = [{
                ...label,
                variant: 'info',
                text: text.replace('MSP', ''),
            }];
            if (this.course.code) {
                labels.push({
                    ...label,
                    variant: 'success',
                    text: this.course.code,
                });
            }
            const background = {};
            if (this.course.imageUrl) {
                background.alt = this.course.name || '';
                background.src = this.course.imageUrl;
            }

            const buttons = (this.page.header?.buttons || [])
                .filter((item) => this.courseLink(item.buttonTo))
                .map((item) => ({
                    type: item.buttonType,
                    text: item.buttonText,
                    to: this.courseLink(item.buttonTo),
                }));

            return {
                hasStickyBar: false,
                section: {
                    to: '/catalogue/',
                    title: this.translation('MSP Academy Portal'),
                },
                title: this.course.name || '',
                settings: {
                    buttons,
                },
                background,
                labels,
            };
        },
    },

    methods: {
        translation(text) {
            return this.preset?.translation?.[text] || text;
        },

        courseLink(to) {
            if (to === 'ConstructorLMS') {
                return `https://learn.acronis.com/learn/courses/${this.course.id}/view/details`;
            }
            return this.preset?.settings?.catalogue?.find((course) => course.id === this.course.id)?.doceboLMS || '';
        },
    },

    async serverPrefetch() {
        const locale = this.$route?.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale });
    },
};
</script>

<style lang="postcss" scoped>
.page-container {
    &:deep(.s-header-academy) {
        position: relative;
        min-height: unset;
        padding-top: 80px;
        padding-bottom: 248px;

        .section-name {
            visibility: hidden;
        }
        @media (--viewport-mobile-wide) {
            padding-bottom: 432px;
        }

        @media (--viewport-desktop) {
            padding-bottom: 40px;
        }

        &:before {
            @media (--viewport-desktop) {
                top: 0;
                inset-inline-start: 0;
                z-index: 1;
                content: '';
                width: 100%;
                height: 90px;
                opacity: 0.5;
                position: absolute;
                background: linear-gradient(180deg, #00204d 0%, rgba(0, 32, 77, 0) 100%);
            }
        }

        .title {
            @mixin display-accent;
            text-align: center;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
                max-width: 66%;
                text-align: start;
            }
        }

        .bottom {
            display: flex;
            justify-content: center;

            @media (--viewport-desktop) {
                justify-content: left;
            }
        }

        .button {
            height: 40px;
            display: flex;
            padding: 0 16px;
            align-items: center;
        }

        .bg-container {
            bottom: 0;
            padding: 0;
            display: flex;
            width: 100%;
            margin: 0 auto;
            position: absolute;
            justify-content: flex-end;

            @media (--viewport-mobile-wide) {
                height: 384px;
                justify-content: center;
            }

            @media (--viewport-desktop) {
                top: 0;
                height: 100%;
            }

            .picture {
                width: 100%;
                height: 224px;
                position: relative;
                margin: auto auto 0;

                @media (--viewport-mobile-wide) {
                    margin: 0;
                    height: 100%;
                }

                @media (--viewport-desktop) {
                    width: auto;
                    margin-inline-start: auto;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center top;
                }

                &:before {
                    top: -1px;
                    inset-inline-start: 0;
                    content: '';
                    width: 100%;
                    height: 80px;
                    position: absolute;
                    background: linear-gradient(180deg, #00204d, transparent);

                    @media (--viewport-desktop) {
                        top: 0;
                        inset-inline-start: -1px;
                        width: 50%;
                        height: 100%;
                        background: linear-gradient(90deg, #00204d, transparent);
                    }
                }

                &:after {
                    @media (--viewport-desktop) {
                        top: 0;
                        inset-inline-end: -1px;
                        content: '';
                        width: 10%;
                        height: 100%;
                        position: absolute;
                        background: linear-gradient(270deg, #00204d, transparent);
                    }
                }
            }
        }

        .labels {
            margin-bottom: 8px;
            justify-content: center;

            @media (--viewport-desktop) {
                justify-content: left;
            }
        }

        .section-name {
            @media (--viewport-tablet) {
                margin-bottom: 56px;
            }

            @media (--viewport-desktop) {
                @mixin hero-subtle;
                margin-bottom: 74px;
            }
        }
    }

    &:deep(.s-cta-cards) {

        margin-top: auto;

        +.s-global-footer {
            margin-top: 0;
        }
    }

    &:deep(.heading) {
        @mixin display-accent;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin hero-accent;
        }

        &+.paragraph {
            @mixin lead !important;
            margin-top: 32px  !important;
            color: var(--av-nav-primary) !important;

            @media (--viewport-tablet) {
                @mixin title  !important;
                margin-top: 24px !important;
            }
        }
    }

    &:deep(.paragraph) {
        margin-top: 24px;

        &~.heading {
            margin-top: 48px;
        }
    }

    &:deep(.list) {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        counter-reset: number;

        .list-item {
            @mixin paragraph;
            width: 100%;
            border-radius: 16px;
            padding: 16px 24px 32px;
            counter-increment: number;
            color: var(--av-nav-primary);
            background: var(--av-brand-accent);
            border: 2px solid var(--av-brand-secondary-light);

            &::before {
                content: counter(number)". ";
            }

            @media (--viewport-tablet) {
                width: calc(50% - 8px);
            }

            @media (--viewport-desktop) {
                width: calc(33.33% - 12px);
            }
        }
    }

    .list-version-1 {
        &:deep(.list) {
            .list-item {
                @media (--viewport-tablet) {
                    &:last-child {
                        width: 100%;
                    }
                }

                @media (--viewport-desktop) {
                    &:last-child,
                    &:nth-last-child(2),
                    &:nth-last-child(3),
                    &:nth-last-child(4) {
                        width: calc(50% - 8px);
                    }
                }
            }
        }
    }

    .list-version-2 {
        &:deep(.list) {
            .list-item {
                @media (--viewport-tablet) {
                    &:last-child {
                        width: 100%;
                    }
                }

                @media (--viewport-desktop) {
                    &:last-child,
                    &:nth-last-child(2) {
                        width: calc(50% - 8px);
                    }
                }
            }
        }
    }
}
</style>
