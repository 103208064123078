<template>
    <section :id="id" class="s-slice" :class="className">
        <div class="a-container">
            <a-slice-header
                v-if="title || lead || bodyText"
                :title="title"
                :lead="lead"
                :body-text="bodyText"
            />
            <slot />
        </div>
    </section>
</template>

<script>
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import { LAYOUTS } from './constants.js';

export default {
    name: 'SBasicSlice',

    components: {
        ASliceHeader,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        layout: {
            type: String,
            default: null,
            validator: (value) => value === null || LAYOUTS.includes(value),
        },
    },

    computed: {
        className() {
            return {
                ...this.basicClassName,
                [`s-slice_layout_${this.layout}`]: this.layout,
            };
        },
    },
};
</script>

<style lang="postcss">
.s-slice {
    @mixin basic-slice-paddings;
    @mixin border-top;
    @mixin border-bottom;

    &_background {
        @mixin background;
    }

    &_layout_row {
        @media (--viewport-desktop) {
            & .a-container {
                display: flex;
            }

            & .a-slice-header {
                width: calc((100% - 48px) / 4);
                flex-shrink: 0;
                padding-inline-end: 16px;
                margin-inline-end: 16px;
            }
        }
    }
}
</style>
