<template>
    <section :id="id" class="s-message-highlights-combo" :class="{ ...className, ...basicClassName }">
        <div v-if="title || lead" class="a-container">
            <div v-if="title || lead" class="s-message-highlights-combo__header a-slice-header">
                <h2 v-if="title" class="a-slice-header__title">
                    {{ title }}
                </h2>
                <p v-if="lead" class="a-slice-header__lead">
                    {{ lead }}
                </p>
            </div>
        </div>
        <s-message-highlights
            v-for="(slice, index) in slices"
            v-bind="slice"
            :key="index"
            class="s-message-highlights-combo__item"
            :title="null"
            :border-bottom="isInnerBorderBottom(slices, index)"
            :is-reversed="evenReversed(index)"
            :is-centered="isCentered"
        />
    </section>
</template>

<script>
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SMessageHighlights from '@core/slices/pages/message-highlights/message-highlights.vue';

export default {
    name: 'SMessageHighlightsCombo',

    components: {
        SMessageHighlights,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        slices: {
            type: Array,
            required: true,
        },

        innerBorderBottom: {
            type: String,
            default: 'short',
        },

        indent: {
            type: String,
            default: 'small',
        },

        isCentered: {
            type: Boolean,
            default: true,
        },

        inverse: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        className() {
            const classes = {};
            if (this.indent) {
                classes[`s-slice_indent_${this.indent}`] = this.indent;
            }
            if (this.borderTop) {
                classes['s-slice_border-top'] = this.borderTop;
                classes[`s-slice_border-top_${this.borderTop}`] = this.borderTop;
            }
            if (this.borderBottom) {
                classes['s-slice_border-bottom'] = this.borderBottom;
                classes[`s-slice_border-bottom_${this.borderBottom}`] = this.borderBottom;
            }
            if (this.firstChildTopPadding) {
                classes['s-slice_first-child-padding'] = this.firstChildTopPadding;
            }
            if (this.bottomPadding) {
                classes[`s-slice_${this.bottomPadding}-padding`] = this.bottomPadding;
            }
            return classes;
        },
    },

    methods: {
        evenReversed(index) {
            return this.inverse ? index % 2 === 0 : index % 2 !== 0;
        },

        isInnerBorderBottom(arr, index) {
            return arr.length - 1 !== index ? this.innerBorderBottom : '';
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-message-highlights-combo {
    @mixin basic-slice-paddings;
    @mixin indents-borders;

    overflow: hidden;

    &__header {
        margin-bottom: 48px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 64px;
        }
    }
}

@mixin indents-borders;
</style>
