<template>
    <div class="color-scheme-tru cpoc-page cpc-authors">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" :tru-search-link="truSearchLink" />

                <s-blog-cpc-bar
                    :links="menuLinks"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                />
                <div class="a-container">
                    <div class="intro">
                        <blog-bcrumbs v-bind="bcrumbs" />
                        <h1 class="title">
                            {{ translations.cpcExpertsFull }}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="a-container">
                <blog-experts
                    class="experts"
                    :link-text="translations.seePublications"
                    :authors="authors"
                />
            </div>
            <div class="cpoc-wrapper">
                <div ref="morePostsBlock" class="more-posts">
                    <div class="a-container">
                        <h3 class="title">
                            {{ translations.cpcMorePostsTitle }}
                        </h3>
                        <s-blog-cpc-list :cards="morePosts" :show-only-one-row="true" />
                    </div>
                </div>
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import SBlogCpcList from '@core/slices/blog/blog-cpc/shared-components/blog-cpc-list.vue';
import BlogExperts from '@core/slices/blog/blog-experts/blog-experts.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogAuthors',

    components: {
        SBlogCpcHeader,
        SBlogCpcBar,
        BlogExperts,
        BlogBcrumbs,
        SBlogCpcList,
        SGlobalFooter,
    },

    mixins: [blog, analytics, localeRibbon],

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'dark',
            };
        },

        truSearchLink() {
            return this.isOnEnLocale ? this.sliceDataHeader.truSearchLink : null;
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        translations() {
            return this.$store.state.slices.items.blog || {};
        },

        morePosts() {
            return (this.$store.state.blog.morePosts.items || [])
                .map((card) => this.getBlogPostFields(card, this.translations));
        },

        bcrumbs() {
            const bcrumbs = [{
                to: '/cyber-protection-center/',
                title: this.translations.cpcTitle,
            },
            { title: this.translations.cpcExperts },
            ];
            const mobileLink = bcrumbs[0];
            return {
                theme: 'dark',
                bcrumbs,
                mobileLink,
            };
        },

        authors() {
            const authors = this.$store.state.blog.authors.items || [];
            return authors.map(this.getAuthorFields);
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpc-authors {
    .experts {
        @media (--viewport-desktop-wide) {
            padding-bottom: 40px;
        }
        &:deep(.expert) {
            &:last-child {
                margin-bottom: 48px;
                @media (--viewport-tablet) {
                    margin-bottom: 64px;
                }
            }
        }
    }
    .intro {
        text-align: center;
        padding: 24px 0 48px;
        @media (--viewport-tablet) {
            text-align: start;
        }
        @media (--viewport-desktop) {
            padding: 48px 0 64px;
        }
        .title {
            @mixin title-accent;
            color: var(--av-inversed-primary);
            @media (--viewport-desktop) {
                @mixin hero-accent;
            }
        }
    }
    .more-posts {
        padding: 32px 0 64px;
        .title {
            @mixin display-accent;
            color: var(--av-fixed-primary);
            text-align: center;
            margin-bottom: 24px;
            @media (--viewport-tablet) {
                text-align: start;
                margin-bottom: 32px;
            }
            @media (--viewport-desktop) {
                @mixin hero;
                margin-bottom: 24px;
            }
            @media (--viewport-desktop-wide) {
                margin-bottom: 8px;
            }
        }
        &:deep(.blog-cpc-list) {
            .items {
                @media (--viewport-desktop-wide) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
            }
            .item:last-child {
                display: none;
                @media (--viewport-desktop-wide) {
                    display: block;
                }
            }
        }
    }

    &:deep(.blog-experts) {
        padding-top: 48px;
        @media (--viewport-tablet) {
            padding-top: 64px;
        }
    }
}
</style>
