<template>
    <div class="select-wrapper">
        <div v-if="!isOtherSelected" class="select-input" :class="{'error': isSelectError}">
            <el-select
                v-if="field"
                v-bind="labelSelect"
                ref="select"
                v-model="value"
                :label="label || field.label"
                size="large"
                class="select"
                :remote="true"
                :filterable="filterable"
                :hide-on-resize="isDesktop"
                @change="onSelect($event)"
                @focus="onFocus()"
                @visible-change="onBlur($event)"
                @blur="onBlur()"
            >
                <template v-if="field.options">
                    <el-option
                        v-for="(option, i) in field.options"
                        :key="`${field.reference}_options_${i}`"
                        :value="option.amount"
                        :label="option.label"
                    />
                    <el-option
                        v-if="hasInput"
                        value="other"
                        :label="field.other"
                    />
                </template>
                <slot v-else />
            </el-select>
            <a-tooltip
                class="col"
                :text="field.tooltip"
                placement="top"
            >
                <a-glyph name="tooltip-o" class="tooltip-icon" />
            </a-tooltip>
        </div>
        <div v-if="hasInput && isOtherSelected" class="text-input" :class="{'error': isInputError }">
            <el-input
                ref="input"
                v-model.number="inputValue"
                type="number"
                :min="0"
                :max="999999"
                class="input"
                :label="label || field.label"
                @change="onInput($event)"
                @focus="onFocus()"
                @blur="onBlur()"
            />
            <div class="col" @click="showSelect()">
                <a-glyph name="close" class="icon" />
            </div>
        </div>
        <span
            v-if="field.error && showMessage"
            class="message"
            :class="{error: isSelectError || isInputError }"
        >
            {{ field.error }}
        </span>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';

export default {
    name: 'ASeelctInput',
    components: {
        AGlyph,
        ATooltip,
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [form, breakpoint],
    props: {
        field: {
            type: Object,
            required: true,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        default: {
            type: [String, Number],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        min: {
            type: Number,
            default: 0,
        },
        triggerError: {
            type: Boolean,
            default: false,
        },
        otherLabel: {
            type: String,
            default: null,
        },
        hasInput: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['change'],
    data() {
        return {
            inputValue: '',
            value: '',
            isDirty: false,
            isBlur: false,
            isOtherSelected: false,
        };
    },
    computed: {
        showMessage() {
            return this.triggerError && this.isDirty;
        },
        isSelectError() {
            if (this.isOtherSelected) return false;
            if (!this.isBlur) return false;
            if (this.isBlur && this.triggerError) return true;

            return false;
        },
        isInputError() {
            if (!this.isOtherSelected) return false;
            if (!this.isBlur) return false;
            if (this.isBlur && this.triggerError) return true;

            return false;
        },
    },
    watch: {
        async isOtherSelected(isOther) {
            await this.$nextTick();
            this.isBlur = false;
            const refName = isOther ? 'input' : 'select';
            /* Passing empty delay param so it waits for the next event cycle,
            if you don't do this it will not run the first time, only consecutive */
            setTimeout(() => {
                this.$refs[refName].focus();
            });
        },
    },
    mounted() {
        this.onSelect(this.default || this.value);
    },
    methods: {
        onFocus() {
            this.isBlur = false;
        },
        onBlur(exception) {
            if (exception) {
                this.isBlur = false;
                return;
            }
            this.isDirty = true;
            this.isBlur = true;
        },
        showInput() {
            this.onInput('');
            this.isOtherSelected = true;
        },
        showSelect() {
            this.onInput('');
            this.onSelect('');
            this.isOtherSelected = false;
        },
        onSelect(value) {
            if (value === 'other') return this.showInput();
            this.value = value;
            return this.$emit('change', this.value);
        },
        onInput(value) {
            this.inputValue = value;
            return this.$emit('change', this.inputValue);
        },
    },
};
</script>

<style lang="postcss" scoped>
.select-wrapper {
    @mixin colls 12;
    .select-input, .text-input {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 0 8px;
        .select {
            box-shadow: var(--av-shadow-small);
        }
        .select, .input {
            width: calc(100% - 56px);
        }
        &.error {
            &:deep(.el-input__container) {
                border: 1px solid var(--av-fixed-danger);
            }
        }
        .col {
            display: flex;
            align-items: center;
            width: 48px;
            justify-content: center;
        }
    }
    .text-input {
        &.error {
            .input {
                &:deep(.el-input__container) {
                    border: 1px solid var(--av-fixed-danger);
                }
            }
        }
        .col {
            cursor: pointer;
        }
    }
    .message {
        @mixin caption;
        display: block;
        margin-top: 2px;
        color: var(--av-fixed-light);
        &.error {
            font-weight: 500;
            color: var(--av-fixed-danger);
        }
    }
}
</style>
