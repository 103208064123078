<template>
    <div class="features">
        <div class="a-container">
            <h2 class="title">
                {{ title }}
            </h2>
            <div v-if="lead" class="lead">
                {{ lead }}
            </div>
            <div class="cards">
                <div
                    v-for="card in cards"
                    :key="card.title"
                    class="card"
                >
                    <a-icon class="icon" :number="card.iconNumber" :theme="card.iconTheme" />
                    <div class="card-title">
                        {{ card.title }}
                    </div>
                    <div class="card-text">
                        {{ card.text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';

export default {
    name: 'SCareersFeatures',

    components: {
        AIcon,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },

        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.features {
    @mixin basic-slice-paddings;
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    @media (--viewport-desktop) {
        padding-top: 64px;
        padding-bottom: 80px;
    }

    .title {
        @mixin hero-accent;

        text-align: center;
        margin-bottom: 16px;
    }

    .lead {
        @mixin lead;
        font-weight: 500;
        text-align: center;
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            @mixin title;
        }

        @media (--viewport-desktop) {
            @mixin colls 10;

            margin: 0 auto 48px;
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (--viewport-tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .icon {
        width: 48px;
        height: 48px;
        margin-bottom: 8px;
    }

    .card-title {
        @mixin title-accent;

        background: linear-gradient(90deg, rgba(3, 104, 223, 1), rgba(109, 228, 252, 1));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            @mixin display-accent;
        }
    }

    .card-text {
        @mixin paragraph;
    }
}
</style>
