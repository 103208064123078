<template>
    <div class="resource-home-search-panel">
        <div class="container">
            <div class="top-block">
                <div class="title">
                    {{ commonTranslations.searchForResources }}
                </div>

                <div class="top-block-wrapper">
                    <el-input
                        v-model="searchRequest"
                        name="search"
                        class="search-input"
                        :placeholder="commonTranslations.homeSearchPlaceholder"
                        @focus="onSearchFocus"
                        @blur="onSearchBlur"
                    />

                    <a-button
                        ref="searchButton"
                        class="search-button"
                        type="main"
                        :text="commonTranslations.search"
                        :to="searchUrl"
                    />
                </div>
            </div>
            <div class="bottom-block">
                <div class="quick-links">
                    {{ commonTranslations.quickLinks }}
                </div>

                <div class="bottom-block-wrapper">
                    <a-link
                        v-for="audience in audiences"
                        :key="audience.audience_id"
                        class="quick-link"
                        :text="`${commonTranslations.for} ${audience.title}`"
                        glyph="arrow"
                        has-non-breaking-glyph
                        :to="audience.to"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import { RC_ROOT_URL } from '@model/const/resource-center';

export default {
    name: 'ResourceHomeSearchPanel',

    components: {
        AButton,
        ALink,
        ElInput: () => import('@uikit/ui-kit/packages/input'),
    },

    props: {
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        audiences: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        searchRequest: '',
    }),

    computed: {
        searchUrl() {
            return `${RC_ROOT_URL}/search/#searchString=${encodeURIComponent(this.searchRequest)}`;
        },
    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.keyPressHandler);
    },

    methods: {
        keyPressHandler(event) {
            if (event.key !== 'Enter') return;
            this.$refs.searchButton?.$el?.click();
        },

        onSearchFocus() {
            document.addEventListener('keyup', this.keyPressHandler);
        },

        onSearchBlur() {
            document.removeEventListener('keyup', this.keyPressHandler);
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-home-search-panel {
    width: 100%;
    background: var(--av-solid-brand-accent);
    box-shadow: 0 5px 16px rgba(0, 54, 130, 0.15);

    .container {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 24px 16px;

        @media (--viewport-tablet) {
            align-items: unset;
            padding: 24px 32px;
        }

        @media (--viewport-desktop-wide) {
            align-items: unset;
            padding: 24px 64px;
        }
    }
}

.title,
.quick-links {
    @mixin lead-accent;
    color: var(--av-nav-primary);
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
    }

    @media (--viewport-desktop) {
        @mixin nav-title-accent;
    }
}

.quick-links {
    margin-top: 8px;

    @media (--viewport-tablet) {
        margin-top: 0;
    }
}

.quick-link {
    &:deep(.a-glyph) {
        margin-top: 1px;
    }

    &:deep(.a-link__content) {
        @mixin paragraph;
        font-weight: 500;
    }
}

.search-input {
    z-index: 0;
    width: 100%;
}

.search-button {
    width: 100%;

    @media (--viewport-tablet) {
        max-width: 164px;
    }
}

.top-block,
.bottom-block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @media (--viewport-tablet) {
        display: grid;
        grid-template-columns: 240px 1fr;
        align-items: flex-end;
    }
}

.top-block-wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (--viewport-tablet) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.bottom-block-wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;

    @media (--viewport-tablet) {
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;
    }

    @media (--viewport-desktop) {
        gap: 48px;
    }
}
</style>
