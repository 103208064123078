<template>
    <div class="s-side-menu-control">
        <template v-if="type === 'tabs'">
            <ul class="s-side-menu-control__list">
                <li v-for="(item, index) in slices" :key="`tab-item-${index}`" class="s-side-menu-control__item">
                    <button
                        class="s-side-menu-control__element"
                        :class="getClassElement(item, index)"
                        type="button"
                        :data-href="`#${item.info.id}`"
                        @click="onChange(item.info.id)"
                    >
                        {{ item.info.caption }}
                        <span v-if="hasCounter(index)" class="s-side-menu-control__element-counter">
                            {{ slices[index].data.cards.length }}
                        </span>
                    </button>
                </li>
            </ul>
            <el-select class="s-side-menu-control__select" :value="activeElement" @change="onChange">
                <el-option
                    v-for="(item, index) in slices"
                    :key="`option-item-${index}`"
                    :label="item.info.caption"
                    :hide-on-resize="isDesktop"
                    :value="item.info.id"
                />
            </el-select>
        </template>
        <template v-else-if="type === 'links'">
            <ul class="s-side-menu-control__list">
                <li v-for="(item, index) in slices" :key="`anchor-item-${index}`" class="s-side-menu-control__item">
                    <a
                        class="s-side-menu-control__element"
                        :class="getClassElement(item, index)"
                        :href="item.info.link || `#${item.info.id}`"
                        @click="onChange(item.info.id)"
                    >
                        {{ item.info.caption }}
                        <span v-if="hasCounter(index)" class="s-side-menu-control__element-counter">
                            {{ slices[index].data.cards.length }}
                        </span>
                    </a>
                </li>
            </ul>
            <el-select class="s-side-menu-control__select" :value="activeElement" @change="onChange">
                <el-option
                    v-for="(item, index) in slices"
                    :key="`option-item-${index}`"
                    :label="item.info.caption"
                    :hide-on-resize="isDesktop"
                    :value="item.info.id"
                />
            </el-select>
        </template>
        <div v-else-if="type === 'combined'" class="s-side-menu-control__combined-wrapper">
            <el-select class="s-side-menu-control__select" :value="activeElement" @change="onChange">
                <el-option
                    v-for="(item, index) in slices"
                    :key="`option-item-${index}`"
                    :label="item.info.caption"
                    :hide-on-resize="isDesktop"
                    :value="item.info.id"
                />
            </el-select>
            <a-accordion :is-wide="false" one-only-mode class="s-side-menu-control__list">
                <a-accordion-item
                    v-for="(item, index) in slices"
                    :key="`anchor-item-${index}`"
                >
                    <template #title>
                        <button
                            class="s-side-menu-control__element a-accordion__title"
                            :class="getClassElement(item, index)"
                            type="button"
                            @click="onChange(item.info.id)"
                        >
                            <a-glyph v-if="item.slices.length > 1" class="s-side-menu-control__arrow" name="drop-down" />
                            {{ item.info.caption }}
                        </button>
                    </template>
                    <div class="a-accordion__content">
                        <div class="a-accordion__content-wrapper">
                            <ul v-if="item.slices.length > 1" class="s-side-menu-control__sublist">
                                <li
                                    v-for="(link, linkIndex) in item.slices"
                                    :key="linkIndex"
                                    class="s-side-menu-control__subitem"
                                >
                                    <a class="s-side-menu-control__link a-link" :href="`#${link.info.id}`">
                                        {{ link.info.caption }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </a-accordion-item>
            </a-accordion>
        </div>
    </div>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SSideMenuControl',

    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        AGlyph,
        AAccordion,
        AAccordionItem,
    },

    mixins: [breakpoint],

    props: {
        activeElement: {
            type: String,
            required: true,
        },
        /**
         * Type of side menu
         */
        type: {
            type: String,
            required: true,
        },

        /**
         * Content slice data
         */
        slices: {
            type: Array,
            required: true,
        },
    },

    emits: ['tab-change'],

    methods: {
        onChange(event) {
            const active = this.slices.find((item) => item.info.id === event);
            if (active.info.link) {
                window.location.assign(active.info.link);
                return;
            }
            this.$emit('tab-change', event);
        },
        hasCounter(index) {
            return this.slices[index].data && this.slices[index].data.counter;
        },
        getClassElement(item, index) {
            return {
                's-side-menu-control__element_with-counter': this.hasCounter(index),
                's-side-menu-control__element_active': item.info.id === this.activeElement,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-side-menu-control {
    background: white;
    border: 1px solid rgba(26, 96, 179, 0.15);
    border-radius: 4px;

    @media (--viewport-desktop) {
        top: 88px;
        max-height: calc(100vh - 88px);
        border: none;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        background: rgba(255, 255, 255, 0);
    }

    &--sticky {
        position: sticky;
        top: 20px;
    }

    &__list {
        display: none;

        @media (--viewport-desktop) {
            display: block;
        }
    }

    &__element {
        @mixin paragraph;

        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 16px;
        border: none;
        border-radius: 4px;
        background: none;
        text-align: start;
        text-decoration: none;
        color: var(--av-nav-primary);
        cursor: pointer;

        &:hover {
            background-color: var(--av-brand-lightest);
            color: var(--av-nav-primary);
        }

        &_active {
            background-color: var(--av-brand-accent);
        }

        &_with-counter {
            font-weight: 600;
            justify-content: space-between;
        }
    }

    &__element-counter {
        @mixin body;
        font-weight: 400;
        color: var(--av-fixed-light);
    }

    &__select {
        display: block;

        &:deep(.el-input) {
            &__icon {
                color: #024aa2 !important;
            }

            &__editor--absolute-position {
                color: var(--av-nav-primary);
                font-size: 16px;
            }
        }

        @media (--viewport-desktop) {
            display: none !important;
        }
    }

    &__arrow {
        margin-inline-end: 11px;
        fill: var(--av-nav-primary);
        transform: rotate(-90deg);
        transition: transform 0.3s ease-out;
    }

    &__sublist {
        padding: 16px;
    }

    &__subitem {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &__link {
        color: var(--av-nav-primary);

        &:hover {
            color: var(--av-nav-secondary);
        }
    }

    &__combined-wrapper {
        &:deep(.s-side-menu-control) {
            &__select {
                @media (--viewport-desktop) {
                    display: none;
                }
            }

            &__list {
                display: none;

                @media (--viewport-desktop) {
                    display: block;
                }
            }
        }
    }

    &:deep(.a-accordion-item) {
        border: 0;

        .title {
            padding: 0;
        }

        .content {
            @mixin paragraph;
        }

        .content-wrapper {
            padding: 0;
        }

        &.active {
            .s-side-menu-control__arrow {
                transform: rotate(0);
            }
        }
    }

}
</style>
