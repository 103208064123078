<template>
    <main class="s-landing-page">
        <component :is="`SSymbols`" />
        <template v-for="(item, itemIndex) in list">
            <component
                :is="`s-${item.name}`"
                :key="`s-${itemIndex}`"
                :content="item"
            />
        </template>
    </main>
</template>

<script>
import l10n from '@core/mixins/l10n.js';
import SSymbols from './components/symbol.vue';
import SCasestudy from './slices/casestudy.vue';
import SForm from './slices/form.vue';
import SGuideline from './slices/guideline.vue';
import SHeader from './slices/header.vue';
import SIntro from './slices/intro.vue';
import SPartners from './slices/partners.vue';
import SProducts from './slices/products.vue';
import SVideo from './slices/video.vue';

export default {
    name: 'SMainPage',
    components: {
        SSymbols,
        SCasestudy,
        SForm,
        SGuideline,
        SHeader,
        SIntro,
        SPartners,
        SProducts,
        SVideo,
    },
    mixins: [l10n],
    computed: {
        list() {
            return this.l10n.slices.filter((item) => item.name);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-landing-page {
    color: var(--av-inversed-primary);
    background: var(--av-nav-primary);
}
</style>
