<template>
    <div class="s-dropdown-navigation">
        <div
            ref="toggler"
            class="toggler"
            :class="{ active: mobileMenuOpened, 'with-label': showMenuLabel }"
            @click="toggleMobileMenu"
        >
            <div ref="mobileSubmenuToggle" class="toggler-menu-label">
                <span class="toggler-label">
                    {{ menuLabel }}
                </span>
                <span class="toggler-label">{{ menuLabel }}</span>
                <a-glyph class="dropdown-glyph" name="drop-down" />
            </div>
            <div ref="mobileSubmenuToggleWithProduct" class="toggler-product-label">
                <span class="toggler-label">
                    {{ title }}
                </span>
                <a-glyph class="dropdown-glyph" name="drop-down" />
            </div>
        </div>

        <div class="dropdown" :style="{ 'max-height': dropdownMaxHeight }">
            <div v-for="(item, i) in menu" :key="`mobile-submenu-${i}`" class="item">
                <a-link
                    v-if="item.to"
                    class="link"
                    size="lead"
                    v-bind="item"
                    :class="{ 'link-active': isLinkActive(item.to) }"
                    :event="getDefaultALinkGAEvent(item)"
                />
                <div v-else-if="item.submenu || item.sublinks" class="sublist-container">
                    <button
                        ref="mobileSublist"
                        class="sublist-toggler"
                        :class="{ active: mobileSublistOpened }"
                        @click="toggleMobileSublist"
                    >
                        {{ item.text }}
                        <a-glyph class="dropdown-glyph" name="i-chevon-big-down--16" />
                    </button>
                    <div v-if="item.submenu" class="submenu">
                        <a-accordion>
                            <a-accordion-item v-for="(tab, j) in item.submenu.columns" :key="`${tab.text}-${j}`">
                                <template #title>
                                    {{ tab.text }}
                                    <a-glyph class="dropdown-glyph" name="i-chevon-big-down--16" />
                                </template>
                                <template v-for="(link, l) in tab.links">
                                    <a-link
                                        v-if="link.to"
                                        :key="`${link.to}-${l}`"
                                        class="sublink"
                                        size="paragraph"
                                        v-bind="link"
                                        :class="{ 'link-active': isLinkActive(link.to) }"
                                        :event="getDefaultALinkGAEvent(link)"
                                    />
                                </template>
                            </a-accordion-item>
                        </a-accordion>
                        <div v-if="item.submenu.bottomLinks" class="submenu-bottom">
                            <template v-for="(link, l) in item.submenu.bottomLinks">
                                <a-link
                                    v-if="link.to"
                                    :key="`${link.to}-${l}`"
                                    class="link"
                                    v-bind="link"
                                    :event="getDefaultALinkGAEvent(link)"
                                    type="dark"
                                />
                            </template>
                        </div>
                    </div>
                    <div v-else-if="item.sublinks" class="submenu">
                        <div class="submenu-inner">
                            <template v-for="(link, l) in item.sublinks">
                                <a-link
                                    v-if="link.to"
                                    :key="`${link.to}-${l}`"
                                    class="sublink"
                                    size="paragraph"
                                    v-bind="link"
                                    :class="{ 'link-active': isLinkActive(link.to) }"
                                    :event="getDefaultALinkGAEvent(link)"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="buttons.length" :class="['buttons', {'no-submenu': !menu.length}]">
                <a-link
                    v-for="(button, j) in buttons"
                    :key="`button-${j}`"
                    class="button"
                    size="paragraph"
                    v-bind="button"
                    :event="getButtonGA(button)"
                    :glyph="button.glyph || 'arrow-long'"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

const DROPDOWN_TOP_MARGIN = 16;

export default {
    name: 'SDropdownNavigation',

    components: {
        AGlyph,
        ALink,
        AAccordion,
        AAccordionItem,
    },

    props: {
        buttons: {
            type: Array,
            default: () => [],
        },

        menu: {
            type: Array,
            required: true,
        },

        showMenuLabel: {
            type: Boolean,
            default: true,
        },

        menuLabel: {
            type: String,
            default: 'Menu',
        },

        title: {
            type: String,
            required: true,
        },

        event: {
            type: Object,
            default: () => ({
                category: 'Conversions',
                location: 'Top CTA',
            }),
        },
    },

    data() {
        return {
            mobileMenuOpened: false,
            mobileSublistOpened: false,
            dropdownMaxHeight: 0,
        };
    },

    mounted() {
        document.addEventListener('click', this.hideDropdown);
        window.addEventListener('resize', this.calculateDropdownMaxHeight);
        window.addEventListener('scroll', this.calculateDropdownMaxHeight);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.hideDropdown);
        window.removeEventListener('scroll', this.calculateDropdownMaxHeight);
        window.removeEventListener('resize', this.calculateDropdownMaxHeight);
    },

    methods: {
        toggleMobileMenu() {
            this.mobileMenuOpened = !this.mobileMenuOpened;
            this.calculateDropdownMaxHeight();
        },

        calculateDropdownMaxHeight() {
            const maxHeight = window.innerHeight -
                this.$refs.toggler.getBoundingClientRect().top -
                this.$refs.toggler.getBoundingClientRect().height -
                DROPDOWN_TOP_MARGIN;
            this.dropdownMaxHeight = `${maxHeight}px`;
        },

        toggleMobileSublist() {
            this.mobileSublistOpened = !this.mobileSublistOpened;
        },

        hideMobileSublist() {
            this.mobileSublistOpened = false;
        },

        getDefaultALinkGAEvent(item) {
            return {
                ...this.event,
                category: 'Interactions',
                action: 'top product tab',
                label: item.text,
            };
        },

        isLinkActive(link) {
            if (!this.$route) {
                // We're in Storybook, no refs available
                return false;
            }

            return this.$route.path === `/${this.locale}${link}`;
        },

        getButtonGA(button) {
            return {
                ...this.event,
                content: this.productName,
                ...button.event,
            };
        },

        hideDropdown(event) {
            if (!this.mobileMenuOpened) return;

            if (!this.$el.contains(event.target)) {
                this.mobileMenuOpened = false;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-dropdown-navigation {
    display: block;
    max-width: 100%;
    float: left;
}

.toggler {
    font-size: 18px;
    line-height: 32px;
    color: var(--av-inversed-primary);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    &.active {
        .toggler-menu-label,
        .toggler-product-label {
            .dropdown-glyph {
                transform: rotate(180deg);
                transition: transform 0.3s ease-out;
            }
        }

        & + .dropdown {
            display: block;
        }
    }

    &.with-label {
        padding-top: 5px;

        .toggler-menu-label {
            visibility: visible;
            position: static;
        }

        .toggler-product-label {
            visibility: hidden;
            position: absolute;
            inset-inline-start: -100vw;
        }
    }

    @media (--viewport-mobile-wide) {
        font-size: 22px;
        line-height: 32px;
    }
}

.toggler-product-label {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.toggler-menu-label {
    @mixin paragraph;
    display: flex;
    align-items: center;
    margin-inline-end: -11px;
    visibility: hidden;
    position: absolute;
    inset-inline-start: -100vw;

    .dropdown-glyph {
        top: 1px;
    }
}

.toggler-label {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-glyph {
    margin-inline-start: 19px;
    position: relative;
    top: 2px;
    fill: currentcolor;
    flex-shrink: 0;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    inset-inline-start: 0;
    width: 100%;
    background-color: var(--av-inversed-primary);
    box-shadow: 0 6px 18px rgba(0, 32, 77, 0.2);
    padding: 16px 0 16px;
    overflow-y: scroll;
    margin-top: 10px;
    padding-inline: 16px;

    @media (--viewport-mobile-wide) {
        margin-top: 14px;
        padding-inline: 24px;
    }

    @media (--viewport-desktop) {
        overflow-y: auto;
        margin-top: 16px;
        width: auto;
        max-width: 560px;
        border: 1px solid var(--av-brand-secondary);
        border-radius: 4px;
        margin-inline-start: 32px;
        padding-inline: 16px;
    }
}

.link,
.button,
.sublist-toggler,
.a-accordion-item:deep(.title) {
    font-weight: 700;
    display: block;
    padding: 12px 8px;
    color: var(--av-nav-primary);
    width: 100%;
    text-decoration: none;
    border-radius: 4px;
    white-space: normal;

    .dropdown-glyph {
        margin-inline-start: 11px;
        position: relative;
        top: 0;
    }
}

.link {
    font-weight: 700;

    &:hover,
    &.link-active {
        background: var(--av-brand-secondary-accent);
    }
}

.a-accordion-item:deep(.title) {
    @mixin lead-accent;
}

.sublist-toggler {
    @mixin lead-accent;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    color: var(--av-brand-primary);
    justify-content: space-between;

    &.active {
        color: var(--av-nav-primary);
        background: var(--av-brand-secondary-accent);

        > .dropdown-glyph {
            transform: rotate(180deg);
            transition: transform 0.3s ease-out;
        }

        & + .sublist,
        & + .submenu {
            max-height: 100vh;

            @media (--viewport-desktop) {
                padding: 8px 0;
            }
        }
    }
}

.sublist,
.submenu {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
}

.sublink {
    @mixin paragraph;
    display: block;
    white-space: normal;

    &:hover,
    &.link-active {
        background: var(--av-brand-secondary-accent);
    }
}

.submenu-inner {
    padding: 8px 8px 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: var(--av-border);
}

.buttons {
    position: relative;
    padding-top: 5px;
    margin-top: 12px;

    &.no-submenu {
        padding-top: 0;
        &:before {
            height: 0;
        }
    }
}

.buttons {
    border-top: var(--av-border);
    margin-top: 24px;
    padding-top: 24px;
}
.submenu-bottom {
    border-bottom: var(--av-border);
    margin-bottom: 24px;
    padding-bottom: 24px;

    .link {
        color: var(--av-nav-primary);
    }

    &:deep(.a-glyph) {
        fill: var(--av-brand-primary);
        color: var(--av-brand-primary);
    }
}

.button {
    color: var(--av-nav-primary);

    &:hover {
        color: var(--av-nav-primary);
    }
}

.a-accordion {
    &:deep(.a-accordion-item:last-of-type) {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
}

.a-accordion-item {
    border: 0;

    &.active {
        &:deep(.title .a-glyph) {
            transform: rotate(180deg);
            transition: transform 0.3s ease-out;
        }
    }

    &:deep(.title) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            color: var(--av-nav-primary);
        }
    }

    &:deep(.content-wrapper) {
        padding: 8px 8px 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

</style>
