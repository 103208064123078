/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.price-calculator .el-tooltip__popper .text {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
            }

.price-calculator .el-tooltip__popper .text strong {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }

.price-calculator .el-tooltip__popper ul {
                list-style: disc;
                list-style-position: outside;
                padding-inline-start: 8px;
                margin-inline-start: 8px;
            }

.price-calculator .el-tooltip__popper ul li::before { display: none; }

.price-calculator .el-tooltip__popper ul li {
                    margin:0;
}
