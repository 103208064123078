/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

@media (min-width: 768px) {

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__body_type_products) {
                display: grid;
                padding: 8px 48px 8px 32px;
                grid-template-columns: 2fr 1fr 1fr;
                grid-column-gap: 40px
        }
            }

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__body_type_products) > * {
                padding: 32px 0;
                grid-row: auto;
                order: initial;
            }

@media (min-width: 1280px) {

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__body_type_products) {
                padding: 8px 112px 8px 64px;
                grid-column-gap: 80px
        }
            }

@media (min-width: 1440px) {

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__body_type_products) {
                padding: 8px 0 8px 0
        }
            }

@media (min-width: 768px) {

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products).s-menu-tab__section_full-width .s-menu-tab__text {
                        margin-top: 0
                }
                    }

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products) .s-menu-tab__linktitle {
                    margin-top: 8px;
                }

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products) .s-menu-tab__link-strong .a-link__content {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 700;
                        color: var(--av-nav-primary);
                    }

@media (min-width: 1024px) {

.menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products) .s-menu-tab__link-strong .a-link__content {
                            font-size: 14px;
                            line-height: 24px
                    }
                        }

@media (min-width: 768px) {
                .menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products):nth-child(4) {
                    border-top: 1px solid var(--av-brand-accent);
                }
            }

@media (min-width: 1024px) {
                        .menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__description {
                            font-size: 14px;
                            line-height: 24px;
                        }
                            .menu-tab-products .s-menu-tab.s-menu-tab_mini:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__linktitle .a-link__content {
                                font-size: 24px;
                                line-height: 32px;
                            }
            }

@media (min-width: 768px) {

.menu-tab-products:deep(.s-menu-tab__body_type_products) {
            padding: 0 32px 8px;
            grid-column-gap: 40px;
            grid-template-columns: repeat(3, 200px)
    }
        }

@media (min-width: 1024px) {

.menu-tab-products:deep(.s-menu-tab__body_type_products) {
            grid-column-gap: 32px;
            grid-template-columns: 2fr 1fr 1fr
    }
        }

@media (min-width: 1280px) {

.menu-tab-products:deep(.s-menu-tab__body_type_products) {
            padding: 0 64px 8px;
            grid-column-gap: 40px;
            grid-template-columns: 546px 250px 250px
    }
        }

@media (min-width: 1440px) {

.menu-tab-products:deep(.s-menu-tab__body_type_products) {
            padding: 0 0 8px;
            grid-column-gap: 48px;
            grid-template-columns: 618px 284px 320px
    }
        }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__glyphlist-item-link .a-link__content {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                }

.menu-tab-products:deep(.s-menu-tab__section_type_products).s-menu-tab__section_full-width {
            grid-column: 1 / 4;
        }

.menu-tab-products:deep(.s-menu-tab__section_type_products).s-menu-tab__section_full-width .s-menu-tab__text {
                margin-top: 0;
            }

@media (min-width: 768px) {
                .menu-tab-products:deep(.s-menu-tab__section_type_products).s-menu-tab__section_full-width .a-glyph__products {
                    vertical-align: middle;
                    margin-top: -1px;
                }
            }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__linktitle {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
            }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__linktitle .a-link {
                    display: inline;
                    margin-inline-end: 4px;
                }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__linktitle .a-link .a-link__content {
                        display: inline;
                    }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__linktitle .a-label {
                    transform: translateY(-1px);
                }

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__link-strong {
                font-size: 14px;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 8px;
            }

@media (min-width: 1024px) {

.menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__link-strong {
                    font-size: 14px;
                    line-height: 24px
            }
                }

@media (min-width: 768px) {
            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) {
                grid-row: 1 / span 2;
            }
                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__list {
                        margin: 0;
                    }

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(3) {
                order: 4;
                padding: 16px 0 40px;
                grid-column: span 3;
            }

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(2),
            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(4) {
                grid-row: 1 / span 3;
            }
                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(2) .s-menu-tab__list, .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(4) .s-menu-tab__list {
                        margin: 40px 0 0;
                    }
                @media (min-width: 1024px) {

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(2),
            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(4) {
                    max-width: 250px
            }
                }

                @media (min-width: 1280px) {

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(2),
            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(4) {
                    max-width: initial
            }
                }

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(5) {
                order: 5;
                border-top: 1px solid var(--av-brand-accent);
                grid-column: span 3;
            }
        }

@media (min-width: 1024px) {
                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__description {
                        font-size: 16px;
                        line-height: 24px;
                    }
                        .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__linktitle .a-link__content {
                            font-size: 24px;
                            line-height: 32px;
                        }

                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__cta {
                        display: flex;
                    }
                        .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__cta-item:not(:first-child) {
                            margin-top: 0;
                            margin-inline-start: 40px;
                        }

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(3) {
                grid-column: span 1;
            }

                .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(3) .s-menu-tab__text {
                    font-size: 16px;
                    line-height: 24px;
                }
                .menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__description {
                    font-size: 14px;
                    line-height: 24px;
                }

                .menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__description {
                    margin: 8px 0 0;
                }
        }

@media (min-width: 1280px) {
                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__list {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin: 8px 0 0;
                    }

                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__list-item {
                        min-width: 216px;
                        max-width: 256px;
                        width: 50%;
                    }

                        .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(1) .s-menu-tab__list-item:first-child {
                            margin: 0;
                        }

            .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(3) {
                padding-top: 8px;
            }
                .menu-tab-products:deep(.s-menu-tab__section_type_products) .s-menu-tab__text {
                    max-width: 617px;
                }
        }

@media (min-width: 1440px) {
                    .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(2) .s-menu-tab__description, .menu-tab-products:deep(.s-menu-tab__section_type_products):nth-child(4) .s-menu-tab__description {
                        max-width: 269px;
                    }
        }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .list-columns {
                gap: 32px;
        }
            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .list-columns {
            padding-inline-start: 0
}

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__body_type_products {
            display: grid;
            grid-row-gap: 0;
            padding: 0 24px 0;
        }

@media (min-width: 768px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__body_type_products {
                padding: 0 32px;
                grid-column-gap: 24px;
                grid-template-columns: 1fr 1fr
        }
            }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__body_type_products {
                grid-template-columns: 2fr 1fr 1fr
        }
            }

@media (min-width: 1280px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__body_type_products {
                grid-column-gap: 32px;
                padding: 0 64px
        }
            }

@media (min-width: 1440px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__body_type_products {
                padding: 0
        }
            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section {
            padding-top: 24px;
        }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section {
                padding-top: 32px
        }
            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) {
                order: unset;
                padding-bottom: 0;
                grid-column: unset;
            }

@media (min-width: 768px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) {
                    grid-row: 1 / span 3
            }
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) {
                    grid-column: unset
            }
                }

@media (min-width: 1280px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) {
                    min-width: 560px
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .list-column {
                    margin: 0;
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .list-column {
                        max-width: 50%;
                        margin: 16px 0 0
                }
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .list-column-category {
                    margin: 16px 0 0;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .list-column-link {
                    margin: 8px 0 0;
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .list-column-link {
                        margin: 16px 0 0
                }
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__cta {
                    gap: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 32px;
                    align-items: center;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__cta-item:not(:first-child) {
                        margin: 0;
                    }

@media (min-width: 1280px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__cta-item:not(:first-child) {
                            margin-inline-start: 16px
                    }
                        }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__button {
                    gap: 16px;
                    display: flex;
                    margin-top: 32px;
                    align-items: center;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__button .a-button__glyph {
                        margin-inline-start: 8px;
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__linktitle {
                    margin-top: 4px;
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__linktitle {
                        margin-top: 16px
                }
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__linktitle .a-link .a-link__content {
                            font-size: 24px;
                            line-height: 32px;
                            font-weight: 700;
                        }

@media (min-width: 1280px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__linktitle .a-link .a-link__content {
                                font-size: 28px;
                                font-weight: 700;
                                line-height: 40px
                        }
                            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(1) .s-menu-tab__description {
                    font-size: 16px;
                    line-height: 24px;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(2) {
                order: unset;
                max-width: none;
                grid-row: unset;
                padding: 32px 0 0;
                grid-column: unset;
            }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(2) {
                    grid-column: unset
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(3) {
                order: unset;
                max-width: none;
                grid-row: unset;
                padding: 16px 0 0;
                grid-column: unset;
            }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(3) {
                    padding: 64px 0 0
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(4) {
                padding: 0;
                order: unset;
                max-width: none;
                grid-row: unset;
                grid-column: unset;
            }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(4) {
                    grid-column: span 2
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(4) .s-menu-tab__link-strong {
                    padding-top: 32px;
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products:nth-child(4) .s-menu-tab__link-strong {
                        padding-top: 24px
                }
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products.s-menu-tab__section_full-width {
                gap: 16px;
                display: flex;
                padding: 24px 0;
                margin-top: 48px;
                grid-row: unset;
                grid-column: unset;
                flex-direction: column;
                border-top: 1px solid var(--av-brand-accent);
            }

@media (min-width: 768px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products.s-menu-tab__section_full-width {
                    flex-wrap: wrap;
                    grid-column: 1 / 3;
                    flex-direction: row
            }
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products.s-menu-tab__section_full-width {
                    grid-row: 4 / span 1;
                    grid-column: span 3
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products.s-menu-tab__section_full-width .a-glyph__products {
                    margin: 0;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__cta {
                margin-top: 16px;
            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__link-strong {
                font-size: 14px;
                line-height: 24px;
            }

@media (min-width: 1280px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__link-strong {
                    font-size: 22px;
                    line-height: 32px;
                    font-weight: 700;
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__link-strong {
                margin-bottom: 0
}

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle {
                display: flex;
                margin-top: 4px;
                flex-direction: column;
                justify-content: flex-start;
            }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle {
                    margin-top: 8px;
                    flex-direction: row;
                    align-items: center
            }
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle:hover .a-button {
                        visibility: visible;
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-link {
                    order: 1;
                    margin-inline-end: 8px;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-link .a-link__content {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-label {
                    order: 0;
                    margin-top: 2px;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-button {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    order: 3;
                    margin-top: 16px;
                }

@media (min-width: 1024px) {

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-button {
                        margin-top: 0;
                        padding: 4px 8px;
                        visibility: hidden
                }
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .a-button .a-button__glyph {
                        margin-inline-start: 8px;
                    }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__linktitle .s-menu-tab__description {
                    order: 2;
                }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__section_type_products .s-menu-tab__description {
                font-size: 14px;
                line-height: 20px;
                max-width: unset;
            }

.menu-tab-products_version-46942:deep(.s-menu-tab) .s-menu-tab__cta {
            margin-top: 32px;
        }
