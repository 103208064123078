<template>
    <div class="cpc-post-head">
        <div class="a-container">
            <div class="wrapper">
                <div class="head-wrapper">
                    <div class="meta">
                        <blog-date-localized :date="publishedAt" />
                        <template v-if="author">
                            &nbsp;—&nbsp;
                            <a-link
                                class="author"
                                :to="author.link"
                                :text="author.name"
                                size="paragraph"
                            />

                            <template v-if="editors.length">
                                <template v-for="editor in editors">
                                    <span :key="`editor-comma-${editor.id}`">,&nbsp;</span>
                                    <a-link
                                        :key="`editor-${editor.id}`"
                                        class="author"
                                        :to="editor.link"
                                        :text="editor.name"
                                        size="paragraph"
                                    />
                                </template>
                            </template>
                        </template>
                    </div>
                    <template v-if="categories">
                        <dynamic-link
                            v-for="(category, i) in categories"
                            :key="i"
                            class="category"
                            :to="category.link"
                            native-type="a"
                        >
                            {{ category.title }}
                        </dynamic-link>
                    </template>
                </div>
                <h1 class="title">
                    {{ title }}
                </h1>
                <div v-if="socials" class="socials">
                    <div class="social-label">
                        {{ shareTitle }}
                    </div>
                    <a-social class="social" :social="socials" glyph-size="s" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// There's a lot of dead code here, probably introduced in https://pmc.acronis.work/browse/WEB-45072
// TODO: review the entire CPC section templates, remove dead code.

// TODO: why blog-cpc slices section is inside the blog section?

import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import ASocial from '@core/components/social/social.vue';
import BlogDateLocalized from '../shared-components/blog-date-localized/blog-date-localized.vue';

export default {
    name: 'SBlogCpcPostHead',
    components: {
        ASocial,
        ALink,
        DynamicLink,
        BlogDateLocalized,
    },
    props: {
        socials: {
            type: Array,
            default: () => [],
        },

        author: {
            type: Object,
            required: true,
        },

        editors: {
            type: Array,
            default: () => [],
        },

        categories: {
            type: Array,
            default: () => [],
        },

        publishedAt: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        shareTitle: {
            type: String,
            required: true,
        },

        body: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            default: 'light',
        },
    },
    data() {
        return {
            SCROLL_MARGIN: 16,
        };
    },
    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
            };
        },
        tableOfContents() {
            const body = JSON.parse(this.body);
            const toc = body.blocks.find((block) => block.type === 'tableofcontentstool');

            if (!toc) return null;

            return body.blocks
                .filter(
                    (block) => block.type === 'header' && toc.data.levels.includes(block.data.level),
                )
                .map((block) => ({
                    text: block.data.text,
                    level: block.data.level,
                    anchor: `#${block.id}`,
                }));
        },
    },
    mounted() {
        const hash = this.$route.hash;

        if (hash) {
            this.scroll(hash);
        }
    },
    methods: {
        scroll(hash) {
            if (!('scrollBehavior' in document.documentElement.style)) return;

            const el = document.querySelector(hash);
            if (!el) return;

            window.scroll({
                top: el.getBoundingClientRect().top + window.scrollY - this.SCROLL_MARGIN,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpc-post-head {
    position: relative;
    .wrapper {
        position: relative;
        padding: 24px 0 56px;
        width: 100%;
        @media (--viewport-tablet) {
            padding: 16px 0 56px;
        }
        @media (--viewport-desktop) {
            padding: 24px 0 56px;
            width: 760px;
            margin: 0 auto;
        }
        &:before {
            content: '';
            display: none;
            position: absolute;
            width: 1px;
            background: var(--av-nav-secondary);
            top: 24px;
            bottom: 0;
            inset-inline-start: -40px;
            @media (--viewport-desktop) {
                display: block;
            }
        }
    }
    .head-wrapper {
        @mixin paragraph-accent;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 8px;
        gap: 8px 16px;
        @media (--viewport-tablet) {
            flex-flow: row wrap;
            align-items: center;
        }
        .meta {
            display: inline-block;
            text-align: center;
            color: var(--av-link-inversed-active);

            @media (--viewport-tablet) {
                text-align: start;
            }
        }
        .category {
            @mixin note-heading;
            display: inline-block;
            font-weight: 700;
            padding: 0 8px;
            color: var(--av-inversed-primary);
            border-radius: 4px;
            background: var(--av-nav-secondary);
            text-decoration: none;
        }
    }

    .title {
        @mixin hero-accent;
        color: var(--av-inversed-primary);
        text-align: center;
        margin-bottom: 24px;
        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    .socials {
        @mixin body-accent;
        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 0 auto 24px;
        align-items: center;
        @media (--viewport-tablet) {
            justify-content: start;
            text-align: start;
        }
        &:deep(.a-social__link) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .social-label {
        font-size: 11px;
        line-height: 16px;
        white-space: nowrap;
        margin-inline-end: 16px;
        display: inline-block;
        color: var(--av-inversed-primary);
        opacity: 0.9;
    }
}
</style>
