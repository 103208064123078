<template>
    <s-basic-slice :id="id" class="s-purchasing-v1" :class="{ ...className, ...basicClassName }">
        <a-slice-header :title="pageTitle" :lead="pageLead" :body-text="pageBodyText" />
        <div
            v-if="!loaded"
            v-loading="true"
            class="loader"
            el-loading-size="48"
            el-loading-color="brand-primary"
        />
        <template v-else>
            <div v-if="message.description || message.text" class="message">
                <div class="description">
                    <a-glyph v-if="message.glyph" :name="message.glyph" size="m" />
                    <div v-if="message.description" v-html="message.description" />
                </div>
                <p v-if="message.text" class="text" v-html="message.text" />
            </div>
            <div class="container" :class="`items-${products.items?.length}`">
                <div class="product">
                    <div
                        v-for="(product, index) in products.items"
                        :key="`product-${index}`"
                        :class="['license', { 'license-active': index === products.selected }]"
                        @click="products.selected = index"
                    >
                        <div class="description">
                            <p class="name">
                                {{ product.name }}
                            </p>
                            <div class="headline-container" :style="{ height: heightHeadline || 'auto' }">
                                <p v-if="product.headline" class="headline">
                                    {{ product.headline }}
                                </p>
                                <a-label v-if="products.label && index === pageProductCards.selected" size="s">
                                    {{ products.label }}
                                </a-label>
                            </div>
                            <p class="type">
                                {{ product.type }}
                            </p>
                            <div class="price-container">
                                <div class="cost">
                                    <div v-if="promo.isPromoActive">
                                        <span
                                            class="price-promo"
                                            v-html="productPriceFull(index)"
                                        /><span
                                            class="label-promo"
                                            v-html="productPriceLabel(index)"
                                        />
                                    </div>
                                    <p>
                                        <span class="price" v-html="productPrice(index)" />
                                        <span class="period">
                                            {{ product.period }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="cta">
                            <div v-if="product.pc" class="product-pc">
                                <div v-if="product.pc.options">
                                    <el-select
                                        :name="product.name"
                                        :value="product.pc.selected"
                                        popper-class="el-select-dropdown__custom-blue"
                                        :hide-on-resize="isDesktop"
                                        @input="onChange(index, 'pc', $event)"
                                    >
                                        <el-option
                                            v-for="(value, key) in product.pc.options"
                                            :key="`pc-${key}`"
                                            :value="key"
                                            :label="value"
                                            icon="icon-pc"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div class="product-cloud">
                                <template v-if="product.cloud">
                                    <div v-if="product.cloud.options">
                                        <el-select
                                            :name="product.name"
                                            :value="product.cloud.selected"
                                            :hide-on-resize="isDesktop"
                                            popper-class="el-select-dropdown__custom-blue"
                                            @input="onChange(index, 'cloud', $event)"
                                        >
                                            <el-option
                                                v-for="(value, key) in product.cloud.options"
                                                :key="`cloud-${key}`"
                                                :value="key"
                                                :label="value"
                                                icon="icon-cloud"
                                            />
                                        </el-select>
                                    </div>
                                    <div v-if="product.cloud.info">
                                        <el-select
                                            :disabled="true"
                                            :value="1"
                                            :hide-on-resize="isDesktop"
                                        >
                                            <el-option :label="product.cloud.info.text" :value="1" icon="icon-cloud" />
                                        </el-select>
                                    </div>
                                </template>
                            </div>
                            <div class="product-button">
                                <a-button
                                    v-if="product.button && product.button.text"
                                    :type="index === products.selected ? 'action' : 'main'"
                                    v-bind="product.button"
                                    :event="{ doNotSendGA: true }"
                                    @click="proceedToCheckout(index)"
                                />
                            </div>
                        </div>
                        <ul class="features">
                            <li v-for="(item, key) in product.features" :key="`features-${key}`" :class="classNameItem('features', item)">
                                <a-glyph v-if="item.glyph" :name="item.glyph" />
                                <span v-html="item.text ? item.text : item" />
                            </li>
                        </ul>
                        <ul class="benefits">
                            <li v-for="(item, key) in product.benefits" :key="`benefits-${key}`" :class="classNameItem('benefits', item)">
                                <a-glyph v-if="item.glyph" :name="item.glyph" />
                                <span v-html="item.text ? item.text : item" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="products.information" class="info">
                    <div v-for="(item, index) in products.information" :key="`info-${index}`" class="info-item">
                        <p v-for="(value, valueIndex) in item" :key="`info-item-${valueIndex}`" v-html="value" />
                    </div>
                </div>
                <div v-if="products.note" class="note">
                    <p v-html="products.note" />
                </div>
            </div>
        </template>
        <a-loader :is-visible="isVisibleSURL" />
    </s-basic-slice>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALoader from '@core/components/loader/loader.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import mixin from '../mixin.js';

export default {
    name: 'SPurchasingCyberProtectHomeOfficeV1',
    components: {
        AButton,
        AGlyph,
        ALabel,
        ALoader,
        ASliceHeader,
        SBasicSlice,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    directives: {
        Loading,
    },
    mixins: [mixin, breakpoint, contentMixin, styleMixin, purchasing, currency, analytics, cleverbridge],
    data() {
        return {
            cta: {},
            promo: {},
            coupon: '',
            products: {},
            loaded: false,
            heightHeadline: null,
            STORE_SLICE_NAME: 's-purchasing-cyber-protect-home-office',
        };
    },
    updated() {
        this.resize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        resize() {
            const heightDefault = 24;
            const elements = [...document.querySelectorAll('.headline')];
            if (!elements.length) {
                this.heightHeadline = this.products.label ? `${heightDefault}px` : null;
                return;
            }
            const active = this.pageProductCards.selected;
            const itemHeight = (item, i) => item.clientHeight + (i === active ? heightDefault : 0);
            this.heightHeadline = `${Math.max(...elements.map(itemHeight))}px`;
        },
        productPrice(index) {
            const license = this.findLicense(index);
            return license.price ? this.setCurrency(license.price) : '???';
        },
        productPriceFull(index) {
            const license = this.findLicense(index);
            if (!license.discount_absolute) return '';
            const result = license.price + license.discount_absolute;
            return result ? this.setCurrency(result, { withoutCurrencySymbol: true }) : '???';
        },
    },
};
</script>

<style lang="postcss">
.s-purchasing-v1 {
    .loader {
        z-index: 1;
        height: 400px;
        position: relative;
    }
    .a-slice-header {
        margin: 0;
        width: 100%;

        &__title {
            margin: 0;
            @mixin display;
        }
        &__lead {
            @mixin title;
            margin: 32px 0 0;
        }
    }
    .container {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
    }
    .message {
        margin: 8px 0 0;

        .description {
            @mixin paragraph;
            gap: 8px;
            width: 100%;
            display: flex;
            font-weight: 600;
            color: var(--av-fixed-success);

            .a-glyph {
                min-width: 24px;
                fill: var(--av-fixed-success);
            }
        }
        .text {
            @mixin descriptor;
            font-weight: 600;
            color: var(--av-nav-primary);
        }
    }
    .product {
        display: block;
        overflow: hidden;
        border-radius: 4px;
        background-color: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-light);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    }
    .license {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 32px;
        &:not(:first-child) {
            border-top: 1px solid var(--av-brand-light);
        }
    }
    .name {
        @mixin title-accent;
        color: var(--av-nav-primary);

        .a-glyph {
            margin-inline-start: 16px;
            vertical-align: baseline;
        }
    }
    .headline {
        @mixin lead;
        margin-top: 4px;
        color: var(--av-nav-primary);
    }
    .type {
        @mixin lead-accent;
        margin-top: 16px;
        color: var(--av-nav-primary);
    }
    .price-container {
        padding-top: 16px;
        position: relative;
        color: var(--av-nav-primary);
    }
    .cost {
        position: relative;
    }
    .price {
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;

        sup {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            vertical-align: top;
        }
    }
    .price-promo {
        color: var(--av-nav-primary);
        text-decoration-line: line-through;
    }
    .label-promo {
        margin: 0 8px;
        font-weight: bold;
        color: var(--av-fixed-success);
    }
    .period {
        @mixin paragraph-accent;
        margin: 0 8px;
        vertical-align: top;
    }
    .features {
        @mixin caption;
        width: 100%;
        padding-top: 32px;
        color: var(--av-fixed-light);
    }
    .features-item {
        position: relative;
        padding-inline-start: 20px;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
    .features-bullet {
        &:before {
            top: 6px;
            content: "";
            width: 4px;
            height: 4px;
            display: block;
            border-radius: 50%;
            position: absolute;
            inset-inline-start: 8px;
            background: var(--av-fixed-light);
        }
    }
    .features-title {
        @mixin lead-accent;
        padding: 0;
    }
    .features-text-blue {
        @mixin caption-accent;
        color: var(--av-brand-primary);
    }
    .features-glyph {
        padding: 0;
        display: flex;

        .a-glyph {
            min-width: 16px;
            margin-inline-end: 8px;
            fill: var(--av-fixed-light);
        }
    }
    .features-glyph-blue {
        .a-glyph {
            opacity: 1;
            fill: var(--av-brand-primary);
        }
    }
    .features-plus {
        font-size: 24px;
        text-align: left;
        color: var(--av-fixed-light);
    }
    .benefits {
        width: 100%;
        margin: auto 0 0;
        padding-top: 32px;
    }
    .benefits-item {
        @mixin caption;
        padding-inline-start: 24px;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }
    .benefits-text-blue {
        @mixin caption-accent;
        color: var(--av-brand-primary);
    }
    .benefits-glyph {
        display: flex;

        .a-glyph {
            margin: 0;
            margin-inline-end: 8px;
            margin-inline-start: -24px;
            fill: var(--av-fixed-light);
        }
    }
    .benefits-glyph-blue {
        .a-glyph {
            fill: var(--av-brand-primary);
        }
    }
    .product-pc {
        margin: 16px 0 8px;

        .el-select {
            .el-input {
                &__wrapper {
                    &:before {
                        mask: url('/public/assets/images/pc.svg') no-repeat 0 0;
                        background-color: var(--av-brand-primary);
                    }
                }
            }
        }
    }
    .product-cloud {
        .el-select {
            .el-input {
                &__wrapper {
                    &:before {
                        mask: url('/public/assets/images/cloud.svg') no-repeat 0 0;
                        background-color: var(--av-brand-primary);
                    }
                }
            }
        }
    }
    .product-button {
        margin-top: 16px;
        .a-button {
            width: 100%;
            justify-content: space-between;
        }
        .a-button_type_main {
            height: 48px;
            color: var(--av-brand-primary);
            background: var(--av-brand-accent);
            border: 1px solid var(--av-brand-primary);

            .a-button__glyph {
                fill: var(--av-brand-primary);
            }
        }
        .a-glyph {
            transition: none;
            transform: scaleX(-1);
        }
    }
    .license-active {
        background: var(--av-solid-brand-lightest);
    }
    .info-item {
        @mixin paragraph;
        margin: 40px 0 0;
        padding-inline-start: 8px;

        b {
            font-weight: 600;
        }
        a {
            font-weight: 600;
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
    }
    .note {
        margin: 32px 0 0;

        a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
    }
    .el-select {
        .i--size-16::before {
            color: var(--av-brand-secondary);
        }
        .el-input__container {
            background: var(--av-inversed-primary);
            border: 1px solid var(--av-brand-light);
        }
        .el-input__wrapper {
            position: relative;
            &:before {
                content: '';
                top: 16px;
                inset-inline-start: 8px;
                width: 16px;
                height: 16px;
                position: absolute;
            }
        }
        .el-input__editor {
            @mixin paragraph-accent;
            color: var(--av-brand-primary);
            padding-inline-start: 32px;
        }
        .is-focus {
            .el-input__container {
                border-color: var(--av-brand-primary);
            }
        }
        .is-disabled {
            .el-input__editor {
                font-weight: 500;
                color: var(--av-fixed-lighter);
            }
            .el-input__wrapper:before {
                background-color: var(--av-brand-light);
            }
            .el-input__container {
                border-color: var(--av-brand-lightest);
                background-color: var(--av-brand-lightest);
            }
        }
    }
}
.el-select-dropdown__custom-blue {
    .el-select-dropdown__wrap {
        margin-inline-start: 0 !important;
    }
    .el-select-dropdown__item {
        @mixin paragraph-accent;
        color: var(--av-brand-primary);

        &.selected {
            @mixin paragraph-accent;
            color: var(--av-brand-primary);
        }
        .i--size-16 {
            margin-inline-end: 8px;

            &:before {
                display: none;
            }
        }
        .i-icon-pc--16,
        .i-icon-cloud--16 {
            width: 16px;
            height: 16px;
            margin-inline-end: 8px;
        }
        .i-icon-pc--16 {
            mask: url('/public/assets/images/pc.svg') no-repeat 0 0;
            background-color: var(--av-brand-primary);
        }
        .i-icon-cloud--16 {
            mask: url('/public/assets/images/cloud.svg') no-repeat 0 0;
            background-color: var(--av-brand-primary);
        }
    }
}
@media (--viewport-tablet) {
    .s-purchasing-v1 {
        .license {
            padding: 32px 40px;
            flex-direction: row;
        }
        .description {
            width: 50%;
            margin-inline-end: auto;
        }
        .cta {
            min-width: auto;
        }
        .product-pc {
            margin-top: 0;
        }
    }
}
@media (--viewport-desktop) {
    .s-purchasing-v1 {
        .a-slice-header {
            &__title {
                @mixin display;
            }
        }
        .product {
            display: flex;
            flex-wrap: nowrap;
        }
        .license {
            flex: 1 1 0;
            padding: 32px;
            flex-direction: column;
            &:not(:first-child) {
                border-top: none;
                border-inline-start: 1px solid var(--av-brand-light);
            }
        }
        .description {
            width: 100%;
        }
        .features-plus {
            text-align: center;
        }
        .cta {
            margin: 0;
        }
        .product-pc {
            margin-top: 16px;
        }
        .items-2 {
            .license,
            .info-item {
                width: 50%;
            }
        }
        .items-3 {
            .license,
            .info-item {
                width: 33.33%;
            }
        }
        .info {
            display: flex;
            flex-direction: row;
        }
    }
}
@media (--viewport-desktop-wide) {
    .s-purchasing-v1 {
        .items-2 {
            display: flex;
            flex-direction: row;

            .product {
                width: 66.66%;
            }
            .note {
                margin: 0;
                width: 33.33%;
                padding-inline-start: 32px;
            }
        }
    }
}
</style>
