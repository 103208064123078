/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.suggestion-link {
    font-size: 16px;
    line-height: 24px;
}

.filters-accordion:deep(.title):active {
            color: var(--av-nav-primary);
        }

.filters-accordion:deep(.title) {
        padding: 0;
        display: flex;
        align-items: center;
}

.filters-accordion:deep(.a-accordion-item) {
        border-top: none;
    }

.filters-accordion:deep(.a-accordion-item).active {
            margin-bottom: 20px;
        }

.filters-accordion:deep(.a-accordion-item):last-of-type {
            margin-bottom: 0;
        }

.filters-accordion:deep(.a-accordion__icon) {
        height: 16px;
        width: 16px;
    }

.filters-accordion:deep(.a-accordion-item.active .a-accordion__icon) {
        transform: rotate(180deg);
    }

.filters-accordion:deep(.content-wrapper) {
        padding: 0 4px;
    }

.product-group {
    display: flex;
    align-items: center;
}

.product-group .el-radio {
        margin-inline-end: 8px;
    }
