<template>
    <s-basic-slice
        :id="id"
        class="s-numbers"
        :class="{ ...className, ...basicClassName }"
        v-bind="l10n"
    >
        <ul class="s-numbers__list">
            <li
                v-for="item in l10n.numbers"
                :key="item.title"
                class="s-numbers__item"
                :class="{ 's-numbers__item_type_reversed': item.reversed }"
                title=""
            >
                <div class="s-numbers__item-value">
                    {{ item.value }}
                </div>
                <div class="s-numbers__item-title">
                    {{ item.title }}
                </div>
                <div v-if="item.desc" class="s-numbers__item-desc">
                    {{ item.desc }}
                </div>
            </li>
        </ul>
        <a-link
            v-if="!centered && l10n.link"
            class="s-numbers__link"
            v-bind="l10n.link"
            :type="theme === 'light' ? 'regular' : 'light'"
            :size="l10n.link.size || 'title'"
            :accent="true"
            :glyph="l10n.link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SNumbers',

    components: {
        SBasicSlice,
        ALink,
    },

    mixins: [l10n, contentMixin, styleMixin],

    props: {
        numbers: {
            type: Array,
            required: true,
        },

        theme: {
            type: String,
            default: 'light',
        },

        centered: {
            type: Boolean,
            default: false,
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    computed: {
        className() {
            return {
                's-numbers_theme_dark': this.theme === 'dark',
                's-numbers_layout_centered': this.centered,
                's-numbers_layout_4': this.numbers.length % 2 === 0,
                's-numbers_layout_3': this.numbers.length % 3 === 0,
                's-numbers_layout_with-desc': this.numbers[0].desc,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-numbers {
    overflow: hidden;

    &__link {
        margin-top: 48px;
        @media (--viewport-desktop) {
            position: absolute;
            top: 6px;
            inset-inline-end: 36px;
            max-width: 25%;
            margin-top: 0;
        }
        @media (--viewport-desktop-wide) {
            inset-inline-end: 74px;
        }
        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }

    &:deep(.a-slice-header) {
        @media (--viewport-desktop) {
            @mixin colls 9;
        }
        @media (--viewport-desktop-large) {
            @mixin colls 8;
        }

        &__lead {
            @media (--viewport-desktop) {
                max-width: 100%;
            }
        }
    }

    &__list {
        @media (--viewport-mobile-wide) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        @media (--viewport-mobile-wide) {
            width: 50%;
        }

        &-value {
            margin-bottom: 8px;
            font-size: 32px;
            line-height: 1.25;
            font-weight: 700;
            color: var(--av-brand-secondary);
        }

        &-title {
            @mixin lead-accent;

            color: var(--av-nav-primary);
        }

        &_type_reversed {
            .s-numbers__item {
                &-title {
                    order: 1;
                }

                &-value {
                    margin-bottom: 0;
                    margin-top: 8px;
                    order: 2;
                }

                &-desc {
                    order: 3;
                }
            }
        }
    }

    &_layout {
        &_4 {
            .s-numbers {
                &__list {
                    margin-bottom: -40px;
                }

                &__item {
                    margin-bottom: 40px;
                }
            }
        }

        &_3 {
            .s-numbers {
                &__list {
                    margin-bottom: -48px;
                }

                &__item {
                    margin-bottom: 48px;
                }
            }
        }

        &_with-desc {
            .s-numbers {
                &__list {
                    margin-bottom: -40px;
                }

                &__item {
                    margin-bottom: 40px;
                }
            }
        }

        &_centered {
            &:deep(.a-slice-header) {
                text-align: center;
                margin: 0 auto 48px;
            }
            .s-numbers {
                &__list {
                   text-align: center;
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &_layout {
            &_4 {
                .s-numbers {
                    &__list {
                        margin-bottom: -56px;
                        margin-inline-end: -16px;
                    }

                    &__item {
                        margin-bottom: 56px;
                        padding-inline-end: 32px;
                    }
                }
            }

            &_3 {
                .s-numbers {
                    &__list {
                        margin-bottom: -48px;
                        margin-inline-end: -16px;
                    }

                    &__item {
                        margin-bottom: 48px;
                        padding-inline-end: 32px;
                    }

                    &__link {
                        margin-top: 56px;
                    }
                }
            }

            &_with-desc {
                .s-numbers {
                    &__list {
                        margin-bottom: -40px;
                        margin-inline-end: -48px;
                    }

                    &__item {
                        margin-bottom: 40px;
                        padding-inline-end: 48px;
                    }

                    &__link {
                        margin-top: 48px;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &_layout {
            &_4 {
                .s-numbers {
                    &__list {
                        margin-bottom: -56px;
                    }

                    &__item {
                        width: 25%;
                    }
                }
            }

            &_3 {
                .s-numbers {
                    &__list {
                        margin-bottom: -48px;
                    }

                    &__item {
                        width: 33.3%;
                    }

                    &__link {
                        margin-top: 0;
                    }
                }
            }

            &_with-desc {
                .s-numbers {
                    &__list {
                        margin-bottom: -40px;
                    }

                    &__link {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &_theme {
        &_dark {
            color: var(--av-inversed-primary);
            background: var(--av-nav-primary);
            &:deep(.a-slice-header) {
                &__title, &__lead {
                    color: var(--av-inversed-primary);
                }
            }
            .s-numbers__item {
                &-title, &-desc {
                    color: var(--av-inversed-primary);
                }
                &-value {
                    background: linear-gradient(270deg, #0368DF 0%, #6DE4FC 100%);
                    background-clip: text;
                    color: transparent;
                }
            }
        }
    }
}
</style>
