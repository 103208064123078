<template>
    <div v-if="addonList.length" :class="['order-addons', `is-page-${settings.mode}`]">
        <div class="list">
            <div v-for="(addon, addonIndex) in addonList" :key="`addon-${addonIndex}`" class="addon">
                <template v-if="settings.mode === 'addons'">
                    <div class="info">
                        <p class="name" v-html="titleAddon(addon)" />
                        <template v-if="addon.product === 'cloud-storage'">
                            <p v-if="addon.period">
                                {{ translation('License period:') }} <b> {{ titlePeriod(addon.period, 'short') }}</b>
                            </p>
                            <p v-if="addon.cloud">
                                {{ translation('Cloud:') }} <b>{{ titleCloud(addon.cloud) }}</b>
                            </p>
                            <p v-if="valueExtension">
                                {{ translation('Physical data shipping') }}
                            </p>
                        </template>
                        <template v-else-if="addon.product === 'disaster-recovery'">
                            <p v-if="addon.size">
                                {{ translation('Instance:') }} <b>{{ titleInstance(addon.size) }}</b>
                            </p>
                        </template>
                        <template v-else-if="addon.product === 'onboarding-service'">
                            <p v-if="addon.quantity">
                                {{ translation('Quantity:') }} <b>{{ addon.quantity }}</b>
                            </p>
                        </template>
                    </div>
                    <div class="prices">
                        <p v-if="addon.store.discount_absolute" class="price" v-html="setCurrency(priceFull(addon))" />
                        <p class="price-current" v-html="setCurrency(priceCurrent(addon))" />
                    </div>
                </template>
                <template v-else>
                    <div class="info">
                        <div class="product">
                            <div class="name" v-html="titleAddon(addon)" />
                            <a-button v-bind="button.removeProduct" @click="removeProduct(addon)" />
                        </div>
                        <div class="product-info-wrapper">
                            <div class="product-info">
                                <template v-if="addon.product === 'cloud-storage'">
                                    <span v-if="addon.cloud" v-html="titleCloud(addon.cloud)" />
                                    <span v-if="addon.period" v-html="`- ${titlePeriod(addon.period, periodType)}`" />
                                    <p v-if="valueExtension">
                                        {{ translation('Physical data shipping') }}
                                    </p>
                                </template>
                                <template v-else-if="addon.product === 'disaster-recovery'">
                                    <p v-if="addon.size">
                                        {{ translation('Instance:') }} <b>{{ titleInstance(addon.size) }}</b>
                                    </p>
                                </template>
                                <template v-else-if="addon.product === 'onboarding-service'">
                                    <p v-if="addon.quantity">
                                        {{ translation('Quantity:') }} <b>{{ addon.quantity }}</b>
                                    </p>
                                </template>
                            </div>
                            <div class="product-price" v-html="setCurrency(addon.store.price * addon.quantity)" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SOrderAddon',

    components: {
        AButton,
    },

    mixins: [mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleProduct'],

    data: () => ({
        button: {
            removeProduct: {
                text: '',
                glyph: 'delete',
                event: { doNotSendGA: true },
            },
        },
    }),

    computed: {
        addonList() {
            return this.settings.products
                .map((edition) => this.addedLicenses.filter((item) => !item.workload).find((item) => item.product === edition.product))
                .filter(Boolean);
        },
        periodType() {
            return this.settings.version === 3 ? 'long' : 'short';
        },
    },

    created() {
        this.button.removeProduct.text = this.settings.dictionary?.Remove || '';
    },

    methods: {
        titleAddon(addon) {
            return this.settings.products.find((item) => item.product === addon.product)?.title || '';
        },

        titleInstance(name) {
            const result = name.charAt(0).toUpperCase() + name.slice(1);
            return result.replace('-', ' ');
        },

        priceFull(addon) {
            const price = addon.store?.price || 0;
            const discount = addon.store?.discount_absolute || 0;
            return (price + discount) * addon.quantity;
        },

        priceCurrent(addon) {
            const price = addon.store?.price || 0;
            return price * addon.quantity;
        },
        removeProduct(addon) {
            const product = this.settings.products.find((item) => item.product === addon.product);
            this.$emit('toggleProduct', product);
        },
    },
};
</script>

<style lang="postcss" scoped>
.is-page-expert {
    .list {
        display: flex;
        flex-wrap: wrap;
    }
    .addon {
        @mixin body;
        width: 100%;
        margin-bottom: 16px;
        padding-bottom: 16px;
        padding-inline-end: 8px;
        color: var(--av-nav-primary);
        border-bottom: 1px solid var(--av-brand-secondary-light);

        .product {
            display: flex;
            justify-content: space-between;
            .name {
                @mixin paragraph-accent;
            }
            &:deep(.a-button) {
                gap: 8px;
                padding: 0;
                border: none;
                box-shadow: none;
                background: none;
                .a-button__content {
                    @mixin descriptor;
                    font-weight: 400;
                    color: var(--av-brand-primary);
                }
                .a-glyph {
                    margin: 0;
                    fill: var(--av-brand-primary);
                }
            }
        }

        .product-info-wrapper {
            display: flex;
            margin-top: 8px;
            justify-content: space-between;
            .product-info {
                width: 100%;
            }
            .product-price {
                margin-inline-end: 24px;
            }
        }
    }
}

.is-page-addons {
    .list {
        display: flex;
        flex-direction: column;
    }

    .addon {
        @mixin paragraph;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin: 24px 0 0;
        padding: 24px 0 0;
        color: var(--av-nav-primary);
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-secondary-light);

        @media (--viewport-tablet) {
            flex-direction: column;
            &:nth-child(even) {
                padding-inline-start: 16px;
                border-inline-start: 1px solid var(--av-brand-secondary-light);
            }
        }

        @media (--viewport-desktop) {
            flex-direction: row;
        }

        &:nth-child(even) {
            padding-inline-start: 0;
            border-inline-start: none;
        }

        .name {
            @mixin paragraph-accent;
            font-weight: 700;
            margin-bottom: 8px;
            color: var(--av-nav-primary);
        }

        .prices {
            text-align: end;

            @media (--viewport-tablet) {
                margin-top: 8px;
            }

            @media (--viewport-desktop) {
                margin-top: 0;
            }
        }

        .price {
            @mixin paragraph;
            color: var(--av-fixed-light);
            text-decoration: line-through;
        }

        .price-current {
            @mixin lead-accent;
            color: var(--av-nav-primary);
        }
    }
}
</style>
