<template>
    <div class="related-posts" :style="style">
        <div class="title">
            {{ title }}
        </div>
        <div class="posts">
            <div v-for="post in posts" :key="post.slug" class="post">
                <blog-date-localized class="date" :date="post.published_at" />
                <a-link :to="post.link" :text="post.title" />
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import BlogDateLocalized from '../shared-components/blog-date-localized/blog-date-localized.vue';

export default {
    components: {
        ALink,
        BlogDateLocalized,
    },
    props: {
        title: {
            type: String,
            default: () => 'Similar articles',
        },
        posts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            style: {
                top: 0,
            },
        };
    },
    mounted() {
        window.addEventListener('scroll', this.calculateStyle);
        window.addEventListener('resize', this.calculateStyle);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.calculateStyle);
        window.removeEventListener('resize', this.calculateStyle);
    },
    methods: {
        calculateStyle() {
            if (process.env.VUE_ENV === 'server') return;

            const hasStickyElement = Boolean(document.querySelector('.is-sticky'));
            this.style = {
                top: hasStickyElement ? '64px' : 0,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.related-posts {
    position: sticky;
    top: 0;
    margin-top: 16px;
    padding-top: 24px;
}

.title {
    @mixin paragraph-accent;
    color: var(--av-nav-primary);
    margin-bottom: 8px;
}

.posts {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.post {
    border-radius: 4px;
    padding: 8px 8px 16px;
    background: var(--av-brand-secondary-accent);
}

.date {
    @mixin caption;
    display: block;
    color: var(--av-fixed-light);
}

.a-link {
    margin-top: 8px;
    color: var(--av-brand-primary);

    &:deep(.a-link__content) {
        @mixin caption-accent;
    }
}
</style>
