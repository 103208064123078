<template>
    <div v-if="reviewList?.length" class="s-g2-reviews">
        <div class="header-wrapper">
            <div class="reviews-header">
                <a-glyph name="g2-logo" />
                <div class="title">
                    {{ title }}
                </div>
            </div>
            <div class="rating-wrapper">
                <div class="reviews-stars">
                    <g2-review-star
                        v-for="index in maxRating"
                        :key="index"
                        :star-id="`g2-total-star-${index}`"
                        :fill-percentage="getFillPercentage(index)"
                        :data-fill="getFillPercentage(index)"
                    />
                </div>
                <div class="total-rating">
                    {{ totalRating }} {{ outOfText }} 5
                </div>
            </div>
            <a-link
                v-if="isWideDesktop && showAllReviewsLink"
                class="reviews-link"
                :to="reviewsUrl"
                :text="linkText"
                glyph="arrow"
            />
        </div>
        <a-slider
            v-if="reviewList.length > 1 && renderSlider"
            class="reviews-wrapper"
            :options="sliderSettings"
        >
            <a-slide v-for="(review, i) in reviewList" :key="i">
                <g2-review
                    :review="review"
                    :product-name="g2ProductName"
                    :cta-text="showFullReviewLinks ? ctaText : null"
                />
            </a-slide>
        </a-slider>
        <g2-review
            v-else-if="reviewList.length === 1"
            :review="reviewList[0]"
            :product-name="g2ProductName"
            :cta-text="showFullReviewLinks ? ctaText : null"
        />
        <a-link
            v-if="!isWideDesktop && showAllReviewsLink"
            class="reviews-link"
            :to="reviewsUrl"
            :text="linkText"
            glyph="arrow"
        />
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import G2ReviewStar from './components/g2-review-star.vue';
import G2Review from './components/g2-review.vue';

export default {
    name: 'SG2Reviews',

    components: {
        ALink,
        AGlyph,
        ASlide,
        ASlider,
        G2ReviewStar,
        G2Review,
    },

    mixins: [breakpoint],

    props: {
        g2ProductId: {
            type: String,
            required: true,
        },
        g2ProductName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: 'G2 reviews',
        },
        linkText: {
            type: String,
            default: 'Read all reviews',
        },
        ctaText: {
            type: String,
            default: 'Read the full review',
        },
        outOfText: {
            type: String,
            default: 'out of',
        },
        showAllReviewsLink: {
            type: Boolean,
            default: true,
        },
        showFullReviewLinks: {
            type: Boolean,
            default: true,
        },
        sliderOptions: {
            type: Object,
            default: () => ({
                showNavigation: true,
            }),
        },
        reviewIds: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        maxRating: 5,
        ssrRating: null,
        ssrReviews: null,
        renderSlider: true,
    }),

    computed: {
        isWideDesktop() {
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },

        totalRating() {
            return this.ssrRating || this.$store.state.products.g2rating;
        },

        reviewList() {
            const res = this.ssrReviews || this.$store.state.products.g2reviews;

            if (!this.reviewIds.length) return res;

            return res
                .filter((review) => this.reviewIds.includes(Number(review.id)))
                .filter(Boolean);
        },

        reviewsUrl() {
            return `https://www.g2.com/products/${this.g2ProductName}/reviews`;
        },

        sliderSettings() {
            return {
                showNavigation: true,
                showPagination: false,
                showCounter: this.sliderOptions.showNavigation,
                breakpoints: {
                    768: {
                        width: 524,
                    },
                    1024: {
                        width: 636,
                    },
                    1280: {
                        width: 674,
                    },
                    1440: {
                        width: 760,
                    },
                },
            };
        },
    },

    async serverPrefetch() {
        this.ssrRating = await this.$store.dispatch('products/getG2ProductRating', this.g2ProductId);
        this.ssrReviews = await this.$store.dispatch('products/getG2ProductReviews', this.g2ProductId);
    },

    async mounted() {
        if (!this.totalRating || !this.reviewList?.length) {
            await Promise.all([
                this.$store.dispatch('products/getG2ProductRating', this.g2ProductId),
                this.$store.dispatch('products/getG2ProductReviews', this.g2ProductId),
            ]);
        }

        // hack for correct controls disabling after hydration, doesn't work for storybook
        if (!this.$root.STORYBOOK_VALUES) {
            this.renderSlider = false;
            await this.$nextTick();
            this.renderSlider = true;
        }
    },

    methods: {
        getFillPercentage(starIndex) {
            const rating = this.totalRating;
            if (rating >= starIndex) return 100;
            if (starIndex - rating >= 1) return 0;
            return Math.ceil((rating - Math.floor(rating)) * 100);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-g2-reviews {
    width: 100%;
    padding: 64px 16px 80px;

    @media (--viewport-mobile-wide) {
        padding: 64px 32px 80px;
    }

    @media (--viewport-desktop) {
        padding: 88px 32px;
    }

    @media (--viewport-desktop-wide) {
        padding: 88px 64px;
    }

    @media (--viewport-desktop-large) {
        max-width: 1312px;
        margin: 0 auto;
        padding-inline-start: 0;
        padding-inline-end: 0;
    }
}

.reviews-header {
    display: flex;
    align-items: center;
    gap: 24px;

    .a-glyph {
        width: 48px;
        height: 48px;
    }

    .title {
        color: var(--av-nav-primary);
        @mixin display;

        @media (--viewport-mobile-wide) {
            @mixin hero;
        }
    }
}

.reviews-stars {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 32px;
}

.total-rating {
    color: var(--av-nav-primary);
    @mixin lead;
    font-weight: 600;
    margin-top: 16px;

    @media (--viewport-mobile-wide) {
        @mixin title;
        font-weight: 400;
        margin-top: 0;
    }
}

.reviews-wrapper {
    margin-top: 48px;

    &:deep(.a-slide_active) {
        @media (--viewport-tablet) {
            margin-inline-end: 76px;
        }

        @media (--viewport-desktop) {
            margin-inline-end: 96px;
        }

        @media (--viewport-desktop-wide) {
            margin-inline-end: 112px;
        }

        @media (--viewport-desktop-large) {
            margin-inline-end: 126px;
        }
    }

    &:deep(.a-slider__dots) {
        color: var(--av-fixed-light);
        @mixin lead;
    }

    &:deep(.a-slide) {
        &_next {
            opacity: 0.5;
            position: relative;
        }
        &.swiper-slide-prev {
            opacity: 0;
        }
    }
}

.reviews-link {
    margin-top: 48px;

    @media (--viewport-desktop-wide) {
        position: absolute;
        inset-inline-end: 0;
        margin-top: 0;
    }

    &:deep(.a-link__content) {
        display: flex;
        align-items: baseline;
        gap: 4px;
        color: var(--av-brand-secondary);
        @mixin title;
        font-weight: 600;
    }

    &:deep(.a-glyph) {
        transform: translateY(1px);
    }
}

.rating-wrapper {
    margin-top: 16px;

    @media (--viewport-mobile-wide) {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 24px;

        .reviews-stars {
            width: auto;
        }
    }

    @media (--viewport-tablet) {
        margin-top: 0;
    }
}

.header-wrapper {
    position: relative;

    @media (--viewport-tablet) {
        display: flex;
        align-items: center;
        gap: 24px;
    }
}
</style>
