<template>
    <section v-if="content" class="landing-page-info">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <p v-if="content.title" class="title" v-html="content.title" />
                    <p v-if="content.description" class="description" v-html="content.description" />
                </div>
                <ul class="list">
                    <template v-for="(item, itemIndex) in content.list">
                        <li v-bind="item" :key="`item-${itemIndex}`" class="list-item">
                            <a-glyph v-if="item.glyph" size="l" :name="item.glyph" />
                            <span>{{ item.title }}</span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'SIntro',
    components: {
        AGlyph,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-info {
    padding: 32px 0 64px;
    background: #03041a;
    position: relative;
    @media (--viewport-tablet) {
        padding-top: 54px;
    }
    @media (--viewport-desktop) {
        padding-top: 32px;
    }
    @media (--viewport-desktop-wide) {
        padding: 44px 88px;
    }
    @media (--viewport-desktop-large) {
        padding-top: 36px;
    }
    &:after {
        content: "";
        width: 100%;
        height: 88px;
        bottom: -88px;
        position: absolute;
        border-radius: 100%;
        inset-inline-start: 0;
        background: radial-gradient(1985.49% 77.95% at 55.49% 0%, #ED91FF 38.54%, #8C0BF2 65.1%, #0B9FF2 100%);
        filter: blur(80px);
    }

    @media (--viewport-desktop-wide) {
        padding-top: 54px;
    }
    .wrapper {
        gap: 40px;
        z-index: 2;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
        @media (--viewport-desktop-large) {
            width: 1090px;
            margin: 0 auto;
        }
    }
    .content {
        width: 100%;
        text-align: center;
        @media (--viewport-desktop) {
            text-align: left;
        }
    }
    .title {
        @mixin display;
        @media (--viewport-tablet) {
            @mixin large-accent;
        }
        @media (--viewport-desktop) {
            font-size: 56px;
            line-height: 64px;
        }
    }
    .description {
        @mixin title;
        margin-top: 16px;
    }
    .list {
        gap: 8px;
        width: 100%;
        display: flex;
        margin: 12px;
        flex-direction: column;
        @media (--viewport-desktop) {
            max-width: 432px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 464px;
        }
        @media (--viewport-desktop-large) {
            max-width: 402px;
        }
    }
    .list-item {
        gap: 8px;
        color: #ccc;
        display: flex;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        min-height: 48px;
        background: #0C1034;
        border-radius: 12px;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.02);
    }
    .a-glyph {
        min-width: 32px;
    }
    &:deep(.gradient) {
        background: linear-gradient(180deg, #789CE4 0%, #2998F9 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
