.resource-center-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main-content {
        position: relative;
        flex: 1 0 auto;
        padding-top: 78px;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 760px;
            position: absolute;
            top: 72px;
            inset-inline-start: 0;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0) 100%);
        }

        @media (--viewport-desktop) {
            padding-top: 72px;
        }
    }

    .footer {
        flex-shrink: 0;
    }

    .s-main-header {
        @media (--viewport-desktop) {
            top: 20px;
        }
    }

    &--no-border .main-content {
        border: 0;
    }
}
