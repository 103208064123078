<template>
    <main class="page-container">
        <!-- <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" /> -->

        <s-ribbon-container
            :class="{ 'crit-hidden': isRibbonContainerHidden }"
            :ribbons="ribbons"
        />

        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>

        <template v-if="showPopup && isMounted">
            <s-popup-container :popups="popups" />
        </template>

        <s-unsupported-browsers />
    </main>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { merge } from 'lodash';
import Vue from 'vue';
import analytics from '@core/mixins/analytics.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SUnsupportedBrowsers from '@core/slices/pages/unsupported-browsers/unsupported-browsers.vue';
import SChat from '@core/slices/shared/chat/chat.vue';
import SPopupContainer from '@core/slices/shared/popup/popup-container.vue';
import SRibbonContainer from '@core/slices/shared/ribbon/ribbon-container.vue';
import { resolvePageBodySlices } from '@utils/resolve-nested-slices';

import '@core/styles/sections/pages.pcss';

const WEBSITE_ID_DEVELOPER = 3;

export default {
    name: 'PagesComponent',

    components: {
        SRibbonContainer,
        SPopupContainer,
        SChat,
        SUnsupportedBrowsers,
        SLocaleSelectorRibbon,
    },

    directives: {
        Loading,
    },

    mixins: [analytics, localeRibbon],

    computed: {
        isRibbonContainerHidden() {
            // TODO: original condition from main site component
            // return !this.isMounted || this.isLocaleSelectorShown || !this.showRibbon;
            return !this.isMounted || !this.showRibbon;
        },

        page() {
            return this.$store.state.pages.page;
        },
        slices() {
            const page = this.page.body || [];
            return page.map((item) => {
                const translations = item.data?.translations || {};
                const data = merge({}, item.data, translations);
                return { ...item, data };
            });
        },
        ribbons() {
            return this.$store.state.ribbon.data.filter((r) => r.websiteId === WEBSITE_ID_DEVELOPER);
        },
        showRibbon() {
            return Boolean(this.ribbons.length);
        },
        popups() {
            return this.$store.state.popup.data;
        },
        showPopup() {
            return Boolean(this.popups.length);
        },
    },

    beforeCreate() {
        resolvePageBodySlices(this.$store.state.pages.page.body).forEach((fullname) => {
            const sectionName = fullname.startsWith('s-developer') ? 'developer' : 'pages';
            const sliceName = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/${sectionName}/${sliceName}/${sliceName}.vue`));
        });
    },
};
</script>
<style lang="postcss" scoped>
.page {
    display: flex;
    flex-direction: column;

    > * {
        flex-shrink: 0;
    }
}
</style>
