<template>
    <section v-if="content" class="landing-page-video">
        <div class="a-container">
            <a-video-card
                is-embeded
                :yt-id="content.ytId"
                :image="content.image"
                :text="content.title"
            />
        </div>
    </section>
</template>

<script>
import AVideoCard from '@core/components/video-card/video-card.vue';

export default {
    name: 'SVideo',
    components: {
        AVideoCard,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-video {
    background: #03041a;
    .a-container {
        padding: 0;
        height: 100%;
    }
    &:deep(.a-video-card) {
        border: none;
        box-shadow: none;
        transition: none;
        .a-video-card__text {
            margin: 0;
            padding: 0;
            text-align: center;
            @media (--viewport-tablet) {
                height: 50%;
                display: flex;
                font-size: 48px;
                font-weight: 500;
                line-height: 56px;
                justify-content: center;
            }
        }
    }
}
</style>
