<template>
    <main class="page-container">
        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>
    </main>
</template>

<script>
import { merge } from 'lodash';
import Vue from 'vue';
import analytics from '@core/mixins/analytics.js';
import { resolvePageBodySlices } from '@utils/resolve-nested-slices';

import '@core/styles/sections/pages.pcss';

export default {
    name: 'PagesComponent',

    mixins: [analytics],

    computed: {
        page() {
            return this.$store.state.pages.page;
        },
        slices() {
            const page = this.page.body || [];
            return page.map((item) => {
                const translations = item.data?.translations || {};
                const data = merge({}, item.data, translations);
                return { ...item, data };
            });
        },
    },

    beforeCreate() {
        resolvePageBodySlices(this.$store.state.pages.page.body).forEach((fullname) => {
            const sectionName = fullname.startsWith('s-acronis-scs') ? 'scs' : 'pages';
            const sliceName = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/${sectionName}/${sliceName}/${sliceName}.vue`));
        });
    },
};
</script>

<style lang="postcss" scoped>
.page {
    position: relative;
}
</style>
