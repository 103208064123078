<template>
    <section v-if="isVisible" class="main-page-header">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <a-label v-if="item.label?.text" v-bind="item.label">
                        <a-link v-if="item.label?.to" v-bind="item.label" />
                        <p v-else v-html="item.label.text" />
                    </a-label>
                    <s-main-page-tag v-bind="tag({name: 'title'}, item)" />
                    <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true}, item)" />
                    <p v-if="item.promo" class="promo" v-html="item.promo" />
                    <p v-if="item.info" class="info" v-html="item.info" />
                    <s-main-page-cta :content="item.cta" />
                </div>
                <a-media v-if="item.media" v-bind="item.media" class="media" />
            </div>
        </div>
        <a-picture v-if="item.picture?.background" class="background" v-bind="item.picture" />
    </section>
</template>

<script>
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoints from '@core/mixins/breakpoint';
import SMainPageCta from '../../components/cta.vue';
import mixin from '../../components/mixin.js';
import SMainPageTag from '../../components/tag.vue';

export default {
    name: 'SMainPageHeaderVariant2',
    components: {
        ALabel,
        ALink,
        AMedia,
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content.list?.length;
        },
        tabActive() {
            return this.content?.tabActive || 0;
        },
        item() {
            return this.content.list[this.tabActive];
        },
    },
    methods: {
        tab(item) {
            if (!item.tab.label) return {};
            if (this.isMobile) return { label: item.tab.label };
            return this.isTablet ? { ...item.tab, aLabel: null } : item.tab;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-header {
    margin: 0;
    padding-top: 64px;
    position: relative;
    @media (--viewport-tablet) {
        padding-top: 40px;
    }
    @media (--viewport-desktop) {
        padding-top: 64px;
    }
    .wrapper {
        gap: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        @media (--viewport-tablet) {
            @mixin colls 10;
            margin: 0 auto;
        }
        @media (--viewport-desktop) {
            width: 100%;
            flex-direction: row;
        }
        @media (--viewport-desktop-large) {
            gap: 8px;
        }
    }
    .media-wrapper {
        @media (--viewport-desktop) {
            width: 50%;
        }
        @media (--viewport-desktop-large) {
            width: 672px;
        }
    }
    .content {
        text-align: center;
        @media (--viewport-desktop) {
            width: 50%;
            text-align: start;
            padding-inline-end: 16px;
        }
        @media (--viewport-desktop-large) {
            width: 600px;
            padding-inline-end: 24px;
        }
        .title {
            @mixin hero-accent;
            margin-top: 16px;
            color: var(--av-nav-primary);
            @media (--viewport-desktop-wide) {
                @mixin large-accent;
            }
        }
        .description {
            @mixin title;
            margin-top: 16px;
            color: var(--av-fixed-primary);
            @media (--viewport-desktop) {
                @mixin display;
            }
        }
        .promo {
            @mixin body-accent;
            margin-top: 16px;
            color: var(--av-fixed-success-dark);
        }
        .info {
            @mixin body-accent;
            margin-top: 16px;
            color: var(--av-brand-primary);
        }
        &:deep(.a-link) {
            color: inherit;
            .a-link__content {
                color: inherit;
                font-size: inherit;
                line-height: inherit;
            }
        }
        &:deep(.main-page-cta) {
            margin-top: 24px;
            @media (--viewport-desktop) {
                margin-top: 48px;
            }
            .main-page-cta-content {
                display: flex;
                flex-direction: row;
                @media (--viewport-desktop) {
                    justify-content: flex-start;
                }
                .a-button {
                    flex: 1;
                    @media (--viewport-desktop) {
                        flex: none;
                        min-width: 200px;
                    }
                }
            }
        }
    }
    .media {
        &:deep(.a-picture) {
            border: none;
            overflow: visible;
            box-shadow: none;
            border-radius: unset;
            .a-picture__img {
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                box-shadow: 0 24px 54px 0 rgba(45, 48, 57, 0.09), 0 0.755px 1.585px 0 rgba(45, 48, 57, 0.03);
            }
        }
    }
    .background {
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        inset-inline-start: 0;
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
