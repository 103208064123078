/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.side-menu {
    width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
}

@media (min-width: 1024px) {

.side-menu {
        padding-inline-end: 32px
}
    }

.side-menu__search-filters-desktop {
        display: none;
    }

@media(min-width: 1024px) {

.side-menu__search-filters-desktop {
            display: block
    }
        }

.side-menu__search-results-wrapper {
        padding: 8px 0;
    }

@media(min-width: 1024px) {
            .side-menu__search-results-wrapper .result-subtitle {
                display: none;
            }
        }

.side-menu__search-results-wrapper .result-subtitle {
            color: var(--av-fixed-light);
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 700;
        }

.side-menu__search-results-wrapper .result-subtitle:hover {
                background-color: transparent;
            }

.side-menu__search-results-wrapper {

        font-size: 16px;

        line-height: 24px;

        max-height: 336px;
        overflow-y: auto;
        position: absolute;
        z-index: 4;
        top: 60px;
        background-color: var(--av-inversed-primary);
        border-radius: 4px;
        border: 1px solid var(--av-brand-primary);
        cursor: pointer;
        width: 100%;
        box-shadow: var(--av-shadow-regular);
}

.side-menu__search-results-wrapper li {
            padding: 8px 16px;
        }

.side-menu__search-results-wrapper li:hover,
            .side-menu__search-results-wrapper li:focus {
                background-color: var(--av-brand-accent);
            }

.side-menu__product-search {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
    }

.side-menu__product-search .input-suffix {
            display: flex;
            align-content: center;
            cursor: pointer;
        }

.side-menu__product-search-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }

@media(min-width: 1024px) {

.side-menu__product-search-wrapper {
            margin-bottom: 48px
    }
        }

.side-menu__product-search-wrapper .el-search {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
        }

.side-menu__product-search-wrapper:deep(.el-input)--small .el-input__wrapper {
                        padding: 10px 0 0 8px;
                    }

.side-menu__product-search-wrapper:deep(.el-input)--small .el-input__container {
                        height: 48px;
                        padding: 0 8px;
                    }

.side-menu__product-search-wrapper:deep(.el-input)__label,
            .side-menu__product-search-wrapper:deep(.el-input)__editor {
                font-size: 16px;
                line-height: 24px;
            }

.side-menu__product-search-wrapper:deep(.el-input).is-focus .el-input__label, .side-menu__product-search-wrapper:deep(.el-input).is-active .el-input__label {
                    color: var(--av-fixed-light);
                    font-weight: 700;
                    font-size: 12px;
                }

.side-menu__product-search-wrapper:deep(.el-input)__suffix .i {
                    width: 16px;
                    height: 16px;
                    color: var(--av-brand-primary);
                }

.side-menu__product-search-wrapper .menu-activator {
            width: 48px;
            height: 48px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-inline-start: 8px;
            cursor: pointer;
            border-radius: 4px;
        }

@media(min-width: 1024px) {

.side-menu__product-search-wrapper .menu-activator {
                display: none
        }
            }

.side-menu__product-search-wrapper .menu-activator.active,
            .side-menu__product-search-wrapper .menu-activator:hover,
            .side-menu__product-search-wrapper .menu-activator:focus {
                background-color: var(--el-secondary-active);
            }

.side-menu__product-search-wrapper .side-menu__search-filters {
            display: flex;
            flex-direction: column;
            background-color: var(--av-inversed-primary);
            position: absolute;
            z-index: 4;
            border-radius: 4px;
            border: 1px solid var(--av-brand-primary);
            top: 56px;
            width: 100%;
            padding: 24px;
            box-shadow: var(--av-shadow-regular);
        }

@media (min-width: 576px) {

.side-menu__product-search-wrapper .side-menu__search-filters {
                max-width: 328px;
                inset-inline-end: 0
        }
            }

@media (min-width: 1024px) {

.side-menu__product-search-wrapper .side-menu__search-filters {
                display: none
        }
            }

.side-menu__search-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 12px;
    }

.side-menu__action-btns {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--av-brand-accent);
        padding: 24px;
    }

.side-menu__action-btns button {
            margin-inline-start: 16px;
        }

.side-menu:deep(.a-accordion)-item {
            border-top: none;
            border-bottom: none;
        }

.side-menu:deep(.a-accordion)-item:last-of-type {
                border-bottom: none;
            }

@media(min-width: 1024px) {

.side-menu:deep(.a-accordion)-item {
                margin-top: 32px
        }
            }

.side-menu:deep(.a-accordion)-item .title {
                margin-bottom: 4px;
            }

.side-menu:deep(.a-accordion) .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

.side-menu:deep(.a-accordion) .title > div:first-child {
                margin-inline-end: 8px;
            }

.side-menu:deep(.a-accordion) .title:deep(.a-glyph) {
                    margin: 0;
            }

.side-menu:deep(.a-accordion) li:not(last-of-type) {
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 24px;
        }
