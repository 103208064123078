<template>
    <section v-if="isVisible" v-show="isAvailable" :class="['main-page-blog', className]">
        <div class="a-container">
            <div class="wrapper">
                <div v-if="content.title || content.logo?.link" class="card-item-1">
                    <a-picture v-if="content.logo?.link" v-bind="content.logo" />
                    <s-main-page-tag v-bind="tag({name: 'title'})" />
                </div>
                <div v-if="card" class="card-item-2">
                    <a-picture v-if="card.picture?.link" v-bind="card.picture" />
                    <div class="content">
                        <a-label v-if="card.label?.text" v-bind="card.label">
                            <span v-html="card.label.text" />
                        </a-label>
                        <template v-if="card.link?.text">
                            <a-link v-if="card.link?.to" v-bind="card.link" />
                            <p v-else class="lead" v-html="card.link.text" />
                        </template>
                        <p v-if="card.description" class="description" v-html="card.description" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageBlog',
    components: {
        ALabel,
        ALink,
        APicture,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.title || this.card;
        },
        card() {
            return this.content.card || false;
        },
        className() {
            return {
                'is-tru': this.content.isTru,
                'has-border-top': this.content.hasBorderTop,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-blog {
    padding: 40px 0;
    .wrapper {
        gap: 16px;
        display: flex;
        flex-direction: column;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }
    .card-item-1 {
        text-align: center;
        @media (--viewport-desktop) {
            display: flex;
            @mixin colls 3;
            text-align: start;
            flex-direction: column;
            justify-content: center;
            &:deep(.a-picture) {
                .a-picture__img {
                    margin: 0;
                }
            }
        }
    }
    .title {
        @mixin title-accent;
        color: #000;
        @media (--viewport-desktop-wide) {
            @mixin display-accent;
        }
    }
    .card-item-2 {
        display: flex;
        overflow: hidden;
        text-align: center;
        border-radius: 8px;
        flex-direction: column;
        background: var(--av-inversed-primary);
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
        border: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-tablet) {
            text-align: start;
            flex-direction: row;
            align-items: stretch;
        }
        @media (--viewport-desktop) {
            flex: 1 1 0;
            width: auto;
        }
        .content {
            padding: 16px 0;
            @media (--viewport-tablet) {
                flex: 1;
                padding: 16px;
            }
            @media (--viewport-desktop-wide) {
                padding-inline-end: 56px;
            }
            @media (--viewport-desktop-large) {
                padding-inline-end: 16px;
            }
        }
        .lead {
            @mixin nav-title-accent;
            margin-top: 16px;
            color: var(--av-brand-primary);
        }
        .description {
            @mixin paragraph;
            margin-top: 24px;
            color: var(--av-fixed-primary);
            @media (--viewport-desktop-large) {
                @mixin lead;
            }
        }
        &:deep(.a-link) {
            width: 100%;
            margin-top: 16px;
            .a-link__content {
                @mixin nav-title-accent;
                color: var(--av-brand-primary);
            }
        }
        &:deep(.a-label) {
            display: flex;
            margin: 0 auto;
            width: fit-content;
            @media (--viewport-tablet) {
                margin: 0;
            }
        }
        &:deep(.a-picture) {
            width: auto;
            @media (--viewport-tablet) {
                width: 228px;
            }
            @media (--viewport-desktop-wide) {
                width: 316px;
            }
            .a-picture__img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &.is-tru {
        .card-item-2 {
            box-shadow: none;
            border: 1px solid var(--av-fixed-lightest);
            background: linear-gradient(0deg, #FFF 50%, #E3E3E3 100%);
            .lead {
                color: var(--av-fixed-danger-dark);
            }
            &:deep(.a-link) {
                .a-link__content {
                    color: var(--av-fixed-danger-dark);
                }
            }
            &:deep(.a-label) {
                &:after,
                &:before {
                    background: #000;
                }
            }
        }
    }
    &.has-border-top {
        border-top: 2px solid var(--av-brand-secondary-accent);
    }
}
</style>
