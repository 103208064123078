<template>
    <div class="s-form-login">
        <div v-if="content.title" class="title" v-html="content.title" />
        <div v-if="content.subtitles" class="subtitle">
            <p v-for="(item, i) in content.subtitles" :key="`subtitles-${i}`" v-html="item" />
        </div>
        <el-form ref="form" :model="form" :rules="rules">
            <a-row>
                <el-form-item prop="email">
                    <el-input
                        ref="email"
                        v-model="form.email"
                        autofocus
                        name="email"
                        type="input"
                        :label="formLabelText({ name: 'email' })"
                        @input="onInputField"
                    />
                </el-form-item>
            </a-row>
            <a-row>
                <el-form-item prop="password">
                    <el-password
                        ref="password"
                        v-model="form.password"
                        with-icon
                        name="password"
                        :label="formLabelText({ name: 'password' })"
                        @input="onInputField"
                    />
                </el-form-item>
            </a-row>
            <el-form-error v-if="errorMessage" size="large" show-icon>
                <span v-html="errorMessage" />
            </el-form-error>
            <p v-if="content.note" class="note" v-html="content.note" />
            <a-button
                v-if="content.button"
                v-bind="content.button"
                :event="{ doNotSendGA: true }"
                @click="onSubmitForm"
            />
        </el-form>
        <div v-if="content.info" class="info">
            <p v-for="(item, i) in content.info" :key="`info-${i}`" v-html="item" />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ARow from '@core/components/row/row.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormLogin',

    components: {
        AButton,
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormError: () => import('@uikit/ui-kit/packages/form-error'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElPassword: () => import('@uikit/ui-kit/packages/password'),
        ARow,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    emits: ['error', 'loading', 'submit'],

    data: () => ({
        form: {
            email: '',
            password: '',
        },
        errorMessage: '',
    }),

    computed: {
        rules() {
            return {
                email: {
                    trigger: 'submit',
                    validator: this.validateEmail,
                    message: this.formErrorText({ name: 'email' }),
                },
                password: {
                    trigger: 'submit',
                    validator: this.validatePassword,
                    message: this.formErrorText({ name: 'password' }),
                },
            };
        },
    },

    methods: {
        ...mapActions({
            accountLogin: 'trial/accountLogin',
        }),

        onInputField() {
            this.errorMessage = '';
            this.$refs.form.clearValidate();
        },

        async onSubmitForm() {
            try {
                this.prepareFormToValidate();
                await this.$refs.form.validate();
            } catch (e) {
                this.setFocusErrorField();
                return;
            }

            try {
                this.$emit('loading', true);

                const response = await this.accountLogin({
                    email: this.form.email,
                    password: this.form.password,
                });

                if (response.status !== 200) {
                    const msg = 'Wrong email or password';
                    this.errorMessage = this.content?.messages?.error || msg;
                    this.setFocus('password');
                    this.$emit('loading', false);
                    return;
                }

                this.$emit('submit');
            } catch (e) {
                this.$emit('error');
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-login {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 40px 16px 56px;
    justify-content: center;
    background: var(--av-inversed-primary);

    @media (--viewport-mobile-wide) {
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }

    @media (--viewport-tablet) {
        padding: 40px 48px;
    }

    @media (--viewport-desktop) {
        width: 100%;
        margin: 0 auto;
    }

    .title {
        @mixin title-accent;
        text-align: center;
        color: var(--av-nav-primary);
    }

    .subtitle {
        @mixin paragraph;
        margin: 8px 0 0;
        text-align: center;

        &:deep(a) {
            cursor: pointer;
            text-decoration: none;
            color: var(--av-brand-secondary);

            &:hover {
                color: var(--av-brand-secondary);
            }
        }
    }

    .note {
        text-align: end;
        margin-bottom: 16px;

        &:deep(.a-link__content) {
            @mixin descriptor;
            color: var(--av-brand-secondary);
        }
    }

    .info {
        @mixin body;
        margin-top: 24px;
        text-align: center;
        color: var(--av-fixed-secondary);

        a {
            color: var(--av-brand-primary);
        }
    }

    &:deep(.el-form) {
        width: 100%;
        margin: 32px 0 0;
        text-align: start;

        @media (--viewport-tablet) {
            margin: 24px 0 0;
        }

        .el-input__label {
            &.is-active {
                font-weight: 700;
            }
        }

        .el-form-item__error {
            @mixin caption;
            padding-bottom: 16px;
        }
    }

    &:deep(.a-button) {
        width: 100%;
    }
}
</style>
