<template>
    <s-basic-slice :id="id" class="s-purchasing-v2" :class="{ ...className, ...basicClassName }">
        <a-slice-header :title="pageTitle" :lead="pageLead" :body-text="pageBodyText" />
        <div
            v-if="!loaded"
            v-loading="true"
            class="loader"
            el-loading-size="48"
            el-loading-color="brand-primary"
        />
        <template v-else>
            <div v-if="message.description || message.text" class="message">
                <div class="description">
                    <a-glyph v-if="message.glyph" :name="message.glyph" size="m" />
                    <div v-if="message.description" v-html="message.description" />
                </div>
                <p v-if="message.text" class="text" v-html="message.text" />
            </div>
            <div class="container" :class="`items-${products.items?.length}`">
                <div class="product">
                    <div
                        v-for="(product, index) in products.items"
                        :key="`product-${index}`"
                        :class="['license', { 'license-active': index === products.selected }]"
                        @click="products.selected = index"
                    >
                        <div class="description">
                            <div class="name">
                                <p>
                                    {{ product.name }}
                                </p>
                                <a-label v-if="products.label && index === pageProductCards.selected" theme="dark" size="s">
                                    {{ products.label }}
                                </a-label>
                            </div>
                            <div class="headline-container" :style="{ height: heightHeadline || 'auto' }">
                                <p v-if="product.headline" class="headline">
                                    {{ product.headline }}
                                </p>
                            </div>
                            <p v-if="!isPriceForMonth" class="type" v-html="product.type" />
                            <div class="price-container">
                                <p v-if="promo.isPromoActive" class="price-full">
                                    <span class="price-full-item" v-html="productPriceFull(index)" />
                                </p>
                                <div class="price-item">
                                    <span class="price" v-html="productPrice(index)" />
                                    <span
                                        v-if="!isPriceForMonth"
                                        class="price-period"
                                        v-html="product.period.year"
                                    />
                                    <a-label
                                        v-if="promo.isPromoActive && productPriceLabel(index)"
                                        class="price-label"
                                        variant="success"
                                        theme="dark"
                                    >
                                        {{ productPriceLabel(index) }}
                                    </a-label>
                                </div>
                                <div class="headline-container" :style="{ height: heightPrice || 'auto' }">
                                    <p
                                        v-if="isPriceForMonth"
                                        class="price-for-year"
                                        v-html="productPrice(index, product.period.month)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="cta">
                            <p v-if="product.pc.title" class="product-pc-title" v-html="product.pc.title" />
                            <div v-if="product.pc.options" class="product-pc">
                                <a-button
                                    v-for="(value, key) in product.pc.options"
                                    :key="`pc-${key}`"
                                    size="small"
                                    type="secondary"
                                    :text="value"
                                    :event="{ doNotSendGA: true }"
                                    :class="{'is-active': product.pc.selected === key}"
                                    @click="onChange(index, 'pc', key)"
                                />
                            </div>
                            <p v-if="product.cloud.title" class="product-cloud-title" v-html="product.cloud.title" />
                            <div v-if="product.cloud?.options" class="product-cloud">
                                <a-button
                                    v-for="(value, key) in product.cloud.options"
                                    :key="`cloud-${key}`"
                                    size="small"
                                    type="secondary"
                                    :text="value"
                                    :event="{ doNotSendGA: true }"
                                    :class="{'is-active': product.cloud.selected === key}"
                                    @click="onChange(index, 'cloud', key)"
                                />
                            </div>
                            <div v-if="product.cloud?.text" class="product-cloud">
                                <a-button
                                    size="small"
                                    type="secondary"
                                    :text="product.cloud.text"
                                    :disabled="true"
                                    :event="{ doNotSendGA: true }"
                                />
                            </div>
                            <div v-if="product.button?.text" class="product-button">
                                <a-button
                                    :type="index === products.selected ? 'action' : 'secondary'"
                                    v-bind="product.button"
                                    :event="{ doNotSendGA: true }"
                                    @click="proceedToCheckout(index)"
                                />
                            </div>
                        </div>
                        <ul class="features">
                            <li v-for="(item, key) in product.features" :key="`features-${key}`" :class="classNameItem('features', item)">
                                <a-glyph v-if="item.glyph" :name="item.glyph" />
                                <span v-html="item.text ? item.text : item" />
                            </li>
                        </ul>
                        <ul class="benefits">
                            <li v-for="(item, key) in product.benefits" :key="`benefits-${key}`" :class="classNameItem('benefits', item)">
                                <a-glyph v-if="item.glyph" :name="item.glyph" />
                                <span v-html="item.text ? item.text : item" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="products.information" class="info">
                    <div v-for="(item, index) in products.information" :key="`info-${index}`" class="info-item">
                        <p v-for="(value, valueIndex) in item" :key="`info-item-${valueIndex}`" v-html="value" />
                    </div>
                </div>
                <div v-if="products.note" class="note">
                    <p v-html="products.note" />
                </div>
            </div>
        </template>
        <a-loader :is-visible="isVisibleSURL" />
    </s-basic-slice>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALoader from '@core/components/loader/loader.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import mixin from '../mixin.js';

export default {
    name: 'SPurchasingCyberProtectHomeOfficeV2',

    components: {
        AButton,
        AGlyph,
        ALabel,
        ALoader,
        ASliceHeader,
        SBasicSlice,
    },

    directives: {
        Loading,
    },

    mixins: [mixin, breakpoint, contentMixin, styleMixin, purchasing, currency, analytics, cleverbridge],

    data() {
        return {
            cta: {},
            promo: {},
            coupon: '',
            products: {},
            loaded: false,
            heightPrice: null,
            heightHeadline: null,
            STORE_SLICE_NAME: 's-purchasing-cyber-protect-home-office',
        };
    },
    computed: {
        period() {
            return this.isPriceForMonth ? 12 : 1;
        },
    },
    updated() {
        this.resize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        setHeightPrice() {
            const elements = [...document.querySelectorAll('.price-for-year')];
            if (!elements.length);
            this.heightPrice = `${Math.max(...elements.map((item) => item.clientHeight))}px`;
        },
        setHeightHeadline() {
            const elements = [...document.querySelectorAll('.headline')];
            if (!elements.length) return;
            if (document.documentElement.clientWidth < 1024) {
                this.heightHeadline = 'auto';
                return;
            }
            this.heightHeadline = `${Math.max(...elements.map((item) => item.clientHeight))}px`;
        },
        resize() {
            this.setHeightPrice();
            this.setHeightHeadline();
        },
        productPrice(index, text) {
            const license = this.findLicense(index);
            if (text) {
                if (this.promo.isPromoActive) {
                    const productPriceFull = this.setCurrency(license.price + license.discount_absolute);
                    if (!license.discount_absolute) return '';
                    const template = `<span class="price-for-year-full">${productPriceFull}</span> @price`;
                    return text.replace('@price', template).replace('@price', this.setCurrency(license.price));
                }
                return text.replace('@price', this.setCurrency(license.price));
            }
            return license.price ? this.setCurrency(license.price / this.period) : '???';
        },
        productPriceFull(index) {
            const license = this.findLicense(index);
            if (!license.discount_absolute) return '';
            const result = (license.price + license.discount_absolute) / this.period;
            return result ? this.setCurrency(result) : '???';
        },
    },
};
</script>

<style lang="postcss">
.s-purchasing-v2 {
    .loader {
        z-index: 1;
        height: 400px;
        position: relative;
    }
    .a-slice-header {
        margin: 0;
        width: 100%;
        .a-slice-header__title {
            margin: 0;
            @mixin display;
            @media (--viewport-tablet) {
                @mixin hero-accent;
            }
        }
        .a-slice-header__lead {
            @mixin title;
            @media (--viewport-tablet) {
                @mixin display;
            }
            margin: 32px 0 0;
        }
    }
    .container {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
    }
    .description {
        display: flex;
        flex-direction: column;
        @media (--viewport-tablet) {
            width: 50%;
            margin-inline-end: auto;
            padding-inline-end: 16px;
        }
        @media (--viewport-desktop) {
            width: 100%;
            padding-inline-end: 0;
        }
    }
    .message {
        margin: 8px 0 0;
        .description {
            @mixin paragraph;
            gap: 8px;
            width: 100%;
            display: flex;
            flex-direction: row;
            font-weight: 600;
            color: var(--av-fixed-success);
            .a-glyph {
                min-width: 24px;
                fill: var(--av-fixed-success);
            }
        }
        .text {
            @mixin descriptor;
            font-weight: 600;
            color: var(--av-nav-primary);
        }
    }
    .cta {
        @media (--viewport-tablet) {
            width: 50%;
            margin: 0;
            display: flex;
            min-width: auto;
            flex-direction: column;
        }
        @media (--viewport-desktop) {
            width: 100%;
        }
    }
    .product {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        @media (--viewport-desktop) {
            flex-wrap: nowrap;
        }
        @media (--viewport-desktop) {
            gap: 0;
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid var(--av-brand-light);
            box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        }
    }
    .license {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        flex-direction: column;
        padding: 24px 16px 40px;
        border: 1px solid var(--av-brand-light);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        background-color: var(--av-inversed-primary);
        &:first-child {
            @media (--viewport-desktop) {
                border: none;
            }
        }
        &:nth-child(even) {
            .product-cloud {
                .a-button {
                    flex: 1 0 0;
                    @media (--viewport-desktop) {
                        flex: 0 0 0;
                        .a-button__content {
                            min-width: 68px;
                            text-align: center;
                        }
                    }
                    .a-button__content {
                        padding: 0 8px;
                    }
                }
            }
        }
        @media (--viewport-tablet) {
            flex-direction: row;
        }
        @media (--viewport-desktop) {
            flex: 1 1 0;
            flex-direction: column;
            border: none;
            border-radius: 0;
            box-shadow: none;
            border-inline-start: 1px solid var(--av-brand-light);
        }
        @media (--viewport-desktop-wide) {
            padding: 24px 24px 40px;
        }
        @media (--viewport-desktop-large) {
            padding: 24px 40px 40px;
        }
    }
    .name {
        @mixin title-accent;
        gap: 8px;
        display: flex;
        align-items: center;
        color: var(--av-nav-primary);
        .a-glyph {
            margin-inline-start: 16px;
            vertical-align: baseline;
        }
        .a-label {
            overflow: hidden;
            max-height: 16px;
        }
    }
    .headline {
        @mixin lead;
        margin-top: 4px;
        color: var(--av-nav-primary);
    }
    .type {
        @mixin lead-accent;
        margin-top: 16px;
        color: var(--av-nav-primary);
    }
    .price-container {
        padding-top: 16px;
        position: relative;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            margin-top: auto;
            padding-bottom: 20px;
        }
    }
    .price-item {
        display: flex;
        align-items: center;
    }
    .price {
        @mixin display-accent;
    }
    .price-full {
        @mixin lead;
        min-height: 24px;
        color: var(--av-fixed-light);
        text-decoration-line: line-through;
    }
    .price-full-item {
        @mixin lead-accent;
    }
    .price-label {
        margin: 0 8px;
        font-weight: bold;
        color: var(--av-fixed-success);
    }
    .price-for-year {
        @mixin body;
        min-height: 24px;
    }
    .price-for-year-full {
        color: var(--av-fixed-light);
        text-decoration: line-through;
    }
    .price-period {
        @mixin paragraph-accent;
        margin: 0 8px 8px;
        vertical-align: top;
    }
    .features {
        @mixin caption;
        width: 100%;
        padding-top: 32px;
        padding-bottom: 28px;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            width: 50%;
            padding-top: 24px;
            padding-inline-end: 16px;
        }
        @media (--viewport-desktop) {
            width: 100%;
            padding-top: 32px;
            padding-inline-end: 0;
        }
    }
    .features-item {
        position: relative;
        padding-inline-start: 20px;
        &:not(:first-child) {
            margin-top: 12px;
        }
    }
    .features-title {
        @mixin body-accent;
        padding: 0 0 4px;
    }
    .features-glyph {
        padding: 0;
        display: flex;
        .a-glyph {
            min-width: 16px;
            margin-inline-end: 8px;
            fill: var(--av-nav-primary);
        }
    }
    .benefits {
        width: 100%;
        margin: auto 0 0;
        padding-top: 28px;
        color: var(--av-nav-primary);
        border-top: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-tablet) {
            width: 50%;
            margin-top: 0;
            padding-top: 64px;
            border-top: none;
        }
        @media (--viewport-desktop) {
            width: 100%;
            margin-top: auto;
            padding-top: 28px;
            border-top: 1px solid var(--av-brand-secondary-light);
        }
    }
    .benefits-item {
        @mixin caption;
        padding-inline-start: 24px;
        &:not(:first-child) {
            margin-top: 12px;
        }
    }
    .benefits-glyph {
        display: flex;
        .a-glyph {
            margin: 0;
            margin-inline-end: 8px;
            margin-inline-start: -24px;
            fill: var(--av-nav-primary);
        }
    }
    .product-pc,
    .product-cloud {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        flex-direction: row;
        .a-button {
            flex: 1 1 0;
            padding: 3px 0;
            border-radius: 8px;
        }
        .a-button__content {
            @mixin body;
            font-weight: 400;
            color: var(--av-link-primary);
        }
        .is-active {
            background: var(--av-brand-secondary-accent);
            .a-button__content {
                font-weight: 700;
                color: var(--av-nav-primary);
            }
        }
    }
    .product-pc {
        .a-button {
            max-width: 33.33%;
        }
    }
    .product-cloud {
        .a-button {
            &:first-child {
                .a-button__content {
                    padding: 0 16px;
                }
            }
        }
        .a-button_disabled {
            max-width: 100%;
            border-color: var(--av-button-secondary-light);
            @media (--viewport-desktop) {
                max-width: 50%;
            }
            .a-button__content {
                color: var(--av-link-secondary-light);
            }
        }
    }
    .product-pc-title {
        @mixin body;
        color: var(--av-nav-primary);
        margin-top: 20px;
        @media (--viewport-tablet) {
            margin-top: 0;
        }
    }
    .product-cloud-title {
        @mixin body;
        margin-top: 20px;
        color: var(--av-nav-primary);
    }
    .product-button {
        margin-top: 32px;
        .a-button {
            width: 100%;
            max-height: 48px;
            justify-content: center;
            .a-button__glyph {
                margin-inline-start: 8px;
            }
            &.a-button_type_secondary {
                background: var(--av-brand-secondary-accent);
            }
        }
        @media (--viewport-desktop) {
            margin-top: 32px;
        }
    }
    .license-active {
        background: var(--av-solid-brand-lightest);
    }
    .info {
        margin-top: 40px;
        @media (--viewport-tablet) {
            gap: 16px;
            display: flex;
            flex-direction: row;
        }
    }
    .info-item {
        @mixin paragraph;
        margin-top: 16px;
        &:first-child {
            margin-top: 0;
        }
        b {
            font-weight: 600;
        }
        a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
        @media (--viewport-tablet) {
            flex: 1 1 0;
            margin-top: 0;
        }
    }
    .note {
        margin: 32px 0 0;
        a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
    }
    .items-2 {
        @media (--viewport-desktop-wide) {
            display: flex;
            flex-direction: row;
        }
        .product {
            @media (--viewport-desktop-wide) {
                width: 66.66%;
            }
        }
        .license,
        .info-item {
            @media (--viewport-desktop) {
                width: 50%;
            }
        }
        .note {
            @media (--viewport-desktop-wide) {
                margin: 0;
                width: 33.33%;
                padding-inline-start: 32px;
            }
        }
    }
    .items-3 {
        .license,
        .info-item {
            @media (--viewport-desktop) {
                width: 33.33%;
            }
        }
    }
}
</style>
