.blog-page {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > .main-content {
        flex: 1 0 auto;
    }

    > .footer {
        flex-shrink: 0;
    }

    .s-main-header {
        position: relative;
        padding-top: 24px;
        padding-bottom: 22px;
        top: 0;

        .s-main-menu {
            top: 72px;
        }
    }
}

.blog-dropdown.el-select-dropdown {
    border: 1px solid var(--av-brand-primary);
    border-radius: 4px;
    padding: 16px 0 16px;
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

    .el-select-dropdown {
        &__item,
        &__item.selected {
            @mixin body;

            padding: 0 16px;
            font-weight: 500;
            height: 32px;
            color: var(--av-fixed-primary);
        }

        &__list {
            padding: 0;
        }
    }

    .el-scrollbar .el-select-dropdown__item.selected,
    .el-scrollbar .el-select-dropdown__item {
        &:hover,
        &:active {
            background: var(--av-brand-lightest);
        }
    }

    .el-scrollbar__thumb {
        background: var(--av-fixed-lighter);
    }
}
