<template>
    <div class="developer-register-program">
        <s-form-container v-if="form" :form="form" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import form from '@core/mixins/form.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default Vue.extend({
    name: 'DeveloperRegisterProgram',

    components: {
        SFormContainer,
    },

    mixins: [form],

    data() {
        return {
            form: null,
        };
    },

    computed: {
        ...mapState({
            formDeveloperPortal: (state) => state.slices.items?.['s-preset-form-developer-portal']?.form || null,
        }),
    },

    beforeMount() {
        this.form = this.formDeveloperPortal;
    },

    async serverPrefetch() {
        await Promise.all([
            this.$store.dispatch('countries/getCountries'),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-preset-form-developer-portal', locale: this.locale }),
        ]);
    },
});
</script>

<style lang="postcss" scoped>
.developer-register-program {
    padding: 48px 0;
    margin: 0 12px;
    &:deep(.s-form-container) {
        margin: 0 auto;
        @media (--viewport-tablet) {
            width: 560px;
        }
        .s-form-registration {
            padding: 40px;
            border-radius: 4px;
            box-shadow: var(--av-shadow-small);
            border: 1px solid var(--av-brand-light);
            @media (--viewport-tablet) {
                padding: 40px;
            }
            .el-form {
                margin: 0;
            }
        }
        .s-form-registration__title {
            @mixin display;
            font-weight: 600;
            color: var(--av-fixed-primary);
        }
        .s-form-registration__section {
            font-weight: 400;
            text-align: start;
            padding: 32px 8px 16px;
        }
        .s-form-success {
            padding: 40px;
            .title {
                order: 0;
                margin: 0;
            }
            .description {
                order: 1;
            }
            .a-picture {
                order: 2;
                margin-top: 24px;
            }
        }
    }
    &:deep(.el-form) {
        .el-form-item__error-holder {
            min-height: 24px;
        }
    }
}
</style>
