<template>
    <div class="quote">
        <div class="a-container">
            <div class="inner">
                <div class="main-content">
                    <h2 class="title">
                        {{ title }}
                    </h2>
                    <div v-if="quote" class="author-wrap">
                        <div v-if="author" class="author">
                            <a-picture class="photo" :link="author.image" />
                            <div class="name">
                                {{ author.name }}
                            </div>
                            <div class="author-title">
                                {{ author.title }}
                            </div>
                        </div>
                        <div class="quote-text">
                            {{ quote }}
                        </div>
                    </div>
                    <div v-if="cards.length" class="cards">
                        <div
                            v-for="card in cards"
                            :key="card.value"
                            class="card"
                        >
                            <div class="value-wrap">
                                <div class="label">
                                    {{ card.title }}
                                </div>
                                <div class="value">
                                    {{ card.value }}
                                </div>
                            </div>
                            <div class="card-text">
                                {{ card.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="years">
                    {{ numOfYears }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SCareersQuote',

    components: {
        APicture,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        quote: {
            type: String,
            default: null,
        },

        numOfYears: {
            type: Number,
            default: 20,
        },

        author: {
            type: Object,
            default: null,
        },

        cards: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.quote {
    position: relative;
    color: var(--av-inversed-primary);

    .inner {
        @mixin basic-slice-paddings;

        padding-inline: 32px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 12px;
        background: linear-gradient(70.2deg, rgba(0, 54, 134, 1) 0%, rgba(59, 101, 191, 1) 100%);

        @media (--viewport-tablet) {
            padding-inline: 40px;
        }
    }

    .main-content {
        position: relative;
        z-index: 1;
    }

    .years {
        position: absolute;
        color: var(--av-nav-primary);
        opacity: 0.3;
        font-size: 700px;
        top: 50%;
        font-weight: 900;
        text-align: center;
        margin-inline: auto;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        margin-top: -32px;
        display: none;

        @media (--viewport-tablet) {
            display: block;
        }

        @media (--viewport-desktop) {

            display: block;
        }
    }

    .title {
        @mixin hero-strong;

        background: linear-gradient(90deg, rgba(45, 141, 255, 1) 0%, rgba(157, 238, 255, 1) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        margin-bottom: 48px;

        @media (--viewport-desktop) {
            font-size: 56px;
            line-height: 64px;
        }
    }

    .author-wrap {
        margin: 0 auto 64px;
        max-width: 1024px;

        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
        }
    }

    .author {
        text-align: center;

        @media (--viewport-tablet) {
            flex-shrink: 0;
            margin-inline-end: 40px;
        }
    }

    .photo {
        width: 112px;
        height: 112px;
        margin: 0 auto 16px;
    }

    .name {
        @mixin title-accent;
    }

    .author-title {
        @mixin lead-accent;

        margin-bottom: 40px;
        color: var(--av-inversed-light);

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .quote-text {
        @mixin lead;

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;

        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-width: none;
            gap: 48px;
        }
    }

    .card {
        @media (--viewport-tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
        }

        @media (--viewport-desktop) {
            display: block;
        }
    }

    .value-wrap {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }

        @media (--viewport-desktop) {
            margin-bottom: 16px;
        }
    }

    .label {
        @mixin lead-accent;

        @media (--viewport-desktop) {
            margin-bottom: 8px;
        }
    }

    .value {
        @mixin display-strong;

        color: var(--av-inversed-primary);

        @media (--viewport-tablet) {
           @mixin hero-strong;
        }

        @media (--viewport-desktop) {
            @mixin display-strong;
        }

        @media (--viewport-desktop-wide) {
            @mixin hero-strong;
        }
    }

    .card-text {
        @mixin paragraph;

        font-weight: 500;
        padding-inline: 16px;

        @media (--viewport-tablet) {
           @mixin lead;

           text-align: start;
           align-items: center;
           display: flex;
           padding-inline: 0;
        }

        @media (--viewport-desktop) {
            display: block;
            text-align: center;
        }
    }
}
</style>
