<template>
    <div class="events-page events-archive">
        <div class="main-content">
            <event-menu v-sticky="{parent: '.event-menu'}" class="menu">
                <template #locale-select>
                    <event-locale-select v-if="localeSelect.isReady" v-bind="localeSelect" @select="setLocation" />
                </template>
            </event-menu>
            <event-container class="content">
                <a class="event-link back-link" :href="backLink.to">
                    <a-glyph class="left" name="arrow-left" />{{ backLink.title }}
                </a>
                <h1 class="title">
                    {{ translations.onDemandEventInArchiveTitle }}
                </h1>
                <event-filters
                    class="filters"
                    :categories="categoryItems"
                    :products="products"
                    :audiences="audienceItems"
                    :languages="languages"
                    :date="date"
                    :sorting="sorting"
                    :filter-title="translations.filter"
                    :submit-text="translations.apply"
                    :query="$route.query"
                    :default-state="filterDefaults"
                    @filterChange="onFilterChange"
                />
                <event-card-list
                    :cards="cards"
                    :learn-more-title="translations.learnMoreTitle"
                    :not-found-title="translations.noPastEventsForCategory"
                    :is-loading="isLoading"
                    @filterReset="onFilterChange"
                />
                <div v-if="enablePagination" class="pagination">
                    <Pagination
                        url-template="/archive/?currentPage=$PAGE_NUMBER$"
                        :current-page="currentPage"
                        :total-pages="totalPages"
                        :click-handler="goToPage"
                        :disabled="isXHRInProgress"
                    />
                </div>
            </event-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import Pagination from '@core/components/pagination/pagination.vue';
import Sticky from '@core/directives/sticky.js';
import analytics from '@core/mixins/analytics.js';
import events from '@core/mixins/events.js';
import EventCardList from '@core/slices/events/event-card-list/event-card-list.vue';
import EventFilters from '@core/slices/events/event-filters/event-filters.vue';
import EventMenu from '@core/slices/events/event-menu/event-menu.vue';
import EventContainer from '@core/slices/events/shared-components/event-container/event-container.vue';
import EventLocaleSelect from '@core/slices/events/shared-components/event-locale-select/event-locale-select.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import { DEFAULT_COUNTRY_ID, SLICE_DATA_FOOTER } from '@model/const/events.ts';
import { LOCALE_DEFAULT } from '@model/const/locales';
import commonUtils from '@utils/common';
import eventUtils from '@utils/events.js';
import '@core/styles/sections/events.pcss';

const EVENTS_COUNT_TO_SHOW = {
    mobile: 6,
    mobileWide: 6,
    tablet: 6,
    desktop: 9,
    desktopWide: 9,
    desktopLarge: 9,
};

export default {
    name: 'EventsArchivePage',

    components: {
        Pagination,
        SGlobalFooter,
        EventContainer,
        EventMenu,
        EventCardList,
        EventFilters,
        EventLocaleSelect,
        AGlyph,
    },

    directives: {
        Sticky,
    },

    mixins: [events, analytics],

    props: {
        interfaceLocaleData: {
            type: Object,
            required: true,
        },
        dispatchLoadProducts: {
            type: Function,
            required: true,
        },
        dispatchSearch: {
            type: Function,
            required: true,
        },
    },

    emits: ['search'],

    data() {
        return {
            DEFAULT_COUNTRY_ID,
            SLICE_DATA_FOOTER,
            currentPage: 1,
            isXHRInProgress: true,
            theme: 'light',
            firstFilterChanged: false,
            localeData: null,
            language: null,
        };
    },

    computed: {
        // need to explicitly check if there is a flag in store to avoid flickering
        // const pagination = this.eventsStore.searchResults.pagination || {};
        // return ('loading' in pagination) ? pagination.loading : true;
        isLoading() {
            return this.isXHRInProgress;
        },

        countryIsConfirmed() {
            return this.eventsStore.country?.isConfirmed || false;
        },

        enablePagination() {
            return this.searchResultsTotalCount > this.cardsCountToShow;
        },

        filterForm() {
            return this.eventsStore.filterForm || {};
        },

        cardsCountToShow() {
            return EVENTS_COUNT_TO_SHOW[this.currentBreakpoint || 'desktop'];
        },

        searchResultsTotalCount() {
            return this.eventsStore.searchResults.pagination?.items_total || 0;
        },

        totalPages() {
            return Math.ceil(this.searchResultsTotalCount / this.cardsCountToShow) || 1;
        },

        categoryItems() {
            return {
                title: this.translations.type,
                placeholder: this.translations.allEventsTitle,
                items: this.categories,
            };
        },

        date() {
            return {
                title: this.translations.selectPeriodTitle,
                startPlaceholder: this.translations.startDateTitle,
                endPlaceholder: this.translations.endDateTitle,
            };
        },

        products() {
            const products = Object.values(this.$store.state?.products?.items || [])
                .filter((product) => !product.is_virtual)
                .map((product) => ({ ...product, name: `Acronis ${product.name}` }));

            return {
                title: this.translations.product,
                placeholder: this.translations.allProductsTitle,
                items: products,
            };
        },

        audienceItems() {
            return ({
                title: this.translations.audience,
                placeholder: this.translations.anyAudienceTitle,
                items: this.audiences,
            });
        },

        cards() {
            const cards = this.eventsStore.searchResults.items || [];
            const categoryId = this.filterForm.category !== 'all' ? this.filterForm.category : undefined;
            return cards.map((event) => eventUtils.getEventCardFields(event, this.translations, { categoryId, locale: this.locale }));
        },

        languages() {
            const languages = (this.eventsStore.languages.items || []).map((item) => ({
                ...item,
                name: this.translations.languages?.[item.name] || item.name,
            }));
            return {
                title: this.translations.language,
                placeholder: this.translations.allLanguageTitle,
                items: languages,
            };
        },

        sorting() {
            return {
                recent: this.translations.recentEventsTitle,
                popular: this.translations.popularEventsTitle,
            };
        },

        backLink() {
            return {
                to: '/',
                title: this.translations.browseAllEventsTitle,
            };
        },

        notFoundTitle() {
            return commonUtils.handleHtmlInTranslations(
                this.translations.noSearchResultsTitle,
                [{
                    target: '@resetLink',
                    replacement: '<a class="event-link filterReset">$1</a>',
                }],
            );
        },

        filterDefaults() {
            return { language: this.language?.id };
        },

        startPage() {
            return parseInt(this.$route.query?.currentPage, 10) || 1;
        },

        sliceDataFooter() {
            return {
                ...this.SLICE_DATA_FOOTER,
                locale: this.interfaceLocaleData?.locale || LOCALE_DEFAULT,
            };
        },
    },

    created() {
        this.currentPage = this.startPage;
    },

    mounted() {
        const interfaceLocale = this.interfaceLocaleData?.locale || LOCALE_DEFAULT;
        const { locale, ...params } = this.$route.params;
        const isDefaultLocale = interfaceLocale === LOCALE_DEFAULT;
        const isDifferentLocale = locale !== interfaceLocale;
        const languageName = this.interfaceLocaleData?.data?.defaultLanguage || 'English';
        this.language = (this.eventsStore.languages.items || []).find((x) => x.name === languageName);

        if (isDifferentLocale && !isDefaultLocale) {
            this.$router.replace({
                name: 'archive-localized',
                params: { ...params, locale: interfaceLocale },
                query: this.$route.query,
            }).catch(() => {});
        } else if (isDefaultLocale && locale) {
            this.$router.replace({
                name: 'archive',
                params,
                query: this.$route.query,
            }).catch(() => {});
        }

        if (isDifferentLocale) {
            this.dispatchLoadProducts();
        }

        this.$on('onLocationSet', this.startSearch);
        this.isXHRInProgress = !this.countryIsConfirmed;
    },

    methods: {
        ...mapActions({
            setCountry: 'events/setCountry',
        }),

        setLocation(countryId) {
            this.setCountry({ countryId, isConfirmed: true });
            this.setCountryCookie(countryId);
            this.$router.replace({ query: { ...this.$route.query, language: null } }).catch(() => {});
            this.$router.go();
        },

        onFilterChange() {
            if (!this.firstFilterChanged) {
                this.firstFilterChanged = true;
            } else {
                this.currentPage = 1;
            }

            this.startSearch();
        },

        async goToPage(pageNumber, callback = () => {}) {
            this.currentPage = pageNumber;
            callback?.();
            await this.startSearch();
            commonUtils.scrollTop();
        },

        async startSearch() {
            this.isXHRInProgress = true;

            const queryData = {
                date: this.filterForm.date,
                category: this.filterForm.category,
                language: this.filterForm.language,
                audience: this.filterForm.audience,
                product: this.filterForm.product,
                currentPage: this.currentPage,
            };

            const filteredQueryEntries = Object.entries(queryData).filter(([, value]) => value !== null && value !== '');
            const filteredQuery = Object.fromEntries(filteredQueryEntries);

            const searchQuery = {
                ...queryData,
                countryId: this.countryId,
                cardsCountToShow: this.cardsCountToShow,
            };

            await this.dispatchSearch(searchQuery);

            this.$router.replace({ query: filteredQuery }).catch(() => {});

            this.isXHRInProgress = false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.events-archive {
    .content {
        margin-top: 104px;
        margin-bottom: 80px;

        @media (--viewport-desktop) {
            margin-bottom: 104px;
        }
    }

    .back-link {
        font-weight: 500;
        margin-bottom: 40px;
    }

    .title {
        @mixin hero-strong;
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            margin-bottom: 56px;
        }
    }

    .filters {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 64px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 40px;
        }
    }

    .pagination {
        margin-top: 80px;

        @media (--viewport-desktop) {
            margin-top: 104px;
        }
    }
}
</style>

<style lang="postcss">
.events-archive {
    .event-tab-control {
        .label {
            @mixin paragraph;

            @media (--viewport-desktop-wide) {
                @mixin lead;
            }
        }
    }
}
</style>
