<template>
    <div class="locations">
        <div class="a-container">
            <h2 class="title">
                {{ title }}
            </h2>
            <div v-if="lead" class="lead">
                {{ lead }}
            </div>
            <div class="columns">
                <div class="continents">
                    <div class="continents-inner">
                        <a-accordion one-only-mode @change="panToContinent">
                            <template v-for="continent in continents">
                                <a-accordion-item
                                    :key="continent.id"
                                    class="continent"
                                >
                                    <template #title>
                                        <a-glyph class="arrow icon" name="icon-pointer-right" />
                                        <div ref="title" class="continent-title">
                                            {{ continent.title }}
                                        </div>
                                    </template>
                                    <div class="offices">
                                        <div
                                            v-for="office in continent.offices"
                                            :key="office.title"
                                            class="office"
                                        >
                                            {{ office.title }}
                                        </div>
                                    </div>
                                </a-accordion-item>
                            </template>
                        </a-accordion>
                    </div>
                </div>
                <div class="map-container">
                    <div class="map-wrap">
                        <div id="map" class="map">
                            <template v-if="!isLoadedGoogleMaps">
                                <div v-loading="true" el-loading-size="48" el-loading-text="Data is loading..." />
                            </template>
                        </div>
                    </div>
                    <div class="legend">
                        <img class="marker-icon" :src="markerImg" loading="lazy" />
                        {{ markerLegend }}
                    </div>
                </div>
            </div>
            <a-link
                v-if="link"
                class="link"
                v-bind="link"
                :text="link.title || link.text"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import googleMaps from '@core/mixins/google-maps.js';

const MARKER_IMG = '/public/assets/images/office-marker.svg';

export default {
    name: 'SCareersLocations',

    components: {
        AAccordion,
        AAccordionItem,
        ALink,
        AGlyph,
    },

    directives: {
        Loading,
    },
    mixins: [googleMaps],
    props: {
        title: {
            type: String,
            required: true,
        },
        lead: {
            type: String,
            default: null,
        },
        continents: {
            type: Array,
            required: true,
        },
        link: {
            type: Object,
            default: null,
        },
        markerLegend: {
            type: String,
            default: 'Physical Acronis offices',
        },
        localeContinent: {
            type: String,
            default: 'EU',
        },
    },

    data: () => ({
        mapStyles: [
            {
                featureType: 'water',
                stylers: [
                    {
                        visibility: 'on',
                    },
                    {
                        color: '#b5cbe4',
                    },
                ],
            },
            {
                featureType: 'landscape',
                stylers: [
                    {
                        color: '#efefef',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#83a5b0',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#bdcdd3',
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#fff',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#e3eed3',
                    },
                ],
            },
            {
                featureType: 'administrative',
                stylers: [
                    {
                        visibility: 'on',
                    },
                    {
                        lightness: 33,
                    },
                ],
            },
            {
                featureType: 'road',
            },
            {
                featureType: 'poi.park',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                    {
                        lightness: 20,
                    },
                ],
            },
            {},
            {
                featureType: 'road',
                stylers: [
                    {
                        lightness: 20,
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ],
        markerImg: MARKER_IMG,
    }),

    methods: {
        async addMap() {
            const { Map } = await window.google.maps.importLibrary('maps');
            this.map = new Map(document.getElementById('map'), {
                center: new window.google.maps.LatLng(44, 11),
                fullscreenControl: false,
                gestureHandling: 'none',
                isFractionalZoomEnabled: true,
                keyboardShortcuts: false,
                mapId: 'mapLocations',
                mapTypeControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: [window.google.maps.MapTypeId.TERRAIN, 'custom_map_style'],
                },
                maxZoom: 3,
                streetViewControl: false,
                zoom: 3,
                zoomControl: false,
            });
            const styledMap = new window.google.maps.StyledMapType(this.mapStyles, { name: 'Data Center Map' });
            this.map.mapTypes.set('custom_map_style', styledMap);
            this.map.setMapTypeId('custom_map_style');
        },
        async addMarkers() {
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');
            const markers = this.continents
                .flatMap((continent) => continent.offices)
                .map((office) => office.location)
                .filter((item) => item.length);
            const createMarker = () => {
                const img = document.createElement('img');
                img.src = this.markerImg;
                return img;
            };

            markers.map((point) => new AdvancedMarkerElement({
                content: createMarker(),
                map: this.map,
                position: { lat: point[0], lng: point[1] },
            }));

            const selectedItemIndex = this.continents.findIndex((x) => x.id === this.localeContinent);
            this.$refs?.title[selectedItemIndex]?.click();
            this.isLoadedGoogleMaps = true;
        },

        panToContinent(i) {
            const continent = this.continents[i];
            if (!continent || !this.map) return;
            const bounds = new window.google.maps.LatLngBounds();
            const officesWithLocation = continent.offices.filter((office) => office.location?.length);
            if (!officesWithLocation.length) return;

            officesWithLocation.forEach((office) => {
                bounds.extend({
                    lat: office.location[0],
                    lng: office.location[1],
                });
            });
            this.map.panTo(bounds.getCenter());
        },
    },
};
</script>

<style lang="postcss" scoped>
.locations {
    padding-top: 40px;
    padding-bottom: 80px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        padding-top: 64px;
    }

    .title {
        @mixin hero-accent;

        text-align: center;
        margin-bottom: 16px;
    }

    .lead {
        @mixin lead;
        font-weight: 500;
        text-align: center;
        margin: 0 auto 48px;

        @media (--viewport-tablet) {
            @mixin title;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 10;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 8;
        }
    }

    .columns {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: start;

        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }

    .continents {
        position: relative;
        order: 2;

        @media (--viewport-desktop) {
            @mixin colls 4;
            margin-inline-end: 16px;
        }

        @media (--viewport-desktop) {
            margin-inline-end: 32px;
        }
    }

    .map-container {
        position: relative;
        order: 1;
        width: 100%;

        @media (--viewport-desktop) {
            order: 2;
        }
    }

    .map-wrap {
        height: 354px;
        width: 100%;
        border: 1px solid var(--av-brand-light);
        border-radius: 12px;
        overflow: hidden;

        @media (--viewport-desktop) {
            height: 440px;
        }
    }

    .map {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:deep(.el-loading-parent--relative) {
            width: 100%;
            height: 100%;
        }
        &:deep(.marker) {
            transform: translateY(50%);
        }
    }

    .legend {
        @mixin body;

        display: flex;
        align-items: center;
        margin: 8px 0 40px;

        @media (--viewport-tablet) {
            justify-content: center;
        }

        @media (--viewport-desktop) {
            justify-content: flex-start;
            margin-bottom: 0;
        }
    }

    .marker-icon {
        width: 14px;
        height: 18px;
        margin-inline-end: 8px;
    }

    &:deep(.a-accordion-item) {
        margin-bottom: 8px;
        border-bottom: 0;
        border-top: 1px solid var(--av-brand-secondary-accent);

        &:last-of-type {
            padding-bottom: 8px;
        }

        .title {
            padding-inline-start: 0;
            padding-bottom: 8px;
            align-items: center;
            color: var(--av-nav-primary);
        }

        .content-wrapper {
            padding: 0 24px 8px;
        }
    }

    .arrow {
        fill: var(--av-nav-primary);
        margin-top: 1px;
        margin-inline-end: 8px;
    }

    .continent-title {
        @mixin paragraph;
        font-weight: 500;
    }

    .office {
        @mixin body;

        margin-bottom: 8px;
    }

    .link {
        color: var(--av-brand-primary);
        margin-top: 56px;

        &:deep(.a-link__content) {
            @mixin title;
            font-weight: 500;
        }

        &:deep(.a-glyph) {
            margin-bottom: 7px;
        }
    }

    &:deep(.a-accordion .a-accordion-item:last-of-type) {
        margin-bottom: 0;
    }
}
</style>
