@define-mixin large {
    font-size: 48px;
    line-height: 56px;
}

@define-mixin large-subtle {
    @mixin large;
    font-weight: 300;
    letter-spacing: -0.8px;
}

@define-mixin large-accent {
    @mixin large;
    font-weight: 700;
}

@define-mixin hero {
    font-size: 40px;
    line-height: 48px;
}

@define-mixin hero-subtle {
    @mixin hero;
    font-weight: 300;
    letter-spacing: -0.8px;
}

@define-mixin hero-accent {
    @mixin hero;
    font-weight: 700;
}

@define-mixin hero-strong {
    @mixin hero;
    font-weight: 800;
}

@define-mixin display {
    font-size: 32px;
    line-height: 40px;
}

@define-mixin display-accent {
    @mixin display;
    font-weight: 700;
}

@define-mixin display-strong {
    @mixin display;
    font-weight: 800;
}

@define-mixin title {
    font-size: 24px;
    line-height: 32px;
}

@define-mixin title-accent {
    @mixin title;
    font-weight: 700;
}

@define-mixin nav-title-accent {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
}

@define-mixin lead {
    font-size: 18px;
    line-height: 24px;
}

@define-mixin lead-accent {
    @mixin lead;
    font-weight: 700;
}
@define-mixin lead-strong {
    @mixin lead;
    font-weight: 800;
}

@define-mixin paragraph {
    font-size: 16px;
    line-height: 24px;
}

@define-mixin paragraph-accent {
    @mixin paragraph;
    font-weight: 700;
}

@define-mixin body {
    font-size: 14px;
    line-height: 24px;
}

@define-mixin body-accent {
    @mixin body;
    font-weight: 700;
}

@define-mixin body-heading {
    @mixin body-accent;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

@define-mixin descriptor {
    font-size: 14px;
    line-height: 20px;
}

@define-mixin descriptor-accent {
    @mixin descriptor;
    font-weight: 700;
}

@define-mixin caption {
    font-size: 12px;
    line-height: 16px;
}

@define-mixin caption-accent {
    @mixin caption;
    font-weight: 700;
}

@define-mixin note {
    font-size: 11px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

@define-mixin note-accent {
    @mixin note;
    font-weight: 700;
}

@define-mixin note-heading {
    @mixin note;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@define-mixin fineprint-heading {
    font-size: 10px;
    line-height: 16px;
    font-weight: var(--font-weight-fineprint-heading);
    letter-spacing: 4px;
    text-transform: uppercase;
}
