<template>
    <div v-if="isVisibleEdition" :class="className">
        <div
            v-for="(edition, editionIndex) in editionList"
            :id="`edition-${edition.product}`"
            :key="`edition-${editionIndex}`"
            :class="classEdition(edition)"
        >
            <div v-if="isRecommended(edition)" class="recommended">
                <div class="recommended-text">
                    <p>{{ translation('Recommended') }}</p>
                    <a-glyph name="add-action" />
                </div>
            </div>
            <div :class="['edition-content', { 'edition-active': priceEditionTotal(edition.product, 'price') }]">
                <p v-if="edition.label?.text" class="edition-label">
                    <a-label v-bind="{...edition.label, size: 'l', text: ''}">
                        {{ edition.label.text }}
                    </a-label>
                </p>
                <div class="edition-title-wrapper">
                    <p class="edition-title">
                        {{ edition.title }}
                    </p>
                    <a-tooltip v-if="edition.tooltip" :text="edition.tooltip">
                        <a-glyph name="info-circle" />
                    </a-tooltip>
                </div>
                <div
                    v-for="(item, itemIndex) in edition.content"
                    :key="`edition-${itemIndex}`"
                    :class="`edition-${item.name || ''}`"
                >
                    <template v-if="['message', 'description'].includes(item.name)">
                        {{ item.text }}
                    </template>

                    <template v-else-if="item.name === 'features'">
                        <p v-if="item.text" class="feature-description" v-html="item.text" />
                        <div v-for="(feature, featureIndex) in item.list" :key="`feature-${featureIndex}`" class="feature-item">
                            <div class="edition-feature" v-html="feature.category" />
                            <a-tooltip
                                v-if="feature.tooltip"
                                :text="feature.tooltip"
                                :class="['edition-tooltip', `edition-tooltip-type-${feature.type}`]"
                                popper-class="el-tooltip__purchasing-edition"
                            >
                                <a-glyph name="success" />
                                <span>{{ feature.name }}</span>
                            </a-tooltip>
                        </div>
                    </template>

                    <template v-else-if="item.name === 'price'">
                        <div v-if="hasDiscount(edition)" class="edition-price-item">
                            <p class="full" v-html="priceEdition(edition.product, 'full')" />
                            <p class="discount" v-html="priceDiscount(edition)" />
                        </div>
                        <p class="current" v-html="priceEdition(edition.product, 'current')" />
                    </template>

                    <template v-else-if="item.name === 'notification'">
                        <a-glyph name="info-circle-o" /> {{ item.text }}
                    </template>

                    <template v-else-if="item.name === 'dropdowns'">
                        <div class="edition-selector-workload">
                            <s-dropdown
                                :is-selector-active="edition.product === errorEdition"
                                :placeholder="dropdownPlaceholderWorkload(edition.product)"
                                :description="translation('Workload selection')"
                                :list="dropdownWorkloads(edition.product)"
                                @clickOpen="errorEdition = ''"
                                @changeItem="$emit('changeQuantity', $event)"
                                @changeQuantity="$emit('changeQuantity', $event)"
                            />
                        </div>
                        <div class="edition-selector-period">
                            <s-dropdown
                                :placeholder="titlePeriod(edition.selected)"
                                :description="translation('License period')"
                                :list="dropdownWorkloadsPeriod(edition.product)"
                                @changeItem="$emit('changeItem', $event)"
                            />
                        </div>
                    </template>

                    <template v-else-if="item.name === 'button'">
                        <a-button
                            size="s"
                            type="action"
                            glyph="shopping-cart-o"
                            :text="translation('Add to cart')"
                            :event="{ doNotSendGA: true }"
                            @click="toggleCart(edition)"
                        />
                    </template>

                    <template v-else-if="item.name === 'info' && item.list?.length">
                        <div
                            v-for="(info, infoIndex) in item.list"
                            :key="`info-${infoIndex}`"
                            :style="{'min-height': infoMinHeight[infoIndex]}"
                            :class="[`edition-info-content-${infoIndex}`]"
                        >
                            <p v-if="info.title" class="edition-info-title" v-html="info.title" />
                            <ul v-if="info.items">
                                <li
                                    v-for="(infoItem, infoItemIndex) in info.items"
                                    :key="`info-item-${infoItemIndex}`"
                                    class="edition-info-item"
                                    v-html="infoItem"
                                />
                            </ul>
                        </div>
                    </template>

                    <template v-else-if="item.name === 'quantity'">
                        {{ translation('Quantity') }}
                        <ANumberInput
                            :min="0"
                            :step="1"
                            :quantity="settings.licenses[editionIndex].quantity || 0"
                            :max="edition.licenses[0]?.quantityMax || 99"
                            @change="changeQuantity(edition, $event)"
                        />
                    </template>
                </div>
                <div v-if="settings?.page?.isVisibleComparisonLink" class="edition-compare">
                    <a-link
                        glyph="arrow"
                        :accent="true"
                        :text="translation('View all features')"
                        :event="{ doNotSendGA: true }"
                        @click="scrollTo('comparison-acp')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import SDropdown from './dropdown.vue';
import ANumberInput from './num-picker.vue';

export default {
    name: 'SEditions',

    components: {
        AButton,
        AGlyph,
        ALabel,
        ALink,
        ATooltip,
        SDropdown,
        ANumberInput,
    },

    mixins: [breakpoint, mixin, analytics, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleCart', 'changeItem', 'changeQuantity', 'changeLoading'],

    data: () => ({
        errorEdition: '',
        infoMinHeight: {},
    }),

    computed: {
        className() {
            return [
                's-purchasing-acp-editions',
                `s-purchasing-acp-editions-version-${this.settings.version}`,
            ];
        },
        isVisibleEdition() {
            return Boolean(this.settings.licenses?.length);
        },

        infoBlock() {
            const comparison = (list) => list.content.filter((item) => item.name === 'info' && item.list);
            return this.editionList.flatMap(comparison);
        },

    },

    mounted() {
        this.getMaxHeight();
        window.addEventListener('resize', this.getMaxHeight);
        if (this.settings.version !== 2) this.$emit('changeLoading', false);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.getMaxHeight);
    },

    methods: {
        changeQuantity(edition, quantity) {
            const item = this.settings.licenses.find((license) => license.product === edition.product);
            this.$emit('changeQuantity', { ...item, quantity, isChecked: Boolean(quantity) });
        },
        resetInfoMinHeight() {
            const quantity = Math.max(...this.infoBlock.map((item) => item.list?.length));
            this.infoMinHeight = { ...Array(quantity).fill('auto') };
        },

        getMaxHeight() {
            if (this.settings.version !== 2) return;
            this.resetInfoMinHeight();
            this.$nextTick(() => {
                if (this.isDesktop) {
                    Object.keys(this.infoMinHeight).forEach((index) => {
                        const list = document.querySelectorAll(`.edition-info-content-${index}`);
                        const result = [...list].map((item) => item.offsetHeight);
                        this.infoMinHeight[index] = `${Math.max(...result)}px`;
                    });
                }
                this.$emit('changeLoading', false);
            });
        },

        priceDiscount(edition) {
            const total = this.priceEditionTotal(edition.product, 'price');
            const discount = this.priceEditionTotal(edition.product, 'discount_absolute');

            const price = total || edition.from;
            const full = total + discount || edition.fromFull;

            const percent = Math.round(100 - (price / full) * 100);

            if (!percent) return '';
            return this.translation('Save @percent').replace('@percent', `${percent}%`);
        },

        classEdition(edition) {
            return ['edition', { 'edition-recommended': this.isRecommended(edition) }];
        },

        isRecommended(edition) {
            const activeWorkload = this.settings.workloads.find((item) => item.selected) || {};
            return edition.product === activeWorkload.recommended;
        },

        hasDiscount(edition) {
            const full = this.priceEdition(edition.product, 'full');
            const current = this.priceEdition(edition.product, 'current');
            return this.settings.promo.isPromoActive && full !== current;
        },

        currentworkloads(name) {
            const period = this.currentPeriod(name);
            const comparison = (item) => item.quantity && item.product === name && item.period === period;
            return this.settings.licenses.filter(comparison);
        },

        dropdownPlaceholderWorkload(name) {
            const list = this.currentworkloads(name);
            const quantity = list.length;
            const license = list?.[0] || {};
            const workloadTitle = this.currentWorkload(license.workload)?.title || '';
            const selected = `${workloadTitle} (${license.quantity * license.multiplier})`;
            const placeholder = quantity > 1 ? this.translation('@quantity Item(s) selected') : selected;
            return quantity ? placeholder.replace('@quantity', quantity) : this.translation('Select workload');
        },

        dropdownWorkloads(name) {
            const period = this.currentPeriod(name);
            return this.settings.licenses
                .filter((item) => item.product === name && item.period === period)
                .map((item) => ({
                    ...item,
                    hasCheckbox: true,
                    hasNumberPicker: true,
                    isChecked: item.isChecked,
                    text: this.currentWorkload(item.workload).title || '',
                    subtitle: this.currentWorkload(item.workload).subtitle || '',
                }));
        },

        dropdownWorkloadsPeriod(name) {
            let promo = '';
            let pricePerYear = 0;
            const list = this.settings.licenses.filter((item) => item.product === name).map((item) => item.period);

            return [...new Set(list)].map((period) => {
                const price = this.priceEditionPeriod(name, period);
                if (period === 1) {
                    pricePerYear = price;
                }
                if (price && period > 1) {
                    const percent = Math.round(100 - (100 * price) / (pricePerYear * period));
                    const promoText = this.translation('Save @percent');
                    promo = promoText.replace('@percent', `${percent}%`);
                }
                return {
                    period,
                    product: name,
                    text: `${this.titlePeriod(period)}`,
                    price: price ? this.setCurrency(price) : '',
                    promo,
                };
            });
        },

        priceEditionPeriod(name, period) {
            return this.currentEdition(name)
                .filter((item) => item.quantity && item.period === this.currentPeriod(name))
                .map((item) => {
                    const comparison = (i) => i.workload === item.workload && i.period === period && i.product === name;
                    const license = this.settings.licenses.find(comparison);
                    return license.store.price * item.quantity;
                })
                .reduce((a, b) => a + b, 0);
        },

        errorEditionClear() {
            this.errorEdition = '';
            window.removeEventListener('click', this.errorEditionClear, true);
        },

        toggleCart(edition) {
            if (this.priceEditionTotal(edition.product, 'price')) {
                this.$emit('toggleCart', edition.product);
                return;
            }
            this.errorEdition = edition.product;
            window.addEventListener('click', this.errorEditionClear, true);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-editions {
    gap: 24px;
    display: flex;
    margin-top: 52px;
    margin-bottom: 40px;
    flex-direction: column;
    @media (--viewport-tablet) {
        width: 468px;
        margin-inline-end: auto;
        margin-inline-start: auto;
    }
    @media (--viewport-desktop) {
        gap: 16px;
        width: 100%;
        margin-top: 66px;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
    }
}
.edition {
    position: relative;
    padding: 0 2px 2px 2px;
    @media (--viewport-desktop) {
        width: 100%;
    }
}
.edition-recommended {
    margin-top: 32px;
    padding: 0 2px 2px 2px;
    border-end-end-radius: 9px;
    border-end-start-radius: 9px;
    background: var(--av-nav-primary);
    @media (--viewport-desktop) {
      margin-top: 0;
    }
    &:deep(.dropdown) {
        .title {
            color: var(--av-fixed-primary);
        }
    }
}
.edition-content {
    height: 100%;
    padding: 24px;
    border-radius: 8px;
    position: relative;
    background: var(--av-inversed-primary);
    border: 2px solid var(--av-brand-light);
    @media (--viewport-desktop) {
        width: 100%;
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
    }
    &:deep(.dropdown) {
        .title {
            color: rgba(36, 49, 67, 0.7);
        }
    }
    &.edition-active {
        background: var(--av-solid-brand-lightest);
        &:deep(.dropdown) {
            .title {
                color: var(--av-fixed-primary);
            }
        }
    }
}
.edition-title-wrapper {
    gap: 8px;
    display: flex;
    align-items: center;
    &:deep(.a-tooltip) {
        .el-tooltip {
            display: flex;
        }
    }
    &:deep(.a-glyph ) {
        width: 14px;
        fill: var(--av-nav-primary);
    }
}
.edition-title {
    @mixin lead-strong;
    color: var(--av-nav-primary);
    @media (--viewport-desktop) {
        text-align: start;
        padding-inline-start: 0;
    }
    @media (--viewport-desktop-wide) {
        padding-inline-end: 128px;
    }
}
.edition-description {
    @mixin body;
    margin: 8px 0 0;
    color: var(--av-fixed-secondary);
    @media (--viewport-desktop) {
        text-align: start;
    }
}

.edition-message {
    @mixin body-accent;
    margin-top: 8px;
    color: rgba(124, 155, 30, 1);
}

.edition-price {
    padding-top: 16px;
    @media (--viewport-desktop) {
        margin-top: auto;
    }
    .current {
        @mixin nav-title-accent;
        color: var(--av-nav-primary);
    }
}
.edition-price-item {
    gap: 0 8px;
    display: flex;
    flex-wrap: wrap;
    .full {
        text-decoration: line-through;
    }
    .discount {
        font-weight: 700;
        color: rgba(124, 155, 30, 1);
    }
}
.edition-features {
    margin-top: auto;
    padding-top: 32px;
    @media (--viewport-tablet) {
        padding-top: 24px;
    }
    @media (--viewport-desktop) {
        margin-top: auto;
        padding-top: 8px;
    }
    ~ .edition-price {
        margin-top: 0;
    }
    .feature-description {
        @mixin body-accent;
        color: var(--av-nav-primary);
    }
    .edition-feature {
        @mixin body;
        color: var(--av-fixed-primary);
    }
    .feature-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        &:not(:first-child) {
            margin-top: 8px;
        }
    }
    .edition-tooltip {
        cursor: pointer;
        padding: 4px 8px;
        min-width: 118px;
        user-select: none;
        border-radius: 8px;
        background: var(--av-brand-secondary-accent);
        border: 1px solid var(--av-brand-secondary);

        &:deep(.tooltip-wrapper) {
            display: flex;
            align-items: center;
        }

        &:deep(.a-glyph) {
            margin-inline-end: 8px;
            fill: var(--av-brand-primary);
        }
    }

    .edition-tooltip-type-1 {
        background: var(--av-fixed-success-accent);
        border-color: var(--av-fixed-success);

        &:deep(.a-glyph) {
            fill: var(--av-fixed-success);
        }
    }
}
.edition-notification {
    @mixin body-accent;

    display: flex;
    padding: 8px;
    margin: 8px 0 0;
    border-radius: 4px;
    align-items: flex-start;
    color: var(--av-fixed-success-dark);
    background: var(--av-fixed-success-accent);

    .a-glyph {
        margin-top: 4px;
        margin-inline-end: 8px;
        fill: var(--av-fixed-success-dark);
    }
}

.edition-selector-workload {
    margin-top : 8px;
}

.edition-selector-period {
    margin: 8px 0 0;
}

.edition-button {
    margin: 24px 0 0;

    .a-button {
        @mixin paragraph-accent;
        width: 100%;
        justify-content: center;
        flex-direction: row-reverse;
    }

    &:deep(.a-glyph) {
        margin-inline-start: 0;
        margin-inline-end: 8px;
    }
}

.edition-label {
    z-index: 1;
    width: 100%;
    bottom: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    inset-inline-start: 0;
    justify-content: center;

    &:deep(.a-label) {
        top: 12px;
        height: 24px;
        margin: 0 16px;
        overflow: hidden;
        text-align: center;
    }
}

.edition-info-title {
    @mixin body-accent;
    margin-top: 16px;
    color: var(--av-fixed-primary);
}

.edition-info-item {
    @mixin caption;
    margin-top: 8px;
    position: relative;
    padding-inline-start: 16px;
    color: var(--av-nav-primary);
    &::before {
        top: 6px;
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        inset-inline-start: 6px;
        background: var(--av-nav-primary);
    }
}

.edition-compare {
    margin-top: 16px;
    text-align: center;
}

.recommended {
    z-index: 1;
    width: 100%;
    bottom: 100%;
    height: 42px;
    display: flex;
    padding-top: 8px;
    position: absolute;
    inset-inline-start: 0;
    justify-content: center;
    background: var(--av-nav-primary);
    border-start-end-radius: 8px;
    border-start-start-radius: 8px;
}

.recommended-text {
    @mixin note-heading;
    display: flex;
    color: var(--av-inversed-primary);
    &:deep(.a-glyph) {
        fill: var(--av-inversed-primary);
    }
}
.s-purchasing-acp-editions-version-3 {
    gap: 0;
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.10);
    border: 1px solid var(--av-brand-secondary-light);
    @media (--viewport-tablet) {
        width: auto;
        flex-direction: row;
    }
    .edition {
        padding: 0;
        &:not(:first-child) {
            border-block-start: 1px solid var(--av-brand-secondary-light);
        }
        @media (--viewport-tablet) {
            flex: 1;
            &:not(:first-child) {
                border-block-start: none;
                border-inline-start: 1px solid var(--av-brand-secondary-light);
            }
        }
    }
    .edition-content {
        border: none;
        border-radius: 0;
        padding: 16px 16px 24px;
        @media (--viewport-tablet) {
            display: flex;
            padding: 8px 8px 24px;
            flex-direction: column;
        }
        @media (--viewport-desktop) {
            padding: 16px 16px 24px;
        }
    }
    .edition-title {
        @mixin lead-accent;
        padding-inline-end: 0;
        color: var(--av-nav-primary);
    }
    .edition-description {
        @mixin descriptor;
        color: var(--av-fixed-primary);
    }
    .edition-message {
        @mixin caption-accent;
        margin-top: auto;
        padding-top: 16px;
        color: var(--av-fixed-info-dark);
    }
    .edition-price {
        margin-top: 0;
        .current {
            @mixin paragraph-accent;
            color: var(--av-nav-primary);
        }
    }
    .edition-quantity {
        @mixin caption;
        display: flex;
        margin-top: 8px;
        align-items: center;
        justify-content: space-between;
        color: var(--av-fixed-secondary);
    }
    .number-wrapper {
        @mixin body;
        &:deep(.number-input) {
            input {
                @media(--viewport-tablet) {
                    max-width: 40px;
                }
            }
        }
    }
}
</style>
