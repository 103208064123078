<template>
    <section :id="id" class="s-light-wrapper-slice" :class="basicClassName">
        <div v-if="backgroundImage" class="bg-wrapper">
            <a-picture :background="backgroundImage" :fit="backgroundFit" />
        </div>
        <component
            :is="slice.name"
            v-for="(slice, index) in slices"
            v-bind="slice.data"
            :key="index"
            class="s-light-wrapper-slice__item"
        />
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import validateFit from '@core/components/picture/validateFit.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SLightWrapperSlice',

    components: {
        APicture,
    },

    mixins: [styleMixin],

    props: {
        backgroundImage: {
            type: Object,
            default: null,
        },
        /**
         * Image's "object-fit" property.
         *
         * If we want always 'contain' but 'cover' only on desktop
         * e.g. { default: 'contain', desktop: 'cover' }
         */
        backgroundFit: {
            type: [String, Object],
            required: false,
            default: 'cover',
            validator: validateFit,
        },
        slices: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
section {
    position: relative;
    .bg-wrapper {
        inset: 0;
        position: absolute;
        width: 100%;
        .a-picture,
        &:deep(.a-picture__img) {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
