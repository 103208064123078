<template>
    <section
        :id="id"
        class="top-highlights"
        :class="basicClassName"
        v-bind="$attrs"
    >
        <div class="a-container">
            <div class="wrapper">
                <div v-if="title" class="title">
                    {{ title }}
                </div>
                <div v-if="cards" class="cards">
                    <div
                        v-for="(card, index) in cards"
                        :key="`top_highlights_card_${index}`"
                        class="card"
                    >
                        <template v-if="card.to">
                            <dynamic-link :to="card.to">
                                <a-picture :link="card.image" :alt="card.title" class="image" />
                                <span v-if="card.title" class="title">
                                    {{ card.title }}
                                </span>
                                <div v-if="card.description" class="excerpt">
                                    {{ card.description }}
                                </div>
                            </dynamic-link>
                        </template>
                        <template v-else>
                            <a-picture :link="card.image" :alt="card.title" class="image" />
                            <span v-if="card.title" class="title">
                                {{ card.title }}
                            </span>
                            <div v-if="card.description" class="excerpt">
                                {{ card.description }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    components: {
        APicture,
        DynamicLink,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        cards: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.top-highlights {
    padding: 64px 0;
    .wrapper {
        padding: 24px 24px 48px;
        background: var(--av-brand-secondary-accent);
        border-radius: 16px;
        color: var(--av-nav-primary);
        .title {
            @mixin hero-accent;
            margin-bottom: 56px;
            max-width: 600px;
            @media (--viewport-desktop-wide) {
                max-width: 763px;
            }
        }
        .cards {
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            gap: 48px 24px;
            @media (--viewport-mobile-wide) {
                grid-template-columns: repeat(3, 1fr);
                gap: 56px 24px;
            }
            @media (--viewport-desktop) {
                grid-template-columns: repeat(5, 1fr);
            }
            .card {
                .image {
                    display: block;
                    border-radius: 8px;
                    margin-bottom: 16px;
                    overflow: hidden;
                    &:deep(img) {
                        width: 100%;
                    }
                }
                .title {
                    display: block;
                    @mixin lead-accent;
                    margin-bottom: 8px;
                }
                .excerpt {
                    @mixin descriptor;
                    color: var(--av-fixed-secondary);
                }
            }
        }
    }
}
</style>
