<template>
    <div class="product-split">
        <div class="a-container">
            <div class="table">
                <a-table-new v-bind="table" />
            </div>
            <div v-if="buyblock" class="buyblock">
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div class="buyblock__cell-inner">
                            <div v-if="item.text" class="buyblock__text">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div class="buyblock__cell-inner">
                            <a-product-cta :settings="{ hasDescription: true, ...item.productPageCTA }" />
                            <a-button
                                v-if="item.button"
                                class="buyblock__button"
                                v-bind="item.button"
                            />
                        </div>
                    </div>
                </div>
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div v-for="(link, j) in item.links" :key="j" class="buyblock__link-wrap">
                            <a-link class="buyblock__link" v-bind="link" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import ATableNew from '@core/components/table-new/table-new.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';

export default {
    name: 'SProductSplit',

    components: {
        AButton,
        ALink,
        AProductCta,
        ATableNew,
    },

    mixins: [breakpoint, purchasing, analytics, cleverbridge],

    props: {
        header: {
            type: Object,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        table: {
            type: Object,
            required: true,
        },
        buyblock: {
            type: [Object, Boolean],
            default: false,
        },
    },

    data() {
        return {
            EVENT_TIMEOUT: 10 * 1000,
        };
    },

    mounted() {
        setTimeout(() => {
            this.sendDataLayer({
                eventCategory: 'Timing',
                eventContext: 'timing',
                eventAction: this.$route.path,
                eventLabel: '10sec',
            });
        }, this.EVENT_TIMEOUT);
    },

    methods: {
        sendDataLayer(data) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                ...data,
            });
        },
    },

    async serverPrefetch() {
        await this.initSettingsCTA(this.buyblock.items);
    },
};
</script>

<style lang="postcss" scoped>
.product-split {
    padding-top: 0;
    padding-bottom: 80px;
    border-bottom: 1px solid var(--av-brand-secondary-accent);

    .table {
        margin-bottom: 40px;
    }

    .buyblock {
        &__row {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &__cell {
            width: calc(50% - 8px);
            display: flex;
            flex-flow: row wrap;

            @media (--viewport-tablet) {
                flex: 1;
                padding-inline-start: 8px;
                padding-inline-end: 8px;
                width: auto;
            }

            &_empty {
                display: none;

                @media (--viewport-tablet) {
                    display: block;
                }
            }
        }

        &__cell-inner {
            max-width: 276px;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
        }

        &__text {
            @mixin body;

            margin-bottom: 24px;
        }

        &__button {
            width: 100%;
            display: flex;
            margin-bottom: 24px;
            margin-top: auto;
        }

        &__link-wrap {
            margin-bottom: 4px;
            width: 100%;
        }

        &__link {
            @mixin body;
        }
    }
}
</style>
