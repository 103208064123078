@define-mixin border-top {
    &_border-top {
        position: relative;
        margin-top: 1px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            height: 1px;
            background-color: var(--av-brand-secondary-accent);
        }
        &_none {
            margin: 0;
        }
        &_border-dark {
            &::before {
                background-color: var(--av-brand-secondary-light);
            }
        }

        &_full-width {
            &::before {
                width: 100%;
            }
        }

        &_content-wide {
            &::before {
                inset-inline-start: var(--container-paddings-mobile);
                inset-inline-end: var(--container-paddings-mobile);
                width: auto;

                @media (--viewport-mobile-wide) {
                    inset-inline-start: var(--container-paddings-mobile-wide);
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-start: var(--container-paddings-desktop-wide);
                    inset-inline-end: var(--container-paddings-desktop-wide);
                }

                @media (--viewport-desktop-large) {
                    max-width: 1312px;
                    inset-inline-start: var(--container-paddings-desktop-large);
                    inset-inline-end: var(--container-paddings-desktop-large);
                    margin: 0 auto;
                }
            }
        }

        &_short {
            &::before {
                inset-inline-start: 50%;
                right: unset;
                width: 65%;
                max-width: 760px;
                transform: translateX(-50%);
            }
        }
    }
}

@define-mixin border-bottom {
    &_border-bottom {
        position: relative;
        margin-bottom: 1px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            height: 1px;
            background-color: var(--av-brand-secondary-accent);
        }
        &_none {
            margin: 0;
        }
        &_border-dark {
            &::after {
                background-color: var(--av-brand-secondary-light);
            }
        }

        &_full-width {
            &::after {
                width: 100%;
            }
        }

        &_content-wide {
            &::after {
                inset-inline-start: var(--container-paddings-mobile);
                inset-inline-end: var(--container-paddings-mobile);
                width: auto;

                @media (--viewport-mobile-wide) {
                    inset-inline-start: var(--container-paddings-mobile-wide);
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-start: var(--container-paddings-desktop-wide);
                    inset-inline-end: var(--container-paddings-desktop-wide);
                }

                @media (--viewport-desktop-large) {
                    max-width: 1312px;
                    inset-inline-start: var(--container-paddings-desktop-large);
                    inset-inline-end: var(--container-paddings-desktop-large);
                    margin: 0 auto;
                }
            }
        }

        &_short {
            &::after {
                inset-inline-start: 50%;
                right: unset;
                width: 65%;
                max-width: 760px;
                transform: translateX(-50%);
            }
        }
    }
}

@define-mixin background {
    &_white {
        background: rgba(255, 255, 255, 0);
    }

    &_light-blue {
        background: var(--av-brand-lightest);
    }

    &_dark-blue {
        background: var(--av-nav-primary);
    }

    &_l-b-gradient-top {
        background: var(--av-gradient-to-top-lightest);
    }

    &_l-b-gradient-bottom {
        background: var(--av-gradient-to-bottom-lightest);
    }

    &_n-p-gradient-top {
        background: linear-gradient(rgb(25, 58, 131), rgb(0, 32, 77));
    }
}

@define-mixin indents-borders $class {
    .$class {
        &.s-slice_first-child-padding {
            padding-top: 0;

            .$class {
                &__item:not(:first-child) {
                    padding-top: 0;
                }
            }
        }

        &__item {
            padding: 0;

            &:not(:first-child) {
                padding-top: 80px;

                @media (--viewport-desktop) {
                    padding-top: 104px;
                }
            }

            &.s-slice_border-bottom {
                padding-bottom: 48px;
            }
        }

        &.s-slice_indent {
            &_medium {
                .$(class)__item {
                    &:not(:first-child) {
                        padding-top: 48px;
                    }

                    &.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }
                }
            }

            &_small {
                .$(class)__item {
                    &:not(:first-child) {
                        padding-top: 48px;
                    }

                    &.s-slice_border-bottom {
                        padding-bottom: 48px;
                    }
                }
            }
        }
    }
}

@define-mixin aspect-ratio {
    position: relative;

    & > :first-child {
        width: 100%;
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
    }

    &::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / (3 / 4));
    }

    & > img {
        height: auto;
    }
}

@define-mixin colls $num {
    width: calc(((100% - (16px * 11)) / 12) * $num + (16px * ($num - 1)));
}

/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */
@define-mixin colspan $base, $standard {
    &.a-table__cell_colspan {
        &_2 {
            flex: 0 0 calc($base + $standard);
        }

        &_3 {
            flex: 0 0 calc($base + ($standard * 2));
        }

        &_4 {
            flex: 0 0 calc($base + ($standard * 3));
        }

        &_5 {
            flex: 0 0 calc($base + ($standard * 4));
        }

        &_6 {
            flex: 0 0 calc($base + ($standard * 5));
        }

        &_7 {
            flex: 0 0 calc($base + ($standard * 6));
        }

        &_8 {
            flex: 0 0 calc($base + ($standard * 7));
        }
    }
}

@define-mixin spin $time {
    animation: spinning $time linear infinite;
}

@define-mixin page-height {
    /* On some pages we need 100% height thus giving nuxt elements 100% height
    * so we don't use 100vh due to mobile browser address/bottom bars covering viewport
    */
    #root,
    #router-view,
    .page-container,
    .page {
        height: 100%;
    }
}

/* TODO: Replace with global class with base horizontal padding */
@define-mixin page-horizonal-paddings {
    padding: 0 16px;

    @media (--viewport-tablet) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 64px;
    }
}

/* TODO: Replace with global class with base vertical padding */
@define-mixin basic-slice-paddings {
    padding-top: 48px;
    padding-bottom: 48px;
}
