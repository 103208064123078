<template>
    <section :id="id" class="s-awards-ribbon" :class="{ ...basicClassName, ...className }">
        <div class="a-container">
            <div class="s-awards-ribbon__wrapper">
                <h4 v-if="l10n.title" class="s-awards-ribbon__title">
                    {{ l10n.title }}
                </h4>
                <ul class="s-awards-ribbon__list">
                    <li v-for="(item, index) in l10n.awards" :key="index" class="s-awards-ribbon__list__item">
                        <a-picture :link="item" />
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SAwardsRibbon',
    components: {
        APicture,
    },
    mixins: [contentMixin, styleMixin, l10n],
    props: {
        title: {
            type: String,
            default: undefined,
        },
        newRowAfter: {
            type: Number,
            default: 7,
        },
        awards: {
            type: Array,
            required: true,
        },
    },
    computed: {
        className() {
            return {
                's-awards-ribbon__withTitle': this.l10n.title,
                's-awards-ribbon__longText': this.l10n.title && this.l10n.title.split('').length > 30,
                's-awards-ribbon__fullWidth': this.l10n.title && this.l10n.awards.length >= this.newRowAfter,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-awards-ribbon {
    padding: 40px 0;
    @media (--viewport-desktop-wide) {
        padding: 40px 0 48px;
    }
    @media (--viewport-desktop-large) {
        padding: 40px 0;
    }
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }
    &__title {
        @mixin colls 12;
        @mixin title-accent;
        color: var(--av-nav-primary);
        margin-bottom: 25px;
        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
        @media (--viewport-desktop) {
            @mixin colls 3;
            margin: 0;
        }
    }
    &__list {
        @mixin colls 12;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 40px 48px;
        @media (--viewport-tablet) {
            gap: 64px;
        }
        @media (--viewport-desktop) {
            flex-flow: row nowrap;
            gap: 80px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            &:deep(img) {
                max-height: 48px;
                width: auto;
            }
        }
    }
    &__longText {
        .s-awards-ribbon__list {
            flex-flow: row wrap;
        }
        .s-awards-ribbon__wrapper {
            align-items: flex-start;
        }
    }
    &__withTitle {
        @media (--viewport-desktop-wide) {
            padding: 48px 0 40px;
        }
        @media (--viewport-desktop-large) {
            padding: 48px 0;
        }
        .s-awards-ribbon__list {
            gap: 40px 48px;
            padding-inline-start: 0;
            @media (--viewport-desktop) {
                @mixin colls 9;
                gap: 48px;
                padding-inline-start: 48px;
            }
            @media (--viewport-desktop-wide) {
                gap: 64px;
                padding-inline-start: 64px;
            }
            @media (--viewport-desktop-large) {
                gap: 80px;
                padding-inline-start: 80px;
            }
        }
    }
    &__fullWidth {
        .s-awards-ribbon__title {
            @mixin colls 12;
            margin-bottom: 40px;
        }
        .s-awards-ribbon__list {
            flex-flow: row wrap;
            @media (--viewport-desktop) {
                @mixin colls 12;
                padding-inline-start: 0;
            }
        }
    }
    &.s-slice_background_dark-blue {
        .s-awards-ribbon__title {
            color: var(--av-inversed-primary);
        }
    }
}
</style>
