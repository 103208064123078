<template>
    <div v-if="isVisible" class="main-page-cta">
        <p v-if="title" class="main-page-cta-title" v-html="title" />
        <p class="main-page-cta-content">
            <template v-for="(item, i) in content">
                <a-link v-if="item.isLink" :key="`item-cta-${i}`" v-bind="item" />
                <a-button
                    v-else
                    :key="`item-cta-${i}`"
                    v-bind="{ type: 'main', ...item }"
                    @click="$emit('click')"
                />
            </template>
        </p>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SMainPageCTA',
    components: {
        AButton,
        ALink,
    },
    props: {
        content: {
            type: Array,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
    },
    emits: ['click'],
    computed: {
        isVisible() {
            return this.content?.length;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-cta {
    .main-page-cta-title {
        @mixin paragraph-accent;
        margin-top: 8px;
        color: var(--av-nav-primary);
        @media (--viewport-desktop-wide) {
            margin-top: 16px;
        }
        @media (--viewport-desktop-large) {
            margin-top: 42px;
        }
    }
    .main-page-cta-content {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }
    &:deep(.a-button) {
        width: 100%;
        justify-content: center;
        .a-button__glyph {
            margin-inline-start: 8px;
        }
        @media (--viewport-desktop) {
            flex: 1 0 0;
            width: auto;
        }
    }
}
</style>
