<template>
    <s-basic-slice class="s-tabs" v-bind="l10n">
        <a-tabs
            :type="type"
            :type-width="typeWidth"
            :no-top-spacing="noTopSpacing"
            :no-bottom-spacing="noBottomSpacing"
            :use-small-tabs="useSmallTabs"
        >
            <a-tab
                v-for="(slice, index) in l10n.slices"
                :key="index"
                :label="slice.label"
                :anchor-id="slice.anchorId"
            >
                <component :is="slice.name" class="s-tabs__item" v-bind="slice.data" />
            </a-tab>
        </a-tabs>
    </s-basic-slice>
</template>

<script>
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import l10n from '@core/mixins/l10n.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'STabs',
    components: {
        ATab,
        ATabs,
        SBasicSlice,
    },
    mixins: [l10n],

    inheritAttrs: false,

    props: {
        slices: {
            type: Array,
            default: () => [],
        },
        noTopSpacing: {
            type: Boolean,
            required: false,
            default: false,
        },
        noBottomSpacing: {
            type: Boolean,
            required: false,
            default: false,
        },
        useSmallTabs: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: undefined,
        },
        typeWidth: {
            type: String,
            required: false,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-tabs {
    &:deep(.a-container .a-container) {
        padding: 0;
    }

    &__item {
        padding: 0;
    }
}
</style>
