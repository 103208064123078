/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

/* WEB-43924 Check all z-indexes here */

.s-main-header {
    position: absolute;
    top: 24px;
    width: 100%;
    z-index: 400;
}

.s-main-header .logged-in {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 999px;
    }

.s-main-header .logged-in .a-glyph {
            margin-inline-end: 2px;
        }

.s-main-header--menu-active {
        z-index: 999;
    }

@media (min-width: 1280px) {

.s-main-header {
        top: 32px
}
                            .s-main-header_state_active .s-main-header__menu-link_active::before {
                                opacity: 1;
                            }
                                .s-main-header.s-main-header_state_active-light .s-main-header__menu-link_active::before {
                                    background: var(--av-solid-brand-accent);
                                }
                    .s-main-header.s-main-header_state_active-light .s-main-menu {
                        z-index: 100;
                    }
    }

.s-main-header__logo {
        height: 27px;
        width: 133px;
    }

@media (min-width: 1024px) {

.s-main-header__logo {
            height: 32px;
            width: 158px
    }
        }

@media (min-width: 768px) {

.s-main-header__logo-container {
                margin-inline-end: 48px
        }
            }

.s-main-header__logo.icon {
        width: 38px;
    }

.s-main-header__content {
        z-index: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

@media (min-width: 1280px) {

.s-main-header__content {
            justify-content: flex-start
    }
        }

.s-main-header__nav {
        z-index: 100;
    }

.s-main-header__menu {
        display: none;
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        width: 100%;
        height: 0;
        background: var(--av-inversed-primary);
    }

@media (min-width: 1280px) {

.s-main-header__menu {
            position: static;
            height: auto !important;
            background: rgba(255, 255, 255, 0);
            display: flex;
            width: auto;
            margin-inline-end: auto
    }
        }

.s-main-header__menu-item {
            padding: 10px 0;
        }

@media (min-width: 1280px) {

.s-main-header__menu-item {
                padding: 0
        }
            }

.s-main-header__menu-item:first-of-type .s-main-header__menu-link {
                    padding-inline-start: 0;
                }

.s-main-header__menu-item:last-of-type .s-main-header__menu-link {
                    padding-inline-end: 0;
                }

.s-main-header__menu-link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            padding: 0 12px;
            background: none;
            border: none;
            color: var(--av-nav-secondary);
            cursor: pointer;
        }

.s-main-header__menu-link::before {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 12px);
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 27px;
                height: 27px;
                background: linear-gradient(
                    135deg,
                    rgb(255, 255, 255) 10px,
                    rgba(255, 255, 255, 0) 10px
                );
                pointer-events: none;
            }

@media (min-width: 1440px) {

.s-main-header__menu-link::before {
                    top: calc(100% + 17px)
            }
                }

@media (min-width: 1024px) {

.s-main-header__menu-link {
                position: relative;
                text-transform: capitalize;
                color: var(--av-inversed-primary)
        }
            }

.s-main-header__buttons {
        border: 0;
        z-index: 105;
        height: 24px;
        display: flex;
        margin-inline-start: auto;
        position: relative;
        align-items: center;
    }

.s-main-header__buttons.noText .s-main-header__contact-text,
            .s-main-header__buttons.noText .s-main-header__login-text {
                display: none;
            }

.s-main-header__buttons.noText .s-login-menu,
            .s-main-header__buttons.noText .s-contact-menu {
                top: calc(100% + 16px);
            }

.s-main-header__buttons::before {
            top: 0;
            inset-inline-end: 0;
            width: 1px;
            content: '';
            height: 24px;
            position: absolute;
        }

.s-main-header__buttons > div {
            position: relative;
            height: 24px;
            display: flex;
            align-items: center;
        }

.s-main-header__buttons > div::before {
                width: 1px;
                position: absolute;
                content: '';
                background-color: var(--av-fixed-info-dark);
                inset-inline-start: 0;
                top: 0;
                bottom: 0;
            }

.s-main-header__buttons > div:first-of-type {
                padding-inline-start: 0;
            }

.s-main-header__buttons > div:first-of-type::before {
                    width: 0;
                }

.s-main-header__buttons > div {

            margin-inline-end: 0;
            padding-inline-start: 12px;
            padding-inline-end: 12px;
}

@media (min-width: 576px) {

.s-main-header__buttons > div {
                padding-inline-start: 20px;
                padding-inline-end: 20px
        }
            }

@media (min-width: 1280px) {
            .s-main-header__buttons::before {
                display: none;
            }
        }

.s-main-header__lang {
        margin: 0;
        z-index: 100;
    }

.s-main-header__lang-btn {
            margin: 0;
            padding: 0;
            display: flex;
            border: none;
            cursor: pointer;
            background: none;
            align-items: center;
        }

.s-main-header__lang-btn.short-version .s-main-header__lang-text {
                        visibility: hidden;
                        position: absolute;
                    }

.s-main-header__lang-btn.hidden {
                visibility: hidden;
                white-space: nowrap;
            }

.s-main-header__lang-text {
            overflow: hidden;
            max-width: 56px;
            margin-inline-start: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

@media (min-width: 1024px) {

.s-main-header__lang-text {
                margin-inline-end: 8px;
                display: inline-block
        }
            }

.s-main-header__contact,
    .s-main-header__login,
    .s-main-header__search {
        z-index: 90;
    }

.s-main-header__lang,
    .s-main-header__contact,
    .s-main-header__login {
        margin-inline-end: 16px;
        position: relative;
        white-space: nowrap;
    }

@media (min-width: 1024px) {

.s-main-header__lang,
    .s-main-header__contact,
    .s-main-header__login {
            margin-inline-end: 24px
    }
        }

.s-main-header__lang-btn, .s-main-header__contact-btn, .s-main-header__login-btn {
            display: flex;
            border: none;
            cursor: pointer;
            overflow: hidden;
            background: none;
            position: relative;
            align-items: center;
            padding: 0 0 21px 0;
            margin: 0 0 -21px 0;
        }

.s-main-header__lang-btn::before, .s-main-header__contact-btn::before, .s-main-header__login-btn::before {
                content: '';
                opacity: 0;
                width: 24px;
                z-index: 102;
                height: 24px;
                position: absolute;
                pointer-events: none;
                top: calc(100% - 4px);
                inset-inline-start: calc(50% - 12px);
                transform: rotate(45deg);
                background: var(--av-inversed-primary);
                border: 1px solid var(--av-brand-secondary);
            }

.s-main-header__lang-btn_active::before, .s-main-header__contact-btn_active::before, .s-main-header__login-btn_active::before {
                    opacity: 1;
                }

.s-main-header__contact-text, .s-main-header__login-text {
            overflow: hidden;
            margin-inline-start: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

.s-main-header__login-glyph-wrapper {
            display: flex;
            align-items: center;
        }

.s-main-header__search {
        height: 100%;
    }

.s-main-header__search-link {
            height: 100%;
            display: block;
            padding: 4px 0 0;
        }

@media (min-width: 768px) {

.s-main-header__search {
            margin-inline-end: 16px
    }
        }

@media (min-width: 1024px) {

.s-main-header__search {
            margin: 0;
            display: block !important
    }
        }

.s-main-header .menu-toggler {
        padding: 0;
        border: none;
        display: flex;
        z-index: 100;
        height: 32px;
        min-width: 48px;
        cursor: pointer;
        background: none;
        position: relative;
        align-items: center;
        justify-content: flex-end;
    }

.s-main-header .menu-toggler.noText {
            min-width: 40px;
        }

.s-main-header .menu-toggler.noText.menu-toggler_state_active .menu-toggler__icon {
                margin-inline-end: 12px;
            }

.s-main-header .menu-toggler.noText .menu-toggler__caption {
                display: none;
            }

@media (min-width: 576px) {

.s-main-header .menu-toggler {
            min-width: 83px
    }
        }

.s-main-header .menu-toggler__caption {
            font-size: 14px;
            line-height: 24px;
            padding: 0 8px 0 16px;
        }

.s-main-header .menu-toggler__caption-opened-text {
            display: none;
        }

.s-main-header .menu-toggler__icon {
            width: 32px;
            height: 32px;
            transition: none;
            fill: var(--av-inversed-primary);
        }

.s-main-header .menu-toggler_state_active .menu-toggler__caption-default-text {
                        display: none;
                    }

.s-main-header .menu-toggler_state_active .menu-toggler__caption-opened-text {
                        color: var(--av-brand-secondary);
                        display: block;
                    }

.s-main-header .menu-toggler_state_active .menu-toggler__icon {
                        width: 16px;
                        height: 16px;
                        color: var(--av-brand-secondary);
                    }

@media (min-width: 576px) {

.s-main-header .menu-toggler_state_active .menu-toggler__icon {
                            margin-inline-start: 8px
                    }
                        }

@media (min-width: 1280px) {

.s-main-header .menu-toggler {
            display: none
    }
        }

.s-main-header .s-main-menu {
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 48px;
        z-index: 75;
        width: 100%;
        position: absolute;
        min-height: calc(100vh - 75px);
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border: 1px solid var(--av-brand-accent);
    }

@media (min-width: 1280px) {

.s-main-header .s-main-menu--mobile-accordion {
                display: none
        }
            }

.s-main-header .s-main-menu--desktop-accordion {
            display: none;
        }

@media (min-width: 1024px) {

.s-main-header .s-main-menu--desktop-accordion {
                display: block
        }
            }

@media (min-width: 768px) {

.s-main-header .s-main-menu {
            top: 56px
    }
        }

@media (min-width: 1280px) {

.s-main-header .s-main-menu {
            min-height: auto;
            top: 44px;
            width: 100vw;
            max-width: 100%
    }
        }

@media (min-width: 1440px) {

.s-main-header .s-main-menu {
            top: 48px
    }
        }

.s-main-header .s-main-menu.s-main-menu_centered {
            inset-inline-end: auto;
            width: auto;
            transform: translateX(-50%);
            border-radius: 4px;
        }

.s-main-header .s-main-menu__tabs-wrapper {
            position: relative;
            margin: auto;
            width: 100%;
            max-width: 1312px;
        }

.s-main-header .s-main-menu__tabs-wrapper--solutions {
                max-width: initial;
            }

.s-main-header .s-main-menu__tab-information {
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
            text-align: center;
            position: relative;
            color: var(--av-fixed-light);
            background-color: var(--av-solid-brand-accent);
        }

.s-main-header .s-main-menu__tab-information .s-main-menu__closer {
                    top: auto;
                    bottom: -64px;
                }

.s-main-header .s-main-menu__disable {
            display: none;
        }

.s-main-header .s-main-menu__accordion-wrapper {
            width: 100%;
            padding: 16px 0 0;
        }

.s-main-header .s-main-menu__closer {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 36px;
            inset-inline-end: 32px;
            z-index: 1;
            background: none;
            border: none;
            width: 16px;
            height: 16px;
            cursor: pointer;
        }

.s-main-header .s-main-menu__closer:hover .s-main-menu__closer-icon {
                    color: var(--av-link-primary-hover);
                }

@media (min-width: 1024px) {

.s-main-header .s-main-menu__closer {
                display: flex
        }
            }

@media (min-width: 1280px) {

.s-main-header .s-main-menu__closer {
                inset-inline-end: 52px
        }
            }

@media (min-width: 1440px) {

.s-main-header .s-main-menu__closer {
                inset-inline-end: 64px
        }
            }

.s-main-header .s-main-menu__closer-icon {
            flex: 0 0 16px;
            height: 16px;
            color: var(--av-brand-secondary);
        }

.s-main-header .s-main-menu .a-accordion-item:first-of-type {
                border-top: none;
            }

.s-main-header .s-main-menu .a-accordion-item .title {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;

                padding: 24px 16px 24px 16px;
            }

@media (min-width: 768px) {

.s-main-header .s-main-menu .a-accordion-item .title {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;

                    padding: 24px 16px 24px 32px
            }
                }

.s-main-header .s-main-menu .a-accordion-item .icon {
                margin-inline-end: 16px;
            }

@media (min-width: 768px) {

.s-main-header .s-main-menu .a-accordion-item .icon {
                    margin-top: 8px
            }
                }

.s-main-header .s-main-menu .a-accordion-item .content-wrapper {
                padding: 0;
            }

.s-main-header .s-main-menu .a-accordion-item .content {
                padding: 0;
                background-color: var(--av-brand-lightest);
                box-shadow: inset 0 5px 6px rgba(138, 173, 207, 0.1865);
            }

.s-main-header .s-main-menu .a-accordion-item .s-menu-tab__section_type_contact-us {
                padding-top: 0;
            }

.s-main-header .s-contact-menu,
    .s-main-header .s-login-menu {
        inset-inline-start: 0;
        width: 264px;
        z-index: 101;
        border-radius: 4px;
        position: absolute;
        margin-inline-start: -166px;
        top: calc(100% + 20px);
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border: 1px solid var(--av-brand-secondary);
    }

@media(min-width: 576px) {

.s-main-header .s-contact-menu,
    .s-main-header .s-login-menu {
            top: calc(100% + 20px)
    }
        }

.s-main-header .s-contact-menu__top, .s-main-header .s-login-menu__top {
            padding-top: 24px;
            padding-inline-start: 16px;
        }

.s-main-header .s-contact-menu__title, .s-main-header .s-login-menu__title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            color: var(--av-nav-primary);
        }

.s-main-header .s-contact-menu__email, .s-main-header .s-login-menu__email {
            font-size: 16px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--av-brand-secondary);
        }

.s-main-header .s-contact-menu__list, .s-main-header .s-login-menu__list {
            padding: 24px 0 0;
        }

.s-main-header .s-contact-menu__list-services, .s-main-header .s-login-menu__list-services {
                margin: -1px 0 0 0;
            }

.s-main-header .s-contact-menu__list-services-item, .s-main-header .s-login-menu__list-services-item {
                    border-top: 1px solid var(--av-brand-accent);
                }

.s-main-header .s-contact-menu__list-services-item .a-link__content, .s-main-header .s-login-menu__list-services-item .a-link__content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: calc(100%);
                    }

.s-main-header .s-contact-menu__list-services-link, .s-main-header .s-login-menu__list-services-link {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    display: block;
                    padding: 12px 16px;
                    color: var(--av-nav-primary);
                }

.s-main-header .s-contact-menu__list-services-link .a-link__content, .s-main-header .s-login-menu__list-services-link .a-link__content {
                        font-size: 16px;
                    }

.s-main-header .s-contact-menu__list-services-link .a-link__desc, .s-main-header .s-login-menu__list-services-link .a-link__desc {
                        font-size: 14px;
                        line-height: 24px;
                        color: var(--av-fixed-secondary);
                        margin-top: 0;
                        font-weight: 400;
                        white-space: pre-wrap;
                    }

.s-main-header .s-contact-menu__link, .s-main-header .s-login-menu__link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            display: block;
            padding: 8px 0;
            padding-inline-start: 16px;
        }

.s-main-header .s-contact-menu__bottom, .s-main-header .s-login-menu__bottom {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            padding: 8px 0 0;
            position: relative;
            margin: 16px 0 24px;
            color: var(--av-nav-primary);
        }

.s-main-header .s-contact-menu__bottom::before, .s-main-header .s-login-menu__bottom::before {
                content: '';
                top: 0;
                inset-inline-start: 16px;
                height: 1px;
                width: 40px;
                position: absolute;
                background: var(--av-brand-accent);
            }

.s-main-header .s-contact-menu__logout, .s-main-header .s-login-menu__logout {
            width: 100%;
            border: none;
            display: block;
            cursor: pointer;
            padding: 8px 16px;
            align-items: center;
            background: rgba(255, 255, 255, 0);
        }

.s-main-header .s-contact-menu__logout-text, .s-main-header .s-login-menu__logout-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                margin-inline-end: 8px;
                color: var(--av-nav-primary);
            }

.s-main-header .s-contact-menu__logout-icon, .s-main-header .s-login-menu__logout-icon {
                fill: var(--av-brand-secondary);
                vertical-align: middle;
            }

.s-main-header .s-contact-menu__link:hover, .s-main-header .s-contact-menu__logout:hover, .s-main-header .s-contact-menu__list-services-link:hover, .s-main-header .s-login-menu__link:hover, .s-main-header .s-login-menu__logout:hover, .s-main-header .s-login-menu__list-services-link:hover {
                background: var(--av-brand-secondary-accent);
            }

.s-main-header .s-contact-menu__link:active, .s-main-header .s-contact-menu__logout:active, .s-main-header .s-contact-menu__list-services-link:active, .s-main-header .s-login-menu__link:active, .s-main-header .s-login-menu__logout:active, .s-main-header .s-login-menu__list-services-link:active {
                background: var(--av-brand-secondary-light);
            }

@media (min-width: 576px) {

.s-main-header .s-contact-menu,
    .s-main-header .s-login-menu {
            margin-inline-start: -169px
    }
        }

@media (min-width: 768px) {

.s-main-header .s-contact-menu,
    .s-main-header .s-login-menu {
            margin-inline-start: -135px
    }
        }

@media (min-width: 1024px) {

.s-main-header .s-contact-menu,
    .s-main-header .s-login-menu {
            margin-inline-start: -168px
    }
        }

.s-main-header__lang + .s-main-header__contact .s-contact-menu {
        margin-inline-start: -122px;
    }

@media (min-width: 768px) {

.s-main-header__lang + .s-main-header__contact .s-contact-menu {
            margin-inline-start: -106px
    }
        }

@media (min-width: 1024px) {

.s-main-header__lang + .s-main-header__contact .s-contact-menu {
            margin-inline-start: -85px
    }
        }

.s-main-header__contact .s-contact-menu {
            min-width: 216px;
            width: -moz-fit-content;
            width: fit-content;
            margin-inline-start: -142px;
        }

@media (min-width: 768px) {

.s-main-header__contact .s-contact-menu {
                margin-inline-start: -106px
        }
            }

@media (min-width: 1024px) {

.s-main-header__contact .s-contact-menu {
                margin-inline-start: -85px
        }
            }

.s-main-header__contact-text {
            max-width: initial;
        }

.s-main-header_theme_dark .s-main-header__menu-link {
                    color: var(--av-inversed-primary);
                }

.s-main-header_theme_dark .s-main-header__menu-link:hover {
                        color: var(--av-link-inversed);
                    }

.s-main-header_theme_dark .s-main-header__menu-link:active {
                        color: var(--av-inversed-light);
                    }

.s-main-header_theme_dark .s-main-header__menu-link_active {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        color: var(--av-inversed-primary);
                    }

.s-main-header_theme_dark .s-main-header__menu-link_active:hover {
                            color: var(--av-inversed-primary);
                        }

.s-main-header_theme_dark .s-main-header__login-text.active  {
                    color: var(--av-link-inversed);
                }

.s-main-header_theme_dark .s-main-header__lang-btn,
                .s-main-header_theme_dark .s-main-header__login-btn,
                .s-main-header_theme_dark .s-main-header__contact-btn,
                .s-main-header_theme_dark .s-main-header__search-link {
                    color: var(--av-inversed-primary);
                    transition: all linear 0.12s;
                }

.s-main-header_theme_dark .s-main-header__lang-btn .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn .a-glyph, .s-main-header_theme_dark .s-main-header__search-link .a-glyph {
                        fill: var(--av-inversed-primary);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn:hover, .s-main-header_theme_dark .s-main-header__login-btn:hover, .s-main-header_theme_dark .s-main-header__contact-btn:hover, .s-main-header_theme_dark .s-main-header__search-link:hover {
                        color: var(--av-link-inversed-active);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn:hover .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn:hover .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn:hover .a-glyph, .s-main-header_theme_dark .s-main-header__search-link:hover .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn:hover .logged-in, .s-main-header_theme_dark .s-main-header__login-btn:hover .logged-in, .s-main-header_theme_dark .s-main-header__contact-btn:hover .logged-in, .s-main-header_theme_dark .s-main-header__search-link:hover .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn_logged-in + .s-login-menu, .s-main-header_theme_dark .s-main-header__login-btn_logged-in + .s-login-menu, .s-main-header_theme_dark .s-main-header__contact-btn_logged-in + .s-login-menu, .s-main-header_theme_dark .s-main-header__search-link_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn:active, .s-main-header_theme_dark .s-main-header__login-btn:active, .s-main-header_theme_dark .s-main-header__contact-btn:active, .s-main-header_theme_dark .s-main-header__search-link:active {
                        color: var(--av-link-inversed-active);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn:active .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn:active .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn:active .a-glyph, .s-main-header_theme_dark .s-main-header__search-link:active .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn:active .logged-in, .s-main-header_theme_dark .s-main-header__login-btn:active .logged-in, .s-main-header_theme_dark .s-main-header__contact-btn:active .logged-in, .s-main-header_theme_dark .s-main-header__search-link:active .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn .logged-in, .s-main-header_theme_dark .s-main-header__login-btn .logged-in, .s-main-header_theme_dark .s-main-header__contact-btn .logged-in, .s-main-header_theme_dark .s-main-header__search-link .logged-in {
                        background-color: var(--av-inversed-primary);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn .logged-in .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn .logged-in .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn .logged-in .a-glyph, .s-main-header_theme_dark .s-main-header__search-link .logged-in .a-glyph {
                            fill: var(--av-brand-primary);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn .logged-in.active, .s-main-header_theme_dark .s-main-header__login-btn .logged-in.active, .s-main-header_theme_dark .s-main-header__contact-btn .logged-in.active, .s-main-header_theme_dark .s-main-header__search-link .logged-in.active {
                            background-color: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn_active, .s-main-header_theme_dark .s-main-header__login-btn_active, .s-main-header_theme_dark .s-main-header__contact-btn_active, .s-main-header_theme_dark .s-main-header__search-link_active {
                        color: var(--av-link-inversed-active);
                    }

.s-main-header_theme_dark .s-main-header__lang-btn_active .logged-in, .s-main-header_theme_dark .s-main-header__login-btn_active .logged-in, .s-main-header_theme_dark .s-main-header__contact-btn_active .logged-in, .s-main-header_theme_dark .s-main-header__search-link_active .logged-in {
                            background-color: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn_active .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn_active .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn_active .a-glyph, .s-main-header_theme_dark .s-main-header__search-link_active .a-glyph {
                            fill: var(--av-link-inversed-active);
                        }

.s-main-header_theme_dark .s-main-header__lang-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_dark .s-main-header__login-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_dark .s-main-header__contact-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_dark .s-main-header__search-link:hover .logged-in:hover .a-glyph {
                                fill: var(--av-brand-primary);
                            }

.s-main-header_theme_dark .s-main-header__buttons::before {
                        background-color: var(--av-inversed-primary);
                    }

.s-main-header_theme_dark .menu-toggler__icon {
                    fill: var(--av-inversed-primary);
                }

.s-main-header_theme_dark .menu-toggler__caption {
                    color: var(--av-inversed-primary);
                }

.s-main-header_theme_dark .menu-toggler:hover .menu-toggler__caption {
                        color: var(--av-link-inversed-active);
                    }

.s-main-header_theme_dark .menu-toggler:hover .menu-toggler__icon {
                        fill: var(--av-link-inversed-active);
                        color: var(--av-link-inversed-active);
                    }

.s-main-header_theme_dark .menu-toggler:active .menu-toggler__caption {
                        color: var(--av-link-inversed-hover);
                    }

.s-main-header_theme_dark .menu-toggler:active .menu-toggler__icon {
                        color: var(--av-link-inversed-hover);
                        fill: var(--av-link-inversed-hover);
                    }

.s-main-header_theme_light .s-main-header__menu-link {
                    color: var(--av-nav-primary);
                }

.s-main-header_theme_light .s-main-header__menu-link:hover {
                        color: var(--av-brand-primary);
                    }

.s-main-header_theme_light .s-main-header__menu-link_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

.s-main-header_theme_light .s-main-header__menu-link_active {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        color: var(--av-brand-primary);
                    }

.s-main-header_theme_light .s-main-header__menu-link:active {
                        color: var(--av-brand-secondary);
                    }

.s-main-header_theme_light .s-main-header__menu-link:active .a-glyph {
                            fill: var(--av-brand-secondary);
                        }

.s-main-header_theme_light .s-main-header__login-text.active  {
                    color: var(--av-brand-primary);
                }

.s-main-header_theme_light .s-main-header__lang-btn,
                .s-main-header_theme_light .s-main-header__login-btn,
                .s-main-header_theme_light .s-main-header__contact-btn,
                .s-main-header_theme_light .s-main-header__search-link {
                    color: var(--av-nav-primary);
                }

.s-main-header_theme_light .s-main-header__lang-btn .a-glyph, .s-main-header_theme_light .s-main-header__login-btn .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn .a-glyph, .s-main-header_theme_light .s-main-header__search-link .a-glyph {
                        fill: var(--av-nav-primary);
                    }

.s-main-header_theme_light .s-main-header__lang-btn:hover, .s-main-header_theme_light .s-main-header__login-btn:hover, .s-main-header_theme_light .s-main-header__contact-btn:hover, .s-main-header_theme_light .s-main-header__search-link:hover {
                        color: var(--av-brand-primary);
                    }

.s-main-header_theme_light .s-main-header__lang-btn:hover .a-glyph, .s-main-header_theme_light .s-main-header__login-btn:hover .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn:hover .a-glyph, .s-main-header_theme_light .s-main-header__search-link:hover .a-glyph {
                            fill: var(--av-brand-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn:hover .logged-in, .s-main-header_theme_light .s-main-header__login-btn:hover .logged-in, .s-main-header_theme_light .s-main-header__contact-btn:hover .logged-in, .s-main-header_theme_light .s-main-header__search-link:hover .logged-in {
                            background-color: var(--av-brand-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn:active, .s-main-header_theme_light .s-main-header__login-btn:active, .s-main-header_theme_light .s-main-header__contact-btn:active, .s-main-header_theme_light .s-main-header__search-link:active {
                        color: var(--av-brand-secondary);
                    }

.s-main-header_theme_light .s-main-header__lang-btn:active .a-glyph, .s-main-header_theme_light .s-main-header__login-btn:active .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn:active .a-glyph, .s-main-header_theme_light .s-main-header__search-link:active .a-glyph {
                            fill: var(--av-brand-secondary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn:active .logged-in, .s-main-header_theme_light .s-main-header__login-btn:active .logged-in, .s-main-header_theme_light .s-main-header__contact-btn:active .logged-in, .s-main-header_theme_light .s-main-header__search-link:active .logged-in {
                            background-color: var(--av-brand-secondary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn_logged-in + .s-login-menu, .s-main-header_theme_light .s-main-header__login-btn_logged-in + .s-login-menu, .s-main-header_theme_light .s-main-header__contact-btn_logged-in + .s-login-menu, .s-main-header_theme_light .s-main-header__search-link_logged-in + .s-login-menu {
                        top: calc(100% + 20px);
                    }

.s-main-header_theme_light .s-main-header__lang-btn_active, .s-main-header_theme_light .s-main-header__login-btn_active, .s-main-header_theme_light .s-main-header__contact-btn_active, .s-main-header_theme_light .s-main-header__search-link_active {
                        color: var(--av-brand-primary);
                    }

.s-main-header_theme_light .s-main-header__lang-btn_active .a-glyph, .s-main-header_theme_light .s-main-header__login-btn_active .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn_active .a-glyph, .s-main-header_theme_light .s-main-header__search-link_active .a-glyph {
                            fill: var(--av-brand-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn .logged-in, .s-main-header_theme_light .s-main-header__login-btn .logged-in, .s-main-header_theme_light .s-main-header__contact-btn .logged-in, .s-main-header_theme_light .s-main-header__search-link .logged-in {
                        background-color: var(--av-nav-primary);
                    }

.s-main-header_theme_light .s-main-header__lang-btn .logged-in .a-glyph, .s-main-header_theme_light .s-main-header__login-btn .logged-in .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn .logged-in .a-glyph, .s-main-header_theme_light .s-main-header__search-link .logged-in .a-glyph {
                            fill: var(--av-inversed-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn .logged-in.active, .s-main-header_theme_light .s-main-header__login-btn .logged-in.active, .s-main-header_theme_light .s-main-header__contact-btn .logged-in.active, .s-main-header_theme_light .s-main-header__search-link .logged-in.active {
                            background-color: var(--av-brand-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn_active .logged-in, .s-main-header_theme_light .s-main-header__login-btn_active .logged-in, .s-main-header_theme_light .s-main-header__contact-btn_active .logged-in, .s-main-header_theme_light .s-main-header__search-link_active .logged-in {
                            background-color: var(--av-brand-primary);
                        }

.s-main-header_theme_light .s-main-header__lang-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_light .s-main-header__login-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_light .s-main-header__contact-btn:hover .logged-in:hover .a-glyph, .s-main-header_theme_light .s-main-header__search-link:hover .logged-in:hover .a-glyph {
                                fill: var(--av-inversed-primary);
                            }

.s-main-header_theme_light .s-main-header__buttons::before,
                    .s-main-header_theme_light .s-main-header__buttons > div::before {
                        background: var(--av-brand-light);
                    }

.s-main-header_theme_light .menu-toggler__icon {
                    fill: var(--av-nav-primary);
                }

.s-main-header_theme_light .menu-toggler__caption {
                    color: var(--av-nav-primary);
                }

.s-main-header_theme_light .menu-toggler:hover .menu-toggler__caption {
                        color: var(--av-brand-secondary);
                    }

.s-main-header_theme_light .menu-toggler:hover .menu-toggler__icon {
                        fill: var(--av-brand-secondary);
                        color: var(--av-brand-secondary);
                    }

.s-main-header_theme_light .menu-toggler:active .menu-toggler__caption {
                        color: var(--av-brand-secondary);
                    }

.s-main-header_theme_light .menu-toggler:active .menu-toggler__icon {
                        fill: var(--av-brand-secondary);
                        color: var(--av-brand-secondary);
                    }

[dir='rtl'] .s-main-header .s-main-menu.s-main-menu_centered {
                inset-inline-start: auto;
                inset-inline-end: unset;
            }

[dir='rtl'] .s-main-header .logged-in .a-glyph {
            margin-inline-end: -2px;
            margin-top: -2px;
        }

[dir='rtl'] .s-main-header__menu-link::before,
    [dir='rtl'] .s-main-header__menu-link::after {
        inset-inline-start: unset;
        inset-inline-end: 50%;
    }
