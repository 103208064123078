.page {
    &_background {
        background: var(--av-gradient-to-bottom-lightest) no-repeat 0 0 / auto 600px;

        @mixin background;

        &_n-p-gradient-top {
            & > * {
                background: rgba(255, 255, 255, 0);
            }
        }

        &-height_fixed {
            background-attachment: fixed;
        }
    }
}

/* fix for feedback popup (hotjar) close button */
._hj-eEC5y__ExpandedWidget__closeButton {
    text-align: center;
}
