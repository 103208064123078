<template>
    <s-basic-slice
        :id="id"
        class="s-schema-switcher"
        :class="{ ...basicClassName }"
    >
        <a-slice-header class="header" :title="l10n.title" :lead="l10n.lead" />
        <div class="wrapper">
            <div class="list">
                <a-accordion class="accordion" one-only-mode @change="handleChange">
                    <template v-for="(item, i) in l10n.items">
                        <div v-if="item.title" :key="`title-${i}`" class="title">
                            {{ item.title }}
                        </div>
                        <div v-if="item.text" :key="`text-${i}`" class="content">
                            {{ item.text }}
                        </div>
                        <template v-else>
                            <a-accordion-item
                                v-for="(listItem, listItemIndex) in item.list"
                                ref="accordionItem"
                                :key="`accordion-${listItemIndex}`"
                                class="accordion-item"
                                :title="listItem.title"
                            >
                                <div v-if="listItem.text" class="accordion-text">
                                    {{ listItem.text }}
                                </div>
                                <ul
                                    v-if="listItem.bulletList"
                                    class="accordion-list a-list a-list_type_bullet"
                                >
                                    <li
                                        v-for="(bullet, bulletIndex) in listItem.bulletList"
                                        :key="`list-item-${bulletIndex}`"
                                        class="a-list__item"
                                    >
                                        {{ bullet }}
                                    </li>
                                </ul>
                                <a-link
                                    v-if="listItem.link"
                                    class="accordion-link"
                                    v-bind="listItem.link"
                                    :size="listItem.size || 'paragraph'"
                                    :glyph="listItem.glyph || 'arrow'"
                                    accent
                                />
                            </a-accordion-item>
                        </template>
                    </template>
                </a-accordion>
            </div>
            <div ref="slides" class="slides" :style="slidesStyle">
                <div
                    v-for="(slide, index) in l10n.slides"
                    v-show="index === activeItemIndex"
                    :key="`slide-${index}`"
                    class="slide"
                >
                    <a-picture
                        class="img"
                        :link="slide.image"
                        :alt="slide.imageAlt"
                        position="center"
                        @imageLoaded="setSlidesHeight"
                        @imageResized="setSlidesHeight"
                    />
                </div>
            </div>
        </div>
        <a-link
            v-if="l10n.link"
            class="link"
            v-bind="l10n.link"
            :type="l10n.link.type || 'direct'"
            :glyph="l10n.link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SSchema',

    components: {
        AAccordionItem,
        AAccordion,
        ALink,
        APicture,
        ASliceHeader,
        SBasicSlice,
    },

    mixins: [l10n, contentMixin, styleMixin],

    props: {
        /**
         * items
         */
        items: {
            type: Array,
            required: true,
        },

        /**
         * slides
         */
        slides: {
            type: Array,
            required: true,
        },

        /**
         * Optional link
         */
        link: {
            type: Object,
            default: undefined,
        },
    },

    data: () => ({
        activeItemIndex: 0,
        slideHeight: 0,
    }),

    computed: {
        slidesStyle() {
            if (!this.slideHeight) return '';
            return `height: ${this.slideHeight}px;`;
        },
    },

    methods: {
        async setSlidesHeight(imageElement) {
            if (!imageElement?.clientHeight) return;
            await this.$nextTick();
            this.slideHeight = imageElement.clientHeight;
        },

        handleChange(i) {
            this.activeItemIndex = i >= 0 ? i + 1 : 0; // +1 because the first element is shown by default
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-schema-switcher {
    z-index: 1;

    &:deep(.a-container) {
        /* manually writing this because otherwise we need to override for other breakpoints */
        @media only screen and (max-width: 767px) {
            padding: 0;

            /* design requires everything in .a-container (which has x-padding)
             * to have padding besides expandable items list on mobile, therefore this */
            .header,
            .title,
            .content,
            .link {
                padding: 0 16px;
            }
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;

        @media (--viewport-desktop) {
            justify-content: space-between;
            flex-direction: row;
        }
    }

    .title {
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
            line-height: 32px;
            font-size: 24px;
            font-weight: 400;
        }
    }
    .list {
        order: 2;

        @media (--viewport-desktop) {
            @mixin colls 5;

            order: 1;
        }
    }

    .a-accordion-item {
        padding: 24px 0;
    }

    .accordion-list {
        margin-top: 40px;
    }

    .accordion-text {
        @mixin paragraph;

        padding-top: 24px;
    }

    .accordion-link {
        @mixin paragraph;

        margin-top: 40px;
        padding-bottom: 24px;
    }

    .slides {
        margin-bottom: 56px;
        order: 1;
        position: relative;
        min-height: 195px;

        /* start from iPad mini */
        @media (min-width: 744px) {
            min-height: 325px;
        }

        @media (--viewport-desktop) {
            @mixin colls 6;
            position: sticky;
            top: 32px;
            margin-bottom: 0;
            order: 2;
            min-height: 350px;
        }
    }

    .slide {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
        transition: opacity 0.25s ease-out;
        height: 100%;
    }

    .img {
        height: auto;

        &:deep(.a-picture__img) {
            margin: 0 auto;
            object-fit: contain;
            @media (--viewport-desktop) {
                width: 100%;
            }
        }
    }

    .link {
        margin-top: 56px;
    }

    .content {
        margin-top: 16px;
    }

    &:deep(.a-accordion) {
        @media (--viewport-tablet) {
            padding: 0;
        }

        .a-accordion-item {
            .title {
                @mixin paragraph-accent;

                padding: 0 16px;

                @media (--viewport-tablet) {
                    padding-inline-start: 0;
                }
            }

            &.active {
                background: var(--av-brand-lightest);
                box-shadow: inset 0 5px 6px rgba(138, 173, 207, 0.1865);

                @media (--viewport-tablet) {
                    background: none;
                    box-shadow: none;
                }
            }

            .content-wrapper {
                padding: 0 24px;
            }
        }
    }
}
[dir='rtl'] {
    .s-schema-switcher {
        &:deep(.a-accordion-item) {
            .title {
                padding-inline-end: 0;
            }
            .icon {
                margin-inline-end: 8px;
            }
        }
    }
}
</style>
