<template>
    <section :id="id" class="s-slice s-values" :class="sliceClassName">
        <a-picture
            v-if="backgroundImage"
            class="bg"
            fit="cover"
            is-background
            :background="backgroundImage"
        />
        <div class="a-container">
            <a-slice-header
                v-if="l10n.title || l10n.lead"
                :title="l10n.title"
                :lead="l10n.lead"
            />
            <div class="s-values__content">
                <ul class="s-values__list" :class="className">
                    <li v-for="(card, i) in l10n.cards" :key="i" class="s-values__item">
                        <a-value-card v-bind="{...card, hasCardsBackground}" title-tag="h3">
                            {{ card.text }}
                        </a-value-card>
                    </li>
                </ul>
                <a-link
                    v-if="l10n.link"
                    class="s-values__link"
                    v-bind="l10n.link"
                    size="title"
                    :type="l10n.link.type || theme === 'dark' ? 'light' : 'direct'"
                    :glyph="l10n.link.glyph || 'arrow'"
                />
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import AValueCard from '@core/components/value-card/value-card.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SValueCards',
    components: {
        ALink,
        APicture,
        AValueCard,
        ASliceHeader,
    },
    mixins: [l10n, contentMixin, styleMixin],
    inheritAttrs: false,
    props: {
        /**
         * Slice cards
         */
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1 && cards.length < 7,
        },

        /**
         * Optional link
         */
        link: {
            type: [Object, Boolean],
            default: false,
        },

        /**
         * Background image
         */
        backgroundImage: {
            type: Object,
            default: null,
        },

        /**
         * Adds optional margin inside a card background
         */
        hasCardsBackground: {
            type: Boolean,
            default: false,
        },

        /**
         * Theme
         */
        theme: {
            type: String,
            validator: (val) => ['light', 'dark'].includes(val),
            default: 'light',
        },
    },
    computed: {
        sliceClassName() {
            return {
                ...this.basicClassName,
                dark: this.theme === 'dark',
            };
        },
        className() {
            return {
                's-values__list_layout_short': this.cards.l10n?.length === 2 || this.l10n.cards?.length === 4,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-values {
    position: relative;

    @mixin basic-slice-paddings;

    &.dark {
        color: var(--av-inversed-primary);

        &:deep(.a-slice-header__title),
        &:deep(.a-slice-header__lead),
        &:deep(.a-slice-header__bodyText) {
            color: var(--av-inversed-primary);
        }
    }

    .a-value-card {
        border: 0;
    }

    &__list {
        @media (--viewport-mobile-wide) {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 16px;
            margin: -8px -8px -24px;
            width: 100%;
        }

        &_layout_short {
            @media (--viewport-desktop) {
                margin: -8px 0;
                justify-content: space-between;
            }

            @media (--viewport-desktop-wide) {
                width: calc(((100% - 32px) / 3) * 2 + 16px);
            }
        }
    }

    &__item {
        color: var(--av-fixed-secondary);
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            pointer-events: none;
            border-radius: 4px;
            border: 1px solid var(--av-brand-secondary-light);
        }

        &:not(:first-child) {
            margin-top: 16px;

            @media (--viewport-mobile-wide) {
                margin-top: 8px;
            }
        }

        @media (--viewport-mobile-wide) {
            width: calc(50% - 16px);
            margin: 8px;
        }

        @media (--viewport-desktop) {
            width: calc((100% - 48px) / 3);
        }

        .s-values__list_layout_short & {
            @media (--viewport-desktop) {
                width: calc(50% - 8px);
                margin: 8px 0;
            }
        }
    }

    &__link {
        font-weight: 700;
        margin: 56px 0 0;

        @media (--viewport-desktop) {
            margin: 0;
            position: absolute;
            top: 16px;
            inset-inline-end: var(--container-paddings-mobile-wide);
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: var(--container-paddings-desktop-wide);
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: var(--container-paddings-desktop-large);
        }
    }
}

.bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}
</style>
