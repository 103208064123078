<!-- eslint-disable max-len -->
<template>
    <section id="price-calculator">
        <transition name="vroi-fade-in">
            <div v-if="cartBackdropVisible" class="backdrop" />
        </transition>
        <div
            v-if="isLoading && !greetModalShown && !gated && isAvailable"
            id="loading"
            v-loading="true"
            el-loading-size="48"
        >
            <span v-html="loadingText" />
        </div>
        <a-picture v-if="gated || greetModalShown || !isAvailable || isServerError" :link="gatedBackground" class="gated-image" />
        <div v-if="!isLoading && !gated && isAvailable" class="a-container">
            <div id="main-content" class="main">
                <div id="content" class="content">
                    <div class="stepper">
                        <h1 v-if="stepper.title" class="main-title">
                            {{ stepper.title }}
                        </h1>
                        <div class="steps">
                            <s-collapse-section
                                v-for="(step, index) in steps"
                                :key="`steps_${index}`"
                                :step="index + 1"
                                :count="steps.length"
                                :title="step.title"
                                :current-step="currentStep"
                                :is-required="step.required"
                                :is-mobile="isMobile"
                                :selected-services="selectedServices"
                                :is-shown="currentStep === index + 1"
                                :is-done="lastStepClicked || currentStep > index + 1"
                                :navigation="stepper.navigation"
                                :selected="selectedDisplay[index]"
                                @onNextClick="onNextClick"
                                @onPrevClick="onPrevClick"
                                @onHeadClick="changeStep($event)"
                                @onLastClick="onLastClick"
                            >
                                <div v-show="isMobile || currentStep === index + 1">
                                    <!-- Step 1: Checkbox Selection -->
                                    <div v-if="step.type === 'checkbox'">
                                        <div class="checkboxes">
                                            <div
                                                v-for="section in step.sections"
                                                :key="section.key"
                                                class="item"
                                                :class="{ selected: selectedServices.includes(section.key) }"
                                                @click="toggleServiceSelection(section)"
                                            >
                                                <div class="checkbox-wrapper">
                                                    <input
                                                        ref="checkboxRefs"
                                                        type="checkbox"
                                                        :data-key="section.key"
                                                        :checked="selectedServices.includes(section.key)"
                                                        class="checkbox"
                                                    />
                                                    <span class="label">{{ section.label }}</span>
                                                    <a-glyph
                                                        v-if="selectedServices.includes(section.key)"
                                                        name="check-square-16"
                                                        class="checkmark"
                                                    />
                                                </div>
                                                <ul class="features">
                                                    <li v-for="feature in section.features" :key="feature">
                                                        {{ feature }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <a-button
                                            v-if="hasUncheckedServices"
                                            class="select-all"
                                            size="tiny"
                                            type="secondary"
                                            :text="stepper.selection?.selectAll"
                                            @click="selectAllUnchecked"
                                        />
                                    </div>

                                    <!-- Step 2: Number Inputs -->
                                    <div v-else-if="step.type === 'number-input'">
                                        <template v-if="!selectedServices.length">
                                            <span class="empty-first">{{ stepper.validations.emptyFirstStep }}</span>
                                        </template>
                                        <template v-for="(section, sectionKey) in step.sections">
                                            <div v-if="unlockedSections[sectionKey]" :key="sectionKey">
                                                <div class="section-wrapper" :class="sectionKey">
                                                    <!-- Standard Backup -->
                                                    <div v-if="section.standard" class="column">
                                                        <div class="head">
                                                            <h3 class="title">
                                                                {{ section.standard.title }}
                                                            </h3>
                                                            <a-tooltip
                                                                :text="section.standard?.tooltip"
                                                                placement="top"
                                                            >
                                                                <a-glyph name="tooltip-o" class="tooltip-icon" />
                                                            </a-tooltip>
                                                        </div>
                                                        <a-number-input
                                                            v-for="fieldRef in section.standard.fields"
                                                            :key="fieldRef"
                                                            :field="findField(fieldRef)"
                                                            @change="changeInput(fieldRef, $event)"
                                                        />
                                                    </div>

                                                    <!-- Advanced Fields -->
                                                    <div v-if="section.advanced" class="column">
                                                        <div class="head">
                                                            <h3 class="title">
                                                                {{ section.advanced.title }}
                                                            </h3>
                                                            <a-tooltip
                                                                :text="section.advanced?.tooltip"
                                                                placement="top"
                                                            >
                                                                <a-glyph name="tooltip-o" class="tooltip-icon" />
                                                            </a-tooltip>
                                                        </div>
                                                        <a-number-input
                                                            v-for="fieldRef in section.advanced.fields"
                                                            :key="fieldRef"
                                                            :disabled="form[fieldRef.replace('advanced', 'standard')] < 1"
                                                            :max="form[fieldRef.replace('advanced', 'standard')]"
                                                            :field="findField(fieldRef)"
                                                            @change="changeInput(fieldRef, $event)"
                                                        />
                                                    </div>
                                                    <!-- Additional Workloads -->
                                                    <div v-if="section.additional" class="additional-wrapper">
                                                        <h3 class="title">
                                                            {{ section.additional.title }}
                                                        </h3>
                                                        <div class="chips-wrapper">
                                                            <template v-for="workload in section.additional?.workloads">
                                                                <button
                                                                    :key="workload.reference"
                                                                    class="chip"
                                                                    :class="{ active: activeWorkloads.includes(workload.reference) }"
                                                                    @click="toggleAdditionalWorkload(workload.reference)"
                                                                >
                                                                    {{ workload.label }}
                                                                </button>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div v-if="activeWorkloads.length" class="additional-fields">
                                                        <template v-for="workload in section.additional?.workloads">
                                                            <div
                                                                v-if="activeWorkloads.includes(workload.reference)"
                                                                :key="workload.reference"
                                                            >
                                                                <a-number-input
                                                                    :field="findField(workload.field)"
                                                                    @change="changeInput(workload.field, $event)"
                                                                />
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <!-- Single Field Sections (e.g., PSA & RMM) -->
                                                    <template v-if="!section.standard && !section.advanced && !section.additional">
                                                        <template v-if="section.subsections">
                                                            <div
                                                                v-for="subsection in section.subsections"
                                                                :key="subsection.title"
                                                                class="subsection"
                                                            >
                                                                <div class="head">
                                                                    <h3 class="title">
                                                                        {{ subsection.title }}
                                                                    </h3>
                                                                    <a-tooltip
                                                                        :text="subsection.tooltip"
                                                                        placement="top"
                                                                    >
                                                                        <a-glyph name="tooltip-o" class="tooltip-icon" />
                                                                    </a-tooltip>
                                                                </div>
                                                                <template v-for="fieldRef in subsection.fields">
                                                                    <a-number-input
                                                                        :key="fieldRef"
                                                                        :field="findField(fieldRef)"
                                                                        :disabled="fieldRef === 'mdr' && !isActiveMDR"
                                                                        :max="fieldRef === 'mdr' && !isActiveMDR ? 0 : 9999"
                                                                        @change="changeInput(fieldRef, $event)"
                                                                    />
                                                                </template>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="head">
                                                                <h3 class="title">
                                                                    {{ section.title }}
                                                                </h3>
                                                                <a-tooltip
                                                                    :text="section.tooltip"
                                                                    placement="top"
                                                                >
                                                                    <a-glyph name="tooltip-o" class="tooltip-icon" />
                                                                </a-tooltip>
                                                            </div>

                                                            <template v-for="fieldRef in section.fields">
                                                                <a-number-input
                                                                    :key="fieldRef"
                                                                    :field="findField(fieldRef)"
                                                                    @change="changeInput(fieldRef, $event)"
                                                                />
                                                            </template>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <!-- Step 3: Select Inputs -->
                                    <div v-else-if="step.type === 'select'" class="selects-wrapper">
                                        <a-select-input
                                            :required="true"
                                            :default="form.cloudStorageOption"
                                            :field="findField('cloudStorageOption')"
                                            @change="changeInput('cloudStorageOption', $event)"
                                        />
                                        <a-select-input
                                            v-show="showDatacenter"
                                            v-bind="labelSelect"
                                            filterable
                                            :field="findField('dataCenterInput')"
                                            :default="preselectDataCenter()"
                                            @change="changeInput('globalsDataCenter', $event)"
                                        >
                                            <el-option
                                                v-for="item in dataCenters"
                                                :key="item.code"
                                                :value="item.code"
                                                :label="item.name"
                                            />
                                        </a-select-input>
                                        <template v-if="individualStorageOption">
                                            <a-select-input
                                                ref="storageAmountInput"
                                                :key="individualStorageOption"
                                                :field="findField('cloudStorageAmount')"
                                                has-input
                                                @change="changeStorageOptionValue(individualStorageOption, $event)"
                                            />
                                        </template>
                                        <a-select-input
                                            v-if="showMsStorage"
                                            :field="findField('cloudMsStorage')"
                                            has-input
                                            @change="changeInput('cloudMsStorage', $event)"
                                        />
                                        <a-select-input
                                            v-if="showDrStorage"
                                            :field="findField('disasterRecoveryStorage')"
                                            has-input
                                            @change="changeInput('disasterRecoveryStorage', $event)"
                                        />
                                    </div>
                                </div>
                            </s-collapse-section>
                        </div>
                        <div
                            v-if="bottomContact"
                            class="bottom-contact"
                            @click="contactAdvisor($event)"
                            v-html="bottomLinkFormatted"
                        />
                    </div>
                </div>
                <div id="sidebar" class="sidebar">
                    <div class="sidebar__inner">
                        <transition name="vroi-slide-up" appear mode="out-in">
                            <section
                                v-if="!requirementsMet"
                                key="validation"
                                class="validation"
                                :class="{ mobile: isMobileTablet}"
                            >
                                <div class="not-filled">
                                    <h5 v-if="!isMobile && cart.notFilled.title" class="title">
                                        {{ cart.notFilled.title }}
                                    </h5>
                                    <div v-if="isMobile" class="mobileTitle">
                                        <h5 v-if="cart.notFilled.title">
                                            {{ cart.notFilled.title }}
                                        </h5>
                                        <a data-has-no-href="true" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <p
                                        v-if="!isMobile || showOnMobile"
                                        class="assistance"
                                        @click="contactAdvisor($event)"
                                        v-html="assistanceLinkFormatted"
                                    />
                                </div>
                            </section>
                            <!-- Not Filled Cart END -->
                            <!-- Qualified for discount: When requirements are met and sum is above 900 -->
                            <section v-if="requirementsMet && isDiscount" key="discount" class="discount">
                                <div class="discount-wrapper">
                                    <a-picture v-if="!isMobileTablet && cart.discount.image" :link="cart.discount.image" class="image" />
                                    <h5 v-if="!isMobileTablet && cart.discount.title" class="title">
                                        {{ cart.discount.title }}
                                    </h5>
                                    <div v-if="isMobileTablet" class="mobileTitle">
                                        <a-picture v-if="cart.discount.image" :link="cart.discount.image" class="image" />
                                        <h5 v-if="cart.discount.title">
                                            {{ cart.discount.title }}
                                        </h5>
                                        <a data-has-no-href="true" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <template v-if="(!isMobileTablet) || showOnMobile">
                                        <p
                                            v-for="(desc, index) in cart.discount.description_blocks"
                                            :key="`cart_discount_description_${index}`"
                                            class="description"
                                            v-html="desc"
                                        />
                                    </template>
                                    <a-button
                                        v-if="cart.discount.button"
                                        class="button"
                                        v-bind="cart.discount.button"
                                        @click="contactAdvisor($event)"
                                    />
                                </div>
                            </section>
                            <!-- Qualified for discount END -->
                            <!-- Cart when requirements are met and pricing is below 900 -->
                            <section v-if="requirementsMet && !isDiscount" key="cartContent" class="cart-content">
                                <div v-if="isMobileTablet" class="mobileOrder">
                                    <div class="label">
                                        <span>{{ cart.labels?.total }}</span>
                                        <span class="perMonth">{{ monthlyTotal.formatted }}</span>
                                        <a-tooltip
                                            v-if="cart.labels?.taxes"
                                            :text="cart.labels?.taxes"
                                            placement="top"
                                        >
                                            <a-glyph name="tooltip-o" class="tooltip-icon" />
                                        </a-tooltip>
                                        <a data-has-no-href="true" class="expand" @click="toggleExpandedCart()">
                                            <a-glyph :name="expandedCart ? 'chevron-down' : 'chevron-up'" class="icon" />
                                        </a>
                                    </div>
                                    <a-button
                                        v-if="cart.order?.button"
                                        v-bind="cart.order.button"
                                        class="button"
                                        @click="contactAdvisor($event)"
                                    />
                                    <a-link
                                        v-if="cart.order?.link"
                                        v-bind="cart.order.link"
                                        class="link"
                                        :size="cart.order.link?.size || 'paragraph'"
                                        target="_blank"
                                    />
                                </div>

                                <div v-if="!isMobileTablet || showOnMobile" class="pricing" :class="{mobile: isMobileTablet}">
                                    <h4 v-if="cart.title" class="main-title">
                                        {{ cart.title }}
                                    </h4>
                                    <div class="licensing-model">
                                        <div class="head">
                                            <h4 class="title">
                                                {{ cart.pricingMethods.title }}
                                            </h4>
                                            <a-tooltip
                                                v-if="cart.pricingMethods.tooltip"
                                                :text="cart.pricingMethods.tooltip"
                                                placement="top"
                                            >
                                                <a-glyph name="tooltip-o" class="tooltip-icon" />
                                            </a-tooltip>
                                        </div>
                                        <div class="pricing-methods">
                                            <button
                                                v-for="(method, index) in cart.pricingMethods.items"
                                                :key="`pricing_method_${index}`"
                                                class="method"
                                                :class="{
                                                    'selected': pricingMethod === method.id,
                                                    'recommended': recommendedPricingMethod === method.id
                                                }"
                                                @click="changePricingMethod(method.id, true)"
                                            >
                                                <a-glyph
                                                    v-if="pricingMethod === method.id"
                                                    :name="selectedPricingMethod[pricingState].glyph"
                                                    class="icon"
                                                />
                                                <span>{{ method.title }}</span>
                                            </button>
                                        </div>
                                        <span
                                            v-if="!isStorage && isDataProtection && selectedPricingMethod.id === 2"
                                            class="pricing-not-available"
                                            v-html="selectedPricingMethod.unavailable.message"
                                        />
                                        <span
                                            v-else-if="recommendedPricingMethod !== selectedPricingMethod.id"
                                            class="pricing-recommendation"
                                            v-html="selectedPricingMethod[pricingState].message"
                                        />
                                    </div>
                                    <template v-if="(isStorage || !isDataProtection) || selectedPricingMethod.id === 1">
                                        <div class="cart-line" />
                                        <div class="order" :class="{ compact: !detailedSummary }">
                                            <template v-for="category in orderCategories">
                                                <div v-if="showOrderGroup(category.key)" :key="category.key" class="order-group">
                                                    <div class="head">
                                                        <h5 v-if="cart.labels.productTitles[category.key]">
                                                            {{ cart.labels.productTitles[category.key] }}
                                                        </h5>
                                                        <span v-if="allOutputs[category.key]">{{ formatPrice(allOutputs[category.key]) }}</span>
                                                        <span v-else>{{ cart.labels.freeLabel }}</span>
                                                    </div>
                                                    <div v-show="detailedSummary" class="options">
                                                        <template v-for="orderLine in category.fields">
                                                            <div
                                                                v-if="showOrderItem(orderLine.reference)"
                                                                :key="orderLine.reference"
                                                                class="order-item"
                                                            >
                                                                <h5>{{ getOrderItemQty(orderLine.reference, orderLine.suffix) }}</h5>
                                                                <span v-if="orderLine.item">{{ formatPrice(orderLine.item) }}</span>
                                                                <span v-else>{{ cart.labels.freeLabel }}</span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-if="detailedSummary" class="cart-line short" />
                                        <a
                                            v-if="detailedSummary && !pdfUrl"
                                            :class="{'in-progress': pdfInProgress}"
                                            class="download-detailed-summary"
                                            :data-has-no-href="true"
                                            @click="sendPdfRenderRequest()"
                                        >
                                            <a-glyph
                                                name="pdf"
                                                class="icon"
                                                :class="{ animate: pdfInProgress }"
                                            />
                                            <span v-if="!pdfError">{{ pdfTitle }}</span>
                                            <span v-else>{{ cart.labels?.export.error }}</span>
                                        </a>
                                        <a
                                            v-if="detailedSummary && pdfUrl"
                                            ref="exportButton"
                                            class="download-detailed-summary"
                                            :href="pdfUrl"
                                            @click="downloadPdfClick()"
                                        >
                                            <a-glyph name="download-arrow" class="icon" />
                                            <span>{{ cart.labels?.export.download }}</span>
                                        </a>
                                        <a
                                            class="toggle-detailed-summary"
                                            :data-has-no-href="true"
                                            :class="{active: !detailedSummary}"
                                            @click="toggleDetailedSummary()"
                                        >
                                            <a-glyph :name="`chevron-${detailedSummary ? 'up' : 'down'}`" />
                                            <span>{{ detailedSummaryTitle }}</span>
                                        </a>
                                        <div v-if="!isMobileTablet" class="cart-line" />
                                        <div v-if="!isMobileTablet" class="totals">
                                            <div class="label">
                                                {{ cart.labels?.total }}
                                            </div>
                                            <div class="sums">
                                                <span class="perMonth">{{ monthlyTotal.formatted }}</span>
                                                <p class="taxes">
                                                    {{ cart.labels?.taxes }}
                                                </p>
                                            </div>
                                        </div>
                                        <div v-if="!isMobileTablet" class="actions">
                                            <a-button
                                                v-if="cart.order?.button"
                                                v-bind="cart.order.button"
                                                class="button"
                                                @click="contactAdvisor($event)"
                                            />
                                            <a-link
                                                v-if="cart.order?.link"
                                                v-bind="cart.order.link"
                                                class="link"
                                                :size="cart.order.link?.size || 'paragraph'"
                                                target="_blank"
                                            />
                                        </div>
                                    </template>
                                </div>
                            </section>
                            <!-- Cart when requirements are met END -->
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modals Start -->
        <s-error-modal
            v-if="isError || isServerError"
            :server-error="isServerError"
            :labels="computedErrorModal"
            @onClose="errorModalClosed()"
        />
        <s-contact-modal
            v-if="contactModalShown || !isAvailable"
            :labels="contactModal"
            :is-available="isAvailable"
            @onClose="contactModalShown = false"
        />
        <s-greet-modal
            v-if="greetModalShown"
            :is-loading="isLoading"
            :content="greetModal"
            :name="userData.first_name"
            @onClose="greetModalClosed()"
        />
        <s-gated-form
            v-if="gated"
            :restricted-countries="restrictedCountries"
            @gatedFormSubmit="gatedFormSubmit($event)"
        />
    </section>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { mapState, mapGetters } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import form from '@core/mixins/form.js';
/* VROI Specific imports */
import SCollapseSection from './components/collapse-section.vue';
import SContactModal from './components/contact-modal.vue';
import SErrorModal from './components/error-modal.vue';
import SGatedForm from './components/gated-form.vue';
import SGreetModal from './components/greet-modal.vue';
import ANumberInput from './components/number-input.vue';
import ASelectInput from './components/select-input.vue';
import calculations from './utils/calculations.js';
import helpers from './utils/helpers.js';
import user from './utils/user.js';

export default {
    name: 'SPriceCalculator',
    components: {
        ALink,
        APicture,
        AButton,
        AGlyph,
        ANumberInput,
        ATooltip,
        ASelectInput,
        SCollapseSection,
        SGatedForm,
        SContactModal,
        SErrorModal,
        SGreetModal,
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },
    directives: {
        Loading,
    },
    mixins: [calculations, helpers, user, breakpoint, currency, form],
    props: {
        restrictedCountries: {
            type: Array,
            default: () => [],
        },
        stepper: {
            type: Object,
            default() {},
        },
        contactButton: {
            type: Object,
            default: null,
        },
        bottomContact: {
            type: Object,
            default: null,
        },
        loadingText: {
            type: String,
            default: 'Please wait..<br/>While we prepare the calculator',
        },
        cart: {
            type: Object,
            default: null,
        },
        errorModal: {
            type: Object,
            default: null,
        },
        serverErrorModal: {
            type: Object,
            default: null,
        },
        greetModal: {
            type: Object,
            default: null,
        },
        contactModal: {
            type: Object,
            default: null,
        },
        gatedBackground: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            timer: null,
            resizeTimer: null,
            sidebar: null,
            isLoading: true,
            isClient: false,
            isError: false,
            isServerError: false,
            selectedDisplay: [[], [], []],
            selectedServices: [],
            unlockedSections: {},
            activeWorkloads: [],
            lastStepClicked: false,
            currentStep: 1,
            totalSteps: 3,
            selectedOptions: {},
        };
    },
    computed: {
        ...mapGetters('config', ['$config']),
        ...mapState({
            fields: (state) => state.vroi.items?.['s-vroi-fields-new'] || [],
            rawSteps: (state) => state.vroi.items?.['s-vroi-steps'] || [],
            dataCenters: (state) => state.vroi.items?.['s-vroi-data-centers'] || [],
            productCodes: (state) => state.vroi.items?.['s-vroi-product-codes-new'] || {},
            currency: (state) => state.vroi.currency || 'USD',
            pricingMethod: (state) => state.vroi.pricingMethod || 1,
            group: (state) => state.vroi?.group,
            discountAfter: (state) => state.vroi.discountAfter || 900,
            currentPriceList: (state) => state.vroi.currentPriceList,
        }),
        isMobileTablet() {
            return ['mobile', 'mobileWide', 'tablet'].includes(this.currentBreakpoint);
        },
        steps() {
            let steps = this.stepper.steps.map((step, index) => ({
                ...step,
                ...this.rawSteps[index],
            }));

            if (!this.isDataProtection) {
                steps = steps.slice(0, -1);
            }

            return steps;
        },
        computedErrorModal() {
            if (this.isServerError) {
                return this.serverErrorModal || this.errorModal;
            }
            return this.errorModal;
        },
        orderCategories() {
            return [
                {
                    key: 'cloud',
                    fields: [
                        ...this.cloudOrderLineFields,
                        ...this.storageOrderLineFields,
                    ],
                },
                { key: 'backup', fields: this.backupOrderLineFields },
                { key: 'security', fields: this.securityOrderLineFields },
                { key: 'psa', fields: this.psaOrderLineFields },
                { key: 'rmm', fields: this.rmmOrderLineFields },
            ];
        },
        cloudOrderLineFields() {
            return [
                { item: this.allOutputs.standardPhysicalServers, reference: 'standardPhysicalServers' },
                { item: this.allOutputs.standardVirtualMachines, reference: 'standardVirtualMachines' },
                { item: this.allOutputs.standardWorkstations, reference: 'standardWorkstations' },
                { item: this.allOutputs.standardM365Seats, reference: 'standardM365Seats' },
                { item: this.allOutputs.standardGoogleSeats, reference: 'standardGoogleSeats' },
                { item: this.allOutputs.standardHostingServers, reference: 'standardHostingServers' },
                { item: this.allOutputs.standardNAS, reference: 'standardNAS' },
                { item: this.allOutputs.standardVMware, reference: 'standardVMware' },
                { item: this.allOutputs.standardWebsite, reference: 'standardWebsite' },
                { item: this.allOutputs.standardMobile, reference: 'standardMobile' },
            ];
        },
        storageOrderLineFields() {
            return [
                {
                    item: this.allOutputs.cloudStorageAcronisCloud,
                    reference: 'cloudStorageAcronisCloud',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageGoogleAzure,
                    reference: 'cloudStorageGoogleAzure',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageThirdParty,
                    reference: 'cloudStorageThirdParty',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudStorageLocal,
                    reference: 'cloudStorageLocal',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.cloudMsStorage,
                    reference: 'cloudMsStorage',
                    suffix: 'GB',
                },
                {
                    item: this.allOutputs.disasterRecoveryStorage,
                    reference: 'disasterRecoveryStorage',
                    suffix: 'GB',
                },
            ];
        },
        backupOrderLineFields() {
            return [
                { item: this.allOutputs.advancedPhysicalServers, reference: 'advancedPhysicalServers' },
                { item: this.allOutputs.advancedVirtualMachines, reference: 'advancedVirtualMachines' },
                { item: this.allOutputs.advancedWorkstations, reference: 'advancedWorkstations' },
                { item: this.allOutputs.advancedM365Seats, reference: 'advancedM365Seats' },
                { item: this.allOutputs.advancedGoogleSeats, reference: 'advancedGoogleSeats' },
                { item: this.allOutputs.advancedHostingServers, reference: 'advancedHostingServers' },
            ];
        },
        securityOrderLineFields() {
            return [
                { item: this.allOutputs.edr, reference: 'edr' },
                { item: this.allOutputs.xdr, reference: 'xdr' },
                { item: this.allOutputs.mdr, reference: 'mdr' },
                { item: this.allOutputs.dlp, reference: 'dlp' },
                { item: this.allOutputs.emailSecurity, reference: 'emailSecurity' },
                { item: this.allOutputs.appSecurity, reference: 'appSecurity' },
                { item: this.allOutputs.sat, reference: 'sat' },
            ];
        },
        psaOrderLineFields() {
            return [
                { item: this.allOutputs.psaUsers, reference: 'psaUsers' },
            ];
        },
        rmmOrderLineFields() {
            return [
                { item: this.allOutputs.rmmEndpoints, reference: 'rmmEndpoints' },
                { item: this.allOutputs.rmmM365Seats, reference: 'rmmM365Seats' },
            ];
        },
        individualStorageOption() {
            switch (this.form.cloudStorageOption) {
                case 1:
                    return 'cloudStorageAcronisCloud';
                case 2:
                    return 'cloudStorageGoogleAzure';
                case 3:
                    return 'cloudStorageThirdParty';
                case 4:
                    return 'cloudStorageLocal';
                default:
                    return false;
            }
        },
        hasUncheckedServices() {
            return this.steps[0]?.sections?.some((section) => !this.selectedServices.includes(section.key)) ?? false;
        },
    },
    beforeDestroy() {
        clearTimeout(this.timer);
        clearTimeout(this.resizeTimer);
        document.removeEventListener('visibilitychange', this.visibilityHiddenHandler);
    },
    async mounted() {
        document.addEventListener('visibilitychange', this.visibilityHiddenHandler);
        await import('sticky-sidebar');
        document.body.classList.add('price-calculator');
        this.isLoading = false;
        await this.$nextTick();
        await this.init();
    },
    methods: {
        onPrevClick() {
            if (this.currentStep > 1) this.changeStep(this.currentStep - 1);
        },
        onNextClick() {
            if (this.currentStep < this.steps.length) this.changeStep(this.currentStep + 1);
        },
        onLastClick() {
            this.currentStep = null;
            this.lastStepClicked = true;
        },
        changeStep(event) {
            this.lastStepClicked = false;
            if (this.currentStep === event) {
                this.currentStep = null;
                return;
            }
            this.currentStep = event;
            this.onCollapse();
        },
        selectAllUnchecked() {
            const checkboxes = Array.isArray(this.$refs.checkboxRefs)
                ? this.$refs.checkboxRefs
                : Object.values(this.$refs.checkboxRefs);

            checkboxes.forEach((checkbox) => {
                const key = checkbox.dataset.key;
                if (this.steps[0].sections.some((section) => section.key === key) && !this.selectedServices.includes(key)) {
                    checkbox.click();
                }
            });
        },
        toggleServiceSelection(section) {
            if (this.selectedServices.includes(section.key)) {
                this.selectedServices = this.selectedServices.filter((service) => service !== section.key);
                this.selectedDisplay[0] = this.selectedDisplay[0].filter((label) => label !== section.label);

                section.unlocks.forEach((unlockKey) => {
                    this.$delete(this.unlockedSections, unlockKey);
                    this.resetFields(unlockKey);
                });
            } else {
                this.selectedServices.push(section.key);
                this.selectedDisplay[0].push(section.label);
                section.unlocks.forEach((unlockKey) => {
                    this.$set(this.unlockedSections, unlockKey, true);
                });
            }
        },

        resetFields(unlockKey) {
            switch (unlockKey) {
                case 'cloudFields':
                    this.cloudOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    this.backupOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    this.storageOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    break;
                case 'securityFields':
                    this.securityOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    break;
                case 'psaFields':
                    this.psaOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    break;
                case 'rmmFields':
                    this.rmmOrderLineFields.forEach((field) => this.changeInput(field.reference, 0));
                    break;
                default:
                    throw new Error(`Unknown field group for reset ${unlockKey}`);
            }
        },

        async init(initialCall = true) {
            if (!this.isAvailable) return;
            if (initialCall) this.adjustPriceList();
            await this.$nextTick();
            const productCodes = this.fetchProductCodesForPriceBook();
            await this.$store.dispatch('vroi/getPricesForPriceBook', productCodes);
            this.calculate();
            if (initialCall) await this.initSidebar();
        },
        changeCloudInputs(reference, value) {
            this.changeInput(reference, value);
            this.checkBackupInputsBounds();
        },
        checkBackupInputsBounds() {
            const backupInputs = ['backupPhysicalServers', 'backupVirtualMachines', 'backupWorkstations', 'backupHostingServers'];
            const cloudInputs = ['cloudPhysicalServers', 'cloudVirtualMachines', 'cloudWorkstations', 'cloudHostingServers'];

            backupInputs.forEach((field, index) => {
                const cloudInputValue = this.form[cloudInputs[index]];
                if (!this.$refs[`backupInputs_${field}`]) return;
                const isOutOfBounds = this.$refs[`backupInputs_${field}`][0];
                if (cloudInputValue >= this.form[field] || !isOutOfBounds) return;

                isOutOfBounds.value = cloudInputValue;
                this.changeInput(field, cloudInputValue);
            });
        },
        checkSidebar() {
            if (!this.sidebar) return;

            if (this.isMobileTablet) {
                this.sidebar.destroy();
                return;
            }

            this.sidebar.initialize();
        },
        async initSidebar() {
            await this.$nextTick();
            try {
                this.sidebar = new window.StickySidebar('#sidebar', {
                    containerSelector: '#main-content',
                    innerWrapperSelector: '.sidebar__inner',
                    topSpacing: 16,
                    bottomSpacing: -56,
                });

                if (!this.isMobileTablet) return this.sidebar.initialize();
                return this.sidebar.destroy();
            } catch {
                return false;
            }
        },
        async visibilityHiddenHandler() {
            if (document.visibilityState !== 'hidden') return;
            if (!this.userData.osauid) return;

            await this.updateOrCreateUser();
        },
        changeInput(reference, value, calculate = true) {
            this.pdfUrl = null;
            this.pdfError = null;
            this.form[reference] = value;
            if (!calculate) return;
            this.calculate();
            this.debouncedUpdateUser();
        },
        debouncedUpdateUser() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.updateOrCreateUser();
            }, 10000);
        },
        onCollapse(collapse) {
            if (this.isMobileTablet) return false;
            this.checkSidebar();
            if (collapse) return this.dispatchEmptyResize();
            this.sidebar.direction = 'down';
            this.sidebar.updateSticky();
            return this.dispatchEmptyResize();
        },
        preselectDataCenter() {
            const country = this.userData.location || this.userData.country;
            const userDataCenter = this.dataCenters.find((item) => item.code === country);
            return userDataCenter?.code || null;
        },
        async sendPdfRenderRequest() {
            await this.exportPdf(this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_SERVICE_API_CLIENT);
        },
    },
    async serverPrefetch() {
        await Promise.all([
            this.$store.dispatch('vroi/getPriceLists'),
            this.$store.dispatch('countries/getCountries'),
            this.$store.dispatch('locales/getLocaleData', { locale: this.$route.params.locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-gated-form', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-unavailable-form', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-fields-new', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-steps', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-data-centers', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-product-codes-new', locale: this.$route.params.locale }),
            this.$store.dispatch('vroi/getSyncedData', { key: 's-vroi-price-books', locale: this.$route.params.locale }),
        ]);
    },
};
</script>
<style lang="postcss">
.price-calculator {
    .el-tooltip {
        &__popper {
            .text {
                @mixin caption;
                font-weight: 400;
                strong {
                    @mixin caption-accent;
                }
            }
            ul {
                list-style: disc;
                list-style-position: outside;
                padding-inline-start: 8px;
                margin-inline-start: 8px;
                li {
                    &::before { display: none; }
                    margin:0;
                }
            }
        }
    }
}
</style>

<style lang="postcss" scoped>
/* z-index guide:
    * Note: usage of some higher values is based on the fact that this
        slice is going to be used separately from others in our system.

    * Backdrop for the mobile cart: 600 (to hide main-menu)
    * mobile cart itself 700 on mobile and auto on desktop to hide everything except the backdrop
    * loader 901 - to hide everything else
    * modals (contact,error,gated,greet) all have 900 on the modal itself and 500 on the close button
*/
#price-calculator {
    margin-top: 96px;
    border-block: 1px solid var(--av-brand-light);
    background: rgb(245, 247, 253);
    @media (--viewport-desktop) {
        background: linear-gradient(to left, var(--av-inversed-primary) 40%, rgb(245, 247, 253) 60%);
    }
    .backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        backdrop-filter: blur(8px);
        background: rgba(36, 49, 67, 0.9);
        content: '';
        z-index: 600;
    }
    #loading {
        position: fixed;
        z-index: 901;
        inset-inline-start: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 1);
        span {
            @mixin body;
            position:absolute;
            top: calc(50% + 80px);
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: var(--av-fixed-primary);
            &:first-line {
                @mixin title;
                font-weight: 500;
            }
        }
    }
    .gated-image {
        &:deep(img) {
            width: 100vw;
        }
    }

    .stepper {
        .main-title {
            @mixin title-accent;
            margin-bottom: 16px;
            color: var(--av-nav-primary);
        }
        .steps {
            display: flex;
            flex-flow: row wrap;
            gap: 16px;
            .step {
                width: 100%;
            }
            &:deep(.step-1) {
                .step-content {
                    padding: 0 16px 24px;
                    @media (--viewport-tablet) {
                        padding: 0 24px;
                    }
                }
                .select-all {
                    width: 100%;
                    display: block;
                    text-align: center;
                    margin: 8px auto 0;
                    @media (--viewport-tablet) {
                        width: auto;
                    }
                    @media (--viewport-desktop) {
                        margin: 8px 0 0;
                    }
                }
            }
            &:deep(.step-2) {
                .section-wrapper:not(.cloudFields) {
                    .number-wrapper {
                        padding: 24px 0;
                        @media (--viewport-desktop) {
                            gap: 16px;
                        }
                    }
                }
                .empty-first {
                    display: block;
                    padding: 0 24px 24px;
                }
                .navigation {
                    margin: 0;
                }
            }
            .checkboxes {
                display: flex;
                flex-flow: row wrap;
                gap: 8px;
                align-items: stretch;
                justify-content: space-between;
                .item {
                    flex-basis: 100%;
                    border: 1px solid var(--av-brand-light);
                    border-radius: 4px;
                    padding: 16px;
                    cursor: pointer;
                    user-select: none;
                    @media (--viewport-tablet) {
                        flex-basis: calc(50% - 4px);
                    }
                }
            }

            .checkbox-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 8px;

                .checkbox {
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--av-brand-light);
                    border-radius: 2px;
                    &:checked {
                        background: var(--av-brand-primary);
                    }
                }
                .label {
                    @mixin body-accent;
                    color: var(--av-nav-primary);
                }
                .checkmark {
                    position: absolute;
                    fill: white;
                }
            }

            .features {
                @mixin body;
                list-style-type: square;
                padding-inline-start: 18px;
                list-style-position: outside;
                color: var(--av-fixed-primary);
            }

            .section-wrapper {
                display: flex;
                flex-flow: row wrap;
                border-top: 2px solid var(--av-brand-light);
                padding: 16px 24px;
                @media (--viewport-tablet) {
                    padding: 24px;
                }
                .head {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .title {
                        @mixin paragraph-accent;
                        color: var(--av-fixed-primary);
                    }
                }
                .column {
                    flex-basis: 100%;
                    display: flex;
                    flex-flow: column;
                    gap: 16px;
                    &:first-child {
                        margin-bottom: 24px;
                        @media (--viewport-tablet) {
                            margin-bottom: 0;
                        }
                    }
                    @media (--viewport-tablet) {
                        flex-basis: 50%;
                    }
                }
                &:deep(.number-wrapper) {
                    width: 100%;
                    .number-inner-wrapper {
                        flex-flow: row nowrap;
                        gap: 16px;
                        @media(--viewport-tablet) {
                            flex-flow: row wrap;
                            gap: 8px;
                        }
                        @media (--viewport-desktop-wide) {
                            flex-flow: row;
                            gap: 16px;
                        }
                        .number-input {
                            order: 0;
                            @media (--viewport-tablet) {
                                order: 1;
                            }
                        }
                    }
                }
                .subsection {
                    width: 100%;
                }
                &:deep(.number-wrapper) {
                    flex-flow: row wrap;
                    @media (--viewport-tablet) {
                        flex-flow: row;
                    }
                }
            }
            .additional-wrapper {
                margin-top: 32px;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                gap: 16px;
                width: 100%;
                .title {
                    @mixin body;
                }
                .chips-wrapper {
                    display:flex;
                    flex-flow: row wrap;
                    gap: 8px;
                    .chip {
                        @mixin caption-accent;
                        padding: 4px 8px;
                        color: var(--av-brand-primary);
                        border: 1px solid var(--av-brand-light);
                        border-radius: 24px;
                        cursor: pointer;
                        &:hover {
                            background: var(--av-brand-lightest);
                        }
                        &.active {
                            background: var(--av-brand-primary);
                            color: var(--av-inversed-primary);
                        }
                    }
                }
            }
            .additional-fields {
                width: 100%;
                margin-top: 16px;
                display: flex;
                flex-flow: column;
                gap: 16px;
            }
            .selects-wrapper {
                display: flex;
                flex-flow: column;
                gap: 16px;
                padding: 16px 8px 24px 24px;
                @media (--viewport-tablet) {
                    padding: 16px 24px 16px 56px;
                }
            }
        }
    }

    #main-content {
        display:flex;
        flex-flow: row wrap;
        align-items: stretch;
        min-height: 71vh;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            flex-flow: row nowrap;
        }
        .bottom-contact {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
            user-select: none;
            margin-top: 48px;
            &:deep(span) {
                cursor: pointer;
                color: var(--av-brand-primary);
                &:hover {
                    color: var(--av-brand-secondary);
                }
            }
        }
        #content {
            @mixin basic-slice-paddings;
            width: 100%;
            background: rgb(245, 247, 253);

            @media (--viewport-desktop) {
                padding-inline-end: 48px;
                border-inline-end: 1px solid var(--av-brand-light);
                width: 60%;
            }
        }
        #sidebar {
            position: sticky;
            bottom: 0;
            width: 100vw;
            background: #fff;
            padding: 16px 16px 24px;
            margin: 0 -16px;
            z-index: 700;
            box-shadow: var(--av-shadow-vertical);
            border-top: 1px solid var(--av-brand-light);
            will-change: min-height;
            @media (--viewport-mobile-wide) {
                padding: 24px 32px 32px;
                margin: 0 -32px;
            }
            @media (--viewport-desktop) {
                padding: 0;
                margin: 0;
                width: 40%;
                box-shadow: none;
                border-top: none;
                z-index: auto;
                bottom: unset;
            }
            &.is-affixed {
                .sidebar__inner{
                    padding-top: 32px;
                }
            }
            .sidebar__inner{
                transform: translate(0, 0); /* For browsers don't support translate3d. */
                transform: translate3d(0, 0, 0);
                will-change: position, transform;
                @media (--viewport-desktop) {
                    padding: 64px 40px 104px;
                    padding-inline-end: 0;
                }
                .cart-line {
                    width: auto;
                    height: 1px;
                    margin: 24px 0;
                    background: var(--av-brand-light);
                    &.short {
                        margin-bottom: 8px;
                        width:80px;
                    }
                }
                .cart-content {
                    .mobileOrder {
                        display: flex;
                        flex-flow: row wrap;
                        user-select: none;
                        .label {
                            align-items: center;
                            width: 100%;
                            display: flex;
                            font-size: 20px;
                            line-height: 24px;
                            flex-flow: row nowrap;
                            font-weight: 700;
                            color: var(--av-nav-primary);
                            @media (--viewport-mobile-wide) {
                                font-size: 20px;
                            }
                            .perMonth {
                                margin-inline-start: 8px;
                            }
                            &:deep(.a-tooltip) {
                                display: flex;
                                margin-inline-start: 8px;
                            }
                            a.expand {
                                display: flex;
                                margin-inline-start: auto;
                            }
                        }
                        .button {
                            width: 100%;
                            margin-top: 16px;
                        }
                        .link {
                            margin-top: 24px;
                            font-weight: 700;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    .pricing {
                        &.mobile {
                            padding: 48px 16px 24px;
                            transform: translateY(-100%);
                            top: -17px;
                            height: calc(100vh - 230px);
                            width: 100vw;
                            margin: 0 -16px;
                            position: fixed;
                            background: var(--av-inversed-primary);
                            border-top: 1px solid var(--av-brand-light);
                            box-shadow: inset 0px -5px 10px var(--av-fixed-lightest);
                            overflow: auto;
                            overflow: -moz-scrollbars-none;
                            -ms-overflow-style: none;
                            scrollbar-width: none;
                            animation: mobile-cart-appear 0.3s;
                            @media (--viewport-mobile-wide) {
                                padding: 48px 32px 24px;
                                margin: 0 -32px;
                                top: -25px;
                                height: calc(100vh - 240px);
                            }
                            @keyframes mobile-cart-appear {
                                0% {
                                    height: 0;
                                    opacity: 0;
                                }
                                100% {
                                    height: calc(100vh - 230px);
                                    opacity: 1;
                                    @media (--viewport-mobile-wide) {
                                        height: calc(100vh - 240px);
                                    }
                                }
                            }
                        }
                        .main-title {
                            @mixin title-accent;
                            margin-bottom: 24px;
                        }
                        .licensing-model {
                            margin-bottom: 24px;
                            .head {
                                @mixin colls 12;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                margin-bottom: 24px;
                                .title {
                                    @mixin paragraph-accent;
                                }
                            }
                            .pricing-not-available {
                                background: var(--av-fixed-success-accent);
                                color: var(--av-fixed-secondary);
                                padding: 16px;
                                border-radius: 4px;
                                margin-top: 24px;
                                display: block;
                            }
                            .pricing-methods {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                margin-bottom: 8px;
                                .method {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    @mixin body-accent;
                                    color: var(--av-brand-primary);
                                    text-align:center;
                                    padding: 4px 16px;
                                    background: var(--av-inversed-primary);
                                    border: 1px solid var(--av-brand-light);
                                    border-radius: 16px;
                                    cursor: pointer;
                                    transition: all 0.3s;
                                    &:hover {
                                        background: var(--el-secondary-hover);
                                    }
                                    &:active {
                                        background: var(--el-secondary-active);
                                    }
                                    &.selected {
                                        color: var(--av-fixed-primary);
                                        background: rgba(255, 249, 230, 1);
                                        border: 1px solid rgba(255, 129, 13, 1);
                                        &.recommended {
                                            color: var(--av-fixed-primary);
                                            background: var(--av-fixed-success-accent);
                                            border: 1px solid var(--av-fixed-success-dark);
                                        }
                                    }
                                }
                            }
                            .pricing-recommendation {
                                @mixin colls 12;
                                @mixin body;
                                color: rgba(255, 129, 13, 1);
                            }
                        }
                        .order {
                            .order-group {
                                display:flex;
                                flex-flow: row wrap;
                                margin-bottom: 24px;
                                transition: all 0.3s;
                                gap: 8px;
                                .head {
                                    @mixin colls 12;
                                    @mixin body;
                                    display:flex;
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    color: var(--av-nav-primary);
                                    font-weight: 500;
                                }
                                .options {
                                    @mixin colls 12;
                                    gap: 8px;
                                    display:flex;
                                    flex-flow: row wrap;
                                    .order-item {
                                        @mixin colls 12;
                                        display:flex;
                                        flex-flow: row nowrap;
                                        justify-content: space-between;
                                        @mixin caption;
                                        color: var(--av-fixed-light);
                                    }
                                }
                            }
                            &.compact {
                                .order-group {
                                    margin-bottom: 8px;
                                    &:last-child {
                                        margin-bottom: 24px;
                                    }
                                }
                            }
                        }
                        .totals {
                            display:flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            margin-bottom: 24px;
                            .label {
                                @mixin title-accent;
                            }
                            .sums {
                                text-align: end;
                                display:flex;
                                flex-flow: row wrap;
                                .perMonth {
                                    @mixin colls 12;
                                    @mixin title-accent;
                                }
                                .taxes {
                                    @mixin colls 12;
                                    @mixin caption;
                                    color: var(--av-fixed-light);
                                }
                            }
                        }
                        .actions {
                            .button {
                                @mixin colls 12;
                                font-weight: 700;
                                margin-bottom: 24px;
                            }
                            .link {
                                @mixin colls 12;
                                text-align: center;
                                &:deep(.a-link__content) {
                                    @mixin body;
                                    @media (--viewport-mobile-wide) {
                                        @mixin paragraph;
                                    }
                                }
                            }
                        }
                        .download-detailed-summary {
                            color: var(--av-fixed-secondary);
                            margin-bottom: 24px;
                            &:hover {
                                color: var(--av-brand-primary);
                            }
                            &.in-progress {
                                color: var(--av-fixed-lighter);
                                pointer-events: none;
                            }
                            .icon {
                                &.animate {
                                    animation: vroi-pdf-rotate 6s linear infinite;
                                    transform-origin: center;
                                    transform-style: preserve-3d;
                                    @keyframes vroi-pdf-rotate {
                                        25% { transform:rotate(0deg);}
                                        50% { transform:rotate(360deg);}

                                        75% { transform:rotate(360deg);}
                                        100% { transform:rotate(0deg);}
                                    }
                                }
                            }
                        }
                        .toggle-detailed-summary {
                            color: var(--av-brand-primary);
                        }
                        .download-detailed-summary,
                        .toggle-detailed-summary {
                            @mixin body;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            &:deep(.a-glyph) {
                                fill: var(--av-brand-primary);
                            }
                        }
                    }
                }
                .validation {
                    .main-title {
                        @mixin title-accent;
                        margin-bottom: 24px;
                    }
                    .not-filled {
                        color: var(--av-fixed-secondary);
                        @mixin paragraph;
                        .message {
                            background: var(--av-fixed-success-accent);
                            color: var(--av-fixed-secondary);
                            padding: 16px;
                            border-radius: 4px;
                            @mixin descriptor-accent;
                        }
                        .mobileTitle {
                            @mixin colls 12;
                            display:flex;
                            align-items: center;
                            user-select: none;
                            color: var(--av-nav-primary);
                            h5 {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                @media (--viewport-mobile-wide) {
                                    font-size: 20px;
                                }
                            }
                            a {
                                margin-inline-start: auto;
                                display: flex;
                            }
                        }
                        .title {
                            @mixin colls 12;
                            @mixin title-accent;
                            margin-bottom: 24px;
                            color: var(--av-nav-primary);
                            animation-delay: 0.2s;
                        }
                        .assistance {
                            user-select: none;
                            margin-top: 16px;
                            @media (--viewport-desktop) {
                                margin-top: 0;
                            }
                            &:deep(span) {
                                cursor: pointer;
                                color: var(--av-brand-primary);
                                &:hover {
                                    color: var(--av-brand-secondary);
                                }
                            }
                        }
                    }
                }

                .discount {
                    .discount-wrapper {
                        display: flex;
                        flex-flow: row wrap;
                        color: var(--av-nav-primary);
                        .image {
                            margin-bottom: 24px;
                        }
                        .mobileTitle {
                            @mixin colls 12;
                            display:flex;
                            align-items: center;
                            user-select: none;
                            .image {
                                display: none;
                                max-width: 72px;
                                margin:0;
                                @media (--viewport-mobile-wide) {
                                    display: block;
                                }
                            }
                            h5 {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                @media (--viewport-mobile-wide) {
                                    margin-inline-start: 16px;
                                    font-size: 20px;
                                }
                            }
                            a {
                                margin-inline-start: auto;
                                display: flex;
                            }
                        }
                        .title {
                            @mixin colls 12;
                            @mixin title-accent;
                            animation-delay: 0.2s;
                        }
                        .description {
                            display: block;
                            @mixin paragraph;
                            color: var(--av-fixed-secondary);
                            font-weight: 500;
                            margin-top: 16px;
                            animation-delay: 0.5s;
                            &:deep(strong) {
                                font-weight: 700;
                                color: var(--av-nav-primary);
                            }
                            @media (--viewport-desktop) {
                                font-weight: 400;
                                margin-top: 24px;
                            }
                        }
                        .button {
                            @mixin colls 12;
                            @mixin paragraph-accent;
                            margin-top: 16px;
                            @media (--viewport-desktop) {
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }
        }
    }

    &:deep(.a-glyph__tooltip-o) {
        fill: #949AA3;
    }
    &:deep(.a-glyph__success-o) {
        fill: #9BC225;
    }
    &:deep(.a-glyph__warning-o) {
        fill: #FFC107;
    }
    &:deep(.a-tooltip) {
        vertical-align: middle;
        .tooltip-wrapper {
            display: flex;
        }
    }
}

[dir="rtl"] {
    #price-calculator {
        @media (--viewport-desktop) {
            background: linear-gradient(to right, var(--av-inversed-primary) 40%, rgb(245, 247, 253) 60%);
        }
    }
}

.vroi-fade-in-enter-active,
.vroi-fade-in-leave-active {
    transition: 0.3s;
}
.vroi-fade-in-enter {
    opacity: 0;
}
.vroi-fade-in-leave-to {
    opacity: 0;
}

.vroi-slide-up-enter-active,
.vroi-slide-up-leave-active {
    transition: 0.3s;
}
.vroi-slide-up-enter {
    opacity: 0;
    transform: translateY(10%);
}
.vroi-slide-up-leave-to {
    opacity: 0;
    transform: translateY(5%);
}

@keyframes vroi-up {
    0% {
        opacity:0;
        transform: translateY(20px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}
</style>
