.bg-section {
    background: var(--av-nav-primary);
}


.cpoc-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .main-content {
        flex: 1 0 auto;
    }

    > .footer {
        flex-shrink: 0;
    }

    .s-cpoc-header {
        position: relative;
        padding-top: 24px;
        padding-bottom: 16px;
        top: 0;
        .s-main-menu {
            top: 94px;
        }
        .a-accordion-item {
            .icon {
                fill: var(--av-nav-secondary);
            }
            .title {
                color: var(--av-nav-secondary);
            }
            &:hover {
                .icon {
                    fill: var(--av-nav-secondary);
                }
                .title {
                    color: var(--av-nav-secondary);
                }
            }
        }
        .inner-tabs {
            background: none;
        }
        .environments-title,
        .menu-tab-solutions .s-menu-tab__section_divider_wide:before {
            border-color: var(--av-brand-accent);
        }
    }

    .s-menu-tab__cta-item-link.make-accent {
        background: transparent;
    }

    .list-column-category {
        color: var(--av-fixed-primary);
    }

    .s-global-footer {
        background: var(--av-nav-primary);
        .a-footer__border-top {
            border-top: 1px var(--av-link-inversed);
        }
        .a-footer__meta {
            display: none;
            @media (--viewport-desktop) {
                display: block;
            }
        }
        .a-footer__item {
            margin-top: 0;
        }
    }

    .a-value-card_linked {
        .a-value-card__title {
            color: var(--av-fixed-primary);
        }
        &:hover {
            .a-value-card__title {
                color: var(--av-nav-secondary);
            }
        }
    }

    .pagination {
        padding-top: 32px;
    }

    .page-button {
        color: var(--av-fixed-primary);

        svg {
            fill: var(--av-fixed-primary);
        }
    }

    .pagination--light .page-button.el-select .is-focus .el-input__container {
        background: var(--av-nav-secondary);

        input {
            color: var(--av-inversed-primary);
        }
    }

    .page-button--current,
    .page-button:active {
        color: var(--av-inversed-primary);
        background: var(--av-nav-secondary);
    }
}

.blog-dropdown.el-select-dropdown {
    border: 1px solid #f00;
    border-radius: 4px;
    padding: 16px 0 16px;
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

    .el-select-dropdown {
        &__item,
        &__item.selected {
            @mixin body;

            padding: 0 16px;
            font-weight: 500;
            height: 32px;
            color: var(--av-fixed-primary);
        }

        &__list {
            padding: 0;
        }
    }

    .el-scrollbar .el-select-dropdown__item.selected,
    .el-scrollbar .el-select-dropdown__item {
        &:hover,
        &:active {
            background: var(--av-brand-lightest);
        }
    }

    .el-scrollbar__thumb {
        background: var(--av-fixed-lighter);
    }
}
