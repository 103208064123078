<template>
    <section v-if="isVisible && isAvailable" :class="className">
        <div class="a-container">
            <div v-if="isMobile">
                <el-select v-model="chosenOption" @change="handleClick($event)">
                    <el-option
                        v-for="(item, itemIndex) in content.list"
                        :key="`item-${itemIndex}`"
                        :label="item.label"
                        :value="itemIndex"
                    />
                </el-select>
            </div>
            <a-tabs type="tile" :active-tab="activeTab" @click="handleClick($event)">
                <a-tab v-for="(item, itemIndex) in content.list" :key="itemIndex" :label="item.label">
                    <div class="wrapper">
                        <a-picture v-if="item.picture?.link" v-bind="item.picture" />
                        <div class="content">
                            <div>
                                <s-main-page-tag v-bind="tag({name: 'title'}, item)" />
                                <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true}, item)" />
                            </div>
                            <s-main-page-cta v-if="item.cta" :content="cta(item)" :title="item.ctaTitle" />
                        </div>
                    </div>
                </a-tab>
            </a-tabs>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import SMainPageCta from '../components/cta.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageServices',
    components: {
        APicture,
        ATab,
        ATabs,
        SMainPageCta,
        SMainPageTag,
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },
    mixins: [mixin, breakpoint, form],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        serviceIDs: {},
        chosenOption: 0,
    }),
    computed: {
        className() {
            return {
                'main-page-services': true,
                'has-border-top': this.content.hasBorderTop,
            };
        },
        isVisible() {
            return this.content?.list?.length;
        },
        activeTab() {
            const id = this.serviceIDs?.[this.content.tabActive];
            if (Number.isInteger(id)) return id;

            if (!this.content?.active?.length) return 0;
            return this.content.active[this.content.tabActive];
        },
    },
    watch: {
        activeTab: {
            handler(value) {
                this.chosenOption = value;
            },
        },
    },
    beforeMount() {
        try {
            this.serviceIDs = JSON.parse(localStorage.getItem('main-page-service-id')) || {};
        } catch {
            this.serviceIDs = {};
        }
    },
    methods: {
        handleClick(id) {
            if (!Number.isInteger(id)) return;
            if (!Number.isInteger(this.content.tabActive)) return;
            this.serviceIDs[this.content.tabActive] = id;
            localStorage.setItem('main-page-service-id', JSON.stringify(this.serviceIDs));

            const event = this.content.list[id].event;
            if (!event) return;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'Homepage Feature Tab',
                eventContext: 'click',
                eventContent: this.content?.eventContent?.[this.content.tabActive] || '',
                eventLocation: 'Homepage Top Product Banner',
                eventButton: (id + 1).toString(),
                ...event,
            });
        },
        cta(content) {
            return content.cta.map((item) => ({
                event: {
                    location: content.event?.eventLabel || '',
                    content: this.content?.eventContent?.[this.content.tabActive] || '',
                },
                ...item,
            }));
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-services {
    margin: 88px 0 0;
    .wrapper {
        gap: 16px;
        display: flex;
        padding: 16px;
        flex-direction: column-reverse;
        @media (--viewport-tablet) {
            padding: 32px 16px 16px;
        }
        @media (--viewport-desktop) {
            flex-direction: row;
        }
        @media (--viewport-desktop-wide) {
            padding: 32px 16px;
        }
        @media (--viewport-desktop-large) {
            gap: 32px;
            padding: 32px;
        }

    }
    .content {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
        @media (--viewport-desktop) {
            flex: 1 1 0;
            text-align: start;
        }
    }
    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin title-accent;
        }
    }
    .description {
        @mixin paragraph;
        margin-top: 16px;
        color: var(--av-nav-primary);
        @media (--viewport-desktop-wide) {
            @mixin lead;
        }
    }
    &:deep(.main-page-cta) {
        .main-page-cta-content {
            display: flex;
            justify-content: flex-start;
        }
        .a-button {
            @media (--viewport-tablet) {
                flex: 0;
            }
        }
    }
    &:deep(.a-tabs) {
        margin-top: 8px;
        border-radius: 16px;
        position: relative;
        min-height: 384px;
        background: linear-gradient(180deg, rgba(235, 242, 251, 0.50) -6.75%, rgba(245, 249, 254, 0.50) 100%);
        @media (--viewport-tablet) {
            margin-top: 0;
            padding-top: 24px;
        }
        @media (--viewport-desktop-large ) {
            margin: 0 -32px;
        }
        .a-tabs__header {
            margin: 0;
            padding: 0;
            display: none;
            overflow: unset;
            justify-content: center;
            @media (--viewport-tablet) {
                display: flex;
                margin-top: -48px;
            }
        }
        .a-tabs__list-wrapper {
            padding: 0;
            width: auto;
            overflow: hidden;
            border-radius: 999px;
            background: var(--av-inversed-primary);
            border: 1px solid var(--av-brand-secondary-light);
        }
        .a-tabs__list-wrapper__list {
            display: flex;
            padding: 8px;
            flex-wrap: wrap;
            justify-content: center;
            backdrop-filter: blur(8px);
            background: linear-gradient(0deg, rgba(64, 139, 234, 0.10) 0%, rgba(64, 139, 234, 0.10) 100%), #fff;
        }
        .a-tabs__item {
            flex: none;
            border-radius: 0;
            box-shadow: none;
        }
        .a-tabs-item {
            margin: 0;
            height: auto;
            border: none;
            box-shadow: none;
            background: none;
            padding: 8px 12px;
        }
        .a-tabs-item_active {
            border-radius: 24px!important;
            background: var(--av-inversed-primary);
        }
        .a-tabs-item__label {
            @mixin paragraph-accent;
            white-space: nowrap;
            color: var(--av-nav-primary);
        }
    }
    &:deep(.a-picture) {
        .a-picture__img {
            width: 100%;
            border-radius: 6px;
            background: var(--av-inversed-primary);
            border: 1px solid var(--av-brand-secondary-light);
            @media (--viewport-tablet) {
                width: 471px;
                height: 283px;
                margin: 0 auto;
                @media (--viewport-desktop-wide) {
                    width: 537px;
                    height: 323px;
                }
            }
        }
    }
    &.has-border-top {
        &:deep(.a-tabs) {
            border: 1px solid var(--av-brand-secondary-light);
        }
    }
}
</style>
