<template>
    <s-event-countdown v-bind="content" />
</template>

<script>
import SEventCountdown from '@core/slices/pages/event-countdown/event-countdown.vue';

export default {
    name: 'SMainPageAwards',
    components: {
        SEventCountdown,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>
<style lang="postcss" scoped>
.s-event-countdown {
    margin-top: 32px;
    margin-bottom: -64px;

    @media (--viewport-tablet) {
        margin-top: 68px;
        margin-bottom: 0;
    }
    @media (--viewport-desktop) {
        margin-top: 48px;
        margin-bottom: -16px;
    }

    &.small-version {
        margin-top: 36px;
        margin-bottom: -48px;

        @media (--viewport-tablet) {
            margin-bottom: -24px;
        }
        @media (--viewport-desktop) {
            margin-bottom: -28px;
        }
    }
}
</style>
