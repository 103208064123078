<template>
    <div class="s-subscription" v-bind="$attrs">
        <div class="wrapper">
            <p v-if="computedTitle" class="title">
                {{ computedTitle }}
            </p>
            <a-button
                v-if="button"
                v-modal="'s-subscription-modal'"
                v-bind="button"
            />
        </div>
        <a-modal
            v-if="slice && slice.form"
            id="s-subscription-modal"
            :is-overlay-close="false"
            :use-layout="false"
            :has-container-close="true"
        >
            <div class="s-subscription__registration">
                <s-form-container :form="slice.form" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { merge } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import l10n from '@core/mixins/l10n.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default {
    components: {
        AModal,
        AButton,
        SFormContainer,
    },
    directives: {
        Modal,
    },
    mixins: [l10n, breakpoint],
    props: {
        title: {
            type: String,
            default: '',
        },
        shortTitle: {
            type: String,
            default: '',
        },
        button: {
            type: Object,
            default() {},
        },
        hasSocials: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            slice: null,
        };
    },
    computed: {
        computedTitle() {
            if (this.isMobile || this.isTablet || this.isDesktop) return this.title;
            if (this.hasSocials) return this.shortTitle;
            return this.title;
        },
        isDesktop() {
            return this.currentBreakpoint === 'desktop';
        },
        isDesktopWide() {
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },
    },
    mounted() {
        const sliceSettings = this.$store.state.slices.items['s-subscription'] || {};
        const propsSettings = this.l10n.settings || {};
        this.slice = merge(sliceSettings, propsSettings);
    },
};
</script>

<style lang="postcss">
.el-select-dropdown {
    &.el-select-subscription {
        z-index: 2147483655 !important; /* this must be on top of modal  which has z-index: 2147483645 because of GEO IP ribbon */
        .el-select-dropdown__item {
            @mixin body;
        }
    }
}
</style>

<style lang="postcss" scoped>
.s-subscription {
    .wrapper {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        gap: 24px;
        @media (--viewport-tablet) {
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
        }
        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
        .title {
            @mixin paragraph-accent;
        }
        .a-button {
            padding: 8px 24px;
        }
    }

    &-modal {
        overflow: hidden;
        background: var(--av-inversed-primary);
    }
    &__registration {
        .s-form-container {
            margin: 0;
            width: auto;
            text-align: start;

            &:deep(.el-form) {
                margin: 40px 0 0;
            }

            &:deep(.s-form-registration) {
                padding: 40px;
                border: none;
                box-shadow: none;

                &__title {
                    @mixin title;
                    font-weight: 600;
                    text-align: start;
                    color: var(--av-nav-primary);
                    font-family: var(--font-family-cyber);
                }

                &__error {
                    @mixin caption;
                }
            }

            &:deep(.s-form-success) {
                display: flex;
                min-height: 568px;
                padding: 106px 16px;
                flex-direction: column-reverse;

                .description {
                    @mixin title;
                    margin: 0;
                    font-weight: 600;
                    text-align: center;
                    padding: 0 16px 40px;
                    color: var(--av-nav-primary);
                }
            }

            &:deep(.el-form-item__error-holder) {
                height: 24px;
            }

            &:deep(input),
            &:deep(.el-input__placeholder) {
                @mixin body;
            }

            &:deep(.el-input__label) {
                @mixin body;

                &.is-active {
                    @mixin caption;
                    padding-top: 1px;
                }
            }
        }
    }
}

#s-subscription-modal {
    :deep(.inner) {
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-modal);
        border-radius: 8px;
        padding: 0;
        max-width: 500px;
    }
}
</style>
