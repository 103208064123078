.events-page {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .main-content {
        flex: 1 0 auto;
    }

    > .footer {
        flex-shrink: 0;
    }

    h1 {
        @mixin display-accent;
        margin-bottom: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
        }
    }

    h2 {
        @mixin display-accent;

        margin-bottom: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin hero-accent;
        }
    }

    .section-lead {
        @mixin lead;

        margin-bottom: 48px;

        @media (--viewport-tablet) {
            @mixin title;
        }

        @media (--viewport-desktop) {
            width: 83%;
        }

        @media (--viewport-desktop-wide) {
            width: 66.6%;
        }
    }

    input,
    button,
    select {
        color: var(--av-fixed-primary);
    }

    .event-link {
        position: relative;
        cursor: pointer;
        color: var(--av-brand-secondary);
        text-decoration: none;
        display: inline-block;
        transition: color linear 0.12s;
        background: none;
        padding: 0;
        border: 0;
        outline: 0;

        .a-glyph {
            fill: var(--av-brand-secondary);
            display: inline-block;
            vertical-align: middle;
            transition: fill linear 0.12s;
            margin-inline-start: 16px;
            margin-top: -2px;

            &.left {
                margin-inline-start: 0;
                margin-inline-end: 8px;
                margin-top: -4px;
            }
        }

        &:hover,
        &:active {
            color: var(--av-brand-primary);
            opacity: 0.9;
            background: transparent;

            .a-glyph {
                fill: var(--av-brand-primary);
                opacity: 0.9;
            }
        }

        &.white {
            color: var(--av-inversed-primary);

            .a-glyph {
                fill: var(--av-inversed-primary);
            }

            &:hover,
            &:active {
                color: var(--av-inversed-primary);
                opacity: 0.9;
                background: transparent;

                .a-glyph {
                    fill: var(--av-inversed-primary);
                    opacity: 0.9;
                }
            }
        }
    }

    .event-button {
        @mixin paragraph-accent;

        display: inline-flex;
        justify-content: center;
        margin: 0;
        padding: 12px 16px;
        box-shadow: 0 0 0 1px var(--av-brand-accent) inset;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        transition: color, background linear 0.2s;
        white-space: nowrap;
        color: var(--av-inversed-primary);
        background: var(--av-button-main);
        &:hover {
            background: var(--av-button-main-hover);
        }
        &:active {
            background: var(--av-button-main-active);
        }

        &.action {
            background: var(--av-button-action);
            &:hover {
                background: var(--av-button-action-hover);
            }
            &:active {
                background: var(--av-button-action-active);
            }
        }

        .a-glyph {
            margin-inline-start: 16px;
            fill: var(--av-inversed-primary);
        }
    }

    .el-input__container {
        border-color: var(--av-brand-light);
    }

    .el-input.is-focus .el-input__container {
        border-color: var(--av-brand-secondary);
    }

    .el-input__suffix .i {
        color: var(--av-brand-secondary);
    }

    .el-select-dropdown.dropdown {
        /* WEB-43924 why do we use 2100 here ? */
        z-index: 2100 !important;
        border-color: var(--av-brand-secondary);

        .el-scrollbar {
            .el-select-dropdown__item,
            .el-select-dropdown__item.selected {
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                padding: 0 16px;
                color: var(--av-nav-primary);

                &:hover {
                    background: var(--av-brand-secondary-accent);
                }

                span {
                    line-height: 24px !important;
                    display: inline-block;
                    vertical-align: bottom;
                }
            }
        }

        .el-select-dropdown-search-form {
            height: 56px;
            border-bottom: 1px solid var(--av-brand-accent);
        }

        .el-select-dropdown-search {
            .el-autocomplete {
                width: 100%;
                padding: 0 8px;
            }

            .el-input__icon--control {
                display: flex;
            }
        }
    }
}
