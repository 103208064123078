<template>
    <div ref="sideMenu" class="side-menu">
        <div>
            <div>
                <p class="side-menu__search-title">
                    {{ dictionaryText.searchTitle }}
                </p>
                <div class="side-menu__product-search-wrapper">
                    <div class="side-menu__product-search">
                        <el-input
                            ref="searchInput"
                            v-model="searchText"
                            :label="dictionaryText.productName"
                            @focus="onSearchInputFocus"
                            @blur="hideSearchResults"
                        >
                            <template #suffix>
                                <span
                                    v-if="searchText.length"
                                    class="input-suffix"
                                    name="close"
                                    @click="clearSearch"
                                >
                                    <a-glyph name="close" />
                                </span>
                                <span v-else class="input-suffix">
                                    <a-glyph name="search" />
                                </span>
                            </template>
                        </el-input>

                        <div
                            v-if="showSearchResults && searchResults.length"
                            ref="searchResultWrapper"
                            class="side-menu__search-results-wrapper"
                        >
                            <ul tabindex="-1">
                                <template v-if="!searchText && !isDesktop">
                                    <li class="suggestions__results result-subtitle" tabindex="1">
                                        {{ dictionaryText.suggestions }}
                                    </li>
                                    <li
                                        v-for="suggestion in suggestions"
                                        ref="suggestion"
                                        :key="`suggestion-${suggestion.id}`"
                                        tabindex="1"
                                        class="search-result__item suggestions__results"
                                        @click="selectProduct(suggestion.text)"
                                        @keydown="onSearchResultKeydown"
                                        @blur="hideSearchResults"
                                        @keydown.enter="selectProduct(suggestion.text)"
                                    >
                                        {{ suggestion.text }}
                                    </li>
                                </template>
                                <li v-if="!searchText" class="result-subtitle" tabindex="1">
                                    {{ dictionaryText.productSearch }}
                                </li>
                                <li
                                    v-for="item in searchResults"
                                    ref="searchResult"
                                    :key="`filter-${item.name}`"
                                    tabindex="1"
                                    class="search-result__item"
                                    @click="selectProduct(item.name)"
                                    @keydown="onSearchResultKeydown"
                                    @blur="hideSearchResults"
                                    @keydown.enter="selectProduct(item.name)"
                                >
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        class="menu-activator"
                        tabindex="0"
                        :class="{ active: showSearchFilter }"
                        @keydown.enter="toggleSearchFiltersDisplay"
                        @click="toggleSearchFiltersDisplay"
                    >
                        <a-glyph name="filter" fill="brand-primary" />
                    </div>

                    <div v-if="showSearchFilter" class="side-menu__search-filters">
                        <s-filters
                            :product-groups="productGroups"
                            :related-resources="relatedResources"
                            :groups-with-products="groupsWithProducts"
                            :dictionary="dictionary"
                            :suggestions="suggestions"
                            @group-changed="groupChange"
                            @product-changed="selectProduct"
                        />
                    </div>
                </div>
            </div>

            <s-filters
                class="side-menu__search-filters-desktop"
                :product-groups="productGroups"
                :related-resources="relatedResources"
                :groups-with-products="groupsWithProducts"
                :dictionary="dictionary"
                :suggestions="suggestions"
                @group-changed="groupChange"
                @product-changed="selectProduct"
                @accordionCollapse="onAccordionCollapse"
            />
        </div>
    </div>
</template>

<script>
import { delay } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SFilters from './filters.vue';

export default {
    name: 'SSideMenu',
    components: {
        AGlyph,
        SFilters,
        ElInput: () => import('@uikit/ui-kit/packages/input'),
    },
    mixins: [breakpoint],
    props: {
        documentation: {
            type: Array,
            default: () => ([]),
        },
        links: {
            type: Array,
            default: () => ([]),
        },
        activeProductGroup: {
            type: String,
            default: 'all-products',
        },
        dictionary: {
            type: Object,
            required: true,
        },
    },
    emits: ['update:active-product-group', 'update:product-name-search', 'accordionCollapse'],
    data() {
        return {
            searchText: '',
            showSearchFilter: false,
            showSearchResults: false,
        };
    },
    computed: {
        dictionaryText() {
            return {
                searchTitle: this.dictionary['Search products'] || 'Search products',
                allProducts: this.dictionary['All products'] || 'All products',
                productName: this.dictionary['Product name'] || 'Product name',
                suggestions: this.dictionary['Top Suggestions'] || 'Top Suggestions',
                productSearch: this.dictionary['Latest products'] || 'Latest products',
            };
        },
        relatedResources() {
            return this.links.filter((item) => item.link);
        },
        suggestions() {
            return this.links.filter((link) => link.id);
        },
        productGroups() {
            return [{ id: 'all-products', caption: this.dictionaryText.allProducts }, ...this.documentation];
        },
        groupsWithProducts() {
            const term = this.searchText.toLowerCase();

            const groups = this.documentation
                .filter((group) => (
                    group.products.some((product) => product.name.toLowerCase().includes(term))
                ))
                .map((group) => group.id);

            return ['all-products', ...groups];
        },
        searchResults() {
            const term = this.searchText.toLowerCase();

            return this.documentation
                .flatMap((doc) => doc.products)
                .filter((product) => product.name.toLowerCase().includes(term))
                .sort((a, b) => {
                    const aLower = a.name.toLowerCase();
                    const bLower = b.name.toLowerCase();

                    if (aLower < bLower) return -1;
                    if (aLower > bLower) return 1;
                    return 0;
                });
        },
    },
    watch: {
        activeProductGroup() {
            this.setUrlHash();
        },
    },
    mounted() {
        const url = new URL(window.location);
        const params = new URLSearchParams(url.hash.slice(1));

        this.groupChange(params.get('group') || '');
        document.addEventListener('keydown', this.handleKeyboardEvents);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyboardEvents);
    },
    methods: {
        onAccordionCollapse(event) {
            this.$emit('accordionCollapse', event);
        },
        setUrlHash() {
            window.location.hash = `group=${this.activeProductGroup}&product=${this.searchText}`;
        },
        toggleSearchFiltersDisplay() {
            this.showSearchFilter = !this.showSearchFilter;
        },
        async groupChange(value) {
            this.$emit('update:active-product-group', value);
            await this.$nextTick();
            this.showSearchFilter = false;
            this.setUrlHash();
        },
        onSearchResultKeydown(e) {
            if (!document.activeElement.classList.contains('search-result__item')) return;

            const activeElement = document.activeElement;

            if (e.key === 'ArrowDown' && activeElement.nextElementSibling) {
                const el = activeElement.nextElementSibling.classList.contains('result-subtitle')
                    ? this.$refs.searchResult[0]
                    : activeElement.nextElementSibling;

                el.focus();
            }

            if (e.key === 'ArrowUp' && activeElement.previousElementSibling) {
                const el = activeElement.previousElementSibling.classList.contains('result-subtitle')
                    ? this.$refs.suggestion[this.$refs.suggestion.length - 1]
                    : activeElement.previousElementSibling;

                el.focus();
            }
        },
        handleKeyboardEvents(e) {
            const searchInput = this.$refs.searchInput.$el.querySelector('input');

            if (e.key === 'Enter' && document.activeElement === searchInput) {
                this.selectProduct(this.searchText, true);
            }

            if (e.key === 'ArrowDown' && document.activeElement === searchInput && this.searchResults.length) {
                e.preventDefault();
                const resultItemEl = document.querySelector('.search-result__item');
                resultItemEl.focus();
            }

            if (e.key !== 'Escape') return;
            if (!document.activeElement.classList.contains('search-result__item') && document.activeElement !== searchInput) return;

            this.searchText = '';
            this.showSearchResults = false;
            searchInput.blur();
        },
        selectProduct(item, showResults = false) {
            this.searchText = item;
            this.showSearchResults = showResults;

            this.$emit('update:product-name-search', item);
            this.setUrlHash();
        },
        applyFilter() {
            this.showSearchFilter = false;
        },
        closeFilter() {
            this.showSearchFilter = false;
        },
        clearSearch() {
            this.searchText = '';
            const searchInput = this.$refs.searchInput.$el.querySelector('input');
            searchInput.focus();
        },
        onSearchInputFocus() {
            this.showSearchResults = true;
        },
        hideSearchResults() {
            delay(() => {
                if (document.activeElement.classList.contains('search-result__item')) return;

                this.showSearchResults = false;

                if (!this.searchText) {
                    this.selectProduct('');
                }
            }, 100);
        },
    },
};
</script>

<style lang="postcss" scoped>
.side-menu {
    @mixin colls 12;
    @media (--viewport-desktop) {
        padding-inline-end: 32px;
    }

    &__search-filters-desktop {
        display: none;

        @media(--viewport-desktop) {
            display: block;
        }
    }

    &__search-results-wrapper {
        padding: 8px 0;
        @media(--viewport-desktop) {
            .result-subtitle {
                display: none;
            }
        }

        .result-subtitle {
            color: var(--av-fixed-light);
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 700;
            &:hover {
                background-color: transparent;
            }
        }

        @mixin paragraph;

        max-height: 336px;
        overflow-y: auto;
        position: absolute;
        z-index: 4;
        top: 60px;
        background-color: var(--av-inversed-primary);
        border-radius: 4px;
        border: 1px solid var(--av-brand-primary);
        cursor: pointer;
        width: 100%;
        box-shadow: var(--av-shadow-regular);

        li {
            padding: 8px 16px;
            &:hover,
            &:focus {
                background-color: var(--av-brand-accent);
            }
        }
    }

    &__product-search {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;

        .input-suffix {
            display: flex;
            align-content: center;
            cursor: pointer;
        }
    }

    &__product-search-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        @media(--viewport-desktop) {
            margin-bottom: 48px;
        }

        .el-search {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
        }

        &:deep(.el-input) {
            &--small {
                .el-input {
                    &__wrapper {
                        padding: 10px 0 0 8px;
                    }

                    &__container {
                        height: 48px;
                        padding: 0 8px;
                    }
                }
            }

            &__label,
            &__editor {
                @mixin paragraph;
            }

            &.is-focus,
            &.is-active {
                .el-input__label {
                    color: var(--av-fixed-light);
                    font-weight: 700;
                    font-size: 12px;
                }
            }

            &__suffix {
                .i {
                    width: 16px;
                    height: 16px;
                    color: var(--av-brand-primary);
                }
            }
        }

        .menu-activator {
            width: 48px;
            height: 48px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-inline-start: 8px;
            cursor: pointer;
            border-radius: 4px;

            @media(--viewport-desktop) {
                display: none;
            }

            &.active,
            &:hover,
            &:focus {
                background-color: var(--el-secondary-active);
            }
        }

        .side-menu__search-filters {
            display: flex;
            flex-direction: column;
            background-color: var(--av-inversed-primary);
            position: absolute;
            z-index: 4;
            border-radius: 4px;
            border: 1px solid var(--av-brand-primary);
            top: 56px;
            width: 100%;
            padding: 24px;
            box-shadow: var(--av-shadow-regular);

            @media (--viewport-mobile-wide) {
                max-width: 328px;
                inset-inline-end: 0;
            }

            @media (--viewport-desktop) {
                display: none;
            }
        }
    }

    &__search-title {
        @mixin paragraph-accent;
        margin-bottom: 12px;
    }

    &__action-btns {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--av-brand-accent);
        padding: 24px;
        button {
            margin-inline-start: 16px;
        }
    }

    &:deep(.a-accordion) {
        &-item {
            border-top: none;
            border-bottom: none;

            &:last-of-type {
                border-bottom: none;
            }

            @media(--viewport-desktop) {
                margin-top: 32px;
            }
            .title {
                margin-bottom: 4px;
            }
        }
        .title {
            @mixin paragraph-accent;
            > div:first-child {
                margin-inline-end: 8px;
            }
            &:deep(.a-glyph) {
                    margin: 0;
            }
        }

        li:not(last-of-type) {
            margin-bottom: 4px;
            @mixin paragraph;
        }
    }
}
</style>
