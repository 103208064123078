<template>
    <s-basic-slice :id="id" class="s-cards-small-slider" v-bind="l10n">
        <div class="s-cards-small-slider__content">
            <a-slider class="s-cards-small-slider__slider" :options="sliceSliderOptions">
                <a-slide
                    v-for="(card, index) in l10n.cards"
                    :key="`small-cards-slide-${index}`"
                    class="s-cards-small-slider__slide"
                >
                    <a-small-card v-bind="card" />
                </a-slide>
            </a-slider>
        </div>
        <a-link
            v-if="l10n.link.to"
            class="s-cards-small-slider__link"
            v-bind="l10n.link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import ASmallCard from '@core/components/small-card/small-card.vue';
import l10n from '@core/mixins/l10n.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCardsSmallSlider',

    components: {
        ALink,
        ASlide,
        ASlider,
        ASmallCard,
        SBasicSlice,
    },
    mixins: [l10n],
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            default: undefined,
        },

        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 3,
        },

        sliderOptions: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            sliceSliderOptions: {
                sliderType: 'card-small',
                slidesPerView: 'auto',
                showNavigation: true,
                slideToClickedSlide: false,
                ...this.sliderOptions,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.s-cards-small-slider {
    &:deep(.a-slice-header) {
        width: 91.66%;
    }

    &__slider {
        width: calc(100% + var(--container-paddings-mobile));
    }

    &__slide {
        width: 66%;
        padding-inline-end: 16px;

        /*
        &:deep(.a-slide) {
            opacity: 0.5;
        }
        */

        &:deep(.a-slide_active) {
            &:deep(.a-small-card) {
                opacity: 1;
            }
        }
    }

    &__link {
        margin-top: 48px;
    }

    @media (--viewport-mobile-wide) {
        &:deep(.a-slice-header) {
            width: 91.66%;
        }

        &__slider {
            width: calc(100% + var(--container-paddings-mobile-wide));
        }

        &__slide {
            width: 33.33%;

            &:deep(.a-slide_active) {
                & + .s-cards-small-slider__slide {
                    &:deep(.a-small-card) {
                        opacity: 1;
                    }

                    & + .s-cards-small-slider__slide {
                        &:deep(.a-small-card) {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &__slide {
            width: 32.35%;
        }

        &__link {
            position: absolute;
            top: 5px;
            inset-inline-end: 35px;
            margin-top: 0;
            max-width: 25%;
        }
    }

    @media (--viewport-desktop-wide) {
        &:deep(.a-slice-header) {
            width: 66.66%;
        }

        &__slider {
            width: calc(100% + var(--container-paddings-desktop-wide));
        }

        &__slide {
            width: 24%;

            &:deep(.a-slide_active) {
                & + .s-cards-small-slider__slide {
                    &:deep(.a-small-card) {
                        opacity: 1;
                    }

                    & + .s-cards-small-slider__slide {
                        &:deep(.a-small-card) {
                            opacity: 1;
                        }

                        & + .s-cards-small-slider__slide {
                            &:deep(.a-small-card) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &__link {
            inset-inline-end: 70px;
        }
    }

    @media (--viewport-desktop-large) {
        &:deep(.a-slice-header) {
            width: 58.33%;
        }

        &__slider {
            width: calc(100% + var(--container-paddings-desktop-large));
        }

        &__link {
            inset-inline-end: 0;
        }
    }
}
</style>
