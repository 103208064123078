<template>
    <a-modal id="acronis-demo-modal" type="screenshot" is-overlay-close>
        <div class="ribbon">
            <span>Try the full Acronis experience!</span>
            <a-link
                text="Start free trial"
                to="/products/cloud/trial/?utm_source=navattic"
                size="paragraph"
                accent
            />
        </div>
        <div class="iframe-container">
            <iframe :src="baseURL" />
        </div>
    </a-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';

export default {
    components: {
        ALink,
        AModal,
    },
    directives: {
        Modal,
    },
    computed: {
        ...mapGetters('config', ['$config']),

        baseURL() {
            return this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_NAVATTIC;
        },
    },
    mounted() {
        this.bumpClickHops();
    },
    methods: {
        bumpClickHops() {
            const demoButtonClickDate = localStorage.getItem('demoButtonClickDate');
            if (!demoButtonClickDate) return;

            const demoButtonHops = localStorage.getItem('demoButtonClickHops');
            const demoButtonHopsCount = parseInt(demoButtonHops, 10);
            if (demoButtonHopsCount) {
                localStorage.setItem('demoButtonClickHops', demoButtonHopsCount + 1);
            } else {
                localStorage.setItem('demoButtonClickHops', 1);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
#acronis-demo-modal {
    &:deep(.wrapper) {
        padding: 64px 0 8px;
    }
    &:deep(.content) {
        width: 98vw;
        border-radius: 4px;
        overflow: auto;
    }
    &:deep(.inner) {
        width: 100%;
        height: 100%;
    }
}
.ribbon {
    @mixin paragraph;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--av-solid-brand-secondary-accent);
    color: var(--av-nav-primary);
    box-shadow: var(--av-shadow-small);
    border-bottom: 1px solid var(--av-brand-accent);
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 90;
    padding: 10px 0;
}
.iframe-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 117px); /* Deduct ribbon height and some padding */
    overflow: auto; /* Allow scrolling inside the container */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align iframe to the top */
}
iframe {
    width: auto; /* Allow iframe content to determine its width */
    height: auto; /* Allow iframe content to determine its height */
    min-width: 100%;
    min-height: 100%;
    border: none;
}
</style>
