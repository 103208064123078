<template>
    <section v-if="content" class="landing-page-form">
        <div class="a-container">
            <div class="wrapper">
                <div class="item">
                    <p v-if="content.title" class="title" v-html="content.title" />
                    <p v-if="content.description && !isMobile" class="description" v-html="content.description" />
                </div>
                <s-trial-form v-if="content.form" :form="content.form" />
            </div>
        </div>
    </section>
</template>

<script>
import breakpoints from '@core/mixins/breakpoint';
import STrialForm from '@core/slices/pages/trial-form/trial-form.vue';

export default {
    name: 'SForm',
    components: {
        STrialForm,
    },
    mixins: [breakpoints],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-form {
    padding-top: 88px;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 32, 77, 0.00) 0%, #00204D 23.89%);
    @media (--viewport-tablet) {
        padding-top: 64px;
    }
    @media (--viewport-desktop) {
        padding-top: 120px;
    }
    @media (--viewport-desktop-wide) {
        padding-top: 128px;
    }
    .wrapper {
        gap: 40px;
        display: flex;
        flex-direction: column;
        @media (--viewport-tablet) {
            gap: 62px;
        }
        @media (--viewport-desktop) {
            gap: 16px;
            flex-direction: row;
        }
        @media (--viewport-desktop-wide) {
            gap: 62px;
        }
        @media (--viewport-desktop-large) {
            gap: 126px;
        }
    }
    .item {
        width: 100%;
        text-align: center;
        @media (--viewport-desktop) {
            text-align: start;
        }
    }
    &:deep(.s-form) {
        padding: 0;
        margin: 0 -16px;
        @media (--viewport-tablet) {
            margin: 0;
        }
        @media (--viewport-desktop) {
            min-width: 554px;
        }
        @media (--viewport-desktop-wide) {
            min-width: 664px;
        }
        @media (--viewport-desktop-large) {
            min-width: 758px;
        }
        .a-container {
            padding: 0;
            height: 100%;
        }
        .s-form__wrapper {
            max-width: none;
            height: 100%;
        }
        .s-form-container {
            margin: 0;
            width: 100%;
        }
        .s-form-container__loading,
        .s-form-registration__loading {
            backdrop-filter: blur(4px);
            background: linear-gradient(180deg, rgba(41, 100, 182, 0.80) 0%, rgba(6, 36, 79, 0.80) 100%);
            .el-loading {
                color: var(--av-inversed-primary);
            }
            .el-spinner--color-brand-secondary {
                border-color: var(--av-inversed-primary);
            }
            ~ .el-form {
                .el-input__container,
                .el-textarea__container {
                    background-color: var(--av-inversed-primary);
                }
                .el-input__label,
                .el-textarea__label {
                    color: var(--av-fixed-primary);
                }
            }
        }
        .form-error {
            color: var(--av-fixed-critical);
        }
        .s-form-error,
        .s-form-success,
        .s-form-registration {
            height: 100%;
            border: none;
            box-shadow: none;
            padding: 40px 16px;
            border-radius: 8px;
            background: linear-gradient(180deg, rgba(41, 100, 182, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
            @media (--viewport-tablet) {
                padding: 40px 32px;
            }
            .title,
            .agreement,
            .el-checkbox,
            .recaptcha-notification {
                color: var(--av-inversed-primary);
                a {
                    color: var(--av-link-inversed-active);
                }
            }
            .el-form-item__hint {
                color: var(--av-inversed-light);
            }
        }
        .s-form-success {
            .title {
                margin: 0;
            }
            .description {
                color: var(--av-inversed-light);
            }
        }
        .el-form {
            margin: 0;
        }
        .el-form-item {
            &.is-error {
                .el-input__container {
                    border-color: var(--av-fixed-critical);
                }
                &.is-focus {
                    .form-error {
                        color: var(--av-fixed-critical);
                    }
                }
            }
        }
    }
    .title {
        @mixin hero;
        font-weight: 500;
    }
    .description {
        @mixin title;
        margin-top: 24px;
        @media (--viewport-desktop) {
            margin-top: 88px;
            padding-bottom: 32px;
        }
    }
}
</style>
