<template>
    <footer
        :id="id"
        class="s-global-footer"
        :class="className"
    >
        <div v-if="displayNavigationItems" class="s-global-footer__block a-navigation">
            <div class="a-container">
                <div
                    :class="[
                        'a-additional-navigation a-footer__padded',
                        { 'a-footer__border-top': hasBorderTopNavigation }
                    ]"
                >
                    <a-accordion>
                        <a-accordion-item v-for="(column, i) in additionalNavigation" :key="i">
                            <template #title>
                                <a-link
                                    class="a-additional-navigation__link"
                                    v-bind="column"
                                    :text="column.title"
                                    :event="{
                                        ...event,
                                        label: column.to,
                                        action: getDataLayerAction(column, column.title)
                                    }"
                                />
                                <a-glyph class="a-accordion__icon" name="i-chevon-big-down--16" size="s" />
                            </template>
                            <div
                                v-for="(item, it) in column.items"
                                :key="`nav-${it}`"
                                class="a-additional-navigation__item"
                            >
                                <a-link
                                    size="paragraph"
                                    v-bind="item"
                                    :type="theme === 'light' || theme === 'white' ? 'dark' : 'light'"
                                    :event="{ ...event, label: item.to, action: getDataLayerAction(item, column.text) }"
                                />
                            </div>
                        </a-accordion-item>
                    </a-accordion>
                </div>
                <div
                    :class="[
                        'a-additional-navigation a-footer__padded',
                        { 'a-footer__border-top': hasBorderTopNavigation }
                    ]"
                >
                    <div class="a-additional-navigation__list">
                        <ul
                            v-for="(column, i) in additionalNavigation"
                            :key="i"
                            class="a-additional-navigation__column"
                        >
                            <li class="a-additional-navigation__title">
                                <a-link
                                    class="a-additional-navigation__link"
                                    v-bind="column"
                                    :text="column.title"
                                    :event="{
                                        ...event,
                                        label: column.to,
                                        action: getDataLayerAction(column, column.title)
                                    }"
                                />
                            </li>
                            <li
                                v-for="(item, cellIndex) in column.items"
                                :key="cellIndex"
                                class="a-additional-navigation__item"
                            >
                                <a-link
                                    size="paragraph"
                                    v-bind="item"
                                    :type="theme === 'light' || theme === 'white' ? 'dark' : 'light'"
                                    :event="{ ...event, label: item.to, action: getDataLayerAction(item, column.text) }"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="displaySubscribe && !isDesktopWide">
            <div class="a-container">
                <SubscribeBlock
                    :class="[
                        's-global-footer__social-wrapper a-footer__padded',
                        { 'a-footer__border-top': displayNavigationItems && hasBorderTopSubscribe }
                    ]"
                    v-bind="subscribe"
                    :has-socials="displaySocials"
                />
            </div>
        </div>
        <div v-if="displaySocials" class="s-global-footer__social">
            <div class="a-container">
                <div
                    :class="[
                        's-global-footer__social-wrapper a-footer__padded',
                        { 'a-footer__border-top': borderTopSocials }
                    ]"
                >
                    <a-dangerous-html class="s-global-footer__social-text" :content="engageWithAcronisText" />
                    <a-social
                        class="s-global-footer__social-actions"
                        :social="socialLinks"
                        :glyph-size="socialGlyphSize"
                    />
                    <SubscribeBlock
                        v-if="displaySubscribe && isDesktopWide"
                        class="a-ml-auto"
                        v-bind="subscribe"
                        :has-socials="displaySocials"
                    />
                </div>
            </div>
        </div>
        <div class="s-global-footer__block a-footer">
            <div class="a-container">
                <div :class="['a-footer__content a-footer__padded', {'a-footer__border-top': hasBorderTopCopyright }]">
                    <div class="a-footer__meta">
                        <dynamic-link
                            v-if="hasLogo"
                            class="a-footer__logo"
                            :to="homePageLink"
                            native-type="a"
                            :event="{ ...event, label: homePageLink, action: 'Acronis logo' }"
                        >
                            <a-logo type="full" :fill="['light', 'white'].includes(theme) ? null : 'white'" />
                        </dynamic-link>
                        <div class="a-footer__copyright">
                            © 2003–{{ currentYear }} {{ copyright }}
                        </div>
                    </div>
                    <ul class="a-footer__info">
                        <li v-for="(item, i) in legalLinks" :key="i" class="a-footer__item">
                            <a-link
                                v-bind="item"
                                :size="item.size || 'paragraph'"
                                :type="theme === 'light' || theme === 'white' ? 'dark' : 'light'"
                                :event="{ ...event, label: item.to, action: getDataLayerAction(item, item.text) }"
                            />
                        </li>

                        <li v-if="isOneTrustGcpEnabled" class="a-footer__item">
                            <button id="ot-sdk-btn" class="ot-sdk-show-settings">
                                {{ oneTrustLinkText }}
                            </button>
                        </li>

                        <li v-if="hasLocaleSelector" class="a-footer__item locale-selector">
                            <button class="a-footer__item-lang-btn" @click="showLocaleSelector">
                                <span class="a-footer__item-lang-text">{{ lang }}</span>
                                <a-glyph name="language-select" />
                            </button>
                            <s-locale-selector :is-shown="isLocaleSelectorShown" @close="hideLocaleSelector" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import ASocial from '@core/components/social/social.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SLocaleSelector from '@core/slices/pages/locale-selector/locale-selector.vue';
import SubscribeBlock from './Blocks/SubscribeBlock.vue';
import { TYPES, THEMES } from './constants.js';

export default {
    name: 'SGlobalFooter',

    components: {
        SubscribeBlock,
        DynamicLink,
        SLocaleSelector,
        AAccordion,
        AAccordionItem,
        ADangerousHtml,
        ALink,
        ALogo,
        AGlyph,
        ASocial,
    },
    mixins: [breakpoint],
    props: {
        id: {
            type: String,
            default: '',
        },

        sliceSettingsName: {
            type: String,
            default: 's-global-footer',
        },

        hasLocaleSelector: {
            type: Boolean,
            default: true,
        },

        hasSubscribe: {
            type: Boolean,
            default: true,
        },

        hasLogo: {
            type: Boolean,
            default: true,
        },

        sections: {
            type: Array,
            default() {
                return ['cards', 'navigation', 'socials'];
            },
        },

        locale: {
            type: String,
            required: false,
            default: () => undefined,
        },

        /**
         * Global footer type
         */
        type: {
            type: String,
            default: 'short',
            validator: (value) => !value || TYPES.includes(value),
        },

        /**
         * Global footer version
         */
        theme: {
            type: String,
            default: '',
            validator: (value) => !value || THEMES.includes(value),
        },

        hasBorderTopSubscribe: {
            type: Boolean,
            default: true,
        },
        /**
         * Global footer top border for Socials
         */
        hasBorderTopSocials: {
            type: Boolean,
            default: false,
        },

        /**
         * Global footer top border for Navigation
         */
        hasBorderTopNavigation: {
            type: Boolean,
            default: false,
        },

        /**
         * Global footer top border for Copyright
         */
        hasBorderTopCopyright: {
            type: Boolean,
            default: true,
        },

        /**
         * GA Event
         */
        event: {
            type: Object,
            default() {
                return {
                    category: 'Interactions',
                };
            },
        },

        isBottomFixed: {
            type: Boolean,
            default: true,
        },

        socialGlyphSize: {
            type: String,
            default: 'm',
        },
    },

    data() {
        return {
            currentYear: new Date().getFullYear(),
            isLocaleSelectorShown: false,
        };
    },

    computed: {
        isDesktop() {
            return this.currentBreakpoint === 'desktop';
        },
        isDesktopWide() {
            return ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },
        isOneTrustGcpEnabled() {
            const location = this.$store.state.geolocation.location?.data;
            if (!location) return false;

            const countries = this.oneTrustPopupConfig.countries || [];
            const regions = this.oneTrustPopupConfig.regions || [];
            const cities = this.oneTrustPopupConfig.cities || [];

            return countries.includes(location.country?.name) ||
                regions.includes(location.region?.name) ||
                cities.includes(location.city?.name);
        },

        className() {
            return {
                [`s-global-footer_theme_${this.theme}`]: this.theme,
                's-global-footer--locale-selector-visible': this.isLocaleSelectorShown,
                'is-bottom-fixed': this.isBottomFixed,
            };
        },

        footerSettings() {
            return this.$store.state.slices.items[this.sliceSettingsName] || {};
        },

        oneTrustPopupConfig() {
            return this.$store.state.slices.items['one-trust-popup'] || {};
        },

        lang() {
            return this.footerSettings.lang || '';
        },

        homePageLink() {
            return this.setLink(this.footerSettings.homePageLink || '/');
        },

        subscribe() {
            return this.footerSettings.subscribe || [];
        },

        socialLinks() {
            const list = this.footerSettings.socialLinks || [];
            return list.map((item) => ({ ...item, link: this.setLink(item.link) }));
        },

        legalLinks() {
            const list = this.footerSettings.legalLinks || [];
            return list.map((item) => ({ ...item, to: this.setLink(item.to) }));
        },

        engageWithAcronisText() {
            return this.footerSettings.engageWithAcronisText || 'Engage with Acronis';
        },

        oneTrustLinkText() {
            return this.footerSettings.oneTrustLinkText || 'Your privacy choices';
        },

        copyright() {
            return this.footerSettings.copyright || '';
        },

        additionalNavigation() {
            return this.footerSettings.additionalNavigation || [];
        },

        displaySubscribe() {
            return this.sections.includes('subscribe') || this.hasSubscribe;
        },

        displaySocials() {
            return this.sections.includes('socials') && this.type !== 'copyright';
        },

        borderTopSocials() {
            return this.hasBorderTopSocials || (this.displaySubscribe && !this.isDesktopWide);
        },

        displayNavigationItems() {
            return this.sections.includes('navigation') && this.type === 'withNav';
        },
    },

    mounted() {
        this.$store.dispatch('geolocation/getGeoLocation');
    },

    methods: {
        getDataLayerAction(item, defaultAction) {
            if (item.dataLayer && item.dataLayer.action) {
                return item.dataLayer.action;
            }
            return defaultAction;
        },
        showLocaleSelector() {
            this.isLocaleSelectorShown = true;
        },
        hideLocaleSelector() {
            this.isLocaleSelectorShown = false;
        },
        setLink(item) {
            if (typeof window === 'undefined' || !item) return item;
            const host = window.location.hostname || '';
            return item.startsWith('/') && !host.includes('www.') ? `https://www.acronis.com${item}` : item;
        },
    },

    async serverPrefetch() {
        const locale = this.locale || this.$route.params.locale;
        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: this.sliceSettingsName, locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'one-trust-popup' }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-subscription', locale }),
        ]);
    },
};
</script>

<style lang="postcss">
.s-global-footer {
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    &--locale-selector-visible {
        position: relative;
        z-index: 2004;
    }

    .a-product-card__text {
        display: none;

        @media (--viewport-tablet) {
            display: block;
        }
    }

    #ot-sdk-btn.ot-sdk-show-settings {
        display: inline-block;
        transition: color linear 0.12s;
        cursor: pointer;
        color: var(--av-brand-primary);
        border: none;
        padding: 0;
        font-size: 14px;
        &:hover {
            color: var(--av-brand-secondary);
            background: none;
        }
    }

    .a-footer__padded {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
    }

    .a-footer__border-top {
        border-top: 1px solid var(--av-brand-primary);
    }

    .a-accordion__icon {
        fill: var(--av-link-inversed-light);
    }

    &__social {
        &-wrapper {
            display: flex;
            flex-flow: row wrap;
            padding-inline: 16px;
            @media (--viewport-tablet) {
                flex-flow: row nowrap;
                padding-inline: 0;
            }
            @media (--viewport-desktop-wide) {
                align-items: center;
            }
        }

        &-text {
            @mixin colls 12;
            @mixin paragraph;
            margin-bottom: 51px;
            @media (--viewport-tablet) {
                width: fit-content;
                margin-bottom: 0;
                margin-inline-end: 51px;
                white-space:nowrap;
            }
        }

        &-actions {
            @mixin colls 12;
            gap: 32px;
            flex-flow: row wrap;
            justify-content: start;
            @media (--viewport-tablet) {
                width: fit-content;
                flex-flow: row nowrap;

            }

            .a-social__item {
                .a-social__link {
                    margin: 0;
                    .a-glyph {
                        fill: var(--av-inversed-primary);
                    }
                    &:hover {
                        .a-glyph {
                            fill: var(--av-link-inversed-hover);
                        }
                    }
                }
            }
        }
    }

    &_theme {
        &_light,
        &_white {
            background: var(--av-brand-lightest);
            color: var(--av-nav-primary);
            .s-global-footer__social-actions .a-social__item .a-social__link .a-glyph {
                fill: var(--av-nav-primary);
            }
            .s-global-footer__social-actions .a-social__item .a-social__link:hover .a-glyph {
                fill: var(--av-brand-primary);
            }
            .a-navigation .a-additional-navigation__link .a-link__content  {
                color: var(--av-nav-primary);
            }
            .a-navigation .a-link__content  {
                color: var(--av-brand-primary);
            }
            .a-footer__border-top {
                border-top: 1px solid var(--av-brand-light);
            }
            .a-link__content {
                color: var(--av-brand-primary);
            }

            .a-footer__copyright {
                color: var(--av-nav-primary);
            }

            .a-accordion__icon {
                fill: var(--av-brand-primary);
            }

            .a-link:hover .a-link__content {
                color: var(--av-brand-secondary);
            }
            .a-footer__info {
                .a-link-content {
                    color: var(--av-brand-primary);
                }
                .a-footer__item {
                    &:before {
                        background: var(--av-brand-light);
                    }
                }
                .a-footer__item-lang-btn {
                    .a-footer__item-lang-text {
                        color: var(--av-brand-primary);
                    }
                    .a-glyph {
                        fill: var(--av-brand-primary);
                    }
                    &:hover {
                        .a-footer__item-lang-text {
                            color: var(--av-brand-secondary);
                        }
                        .a-glyph {
                            fill: var(--av-brand-secondary);
                        }
                    }
                }
            }

            .a-footer__meta {
                @media (--viewport-desktop) {
                    padding: 0;
                }
            }

            .a-navigation .a-additional-navigation__link:hover .a-link__content {
                color: var(--av-brand-secondary);
            }
        }

        &_white {
            background: #fafafc;
            color: var(--av-nav-primary);
            .a-additional-navigation__link .a-link__content {
                color: var(--av-nav-primary);
            }
        }
    }

    &.is-bottom-fixed {
        margin-top: auto;
    }
}

.a-navigation {
    .a-additional-navigation {
        padding-inline: 16px;

        @media (--viewport-tablet) {
            padding-inline: 0;
        }

        .a-accordion-item {
            border-top: 1px solid var(--av-brand-light);

            &:first-of-type {
                border-top: none;
            }

            &:last-of-type {
                border-bottom: none;
                margin: 0;
            }

            &.active .a-accordion__icon {
                transform: rotate(180deg);
            }

            .content-wrapper {
                padding: 0 0 24px;
                @media(--viewport-tablet) {
                    padding-bottom: 40px;
                }
            }

            .title {
                align-items: center;
                padding: 24px 0;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: -40px;

            @media (--viewport-tablet) {
                margin-top: 0;
            }
        }

        &__column {
            width: calc(50% - 8px);
            padding-inline-end: 16px;
            margin-top: 40px;

            @media (--viewport-tablet) {
                width: calc((100% - 48px) / 4);
                margin-top: 0;
            }
        }

        &__title {
            margin-bottom: 16px;
        }

        &__link {
            margin-inline-end: auto;
            + a-glyph {
                color: var(--av-link-inversed);
            }

            .a-link__content {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: var(--av-inversed-primary);
                @media (--viewport-tablet) {
                    @mixin paragraph;
                }
            }
            &:hover,
            &:focus {
                .a-link__content {
                    color: var(--av-brand-primary);
                    background: none;
                    opacity: 0.7;
                }
            }
        }

        &__item {
            font-size: 14px;
            line-height: 20px;

            &:not(:first-child) {
                margin-top: 16px;
            }

            @media (--viewport-tablet) {
                @mixin paragraph;
            }
        }

        &:last-of-type {
            display: none;
        }
    }

    @media (--viewport-desktop) {
        .a-additional-navigation {
            &:first-of-type {
                display: none;
            }
            &:last-of-type {
                display: block;
            }
        }
    }
}

.a-footer {
    &__meta {
        display: flex;
        flex-direction: column;
        padding: 0 0 16px;
        @media (--viewport-tablet) {
            flex-direction: row;
            padding: 0 0 25px;
        }
        @media (--viewport-desktop) {
            padding: 0;
        }
    }

    @media (--viewport-tablet) {
        &__logo {
            margin-inline-end: 40px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__logo {
        display: inline-block;
        margin-bottom: 8px;
        .a-logo {
            height: 22px;
            width: 108px;
        }
    }

    &__info {
        padding-top: 8px;

        .a-link__content {
            font-size: 14px;
        }
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;

        @media (--viewport-desktop) {
            padding-top: 0;
            row-gap: 12px;
            min-height: 32px;
        }
    }

    &__copyright {
        @mixin body;
        color: var(--av-inversed-primary);
        margin-inline-end: 23px;
        display: flex;
        align-items: center;
    }

    &__item {
        margin-top: 16px;
        display: flex;
        align-items: center;
        margin-inline-end: 23px;

        @media (--viewport-desktop) {
            margin-top: 0;
        }

        @media (--viewport-tablet) {
            display: inline-flex;
        }

        .a-link,
        .ot-sdk-show-settings {
            margin-inline-end: 23px;
        }

        &:after {
            height: 16px;
            width: 1px;
            content: '';
            background: var(--av-brand-primary);
        }

        &:first-of-type {
            .a-link {
                margin: 0 23px 0 0;
            }
        }
        &:last-of-type {
            &:after {
                content: inherit;
                @media (--viewport-desktop) {
                    display: none;
                }
            }
        }

        &-lang-btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                .a-footer__item-lang-text {
                    color: var(--av-link-inversed-hover);
                }
                .a-glyph {
                    fill: var(--av-link-inversed-hover);
                }
            }

            .a-glyph {
                fill: var(--av-link-inversed);
            }
        }

        &-lang-text {
            margin-inline-start: 9px;
            margin-bottom: 1px;
            color: var(--av-link-inversed);
            cursor: pointer;
        }

        .a-link {
            display: inline-block;
        }
    }
}

[dir='rtl'] {
    .a-footer__info {
        text-align: end;
    }
}
</style>
