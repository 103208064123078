<template>
    <div v-if="form" class="s-header-trial">
        <s-form-container :form="form" />
    </div>
</template>

<script>
import breakpoint from '@core/mixins/breakpoint.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default {
    name: 'SContentForm',

    components: {
        SFormContainer,
    },

    mixins: [breakpoint],

    props: {
        form: {
            type: Object,
            default: null,
        },
    },

    computed: {
        locale() {
            return this.$route.params.locale;
        },
    },

    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    },

    methods: {
        resize() {
            const header = document.querySelector('.visual');
            const form = document.querySelector('.s-header-trial');

            if (!header || !form) {
                return;
            }

            if (this.isMobile || this.isTablet) {
                form.style.margin = 0;
                return;
            }

            const padding = { top: 48, middle: 160, bottom: 256 };
            const position = this.form?.position || 'top';
            const result = header.offsetHeight - (padding[position] || padding.top);

            form.style.margin = `-${result}px 0 0`;
        },
    },

    destroy() {
        window.removeEventListener('resize', this.resize);
    },

    async serverPrefetch() {
        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-data-centers', locale: this.locale }),
            this.$store.dispatch('countries/getCountries'),
        ]);
    },
};
</script>

<style lang="postcss" scoped>
.s-header-trial {
    width: 100%;
    margin-top: 64px;

    @media (--viewport-desktop) {
        width: 59%;
    }

    &:deep(.el-loading) {
        @media (--viewport-mobile-wide) {
            padding: 0;
            margin: 0 auto;
            max-width: 584px;
            background: var(--av-inversed-primary);
        }
    }

    &:deep(.s-form-container) {
        margin-top: 24px;
        border-radius: 4px;
        background: var(--av-inversed-primary);

        @media (--viewport-mobile-wide) {
            padding: 0;
            margin: 64px auto 0;
            max-width: 584px;
        }

        @media (--viewport-desktop) {
            max-width: 84%;
            margin-top: 0;
        }

        @media (--viewport-desktop-wide) {
            max-width: 556px;
        }

        .s-form-registration {
            .s-form-registration__title {
                @mixin lead-accent;
            }

            .s-form-registration__note-item {
                @mixin body;
                margin: 0;
                color: var(--av-fixed-secondary);

                a {
                    color: var(--av-brand-primary);
                }
            }
        }

        .s-form-login,
        .s-form-update {
            padding: 40px;

            .title {
                @mixin lead-accent;
            }
        }

        .el-form {
            margin-top: 24px;
        }

        .el-input__placeholder {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
        }

        .el-checkbox {
            @mixin body;
            color: var(--av-fixed-primary);

            &:first-child,
            &:not(:first-child) {
                margin-top: 0;
                margin-bottom: 16px;
            }
        }

        .s-form-change {
            padding: 40px;

            .s-form-change__title {
                @mixin lead-accent;
                max-width: none;
                color: var(--av-nav-primary);
            }

            .s-form-change__description {
                margin-top: 24px;
            }

            .s-form-change__picture {
                margin-top: 24px;
                max-width: 328px;
            }

            .s-form-change__form {
                margin-top: 24px;
            }

            .s-form-change__button {
                margin-top: 0;
            }

            .el-form-item__error {
                margin-bottom: 24px;
            }
        }

        .s-form-upsell {
            padding: 40px;

            .s-form-upsell-flag {
                display: none;
            }

            .s-form-upsell-title {
                @mixin lead-accent;
            }

            .s-form-upsell-image {
                margin-top: 24px;
            }

            .s-form-upsell-description {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
            }

            .s-form-upsell-button {
                @mixin paragraph-accent;
                padding: 0;
                line-height: 48px;
                background: var(--av-nav-secondary);
            }

            .s-form-upsell-link {
                @mixin body;
                margin-top: 24px;
                white-space: unset;
                color: var(--av-brand-primary);
            }
        }
    }
}
</style>
