<template>
    <section v-if="content" class="landing-page-header">
        <div class="a-container">
            <dynamic-link class="logo" to="/">
                <a-logo type="full" fill="white" />
            </dynamic-link>
            <div class="info-wrapper">
                <h1 v-if="content.title" class="title" v-html="content.title" />
                <p v-if="content.description" class="description" v-html="content.description" />
                <div v-if="content.buttons?.length" class="buttons">
                    <a-button
                        v-for="(button, buttonIndex) in content.buttons"
                        v-bind="button"
                        :key="`button-${buttonIndex}`"
                    />
                </div>
            </div>
            <div v-if="content.solution" class="solution-wrapper">
                <div class="solution-info">
                    <div class="solution-content">
                        <p v-if="content.solution.title" class="solution-title" v-html="content.solution.title" />
                        <p v-if="content.solution.description" class="solution-description" v-html="content.solution.description" />
                    </div>
                </div>
                <div v-if="content.solution.list" class="slider-wrapper">
                    <a-slider :options="options">
                        <a-slide v-for="(item, itemIndex) in content.solution.list" :key="`solution-${itemIndex}`">
                            <a-picture v-if="item.picture" v-bind="item.picture" />
                            <p v-if="item.title" class="slide-title" v-html="item.title" />
                            <p v-if="item.description" class="slide-description" v-html="item.description" />
                            <p v-if="item.source" class="slide-source" v-html="item.source" />
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
        <a-picture v-if="hasBackground" class="background" v-bind="content.picture" />
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';

export default {
    name: 'SHeader',
    components: {
        AButton,
        DynamicLink,
        ALogo,
        APicture,
        ASlide,
        ASlider,
    },
    mixins: [breakpoints],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: {
                useLoop: true,
                spaceBetween: 16,
                slidesPerView: 1,
                slidesPerGroup: 1,
                showPagination: true,
                showNavigation: true,
                customPagination: { type: 'bullets' },
                loopAdditionalSlides: 1,
                glyphs: {
                    left: 'i-long-arrow-left--16',
                    right: 'i-long-arrow-right--16',
                },
                breakpoints: {
                    576: { slidesPerGroup: 1, slidesPerView: 2 },
                    768: { slidesPerGroup: 1, slidesPerView: 3 },
                    1024: { slidesPerGroup: 1, slidesPerView: 2 },
                },
            },
        };
    },
    computed: {
        hasBackground() {
            return this.content.picture?.background;
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-header {
    overflow: hidden;
    padding-top: 56px;
    text-align: center;
    background: #000;
    @media (--viewport-desktop) {
        text-align: start;
    }
    .a-container  {
        z-index: 1;
    }
    .info-wrapper {
        @media (--viewport-tablet) {
            width: 640px;
            margin: 0 auto;
        }
        @media (--viewport-desktop) {
            margin: 0;
            width: 554px;
        }
        @media (--viewport-desktop-wide) {
            width: 664px;
        }
    }
    .title {
        @mixin large;
        font-weight: 800;
        margin-top: 56px;
        color: var(--av-inversed-primary);
        @media (--viewport-tablet) {
            font-size: 64px;
            margin-top: 48px;
            line-height: 80px;
        }
        @media (--viewport-desktop) {
            font-size: 56px;
        }
        @media (--viewport-desktop-wide) {
            font-size: 64px;
        }
    }
    .description {
        @mixin hero;
        margin-top: 48px;
        color: var(--av-inversed-secondary);
        @media (--viewport-desktop) {
            color: var(--av-inversed-light);
        }
    }
    .buttons {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 72px;
        flex-direction: row;
        justify-content: center;
        @media (--viewport-desktop) {
            justify-content: start;
        }
        .a-button {
            min-width: 200px;
        }
    }
    .background {
        top: 0;
        width: 100%;
        position: absolute;
        inset-inline-start: 0;
        &:deep(.a-picture__img) {
            height: 880px;
            object-fit: cover;
        }
    }
    .solution-wrapper {
        gap: 40px;
        display: flex;
        margin: 56px -24px 0;
        flex-direction: column;
        @media (--viewport-tablet) {
            margin: 32px auto 0;
        }
        @media (--viewport-desktop) {
            gap: 16px;
            flex-direction: row;
            margin: 120px -32px 0;
        }
        @media (--viewport-desktop-wide) {
            margin: 120px -72px 0;
        }
        .solution-info {
            position: relative;
            border: 3px solid #11407c;
            background: linear-gradient(180deg, #00204D 0%, #030616 100%);
            @media (--viewport-tablet) {
                border-radius: 8px;
            }
            @media (--viewport-desktop) {
                z-index: 1;
                min-width: 422px;
                border-inline-start: none;
                border-radius: 0 8px 8px 0;
            }
            @media (--viewport-desktop-wide) {
                min-width: 526px;
            }
            @media (--viewport-desktop-large) {
                min-width: 600px;
            }
            &:before {
                @media (--viewport-desktop-large) {
                    top: -3px;
                    content: "";
                    width: 1000%;
                    position: absolute;
                    height: calc(100% + 6px);
                    border-top: 3px solid #11407c;
                    border-bottom: 3px solid #11407c;
                    inset-inline-start: calc(-1000% + 1px);
                    background: linear-gradient(180deg, #00204D 0%, #030616 100%);
                }
            }
            .solution-content {
                padding: 24px 16px;
                @media (--viewport-tablet) {
                    padding: 24px;
                }
                @media (--viewport-desktop) {
                    max-width: 368px;
                    padding: 32px 32px 80px;
                }
                @media (--viewport-desktop-wide) {
                    max-width: 428px;
                    padding: 32px 70px 96px;
                }
                .solution-title {
                    @mixin display-accent;
                    @media (--viewport-tablet) {
                        @mixin hero;
                        font-weight: 500;
                    }
                }
                .solution-description {
                    @mixin title;
                    margin-top: 24px;
                    color: var(--av-inversed-light);
                    @media (--viewport-tablet) {
                        @mixin display;
                    }
                }
            }
        }
        .slider-wrapper {
            width: 100%;
        }
        .a-picture {
            width: 160px;
            margin:  0 auto;
            @media (--viewport-tablet) {
                width: 148px;
            }
        }
        .slide-title {
            @mixin paragraph-accent;
            padding-top: 32px;
            @media (--viewport-desktop) {
                @mixin title-accent;
            }
        }
        .slide-description {
            @mixin descriptor;
            padding-top: 8px;
            color: var(--av-inversed-light);
            @media (--viewport-desktop) {
                @mixin paragraph;
            }
        }
        .slide-source {
            @mixin caption;
            padding-top: 8px;
            font-style: italic;
            color: var(--av-inversed-light);
        }
    }
    &:deep(.a-slider) {
        width: 214px;
        height: 100%;
        margin: 0 auto;
        overflow: visible;
        @media (--viewport-mobile-wide) {
            width: 444px;
        }
        @media (--viewport-tablet) {
            width: 704px;
        }
        @media (--viewport-desktop) {
            margin: 0;
            width: 472px;
        }
        @media (--viewport-desktop-wide) {
            width: 568px;
        }
        @media (--viewport-desktop-large) {
            width: 648px;
        }
        .a-slide {
            padding: 16px;
            border-radius: 8px;
            border: 3px solid #11407c;
            background: linear-gradient(180deg, rgba(0, 32, 77, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
            @media (--viewport-desktop-wide) {
                padding: 24px;
            }
        }
        .a-slider__nav {
            z-index: 2;
            margin: 16px 0 0;
            justify-content: center;
            @media (--viewport-tablet) {
                margin-top: 24px;
            }
            @media (--viewport-desktop) {
                margin: 0;
                bottom: 16px;
                position: absolute;
                inset-inline-start: -404px;
            }
            @media (--viewport-desktop-wide) {
                inset-inline-start: -468px;
            }
            @media (--viewport-desktop-large) {
                inset-inline-start: -544px;
            }
            .a-slider__dots {
                @media (--viewport-desktop) {
                    display: none;
                }
            }
            .a-slider__controls {
                margin: 0;
                display: none;
                @media (--viewport-desktop) {
                    display: flex;
                    margin-inline-start: -16px;
                }
                .a-slider__control {
                    margin: 0;
                    border: none;
                    .a-glyph {
                        fill: var(--av-inversed-primary);
                    }
                }
                .a-slider__control_disabled {
                    .a-glyph {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}
</style>
