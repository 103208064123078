<template>
    <section
        :id="id"
        class="compete-header"
        :class="basicClassName"
        v-bind="$attrs"
    >
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <h1>{{ title }}</h1>
                    <a-dangerous-html tag="p" class="lead" :content="lead" />
                    <a-button class="custom-button" v-bind="button" />
                </div>
                <div class="compare">
                    <div class="compare-scores">
                        <div v-if="comparison.left" class="left">
                            <strong>{{ comparison.left.score }}</strong>
                            <span>
                                <a-glyph name="acronis-logo-32" class="acronis-logo" />
                            </span>
                        </div>
                        <span class="versus-text">{{ comparison?.versusText }}</span>
                        <div v-if="comparison.right" class="right">
                            <strong>{{ comparison.right.score }}</strong>
                            <span>{{ comparison.right.title }}</span>
                        </div>
                    </div>
                    <div class="features">
                        <div v-for="(feature, index) in comparison.features" :key="`compare_feature_${index}`" class="feature">
                            <a-glyph :name="feature.left ? 'check-circle' : 'close-circle'" />
                            <span>{{ feature.description }}</span>
                            <a-glyph :name="feature.right ? 'check-circle' : 'close-circle'" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="links">
                <span>{{ compareMenu.title }}</span>
                <nav>
                    <ul>
                        <li
                            v-for="(link, index) in compareMenu.links"
                            :key="`comparemenu_links_${index}`"
                            :class="{'current': link.current}"
                        >
                            <a-link v-bind="link" :size="link?.size || 'paragraph'" />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    components: {
        ALink,
        AButton,
        ADangerousHtml,
        AGlyph,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        lead: {
            type: String,
            default: '',
        },
        button: {
            type: Object,
            default() {},
        },
        comparison: {
            type: Object,
            default() {},
        },
        compareMenu: {
            type: Object,
            default() {},
        },
    },
};
</script>

<style lang="postcss" scoped>
.compete-header {
    padding-top: 64px;
    border-top: 1px solid var(--av-brand-secondary-accent);

    .wrapper {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-between;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
        gap: 64px 0;
        .content {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 6;
            }
            h1 {
                @mixin display-accent;
                margin-bottom: 24px;
                @media (--viewport-tablet) {
                    @mixin hero-accent;
                }
            }
            .lead {
                @mixin paragraph;
                margin-bottom: 40px;
                color: var(--av-fixed-secondary);
                &:deep(span) {
                    color: #408BEA;
                }
            }
            .custom-button {
                width: 100%;
                align-items: center;
                justify-content: center;
                background: linear-gradient(90deg, #519EFF 0%, #0071FF 50%, #005BCC 100%);
                padding: 12px 16px;
                border-radius: 16px;
                @media (--viewport-tablet) {
                    width: auto;
                }
                &:deep(.a-button__glyph) {
                    margin-inline-start: 8px;
                }
                &:hover {
                    background: linear-gradient(90deg, #217DEE 0%, #0062DB 50%, #0054BC 100%);
                }
                &:active {
                    background: linear-gradient(90deg, #126EE1 0%, #005BCB 50%, #0051B6 100%);
                }
            }
        }
        .compare {
            border-radius: 32px;
            border: 1px solid var(--av-brand-secondary-light);
            padding: 8px 8px 16px;
            gap: 12px 8px;
            @media (--viewport-tablet) {
                @mixin colls 8;
                margin: 0 auto;
            }
            @media (--viewport-desktop) {
                margin: 0;
                @mixin colls 6;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 5;
            }

            .compare-scores {
                @mixin colls 12;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
                margin-bottom: 8px;
                @media (--viewport-tablet) {
                    gap: 8px;
                    margin-bottom: 12px;
                }
                @media (--viewport-desktop-large) {
                    justify-content: center;
                }
                .versus-text {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 700;
                    @media (--viewport-tablet) {
                        @mixin display-accent;
                    }
                }
                .left, .right {
                    display:flex;
                    text-align: center;
                    border-radius: 24px;
                    flex-flow: row wrap;
                    justify-content: center;
                    background: var(--av-brand-secondary-accent);
                    padding-bottom: 8px;
                    width: 100px;
                    flex-grow: 1;
                    @media (--viewport-tablet) {
                        width: 200px;
                    }
                    @media (--viewport-desktop-large) {
                        max-width: 200px;
                    }
                    strong {
                        width: 100%;
                        font-size: 58px;
                        line-height: 80px;
                        font-weight: 700;
                        @media (--viewport-tablet) {
                            font-size: 88px;
                            line-height: 120px;
                        }
                    }
                    span {
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        font-size: 10px;
                        line-height: 16px;
                        @media (--viewport-tablet) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                    .acronis-logo {
                        width: 41px;
                        height: 16px;
                        @media (--viewport-tablet) {
                            width: 62px;
                            height: 22px
                        }
                    }
                }
            }
            .features {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                gap: 8px;
                .feature {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    line-height: 16px;
                    gap: 13%;
                    span {
                        width: 80px;
                        text-align: center;
                        @media (--viewport-mobile-wide) {
                            width: 120px;
                        }
                        @media (--viewport-desktop) {
                            width: 130px;
                        }
                    }
                    @media (--viewport-mobile-wide) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    @media (--viewport-desktop-large) {
                        gap: 56px;
                    }
                }
            }
        }
    }

    .links {
        padding: 24px 0 32px;
        color: var(--av-nav-primary);
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        align-items: center;
        @media (--viewport-tablet) {
            gap: 24px;
        }
        span {
            @mixin lead-accent;
        }
        ul {
            display: flex;
            flex-flow: row wrap;
            gap: 16px 48px;
            align-items: center;
            @media (--viewport-tablet) {
                gap: 24px;
            }
            .current {
                &:deep(.a-link__content) {
                    color: var(--av-nav-primary);
                }
            }
        }
    }
}
</style>
