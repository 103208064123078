<template>
    <div
        :id="id"
        :class="{ 'blog-posts': true, 'blog-posts_layout_3-cards': blogPosts.length === 3, ...basicClassName }"
    >
        <div v-if="blogPosts.length > 0 && isMounted" class="a-container">
            <div class="blog-posts__header">
                <a-slice-header :title="title" :lead="lead" />
                <a-link
                    v-if="link && isDesktop"
                    class="slice-link"
                    v-bind="link"
                    :glyph="link.glyph || 'arrow'"
                    :type="link.type || 'direct'"
                />
            </div>
            <a-slider
                class="blog-posts__slider"
                :options="sliderOptions"
                :class="sliderClasses"
            >
                <a-slide v-for="(item, i) in blogPosts" :key="item.id" class="blog-posts__slide">
                    <div class="blog-posts__slide-image">
                        <a-picture
                            v-if="item.image_id || (fallback_images && fallback_images.length)"
                            class="image"
                            :link="item.image_id || fallback_images[i] || fallback_images[0]"
                            fit="cover"
                            size="blogCard"
                        />
                    </div>
                    <div class="blog-posts__slide-content">
                        <dynamic-link
                            class="blog-posts__slide-title"
                            :to="blogLocale + item.pathname"
                            native-type="a"
                        >
                            {{ item.title }}
                        </dynamic-link>
                        <v-clamp class="blog-posts__slide-description" tag="div" :max-lines="4">
                            {{ item.description }}
                        </v-clamp>
                        <div v-if="item.published_at" class="blog-posts__slide-date">
                            <b>{{ formatDate(item.published_at) }}</b>
                            <span>{{ item.time_to_read }} {{ translations.minRead }}</span>
                        </div>
                    </div>
                </a-slide>
            </a-slider>
            <a-link
                v-if="link && !isDesktop"
                class="slice-link"
                v-bind="link"
                :glyph="link.glyph || 'arrow'"
                :type="link.type || 'direct'"
            />
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import { SECTION_ID_BLOG, CARD_FIELDS_SUBSET } from '@model/const/blog';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { formatToLocaleDate } from '@utils/locales';

export default {
    name: 'SBlogPosts',

    components: {
        ASliceHeader,
        ALink,
        APicture,
        ASlider,
        ASlide,
        VClamp,
        DynamicLink,
    },

    mixins: [contentMixin, styleMixin, breakpoint],

    async serverPrefetch() {
        if (this.items.length) {
            return;
        }

        const stateID = 'latestBlogPosts';
        const locale = this.$route?.params.locale;
        const productID = this.product_id || this.$store.state.pages.page.product_id || null;

        await this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale });

        const customHasParam = {};

        if (this.categoryId) customHasParam.categories = [this.categoryId];

        if (productID) customHasParam.products = [productID];

        const requestTemplate = new ProductAPIQueryBuilder(stateID)
            .setEntityPath('/api/blog/posts/')
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setCustomParam('has', customHasParam)
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, 3);

        // trying to get a localized version
        const localizedReq = requestTemplate
            .clone(stateID)
            .setLocales([locale])
            .toObject();
        await this.$store.dispatch('blog/getEntity', { request: localizedReq });

        if (this.$store.state.blog[stateID].items?.length || locale === LOCALE_DEFAULT) return;

        // fall back to default locale
        const fallbackReq = requestTemplate
            .clone(stateID)
            .setLocales([LOCALE_DEFAULT])
            .toObject();
        await this.$store.dispatch('blog/getEntity', { request: fallbackReq });
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        title: {
            type: String,
            default: undefined,
        },

        lead: {
            type: String,
            default: undefined,
        },

        link: {
            type: Object,
            default: undefined,
        },

        categoryId: {
            type: Number,
            default: null,
        },

        /* eslint-disable vue/prop-name-casing */
        product_id: {
            type: Number,
            default: null,
        },

        fallback_images: {
            type: Array,
            default: () => ['@ac5c488ea6706d9e8d958f770d983a7b'],
        },
        /* eslint-enable vue/prop-name-casing */
    },

    data: () => ({
        isMounted: false,
    }),

    computed: {
        sliderOptions() {
            const deviceType = this.getDeviceTypeByScreenWidth(window.outerWidth);
            let allowTouchMove;

            if (deviceType === 'mobile') {
                allowTouchMove = this.blogPosts.length > 1;
            } else if (deviceType === 'tablet') {
                allowTouchMove = this.blogPosts.length > 2;
            } else {
                allowTouchMove = this.blogPosts.length > 3;
            }

            return {
                loop: false,
                spaceBetween: 16,
                slidesPerView: 'auto',
                showNavigation: true,
                allowTouchMove,
                // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
                showCounter: false,
            };
        },
        translations() {
            return this.$store.state.slices.items.blog || {};
        },
        blogPosts() {
            return this.$store.state.blog.latestBlogPosts?.items || this.items || [];
        },
        blogLocale() {
            return this.$store.state.blog.latestBlogPosts?.locale;
        },
        sliderClasses() {
            return {
                'a-slider_off': !this.sliderOptions.allowTouchMove,
                'a-slider__two-cards': this.blogPosts.length === 2,
                'a-slider__three-cards': this.blogPosts.length === 3,
            };
        },
    },

    async mounted() {
        await this.$nextTick();
        this.isMounted = true;
    },

    methods: {
        formatDate(date = '') {
            const locale = this.$route?.params.locale || LOCALE_DEFAULT;
            return formatToLocaleDate(date, locale);
        },
    },
};
</script>

<style lang="postcss">
.blog-posts {
    @mixin basic-slice-paddings;

    &:not(.blog-posts_layout_3-cards) {
        &__slide {
            @mixin colls 11;
            @media (--viewport-tablet) {
                @mixin colls 6;
            }
            @media (--viewport-desktop) {
                @mixin colls 6;
            }
        }
        .a-container {
            @media (--viewport-desktop) {
                display: flex;
                justify-content: space-between;
            }
        }
        .slice-link {
            @media (--viewport-desktop) {
                position: unset;
            }
        }
        .blog-posts__slider {
            @media (--viewport-desktop) {
                @mixin colls 8;
            }

            @media (--viewport-desktop-wide) {
                @mixin colls 6;
                margin-inline-start: 0;
            }
        }
        .blog-posts__header {
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 8;
            }
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 5;
            }
        }
    }
    &_layout_3-cards {
        .blog-posts__slider {
            @mixin colls 12;
            @media (--viewport-desktop-wide) {
                @mixin colls 9;
            }
        }
        .blog-posts__slide:nth-child(2).a-slide_active {
            margin-inline-start: 16px;
            @media (--viewport-tablet) {
                margin-inline-start: 0;
            }
        }
    }
    .a-container {
        overflow: hidden;
        @media (--viewport-desktop-wide) {
            display: flex;
            justify-content: space-between;
        }
    }
    &__header {
        @media (--viewport-desktop-wide) {
            @mixin colls 3;
        }
        .a-slice-header {
            width: 100%;
        }
    }

    &__slider {
        @mixin colls 12;
        margin-top: 40px;
        overflow: visible;
        @media (--viewport-tablet) {
            &.a-slider__two-cards {
                .blog-posts__slide {
                    @mixin colls 6;
                }
            }
            &.a-slider__three-cards {
                .blog-posts__slide {
                    @mixin colls 11;
                    @media (--viewport-tablet) {
                        @mixin colls 5;
                        &:nth-child(2) {
                            opacity: 1;
                        }
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 4;
                    }
                }
            }
            &.a-slider_off {
                .blog-posts__slide {
                    img {
                        width: 100%;
                    }
                }
                .a-slider {
                    &__wrapper {
                        display: flex;
                        justify-content: space-between;
                        .a-slide {
                            opacity: 1;
                            &:last-child {
                                margin-inline-end: 0 !important;
                            }
                        }
                    }
                    &__nav {
                        display: none;
                    }
                }
            }
        }
        @media (--viewport-desktop) {
            margin-top: 0;
        }
    }

    &__slide {
        max-width: 85%;
        img {
            width: 100%;
        }
        &:last-child {
            &.a-slide_next {
                @media (--viewport-tablet) {
                    opacity: 1;
                }
            }
        }
        &-content {
            padding-inline-end: 16px;
        }

        &-image {
            border-radius: 4px;
            overflow: hidden;
        }
        &-title {
            margin-top: 24px;
            @mixin lead-accent;
            color: var(--av-brand-secondary);
            display: block;
            text-decoration: none;
        }
        &-description {
            margin-top: 16px;
            @mixin paragraph;
        }
        &-date {
            margin-top: 24px;
            @mixin body;
            b {
                font-weight: 600;
                display: block;
            }
        }
    }

    .slice-link {
        margin-top: 48px;

        @media (--viewport-tablet) {
            position: absolute;
            margin-top: 0;
            top: 12px;
            inset-inline-end: 33px;
        }
        @media (--viewport-desktop) {
            top: 7px;
        }
        @media (--viewport-desktop-wide) {
            position: unset;
        }
    }
}
</style>
