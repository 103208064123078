<template>
    <section v-if="content" class="landing-page-products">
        <div class="a-container">
            <p v-if="content.lead" class="lead" v-html="content.lead" />
            <div v-if="content.list?.length" class="products">
                <div v-for="(item, itemIndex) in content.list" :key="`item-${itemIndex}`" class="product">
                    <a-label v-if="item.label" v-bind="item.label">
                        {{ item.label.text }}
                    </a-label>
                    <p v-if="item.title" class="title" v-html="item.title" />
                    <p v-if="item.description" class="description" v-html="item.description" />
                    <div class="links">
                        <a-link
                            v-for="(link, linkIndex) in item.links"
                            :key="`link-${linkIndex}`"
                            v-bind="link"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SProducts',
    components: {
        ALabel,
        ALink,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.landing-page-products {
  padding-top: 44px;
  background: linear-gradient(360deg, rgba(3, 4, 26, 0.00) 0%, #03041A 23.89%);
  @media (--viewport-tablet) {
    padding-top: 64px;
  }
  @media (--viewport-desktop-wide) {
    padding-top: 72px;
  }
  .lead {
    @mixin display-accent;
    text-align: center;
    @media (--viewport-tablet) {
      @mixin hero-accent;
    }
    @media (--viewport-desktop) {
      @mixin large;
      padding: 0 80px;
      font-weight: 500;
    }
  }
  .products {
    gap: 16px;
    display: flex;
    margin-top: 48px;
    flex-direction: column;
    @media (--viewport-desktop) {
      flex-direction: row;
    }
  }
  .product {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    flex-direction: column;
    text-align: center;
    border: 2px solid var(--av-brand-secondary-light);
    background: linear-gradient(0deg, rgba(11, 89, 198, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    &:nth-child(even) {
      background: linear-gradient(0deg, #0B59C6 0%, rgba(11, 89, 198, 0.20) 100%);
    }
    @media (--viewport-tablet) {
      margin: 0 56px;
    }
    @media (--viewport-desktop) {
      flex: 1;
      margin: 0;
      text-align: start;
    }
    @media (--viewport-desktop-wide) {
      padding: 24px;
    }
    @media (--viewport-desktop-large) {
      padding: 24px 32px;
    }
  }
  .a-label {
    margin: 0 auto 16px;
    text-transform: none;
    @media (--viewport-desktop) {
      margin-inline-start: 0;
    }
  }
  .title {
    @mixin title-accent;
  }
  .description {
    @mixin paragraph;
    font-weight: 500;
    margin-top: 24px;
    color: var(--av-inversed-light);
  }
  .links {
    margin-top: auto;
    padding-top: 16px;
  }
  &:deep(.a-link) {
    width: 100%;
    .a-link__content {
        gap: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--av-inversed-primary);
        @media (--viewport-tablet) {
          justify-content: center;
        }
        @media (--viewport-desktop) {
          justify-content: space-between;
        }
    }
    .a-glyph {
      margin: 0!important;
      fill: var(--av-inversed-primary);
    }
  }
}
</style>
