<template>
    <section class="express-signup-sales-trial-form">
        <div class="a-container">
            <express-signup-header v-if="header" class="header" v-bind="header" />
            <transition name="express-signup-slide-up">
                <div v-if="!isAnimation">
                    <p v-if="lead" class="lead" v-html="lead" />
                    <s-form-container v-if="form" :form="form" />
                </div>
            </transition>
        </div>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </section>
</template>

<script>
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import ExpressSignupHeader from '@core/slices/pages/express-signup-shared/express-signup-header.vue';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default {
    name: 'SExpressSignupTrialSalesForm',
    components: {
        ExpressSignupHeader,
        ExpressSignupFooter,
        SFormContainer,
    },
    props: {
        header: {
            type: Object,
            required: false,
            default: undefined,
        },
        lead: {
            type: String,
            default: '',
        },
        footer: {
            type: Object,
            required: false,
            default: null,
        },
        form: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isAnimation: true,
        };
    },
    mounted() {
        this.isAnimation = false;
    },
    async serverPrefetch() {
        await this.$store.dispatch('countries/getCountries');
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-sales-trial-form {
    min-height: 100vh;
    overflow-x: hidden;
    background: var(--av-gradient-to-bottom-lightest);
    color: var(--av-nav-primary);
    text-align: center;

    .lead {
        @mixin lead;
        margin: 40px auto;
        @media (--viewport-desktop) {
            @mixin colls 10;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 8;
        }
    }
    .footer {
        width: 100%;
        padding-bottom: 40px;
        align-self: flex-end;
        @media (--viewport-tablet) {
            margin: 0;
        }
    }

    &:deep(.s-form-container) {
        & {
            width: 100%;
            max-width: 520px;
            min-height: 520px;
            text-align: start;
            margin: 0 auto;

            .el-input__label.is-active {
                padding-top: 5px;
            }
        }

        .s-form-container__loading {
            background: transparent;

            & + div {
                display: none;
            }
        }

        .s-form-registration {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            .el-form {
                display: flex;
                text-align: start;
                flex-direction: column;
            }
        }

        .s-form-registration__loading {
            background: transparent;

            & ~ .el-form {
                opacity: 0;
            }
        }

        .s-form-registration__title {
            @mixin display;
            text-align: center;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .s-form-registration__subtitle {
            margin: 0;
        }

        .s-form-registration__subtitle-item {
            @mixin paragraph;
            text-align: center;
            margin: 0;
            color: var(--av-fixed-secondary);
        }

        .s-form-registration__section {
            @mixin paragraph;
            text-align: center;
            font-weight: 500;
            margin: 0 0 8px;
            padding: 0;
            color: var(--av-fixed-secondary);
        }

        .s-form-registration__button {
            @mixin paragraph-accent;
            order: 2;
            max-width: 306px;
            width: 100%;
            margin: 0 auto;
            display: block;
            text-align: center;

            @media (--viewport-tablet) {
                margin: 32px auto 0;
            }
        }

        .s-form-registration__checkboxes {
            .el-checkbox__label {
                @mixin body;
                color: var(--av-fixed-secondary);
            }
        }

        .s-form-registration__note {
            max-width: 344px;
            margin: 0 auto;

            @media (--viewport-desktop) {
                max-width: 510px;
            }
        }

        .s-form-registration__note-item {
            @mixin caption;
            line-height: 20px;
            margin-bottom: 16px;
            color: var(--av-fixed-secondary);

            &:last-child {
                margin: 0;
            }
        }

        .s-form-registration__error {
            @mixin caption;
            margin: 8px 0 -8px;
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(30%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
