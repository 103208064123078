<template>
    <div v-if="content" class="notification" :class="className">
        <a-glyph v-if="content.glyph" class="icon" :name="content.glyph" />
        <div v-if="content.title" class="title" v-html="content.title" />
        <div v-if="content.description" class="description">
            <p
                v-for="(item, i) in content.description"
                :key="i"
                class="description-item"
                v-html="item"
            />
        </div>
        <div v-if="content.list" class="list">
            <div v-for="(item, itemIndex) in content.list" :key="`list-item-${itemIndex}`">
                <a-glyph name="checkmark-success" />
                <span>{{ item }}</span>
            </div>
        </div>
        <a-picture v-if="content.image" class="image" :link="content.image" />
        <template v-if="content.buttons?.length">
            <a-button
                v-for="(item, itemIndex) in content.buttons"
                v-bind="item"
                :key="`button-${itemIndex}`"
                :event="item.event || { doNotSendGA: true }"
                @click="handleClick(item)"
            />
        </template>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormNotification',

    components: {
        AButton,
        AGlyph,
        APicture,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    emits: ['backToForm'],

    computed: {
        className() {
            return {
                [`${this.content.className}`]: this.content.className,
            };
        },
    },
    mounted() {
        if (this.content.eventTracking) {
            this.sendDataLayer({
                eventAction: window.location.href,
                ...this.content.eventTracking,
            });
        }
    },
    methods: {
        handleClick(item) {
            if (item.isHistoryBack) window.history.back();
            if (item.backToForm) this.$emit('backToForm', item.backToForm);
        },
    },
};
</script>

<style lang="postcss" scoped>
.notification {
    width: 100%;
    display: flex;
    padding: 40px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--av-inversed-primary);
    @media (--viewport-mobile-wide) {
        border: 1px solid #e3ecf9;
        box-shadow: var(--av-shadow-small);
    }
    &:deep(.a-picture) {
        margin-top: 24px;
        +.a-button {
            margin-top: 24px;
        }
    }
    &:deep(.a-button) {
        margin-top: 8px;
        min-width: 200px;
    }
}

.title {
    @mixin title-accent;
    margin: 16px 0 0;
    color: var(--av-nav-primary);
}

.icon {
    width: 32px;
    height: 32px;
    fill: var(--av-fixed-success);
}

.description {
    margin-top: 16px;
}

.description-item {
    @mixin paragraph;
    color: var(--av-fixed-secondary);

    &:not(:first-child) {
        margin-top: 16px;
    }

    &:deep(a) {
        text-decoration: none;
        color: var(--av-brand-primary);
    }
}

.list {
    gap: 16px;
    display: flex;
    width: 100%;
    margin-top: 24px;
    text-align: start;
    flex-direction: column;
    padding-inline-start: 16px;

    &:deep(.a-glyph) {
        margin: 0 4px 2px 0;
        vertical-align: middle;
    }
}

.version-48244 {
    .title,
    .a-button {
        margin: 0;
    }

    .description {
        margin-top: 8px;
    }

     &:deep(.a-button) {
        width: 100%;
        margin-top: 24px;

        ~ .a-button {
            margin-top: 16px;
        }
    }
}
</style>
