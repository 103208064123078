<template>
    <div class="color-scheme-tru cpoc-page cpoc-post">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" :tru-search-link="truSearchLink" />

                <s-blog-cpc-bar
                    v-if="isOnEnLocale"
                    :links="menuLinks"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                />
                <s-blog-cpc-post-head
                    :author="isOnEnLocale ? author : null"
                    :editors="editors"
                    :title="post.title"
                    :categories="isOnEnLocale ? categories : []"
                    :published-at="post.published_at"
                    :image-id="isBlog ? post.image_id : null"
                    :parent-link="parentLink"
                    :parent-link-title="translations.backTitle"
                    :share-title="translations.shareTitle"
                    :socials="socialsShareLinks"
                    :body="post.body"
                    :table-of-contents-title="translations.tableOfContents"
                />
            </div>
            <div v-if="isDraft" class="draft-warning-banner">
                <a-glyph name="attention2" />
                <span>{{ draftWarningMessage }}</span>
                <a-glyph name="attention2" />
            </div>
            <s-blog-cpc-post-body
                :socials="socialsShareLinks"
                :author="author"
                :locale="locale"
                :path="path"
                :body="postBody"
                :cta="ctaData"
                :cta-product="ctaProduct"
                :share-title="translations.shareTitle"
                :read-progress="readProgress"
            />
            <div class="cpoc-wrapper">
                <div v-if="isOnEnLocale" ref="morePostsBlock" class="more-posts">
                    <div class="a-container">
                        <h3 class="title">
                            {{ translations.cpcMorePostsTitle }}
                        </h3>
                        <s-blog-cpc-list :cards="morePosts" :show-only-one-row="true" />
                    </div>
                </div>
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import SBlogCpcPostBody from '@core/slices/blog/blog-cpc/blog-cpc-post-body.vue';
import SBlogCpcPostHead from '@core/slices/blog/blog-cpc/blog-cpc-post-head.vue';
import SBlogCpcList from '@core/slices/blog/blog-cpc/shared-components/blog-cpc-list.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/cpoc.pcss';

export default {
    name: 'BlogPost',

    components: {
        AGlyph,
        SBlogCpcHeader,
        SBlogCpcPostHead,
        SBlogCpcBar,
        SGlobalFooter,
        SBlogCpcList,
        SBlogCpcPostBody,
    },

    mixins: [blog, analytics],

    props: {
        dispatchSendViewBeacon: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            readProgress: 0,
        };
    },

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'dark',
                logoLink: this.isOnEnLocale ? '/cyber-protection-center/' : '/cyber-protection-center/about/',
            };
        },

        truSearchLink() {
            return this.isOnEnLocale ? this.sliceDataHeader.truSearchLink : null;
        },

        categories() {
            return this.post.categories
                .filter((x) => x.title) // there could be no translation for current locale
                .map((category) => this.getCategoryFields(category, this.locale));
        },

        menuLinks() {
            const categories = this.$store.state.blog.categories.items || [];
            return categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        locale() {
            return this.$route.params.locale;
        },

        path() {
            return this.$route.path;
        },

        draftWarningMessage() {
            return this.$store.state.slices.items.blog.draftWarningMessage;
        },

        isDraft() {
            return !this.post.is_published;
        },

        post() {
            return this.$store.state.blog.post.items[0];
        },

        postBody() {
            return JSON.parse(this.post.body);
        },

        cta() {
            return (this.$store.state.blog.cta.items || [])[0] || {};
        },

        ctaData() {
            return (this.$store.state.blog.ctaData.items || [])[0] || {};
        },

        author() {
            return this.getAuthorFields(this.post.author);
        },

        editors() {
            const editors = this.post.editors || [];
            return editors.map((editor) => this.getAuthorFields(editor));
        },

        parentLink() {
            return '/cyber-protection-center/';
        },

        morePosts() {
            const posts = (this.$store.state.blog.morePosts.items || [])
                .filter((card) => card.id !== this.post.id)
                .map((card) => this.getBlogPostFields(card, this.translations));
            return posts.length > 4 ? posts.slice(0, 4) : posts;
        },

        socialsShareLinks() {
            const title = this.post.title || '';
            const url = typeof window !== 'undefined' ? window.location : '';

            return this.socials.share.map((item) => ({
                name: item.name,
                link: item.link(encodeURIComponent(url), encodeURIComponent(title)),
            }));
        },

        ctaProduct() {
            return this.$store.state.products.items[this.cta.product_id];
        },
    },

    mounted() {
        document.addEventListener('scroll', this.onScroll);
        this.dispatchSendViewBeacon();
    },

    destroyed() {
        document.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll() {
            const docEl = document.documentElement;
            const postEl = this.$refs.morePostsBlock;

            const postContentEndline = postEl.getBoundingClientRect().top + docEl.scrollTop;
            const threshold = postContentEndline - window.innerHeight;

            this.readProgress = (docEl.scrollTop / threshold) * 100;
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpoc-post {
    position: relative;
    .more-posts {
        padding: 32px 0 64px;
        .title {
            @mixin display-accent;
            color: var(--av-inversed-primary);
            text-align: center;
            margin-bottom: 24px;
            @media (--viewport-tablet) {
                text-align: start;
                margin-bottom: 32px;
            }
            @media (--viewport-desktop) {
                @mixin hero;
                margin-bottom: 24px;
            }
            @media (--viewport-desktop-wide) {
                margin-bottom: 8px;
            }
        }
        &:deep(.blog-cpc-list) {
            .items {
                @media (--viewport-desktop-wide) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
            }
            .item:last-child {
                display: none;
                @media (--viewport-desktop-wide) {
                    display: block;
                }
            }
        }
    }
    .head {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            height: 493px;
            background: linear-gradient(
                180deg,
                rgba(38, 104, 197, 0.075) 0%,
                rgba(38, 104, 197, 0) 100%
            );
            z-index: -1;

            @media (--viewport-tablet) {
                height: 709px;
            }
        }
    }
}
</style>
