<template>
    <div class="event-agenda">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>

        <event-tab-control
            ref="tabs"
            class="days"
            :tabs="days"
            :active-tab-id="activeDayId"
            @change="dayChange"
        />

        <template v-if="activeDay">
            <div class="head-row">
                <div class="time">
                    <template v-if="isTimeSwitchShown">
                        <button
                            class="time-switch"
                            :class="{ active: !isLocalTime }"
                            @click="switchToLocalTime(false)"
                        >
                            {{ myTimeTitle }}
                        </button>
                        <button
                            class="time-switch"
                            :class="{ active: isLocalTime }"
                            @click="switchToLocalTime(true)"
                        >
                            {{ eventTimeTitle }}
                        </button>
                    </template>
                    <template v-else>
                        {{ eventTimeTitle }}
                    </template>
                </div>
                <div class="session">
                    {{ topicTitle }}
                </div>
                <div class="speakers">
                    {{ speakerTitle }}
                </div>
            </div>
            <div v-for="(activity, i) in activeDay.activities" :key="i" class="activity">
                <div class="time">
                    {{ activity.time }}
                </div>
                <div class="session">
                    <event-label
                        v-if="activity.isTraining"
                        class="training-label"
                        :title="trainingTitle"
                        type="event-type"
                    />
                    <div v-if="activity.location" class="session-location">
                        <a-glyph name="pin--16" />
                        <span>{{ activity.location }}</span>
                    </div>
                    <div v-if="activity.title" class="session-title">
                        {{ activity.title }}
                    </div>
                    <div v-if="activity.labels.length" class="labels">
                        <div v-for="(label, l) in activity.labels" :key="l" class="label">
                            {{ label }}
                        </div>
                    </div>
                    <template v-for="block in getDescriptionBlocks(activity.description)">
                        <div v-if="block.type === 'header'" :key="block.id" class="description-wrap">
                            <component :is="getTitleElement(block)" class="description">
                                <a-dangerous-html :content="block.data.text" tag="span" />
                            </component>
                        </div>
                        <p v-else-if="block.type === 'paragraph'" :key="block.id" class="description-paragraph">
                            <a-dangerous-html :content="block.data.text" tag="span" />
                        </p>
                        <component
                            :is="getListElement(block)"
                            v-else-if="block.type === 'list'"
                            :key="block.id"
                            class="description-list"
                        >
                            <li v-for="(listItem, j) in block.data.items" :key="j" class="description-list-item">
                                <a-dangerous-html :content="listItem.content ?? listItem" tag="span" />
                            </li>
                        </component>
                    </template>
                </div>
                <div v-if="activity.speakers.length" class="speakers">
                    <div class="speaker-list">
                        <div
                            v-for="(speaker, l) in activity.speakers"
                            :key="l"
                            ref="speaker"
                            class="speaker"
                        >
                            <event-speaker
                                v-bind="speaker"
                                :layout="getSpeakerLayout(activity.speakers)"
                                :modal-id="`speaker${i}-${l}`"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import EventLabel from '../shared-components/event-label/event-label.vue';
import EventSpeaker from '../shared-components/event-speaker/event-speaker.vue';
import EventTabControl from '../shared-components/event-tab-control/event-tab-control.vue';

export default {
    name: 'EventAgenda',

    components: {
        EventSpeaker,
        EventTabControl,
        ADangerousHtml,
        AGlyph,
        EventLabel,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        localTimeDays: {
            type: Array,
            required: true,
        },

        zonedTimeDays: {
            type: Array,
            required: true,
        },

        myTimeTitle: {
            type: String,
            required: true,
        },

        eventTimeTitle: {
            type: String,
            required: true,
        },

        trainingTitle: {
            type: String,
            required: true,
        },

        topicTitle: {
            type: String,
            required: true,
        },

        speakerTitle: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            activeDayId: null,
            isLocalTime: false,
            descriptionBody: {
                blocks: [],
            },
        };
    },

    computed: {
        selectedDays() {
            return this.isLocalTime ? this.localTimeDays : this.zonedTimeDays;
        },

        days() {
            return this.selectedDays.map((item) => ({
                id: item.title,
                title: item.title,
            }));
        },

        activeDay() {
            return this.selectedDays.find((item) => item.title === this.activeDayId);
        },

        isTimeSwitchShown() {
            return this.localTimeDays[0]?.activities?.[0].time !== this.zonedTimeDays[0]?.activities?.[0].time;
        },
    },

    mounted() {
        if (this.days.length > 0) {
            this.activeDayId = this.days[0].id;
        }
    },

    methods: {
        dayChange({ id }) {
            this.activeDayId = id;
        },

        getSpeakerLayout(speakers) {
            if (speakers.length === 1) return 'picture-with-name';
            return 'picture';
        },

        getDescriptionBlocks(text) {
            try {
                return JSON.parse(text).blocks;
            } catch {
                // old events have them as strings
                return [{ type: 'paragraph', data: { text } }];
            }
        },

        getTitleElement(block) {
            const level = Math.max(block.data?.level || 2, 2);
            return `h${level}`;
        },

        getListElement(block) {
            return block?.data?.style === 'ordered' ? 'ol' : 'ul';
        },

        async switchToLocalTime(isLocalTime) {
            this.isLocalTime = isLocalTime;
            await this.$nextTick();

            if (!this.activeDay) {
                this.activeDayId = this.selectedDays[0]?.title;
            }
            this.$refs?.tabs?.moveUnderline();
        },
    },
};
</script>

<style lang="postcss" scoped>
.head-row {
    @mixin paragraph-accent;
    color: var(--av-nav-primary);
    padding: 24px 0;
    border-bottom: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-desktop) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .session,
    .speakers {
        display: none;

        @media (--viewport-desktop) {
            display: block;
        }
    }
}

.time {
    margin-bottom: 8px;

    @media (--viewport-tablet) {
        @mixin colls 3;

        margin-inline-end: 16px;
        margin-bottom: 0;
    }
    @media (--viewport-desktop) {
        @mixin colls 2;
    }
}

.time-switch {
    @mixin note;
    display: inline-block;
    padding: 0 8px;
    border-radius: 4px;
    margin-inline-end: 8px;
    text-transform: uppercase;
    color: var(--av-inversed-primary);
    background: var(--av-fixed-lighter);
    margin-bottom: 8px;
    cursor: pointer;

    &.active {
        background: var(--av-fixed-success);
    }
}

.session {
    @media (--viewport-tablet) {
        @mixin colls 9;
    }
    @media (--viewport-desktop) {
        @mixin colls 6;
        margin-inline-end: 16px;
    }
}

.speakers {
    margin-top: 32px;

    @media (--viewport-tablet) {
        @mixin colls 9;
    }
    @media (--viewport-desktop) {
        margin-top: 0;
        @mixin colls 4;
    }
}

.activity {
    padding: 24px 0;
    border-bottom: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}

.speaker-list {
    display: flex;
    justify-content: flex-start;
    margin-bottom: -8px;
    flex-wrap: wrap;
}

.speaker {
    margin: 0 8px 8px 0;
}

.session-location {
    @mixin descriptor;
    font-weight: 500;
    color: rgba(124, 155, 30, 1);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    &:deep(.a-glyph) {
        fill: rgba(124, 155, 30, 1);
    }
}

.session-title,
.description {
    @mixin paragraph-accent;
    color: var(--av-fixed-primary);
    margin-bottom: 8px;
}

.description {
    color: var(--av-fixed-primary);
}

.description {
    margin-bottom: 16px;
}

.description-paragraph {
    @mixin paragraph;
    margin-bottom: 16px;
}

.description-list {
    margin-bottom: 16px;

    &:is(ol) {
        list-style-type: decimal;
        padding-inline-start: 16px;

        @media (--viewport-mobile-wide) {
            padding-inline-start: 32px;
        }

        .post-body__list-item {
            padding-inline-start: 3px;
        }
    }

    &:is(ul) {
        .description-list-item {
            margin-bottom: 16px;

            &::before {
                content: '';
                display: inline-block;
                height: 3px;
                width: 3px;
                background: var(--av-fixed-secondary);
                margin: 0 10px 0 6px;
                position: relative;
                top: -3px;
            }
        }
    }
}

.labels {
    margin: 0 0 32px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.label {
    @mixin note;
    padding: 0 8px;
    border-radius: 4px;
    margin-inline-end: 8px;
    text-transform: uppercase;
    color: var(--av-nav-secondary);
    border: 1px solid var(--av-nav-secondary);
    margin-bottom: 8px;
}

.training-label {
    display: inline-block;
    margin: 0 0 8px;
}
</style>

<style lang="postcss">
.event-agenda {
    .event-tab-control {
        .label {
            @media (--viewport-tablet) {
                @mixin title-accent;
            }
        }
        .header {
            margin-bottom: 16px;
        }
    }
}
</style>
