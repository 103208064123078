<template>
    <div class="number-wrapper">
        <div class="number-inner-wrapper">
            <div class="number-input" :class="{ disabled, focused }">
                <button
                    :class="{disabled: disabled || value === min}"
                    class="decrement"
                    tabindex="-1"
                    @mousedown="mousedown(-1)"
                    @mouseup="mouseup()"
                    @mouseout="mouseup()"
                >
                    <a-glyph name="minus" />
                </button>
                <input
                    ref="input"
                    v-model="value"
                    type="number"
                    :step="step"
                    :max="max"
                    :min="min"
                    :disabled="disabled"
                    tabindex="0"
                    @change="onInput()"
                    @focus="onFocus()"
                    @blur="onBlur()"
                    @keydown.space.prevent
                    @keyup="debounceUpdate"
                />
                <button
                    class="increment"
                    :class="{disabled: disabled || value === max}"
                    tabindex="-1"
                    @mousedown="mousedown(1)"
                    @mouseup="mouseup()"
                    @mouseout="mouseup()"
                >
                    <a-glyph name="plus" />
                </button>
            </div>
            <div class="label" :class="{ required }">
                <div class="title">
                    <span>{{ field?.label }}</span>
                </div>
            </div>
        </div>
        <template v-if="field.features">
            <ul class="features">
                <li v-for="feature in field.features" :key="feature" class="item">
                    {{ feature }}
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ANumberInput',
    components: {
        AGlyph,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            default: 1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 9999,
        },
    },
    emits: ['change'],
    data() {
        return {
            value: 0,
            interval: null,
            focused: false,
            timer: null,
            focusTimer: null,
        };
    },
    watch: {
        value() {
            this.focused = true;

            if (this.value === null) return;
            clearTimeout(this.focusTimer);
            this.focusTimer = setTimeout(() => {
                this.focused = false;
            }, 200);
        },
        max(newValue) {
            if (this.value < newValue) return;
            this.value = newValue;
            this.$emit('change', this.value);
        },
    },
    methods: {
        async debounceUpdate() {
            if (!this.value) {
                this.onInput();
                await this.$nextTick();
                this.$refs.input.select();
                return;
            }

            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.onInput();
            }, 500);
        },
        onFocus() {
            this.focused = true;
            if (this.value) {
                this.$refs.input.select();
                return;
            }

            this.value = null;
        },
        onBlur() {
            this.focused = false;
            if (this.value !== null) return;
            this.value = 0;
        },
        mousedown(type) {
            if (this.disabled) return;
            const action = type === 1 ? this.increment : this.decrement;

            action();
            this.interval = setInterval(action, 120);
        },
        mouseup() {
            clearInterval(this.interval);
        },
        increment() {
            if (this.disabled) return false;
            if (this.value >= this.max) {
                return this.resetValue(this.max);
            }

            this.value++;
            return this.emit();
        },
        decrement() {
            if (this.disabled) return false;
            if (this.value <= this.min) {
                return this.resetValue(this.min);
            }

            this.value--;
            return this.emit();
        },
        onInput() {
            if (this.disabled) return false;
            const value = parseInt(this.value, 10);

            if (!value || typeof value !== 'number') {
                return this.resetValue(0);
            }

            if (this.isOutOfRange(value)) {
                this.value = value >= this.max ? this.max : this.min;
            } else {
                this.value = value;
            }

            return this.emit();
        },
        isOutOfRange(value) {
            return value >= this.max || value <= this.min;
        },
        resetValue(to) {
            this.value = to;
            return this.emit();
        },
        emit() {
            if (this.disabled) return false;
            return this.$emit('change', this.value);
        },
    },
};
</script>

<style lang="postcss" scoped>
.number-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    .number-inner-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        user-select: none;
        gap: 8px;
        @media (--viewport-tablet) {
            min-width: 40%;
        }
        @media (--viewport-desktop) {
            min-width: 30%;
        }
        @media (--viewport-desktop-wide) {
            min-width: 50%;
            gap: 16px;
            flex-flow: row nowrap;
        }

        .number-input {
            order: 1;
            display: inline-flex;
            border: 1px solid rgba(64, 139, 234, 0.3);
            border-radius: 4px;
            height:30px;
            @media (--viewport-desktop-wide) {
                order: 0;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input {
                background: var(--av-inversed-primary);
                color: var(--av-fixed-secondary);
                width: 40px;
                text-align:center;
                -moz-appearance: textfield;
            }
            .decrement {
                border-inline-end: 1px solid;
            }
            .increment {
                border-inline-start: 1px solid;
            }
            .decrement, .increment  {
                display:flex;
                align-items:center;
                justify-content: center;
                cursor: pointer;
                border-color: var(--av-brand-secondary-light);
                width:30px;
                position: relative;
                &:before {
                    top: 0;
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
                &:deep(.a-glyph) {
                    max-width: 14px;
                    max-height: 14px;
                    fill: var(--av-brand-primary);
                }
                &:hover {
                    background: var(--av-brand-secondary-bright-hover);
                }
                &:active {
                    background: var(--av-brand-secondary-bright-active);
                }
                &.disabled {
                    pointer-events: none;
                    background: var(--av-brand-lightest);
                    border-color: var(--av-brand-secondary-accent);
                    &:deep(.a-glyph) {
                        fill: var(--av-brand-secondary-light);
                    }
                }
            }
            &.disabled {
                border: 1px solid var(--av-brand-secondary-accent);
                input {
                    background: var(--av-brand-lightest);
                    color: var(--av-fixed-lighter);
                }
            }
            &.focused {
                border: 1px solid var(--av-brand-primary);
            }
        }
        .label {
            width: 100%;
            display: block;
            order: 0;
            @media (--viewport-desktop-wide) {
                order: 1;
                width: auto;
            }
            .title {
                @mixin body;
                user-select: none;
                color: var(--av-fixed-secondary);
                span {
                    margin-inline-end: 8px;
                }
            }
            .required {
                margin-top: 8px;
                line-height:0;
                display: inline-block;
                color: var(--av-fixed-danger);
                margin-inline-start: 0;
            }
            &.required {
                .title span {
                    margin-inline-end: 5px;
                }
                .required {
                    margin-inline-end: 8px;
                }
            }
        }
    }
    .features {
        min-width: 100%;
        @mixin caption;
        list-style-type: square;
        padding-inline-start: 18px;
        list-style-position: outside;
        color: var(--av-fixed-secondary);
        margin-top: 16px;
        @media (--viewport-tablet) {
            margin-top: 0;
            min-width: 60%;
        }
        @media (--viewport-desktop) {
            min-width: 70%;
        }
        @media (--viewport-desktop-wide) {
            min-width: 50%;
        }
    }
}
</style>
