/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-header-partners {
    width: 100%;
    height: 800px;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 51px;
    padding-bottom: 24px;
}

.s-header-partners.theme-light {
        background-color: var(--av-solid-brand-accent);
    }

.s-header-partners.theme-light .s-header-partners__product {
            color: var(--av-nav-primary);
        }

.s-header-partners.theme-dark {
        background-color: var(--av-nav-primary);
        color: var(--av-inversed-primary);
    }

.s-header-partners__info {
        grid-row-start: 4;
        -ms-grid-row: 4;
        width: 100%;
    }

.s-header-partners__top {
        width: 50%;
        -ms-grid-row: 1;
    }

@media (min-width: 576px) {

.s-header-partners__top {
            width: 100%
    }
        }

.s-header-partners__links {
        -ms-grid-row: 2;
    }

.s-header-partners__content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows:
            minmax(min-content, 400px) minmax(100px, max-content)
            24px 1fr;
        -ms-grid-columns: 100%;
        -ms-grid-rows: minmax(min-content, 400px) minmax(100px, max-content) 24px 1fr;
        height: 100%;
    }

.s-header-partners__product {
        font-size: 40px;
        line-height: 48px;
        margin: 16px 0;
        font-weight: 300;
    }

@media (min-width: 1280px) {

.s-header-partners__product {
            font-size: 48px;
            line-height: 56px;
            font-weight: 300
    }
        }

.s-header-partners__subname {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

.s-header-partners__label-flag {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

.s-header-partners__info-flag {
        font-weight: 600;
        color: var(--av-inversed-light);
    }

.s-header-partners__price {
        font-size: 24px;
        line-height: 32px;
    }

@media (min-width: 768px) {

.s-header-partners__price {
            font-size: 32px;
            line-height: 40px;
    }
        }

.s-header-partners__price {

        margin-bottom: 24px;
}

.s-header-partners__block:first-child {
            margin-bottom: 40px;
        }

.s-header-partners__title {
        font-size: 24px;
        line-height: 32px;

        margin-bottom: 48px;
    }

@media (min-width: 1024px) {

.s-header-partners__title {
            font-size: 32px;
            line-height: 40px
    }
        }

@media (min-width: 1280px) {

.s-header-partners__title {
            font-size: 40px;
            line-height: 48px
    }
        }

.s-header-partners__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

.s-header-partners__link_call {
            color: var(--av-inversed-primary);
        }

.s-header-partners__update-text {
        align-self: flex-end;
        font-weight: 600;
        color: var(--av-fixed-success);
    }

.s-header-partners__buttons {
        margin-bottom: 24px;
    }

.s-header-partners__button-wrapper {
        position: relative;
    }

.s-header-partners__button-wrapper:not(:first-child) {
            margin-top: 16px;
        }

.s-header-partners__button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

.s-header-partners__button .a-glyph {
            display: none;
        }

.s-header-partners__button-disclaimer {
        font-size: 12px;
        line-height: 16px;

        display: none;
        position: absolute;
        width: 100%;
        margin-top: 8px;
        text-align: center;
        color: var(--av-inversed-light);
    }

@media (min-width: 576px) {
        .s-header-partners__buttons {
            display: flex;
        }

        .s-header-partners__button-wrapper {
            width: calc(50% - 8px);
            min-width: 164px;
        }

            .s-header-partners__button-wrapper:not(:first-child) {
                margin-inline-start: 16px;
                margin-top: 0;
            }

        .s-header-partners__button {
            display: inline-flex;
            justify-content: space-between;
        }

            .s-header-partners__button:deep(.a-glyph) {
                display: block;
            }
    }

@media (min-width: 768px) {

.s-header-partners {
        height: 686px;
        padding-top: 67px;
        padding-bottom: 40px
}

        .s-header-partners__content {
            grid-template-rows:
                minmax(min-content, 350px) minmax(100px, max-content)
                24px 1fr;
            -ms-grid-rows: minmax(min-content, 350px) minmax(100px, max-content) 24px 1fr;
        }

        .s-header-partners__bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

            .s-header-partners__bottom:first-child {
                margin-bottom: 40px;
            }

        .s-header-partners__block {
            display: flex;
        }

            .s-header-partners__block:first-child {
                align-items: flex-end;
                margin-bottom: 24px;
            }

        .s-header-partners__price,
        .s-header-partners__links {
            flex-grow: 0;
            flex-shrink: 0;
            padding-inline-end: 16px;
        }

        .s-header-partners__price {
            margin-bottom: 0;
        }

        .s-header-partners__links {
            display: block;
        }

        .s-header-partners__link-item:not(:last-child) {
            margin-bottom: 16px;
        }

        .s-header-partners__buttons {
            margin-bottom: 16px;
        }

        .s-header-partners__button-wrapper {
            width: auto;
        }

        .s-header-partners__button {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

@media (min-width: 1024px) {

.s-header-partners {
        height: 600px;
        padding-top: 72px;
        padding-bottom: 40px
}

        .s-header-partners__content {
            grid-template-columns: 4fr 8fr;
            -ms-grid-columns: 4fr 8fr;
            grid-template-rows:
                minmax(min-content, 400px) minmax(100px, max-content)
                24px 1fr;
            -ms-grid-rows: minmax(min-content, 400px) minmax(100px, max-content) 24px 1fr;
        }

            .s-header-partners__content .s-header-partners__links {
                grid-row-start: 2;
                -ms-grid-row: 2;
            }

            .s-header-partners__content .s-header-partners__update-text {
                grid-row-start: 2;
                -ms-grid-row: 2;
                align-self: flex-start;
                margin-top: 16px;
            }

        .s-header-partners__links {
            -ms-grid-row: 2;
            padding-top: 32px;
        }

        .s-header-partners__card-wrapper {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        .s-header-partners__info {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        .s-header-partners__actions {
            display: flex;
        }
            .s-header-partners__block:last-child {
                align-items: center;
            }

        .s-header-partners__button-wrapper {
            min-width: 147px;
        }

        .s-header-partners__buttons {
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        .s-header-partners__button-disclaimer {
            display: block;
        }
    }

@media (min-width: 1280px) {
        .s-header-partners__product {
            max-width: 373px;
        }

        .s-header-partners__update-text {
            align-self: flex-end;
        }

        .s-header-partners__button-wrapper {
            width: 180px;
        }
    }

@media (min-width: 1440px) {
        .s-header-partners__product {
            max-width: 426px;
        }

        .s-header-partners__button-wrapper {
            width: 205px;
        }
    }
