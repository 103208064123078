<template>
    <div class="number-wrapper">
        <div class="number-input" :class="{ disabled, focused }">
            <button
                :class="{disabled: disabled || value === min}"
                class="decrement"
                @mousedown="mousedown(-1)"
                @mouseup="mouseup()"
                @mouseout="mouseup()"
            >
                <a-glyph name="minus" />
            </button>
            <input
                ref="input"
                v-model="value"
                type="number"
                :step="step"
                :max="max"
                :min="min"
                :disabled="disabled"
                @change="onInput()"
                @focus="onFocus()"
                @blur="onBlur()"
                @keydown.space.prevent
                @keyup="debounceUpdate"
            />
            <button
                class="increment"
                :class="{disabled: disabled || value === max}"
                @mousedown="mousedown(1)"
                @mouseup="mouseup()"
                @mouseout="mouseup()"
            >
                <a-glyph name="plus" />
            </button>
        </div>
        <div v-if="label" class="label">
            <div class="title">
                <span>{{ label }}</span>
                <a-tooltip v-if="tooltip" glyph="info-circle" :text="tooltip" />
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';

export default {
    name: 'ANumberInput',
    components: {
        AGlyph,
        ATooltip,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        step: {
            type: Number,
            default: 1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 9999,
        },
        quantity: {
            type: Number,
            default: 0,
        },
    },
    emits: ['change'],
    data() {
        return {
            value: this.min,
            stepDirection: 0,
            interval: null,
            focused: false,
            timer: null,
            focusTimer: null,
        };
    },
    watch: {
        quantity(value) {
            this.value = value;
        },
        value() {
            this.focused = true;

            if (this.value === null) return;
            clearTimeout(this.focusTimer);
            this.focusTimer = setTimeout(() => {
                this.focused = false;
            }, 200);
        },
    },
    methods: {
        async debounceUpdate() {
            if (!this.value) {
                this.onInput();
                await this.$nextTick();
                this.$refs.input.select();
                return;
            }
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.onInput();
            }, 500);
        },
        onFocus() {
            this.focused = true;
            if (this.value) {
                this.$refs.input.select();
                return;
            }

            this.value = null;
        },
        onBlur() {
            this.focused = false;
            if (this.value !== null) return;
            this.value = 0;
        },
        mousedown(type) {
            if (this.disabled) return;
            const action = type === 1 ? this.increment : this.decrement;

            action();
            this.interval = setInterval(action, 120);
        },
        mouseup() {
            clearInterval(this.interval);
        },
        increment() {
            if (this.disabled) return false;
            const newValue = this.alignToStep(this.value + this.step);

            if (newValue > this.max) {
                return this.resetValue(this.max);
            }

            this.value = newValue;
            return this.emit();
        },

        decrement() {
            if (this.disabled) return false;
            const newValue = this.alignToStep(this.value - this.step);

            if (newValue < this.min) {
                return this.resetValue(this.min);
            }

            this.value = newValue;
            return this.emit();
        },
        onInput() {
            if (this.disabled) return false;
            let value = parseInt(this.value, 10);

            if (!value || typeof value !== 'number') {
                return this.resetValue(0);
            }

            value = this.alignToStep(value);
            if (value > this.max) {
                value = this.max;
            } else if (value < this.min) {
                value = this.min;
            }

            this.value = value;
            return this.emit();
        },
        alignToStep(value) {
            const aligned = Math.round(value / this.step) * this.step;
            return Math.max(this.min, Math.min(aligned, this.max));
        },
        resetValue(to = this.min) {
            this.value = to;
            return this.emit();
        },
        emit() {
            if (this.disabled) return;
            this.$emit('change', this.value);
        },

    },
};
</script>

<style lang="postcss" scoped>
.number-wrapper {
    display: inline-flex;
    width: auto;
    flex-flow: row wrap;
    align-items: flex-start;
    user-select: none;
    gap: 8px;
    @media (--viewport-desktop-wide) {
        gap: 24px;
        flex-flow: row nowrap;
    }

    .number-input {
        order: 1;
        display: inline-flex;
        border: 1px solid rgba(64, 139, 234, 0.3);
        border-radius: 4px;
        height:30px;
        @media (--viewport-desktop-wide) {
            order: 0;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input {
            background: var(--av-inversed-primary);
            color: var(--av-fixed-secondary);
            width: 64px;
            text-align:center;
            -moz-appearance: textfield;
        }
        .decrement {
            border-inline-end: 1px solid;
        }
        .increment {
            border-inline-start: 1px solid;
        }
        .decrement, .increment  {
            display:flex;
            align-items:center;
            justify-content: center;
            cursor: pointer;
            border-color: var(--av-brand-secondary-light);
            width:30px;
            position: relative;
            &:before {
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
            }
            &:deep(.a-glyph) {
                max-width: 14px;
                max-height: 14px;
                fill: var(--av-brand-primary);
            }
            &:hover {
                background: var(--av-brand-secondary-bright-hover);
            }
            &:active {
                background: var(--av-brand-secondary-bright-active);
            }
            &.disabled {
                background: var(--av-brand-lightest);
                border-color: var(--av-brand-secondary-accent);
                &:deep(.a-glyph) {
                    fill: var(--av-brand-secondary-light);
                }
            }
        }
        &.disabled {
            background: var(--av-brand-lightest);
            border: 1px solid var(--av-brand-secondary-accent);
            input {
                background: var(--av-brand-lightest);
                color: var(--av-fixed-lighter);
            }
        }
        &.focused {
            border: 1px solid var(--av-brand-primary);
        }
    }
    .label {
        width: 100%;
        display: block;
        order: 0;
        @media (--viewport-desktop-wide) {
            order: 1;
            width: auto;
        }
        .title {
            @mixin paragraph;
            line-height: 32px;
            user-select: none;
            color: var(--av-fixed-secondary);
        }
    }
}
</style>
