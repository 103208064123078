<script lang="ts">
import Vue from 'vue';
import HeaderSimple from '@core/slices/pages/header-simple/header-simple.vue';
import Tabs from '@core/slices/pages/tabs/tabs.vue';

export default {
    name: 'DemoLibrary',

    components: {
        HeaderSimple,
        Tabs,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
    },

    data: () => ({}),

    computed: {
        tabsSettings() {
            return {
                type: 'tile',
                useSmallTabs: false,
                sliderOptions: {
                    speed: 300,
                },
                slices: this.tabs,
            };
        },
    },

    created() {
        const slices = new Set(this.tabs.map((tab) => tab.name));
        slices.forEach((systemName) => {
            let diskName = systemName;
            if (diskName.startsWith('s-')) diskName = diskName.slice(2);
            Vue.component(systemName, () => import(`@core/slices/pages/${diskName}/${diskName}.vue`));
        });
    },
};
</script>

<template>
    <div class="demo-library">
        <header-simple id="demo-library-header" :title="title" />
        <tabs id="demo-library-tabs" v-bind="tabsSettings" />
    </div>
</template>

<style lang="postcss" scoped>
.demo-library {
    background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0.00) 100%);
}

.s-tabs {
    margin-top: 48px;
}
</style>
