/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.s-resource-highlight {
    padding-top: 48px;
    padding-bottom: 48px;
    overflow-x: hidden;
}

.s-resource-highlight__header {
        margin-bottom: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

.s-resource-highlight__header__label {
            font-size: 16px;
            line-height: 24px;
            color: var(--av-fixed-light);
            flex-basis: 100%;
            margin-bottom: 8px;
        }

@media (min-width: 1024px) {

.s-resource-highlight__header__label {
                width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
        }
            }

@media (min-width: 1024px) {

.s-resource-highlight__header__content {
                width: calc(((100% - (16px * 11)) / 12) * 8 + (16px * (8 - 1)))
        }
            }

.s-resource-highlight__header__content__title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 24px;
                color: var(--av-nav-primary);
            }

@media (min-width: 576px) {

.s-resource-highlight__header__content__title {
                    font-size: 40px;
                    line-height: 48px
            }
                }

.s-resource-highlight__header__content__lead {
                font-size: 18px;
                line-height: 24px;
                color: var(--av-nav-primary);
            }

@media (min-width: 576px) {

.s-resource-highlight__header__content__lead {
                    font-size: 24px;
                    line-height: 32px
            }
                }

.s-resource-highlight__header__link {
            text-align: end;
        }

.s-resource-highlight__header__link .a-link {
                margin-top: 8px;
            }

.s-resource-highlight__header__link {

            display: none;
}

@media (min-width: 1024px) {

.s-resource-highlight__header__link {
                display: block;
                margin-inline-start: auto;
                width: calc(((100% - (16px * 11)) / 12) * 4 + (16px * (4 - 1)))
        }
            }

@media (min-width: 1280px) {

.s-resource-highlight__header__link {
                width: calc(((100% - (16px * 11)) / 12) * 3 + (16px * (3 - 1)))
        }
            }

.s-resource-highlight_cards-list,
    .s-resource-highlight_single-card {
        overflow-x: visible;
    }

.s-resource-highlight_cards-list .s-resource-highlight__content, .s-resource-highlight_single-card .s-resource-highlight__content {
            margin: 0;
        }

.s-resource-highlight__list .a-resource-highlight-card:not(:first-child) {
                margin-top: 16px;
            }

.s-resource-highlight__content:deep(.a-slider__nav) {
            margin-top: 48px;
            margin-bottom: 0;
        }

.s-resource-highlight__card.single {
        width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
    }

@media (min-width: 768px) {

.s-resource-highlight__card.single {
            width: calc(((100% - (16px * 11)) / 12) * 10 + (16px * (10 - 1)))
    }
        }

.s-resource-highlight__card {
        height: 100%;
    }

.s-resource-highlight:deep(.a-slider) {
        overflow: hidden;
    }

@media (min-width: 768px) {

.s-resource-highlight:deep(.a-slider) {
            overflow: visible
    }
        }

@media (min-width: 1440px) {

.s-resource-highlight:deep(.a-slider) {
            overflow: hidden

            /*
            .a-slide_next {
                .s-resource-highlight__card {
                    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6), transparent);
                }
            }
            */
    }
        }

.s-resource-highlight:deep(.a-slider) .a-slide {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
        }

@media (min-width: 768px) {

.s-resource-highlight:deep(.a-slider) .a-slide {
                width: calc(((100% - (16px * 11)) / 12) * 10 + (16px * (10 - 1)));
                margin-inline-end: 16px
        }
            }

.s-resource-highlight:deep(.a-slider) .a-slide:last-child {
                width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                margin-inline-end: 0;
            }

.s-resource-highlight:deep(.a-slider) .a-slide:last-child .s-resource-highlight__card {
                    width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
                }

@media (min-width: 768px) {

.s-resource-highlight:deep(.a-slider) .a-slide:last-child .s-resource-highlight__card {
                        width: calc(((100% - (16px * 11)) / 12) * 10 + (16px * (10 - 1)))
                }
                    }

.s-resource-highlight:deep(.a-slider) .a-slide_next {
                opacity: 0.5;
                position: relative;
            }

.s-resource-highlight:deep(.a-slider) .a-slide.swiper-slide-prev {
                opacity: 0;
            }

.s-resource-highlight:deep(.a-slider)__counter-total,
            .s-resource-highlight:deep(.a-slider)__counter-text,
            .s-resource-highlight:deep(.a-slider)__counter-current {
                font-size: 18px;
                line-height: 24px;
                color: var(--av-fixed-light);
            }

.s-resource-highlight:deep(.a-slider)_numbers_off .a-slider__dots {
                display: none;
            }

.s-resource-highlight:deep(.a-slider__nav) {
        margin-inline-start: 8px;
    }

@media (min-width: 1024px) {

.s-resource-highlight__mobile__link {
            display: none
    }
        }

.s-resource-highlight__show-more .a-link, .s-resource-highlight__show-less .a-link, .s-resource-highlight__mobile__link .a-link {
            cursor: pointer;
            margin-top: 48px;
        }

.s-resource-highlight__show-more .a-link:focus, .s-resource-highlight__show-less .a-link:focus, .s-resource-highlight__mobile__link .a-link:focus {
                background: none;
            }

.s-resource-highlight__show-less:deep(.a-glyph) {
            transform: rotate(180deg);
        }

.s-side-menu .a-container .s-resource-highlight {
            padding-bottom: 0;
            padding-top: 56px;
        }

@media (min-width: 1024px) {
                .s-side-menu .a-container .s-resource-highlight:not(.s-side-menu .a-container .s-resource-highlight:first-child) {
                    padding: 0;
                    margin: 104px 0 0;
                }
            }

.s-side-menu .a-container .s-resource-highlight:deep(.a-slice-header) {
                margin: 0;
            }

@media (min-width: 1024px) {

.s-side-menu .a-container .s-resource-highlight:deep(.a-slice-header) {
                    margin: 0 0 40px
            }
                }

.s-side-menu .a-container .s-resource-highlight:deep(.a-slice-header)__title {
                    display: none;
                }

@media (min-width: 1024px) {

.s-side-menu .a-container .s-resource-highlight:deep(.a-slice-header)__title {
                        display: block
                }
                    }

.s-side-menu .a-container .a-resource-highlight-card__footer {
                display: block;
            }

.s-side-menu .a-container .a-resource-highlight-card__company {
                margin-bottom: 24px;
            }

@media (min-width: 1440px) {
                .s-side-menu .a-container .a-resource-highlight-card__footer {
                    display: flex;
                }

                .s-side-menu .a-container .a-resource-highlight-card__company {
                    margin-bottom: 0;
                }
            }
