<template>
    <section v-if="hasContent" :id="id" :class="className">
        <div class="a-container">
            <div class="wrapper">
                <a-picture v-if="l10n.picture" v-bind="l10n.picture" />
                <div class="content" :style="minHeight">
                    <component :is="titleTag" v-if="l10n.title" class="title">
                        {{ l10n.title }}
                    </component>
                    <p v-if="l10n.lead" class="lead" v-html="l10n.lead" />
                    <p v-if="l10n.bodyText" class="body-text" v-html="l10n.bodyText" />
                    <p v-if="hasButtons" class="links">
                        <a-button
                            v-for="(item, itemIndex) in listButtons"
                            v-bind="item"
                            :key="`button-${itemIndex}`"
                        />
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import { COLORS } from '@core/slices/pages/basic-slice/constants.js';

export default {
    name: 'SCtaWithImage',
    components: {
        AButton,
        APicture,
    },
    mixins: [breakpoint, contentMixin, styleMixin, l10n],
    props: {
        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },
        background: {
            type: String,
            validator: (value) => value === undefined || COLORS.includes(value),
            default: undefined,
        },
        picture: {
            type: Object,
            default: null,
        },
        titleTag: {
            type: String,
            default: 'h2',
        },
        buttons: {
            type: Array,
            default: null,
        },
        contentMinHeight: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            return {
                ...this.basicClassName,
                's-slice': true,
                's-cta-with-image': true,
                's-cta-with-image-dark': this.theme === 'dark',
            };
        },
        minHeight() {
            return { height: this.contentMinHeight?.[this.currentBreakpoint] || 'auto' };
        },
        hasContent() {
            return this.l10n.title || this.l10n.lead || this.l10n.bodyText;
        },
        hasButtons() {
            return this.l10n?.buttons?.length;
        },
        listButtons() {
            return this.l10n.buttons
                .filter((item) => item.text)
                .map((item) => ({ ...item, type: item.type || 'main' }));
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-with-image {
    .wrapper {
        overflow: hidden;
        background: #fff;
        border-radius: 4px;
        border: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-desktop) {
            @mixin colls 10;
            margin-inline: auto;
        }
        @media (--viewport-desktop-wide) {
            width: 100%;
            gap: 16px;
            display: flex;
            flex-direction: row-reverse;
        }
    }
    .content {
        text-align: center;
        padding: 24px 16px 40px;
        @media (--viewport-mobile-wide) {
            margin: 0 auto;
            max-width: 576px;
            padding: 24px 0 40px;
        }
        @media (--viewport-desktop) {
            max-width: 638px;
        }
        @media (--viewport-desktop-wide) {
            max-width: unset;
            width: 50%;
            display: flex;
            padding: 40px;
            text-align: start;
            flex-direction: column;
            padding-inline-end: 0;
        }
    }
    &:deep(.a-picture) {
        height: 190px;
        @media (--viewport-tablet) {
            height: 240px;
        }
        @media (--viewport-desktop-wide) {
            width: 50%;
            height: auto;
        }
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin display-accent;
        }
        @media (--viewport-desktop-wide) {
            @mixin hero-accent;
        }
    }
    .lead {
        @mixin lead;
        margin-top: 24px;
        @media (--viewport-desktop-wide) {
            @mixin nav-title-accent;
            font-weight: 400;
        }
        @media (--viewport-desktop-large) {
            @mixin title;
        }
    }
    .body-text {
        @mixin paragraph;
        margin-top: 24px;
        color: var(--av-fixed-light);
        @media (--viewport-tablet) {
            @mixin lead;
        }
        @media (--viewport-desktop-wide) {
            @mixin paragraph;
        }
    }
    .links {
        gap: 16px;
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        @media (--viewport-desktop-wide) {
            margin-top: auto;
            padding-top: 40px;
            flex-direction: row;
        }
        &:deep(.a-button) {
            min-width: 168px;
        }
    }
}
.s-cta-with-image-dark {
    .wrapper {
        background-color: var(--av-nav-primary);
    }
    .title,
    .lead,
    .body-text {
        color: var(--av-inversed-primary);
    }
}
</style>
